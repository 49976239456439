import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _a1797608 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _19dfb6ea = () => interopDefault(import('../pages/callback.vue' /* webpackChunkName: "pages/callback" */))
const _4c8fad5e = () => interopDefault(import('../pages/mobile-apps-download.vue' /* webpackChunkName: "pages/mobile-apps-download" */))
const _55395da5 = () => interopDefault(import('../pages/redirect/index.vue' /* webpackChunkName: "pages/redirect/index" */))
const _c9b264cc = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _55b147c2 = () => interopDefault(import('../pages/account/index/bind.vue' /* webpackChunkName: "pages/account/index/bind" */))
const _574a1c2b = () => interopDefault(import('../pages/account/index/contact.vue' /* webpackChunkName: "pages/account/index/contact" */))
const _51d988dc = () => interopDefault(import('../pages/account/index/order/index.vue' /* webpackChunkName: "pages/account/index/order/index" */))
const _b78a0b42 = () => interopDefault(import('../pages/account/index/order/index/index.vue' /* webpackChunkName: "pages/account/index/order/index/index" */))
const _4e9beef2 = () => interopDefault(import('../pages/account/index/order/index/_id.vue' /* webpackChunkName: "pages/account/index/order/index/_id" */))
const _703c2236 = () => interopDefault(import('../pages/account/index/passenger.vue' /* webpackChunkName: "pages/account/index/passenger" */))
const _6a3b00a0 = () => interopDefault(import('../pages/account/index/password.vue' /* webpackChunkName: "pages/account/index/password" */))
const _5f719534 = () => interopDefault(import('../pages/account/index/profile.vue' /* webpackChunkName: "pages/account/index/profile" */))
const _4754ee1d = () => interopDefault(import('../pages/account/index/review.vue' /* webpackChunkName: "pages/account/index/review" */))
const _bd27bb8c = () => interopDefault(import('../pages/app-feedback/index.vue' /* webpackChunkName: "pages/app-feedback/index" */))
const _5571e029 = () => interopDefault(import('../pages/choose-country/index.vue' /* webpackChunkName: "pages/choose-country/index" */))
const _3c3d88b6 = () => interopDefault(import('../pages/confirmemail/index.vue' /* webpackChunkName: "pages/confirmemail/index" */))
const _1e34d3cd = () => interopDefault(import('../pages/contact/index.vue' /* webpackChunkName: "pages/contact/index" */))
const _4e6e0f37 = () => interopDefault(import('../pages/covid19.vue' /* webpackChunkName: "pages/covid19" */))
const _0ef4e8f4 = () => interopDefault(import('../pages/deals/index.vue' /* webpackChunkName: "pages/deals/index" */))
const _404701ca = () => interopDefault(import('../pages/help/index.vue' /* webpackChunkName: "pages/help/index" */))
const _0119f9be = () => interopDefault(import('../pages/information-ja.vue' /* webpackChunkName: "pages/information-ja" */))
const _47ffd456 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _646e91fb = () => interopDefault(import('../pages/order/index.vue' /* webpackChunkName: "pages/order/index" */))
const _3306703e = () => interopDefault(import('../pages/order/index/index.vue' /* webpackChunkName: "pages/order/index/index" */))
const _36dd87ae = () => interopDefault(import('../pages/order/index/_id/index.vue' /* webpackChunkName: "pages/order/index/_id/index" */))
const _fb3d235c = () => interopDefault(import('../pages/pay.vue' /* webpackChunkName: "pages/pay" */))
const _5a973a99 = () => interopDefault(import('../pages/pay/_id/choose.vue' /* webpackChunkName: "pages/pay/_id/choose" */))
const _2b4cda37 = () => interopDefault(import('../pages/pay/_id/confirmation.vue' /* webpackChunkName: "pages/pay/_id/confirmation" */))
const _7e9637b0 = () => interopDefault(import('../pages/payment.vue' /* webpackChunkName: "pages/payment" */))
const _7814eb0a = () => interopDefault(import('../pages/payment/_id/choose.vue' /* webpackChunkName: "pages/payment/_id/choose" */))
const _55b43899 = () => interopDefault(import('../pages/payment/_id/confirmation.vue' /* webpackChunkName: "pages/payment/_id/confirmation" */))
const _97af7734 = () => interopDefault(import('../pages/payment-instruction-cebuana/index.vue' /* webpackChunkName: "pages/payment-instruction-cebuana/index" */))
const _9f866188 = () => interopDefault(import('../pages/pricealert/index.vue' /* webpackChunkName: "pages/pricealert/index" */))
const _136b53dd = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _7cca567c = () => interopDefault(import('../pages/promo/index.vue' /* webpackChunkName: "pages/promo/index" */))
const _75091db0 = () => interopDefault(import('../pages/report-abadoned/index.vue' /* webpackChunkName: "pages/report-abadoned/index" */))
const _1d345abc = () => interopDefault(import('../pages/reset/index.vue' /* webpackChunkName: "pages/reset/index" */))
const _7f0aae05 = () => interopDefault(import('../pages/retrieve/index.vue' /* webpackChunkName: "pages/retrieve/index" */))
const _187de13e = () => interopDefault(import('../pages/signup/index.vue' /* webpackChunkName: "pages/signup/index" */))
const _6db09fa2 = () => interopDefault(import('../pages/cl/dl.php.vue' /* webpackChunkName: "pages/cl/dl.php" */))
const _10105327 = () => interopDefault(import('../pages/cl/maybankredirect.php.vue' /* webpackChunkName: "pages/cl/maybankredirect.php" */))
const _5b259d3a = () => interopDefault(import('../pages/cl/tngredirect2.php.vue' /* webpackChunkName: "pages/cl/tngredirect2.php" */))
const _dcb6d832 = () => interopDefault(import('../pages/flight/confirm.vue' /* webpackChunkName: "pages/flight/confirm" */))
const _44fa9bd1 = () => interopDefault(import('../pages/flight/search.vue' /* webpackChunkName: "pages/flight/search" */))
const _745810e0 = () => interopDefault(import('../pages/help/search.vue' /* webpackChunkName: "pages/help/search" */))
const _c09f3912 = () => interopDefault(import('../pages/hotel/pay.vue' /* webpackChunkName: "pages/hotel/pay" */))
const _bf0be758 = () => interopDefault(import('../pages/hotel/pay/_id/choose.vue' /* webpackChunkName: "pages/hotel/pay/_id/choose" */))
const _cb87989c = () => interopDefault(import('../pages/hotel/pay/_id/confirmation.vue' /* webpackChunkName: "pages/hotel/pay/_id/confirmation" */))
const _7927db56 = () => interopDefault(import('../pages/hotel/payment.vue' /* webpackChunkName: "pages/hotel/payment" */))
const _5759f294 = () => interopDefault(import('../pages/hotel/payment/_id/choose.vue' /* webpackChunkName: "pages/hotel/payment/_id/choose" */))
const _bbd746d8 = () => interopDefault(import('../pages/hotel/payment/_id/confirmation.vue' /* webpackChunkName: "pages/hotel/payment/_id/confirmation" */))
const _e7741eae = () => interopDefault(import('../pages/hotel/search.vue' /* webpackChunkName: "pages/hotel/search" */))
const _665d5706 = () => interopDefault(import('../pages/member/login/index.vue' /* webpackChunkName: "pages/member/login/index" */))
const _0436fe08 = () => interopDefault(import('../pages/newsletter/change-language/index.vue' /* webpackChunkName: "pages/newsletter/change-language/index" */))
const _309e9c41 = () => interopDefault(import('../pages/newsletter/choose-language/index.vue' /* webpackChunkName: "pages/newsletter/choose-language/index" */))
const _90612b9a = () => interopDefault(import('../pages/newsletter/confirm/index.vue' /* webpackChunkName: "pages/newsletter/confirm/index" */))
const _033fa104 = () => interopDefault(import('../pages/newsletter/unsubscribe/index.vue' /* webpackChunkName: "pages/newsletter/unsubscribe/index" */))
const _1ea443bb = () => interopDefault(import('../pages/reset/confirmation.vue' /* webpackChunkName: "pages/reset/confirmation" */))
const _4d88f93b = () => interopDefault(import('../pages/retrieve/list.vue' /* webpackChunkName: "pages/retrieve/list" */))
const _58766906 = () => interopDefault(import('../pages/signup/verify/index.vue' /* webpackChunkName: "pages/signup/verify/index" */))
const _21b34880 = () => interopDefault(import('../pages/terms/condition/index.vue' /* webpackChunkName: "pages/terms/condition/index" */))
const _8de6e8c6 = () => interopDefault(import('../pages/terms/uses/index.vue' /* webpackChunkName: "pages/terms/uses/index" */))
const _66d6c3d2 = () => interopDefault(import('../pages/aftersales/add-baggage/detail/_afsId/index.vue' /* webpackChunkName: "pages/aftersales/add-baggage/detail/_afsId/index" */))
const _6a71517e = () => interopDefault(import('../pages/aftersales/add-baggage/submission/_id/index.vue' /* webpackChunkName: "pages/aftersales/add-baggage/submission/_id/index" */))
const _2f02b848 = () => interopDefault(import('../pages/aftersales/cancellation/detail/_afsId/index.vue' /* webpackChunkName: "pages/aftersales/cancellation/detail/_afsId/index" */))
const _55c67d6d = () => interopDefault(import('../pages/aftersales/cancellation/submission/_id/index.vue' /* webpackChunkName: "pages/aftersales/cancellation/submission/_id/index" */))
const _377c5270 = () => interopDefault(import('../pages/aftersales/change-flight/detail/_afsId/index.vue' /* webpackChunkName: "pages/aftersales/change-flight/detail/_afsId/index" */))
const _5c802845 = () => interopDefault(import('../pages/aftersales/change-flight/submission/_id/index.vue' /* webpackChunkName: "pages/aftersales/change-flight/submission/_id/index" */))
const _77639f82 = () => interopDefault(import('../pages/aftersales/change-passenger/detail/_afsId/index.vue' /* webpackChunkName: "pages/aftersales/change-passenger/detail/_afsId/index" */))
const _19827d73 = () => interopDefault(import('../pages/aftersales/change-passenger/submission/_id/index.vue' /* webpackChunkName: "pages/aftersales/change-passenger/submission/_id/index" */))
const _dd6cbe18 = () => interopDefault(import('../pages/order/manage-hotel/cancel/_id/index.vue' /* webpackChunkName: "pages/order/manage-hotel/cancel/_id/index" */))
const _93aae60e = () => interopDefault(import('../pages/order/manage-hotel/change-data/_id/index.vue' /* webpackChunkName: "pages/order/manage-hotel/change-data/_id/index" */))
const _5ce5a050 = () => interopDefault(import('../pages/aftersales/cancellation/submission/_id/document.vue' /* webpackChunkName: "pages/aftersales/cancellation/submission/_id/document" */))
const _917af736 = () => interopDefault(import('../pages/aftersales/cancellation/submission/_id/flight.vue' /* webpackChunkName: "pages/aftersales/cancellation/submission/_id/flight" */))
const _f08396e6 = () => interopDefault(import('../pages/aftersales/change-flight/submission/_id/flight.vue' /* webpackChunkName: "pages/aftersales/change-flight/submission/_id/flight" */))
const _41788c76 = () => interopDefault(import('../pages/aftersales/change-flight/submission/_id/search.vue' /* webpackChunkName: "pages/aftersales/change-flight/submission/_id/search" */))
const _1e70909a = () => interopDefault(import('../pages/aftersales/cancellation/_id/index.vue' /* webpackChunkName: "pages/aftersales/cancellation/_id/index" */))
const _9df1a0dc = () => interopDefault(import('../pages/aftersales/detail/_afsId/index.vue' /* webpackChunkName: "pages/aftersales/detail/_afsId/index" */))
const _258d12a9 = () => interopDefault(import('../pages/flight/book/_bookid/index.vue' /* webpackChunkName: "pages/flight/book/_bookid/index" */))
const _1107e381 = () => interopDefault(import('../pages/hotel/book/_bookid/index.vue' /* webpackChunkName: "pages/hotel/book/_bookid/index" */))
const _b3f3d492 = () => interopDefault(import('../pages/hotel/review/_hash.vue' /* webpackChunkName: "pages/hotel/review/_hash" */))
const _654c3086 = () => interopDefault(import('../pages/order/summary/_id/index.vue' /* webpackChunkName: "pages/order/summary/_id/index" */))
const _ca6a8534 = () => interopDefault(import('../pages/promo/view/_slug.vue' /* webpackChunkName: "pages/promo/view/_slug" */))
const _5dd872c5 = () => interopDefault(import('../pages/reset/pass/_id/index.vue' /* webpackChunkName: "pages/reset/pass/_id/index" */))
const _3699bbfa = () => interopDefault(import('../pages/aftersales/cancellation/_id/document.vue' /* webpackChunkName: "pages/aftersales/cancellation/_id/document" */))
const _03dad6d8 = () => interopDefault(import('../pages/aftersales/cancellation/_id/flight.vue' /* webpackChunkName: "pages/aftersales/cancellation/_id/flight" */))
const _af70c79c = () => interopDefault(import('../pages/hotel/book/_bookid/detail.vue' /* webpackChunkName: "pages/hotel/book/_bookid/detail" */))
const _1d29f93c = () => interopDefault(import('../pages/hotel/book/_bookid/edit/contact.vue' /* webpackChunkName: "pages/hotel/book/_bookid/edit/contact" */))
const _7d3c37f4 = () => interopDefault(import('../pages/hotel/book/_bookid/edit/guest.vue' /* webpackChunkName: "pages/hotel/book/_bookid/edit/guest" */))
const _08382c2e = () => interopDefault(import('../pages/hotel/book/_bookid/edit/ssr.vue' /* webpackChunkName: "pages/hotel/book/_bookid/edit/ssr" */))
const _42c65424 = () => interopDefault(import('../pages/ตั๋วเครื่องบิน/_slug.vue' /* webpackChunkName: "pages/ตั๋วเครื่องบิน/_slug" */))
const _2d7c6c22 = () => interopDefault(import('../pages/机票/_slug.vue' /* webpackChunkName: "pages/机票/_slug" */))
const _1adecdaa = () => interopDefault(import('../pages/機票/_slug.vue' /* webpackChunkName: "pages/機票/_slug" */))
const _d68337e6 = () => interopDefault(import('../pages/航空券/_slug.vue' /* webpackChunkName: "pages/航空券/_slug" */))
const _07612fc9 = () => interopDefault(import('../pages/항공권/_slug.vue' /* webpackChunkName: "pages/항공권/_slug" */))
const _46aa325a = () => interopDefault(import('../pages/book/_id.vue' /* webpackChunkName: "pages/book/_id" */))
const _175dcd30 = () => interopDefault(import('../pages/deals/_country/index.vue' /* webpackChunkName: "pages/deals/_country/index" */))
const _4d7e2591 = () => interopDefault(import('../pages/help/_type.vue' /* webpackChunkName: "pages/help/_type" */))
const _07c27b54 = () => interopDefault(import('../pages/help/_type/index.vue' /* webpackChunkName: "pages/help/_type/index" */))
const _ef4e78d2 = () => interopDefault(import('../pages/help/_type/_section/index.vue' /* webpackChunkName: "pages/help/_type/_section/index" */))
const _81b62f58 = () => interopDefault(import('../pages/help/_type/_section/_case.vue' /* webpackChunkName: "pages/help/_type/_section/_case" */))
const _ccc511ca = () => interopDefault(import('../pages/mga-tiket-ng-flight/_slug.vue' /* webpackChunkName: "pages/mga-tiket-ng-flight/_slug" */))
const _2a4b11cd = () => interopDefault(import('../pages/paymentpartner/_trxId.vue' /* webpackChunkName: "pages/paymentpartner/_trxId" */))
const _7b13b534 = () => interopDefault(import('../pages/promo/_slug.vue' /* webpackChunkName: "pages/promo/_slug" */))
const _3b4884b7 = () => interopDefault(import('../pages/promo/_slug/index.vue' /* webpackChunkName: "pages/promo/_slug/index" */))
const _45ce48df = () => interopDefault(import('../pages/promo/_slug/_id.vue' /* webpackChunkName: "pages/promo/_slug/_id" */))
const _368c0b30 = () => interopDefault(import('../pages/redirect/dpl/_code.vue' /* webpackChunkName: "pages/redirect/dpl/_code" */))
const _166abf86 = () => interopDefault(import('../pages/deals/_country/_detail.vue' /* webpackChunkName: "pages/deals/_country/_detail" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/bg",
    component: _a1797608,
    name: "index___bg"
  }, {
    path: "/bn",
    component: _a1797608,
    name: "index___bn"
  }, {
    path: "/ca",
    component: _a1797608,
    name: "index___ca"
  }, {
    path: "/callback",
    component: _19dfb6ea,
    name: "callback"
  }, {
    path: "/cs",
    component: _a1797608,
    name: "index___cs"
  }, {
    path: "/da",
    component: _a1797608,
    name: "index___da"
  }, {
    path: "/de",
    component: _a1797608,
    name: "index___de"
  }, {
    path: "/el",
    component: _a1797608,
    name: "index___el"
  }, {
    path: "/en",
    component: _a1797608,
    name: "index___en"
  }, {
    path: "/es",
    component: _a1797608,
    name: "index___es"
  }, {
    path: "/fi",
    component: _a1797608,
    name: "index___fi"
  }, {
    path: "/fr",
    component: _a1797608,
    name: "index___fr"
  }, {
    path: "/hi",
    component: _a1797608,
    name: "index___hi"
  }, {
    path: "/hr",
    component: _a1797608,
    name: "index___hr"
  }, {
    path: "/hu",
    component: _a1797608,
    name: "index___hu"
  }, {
    path: "/id",
    component: _a1797608,
    name: "index___id"
  }, {
    path: "/it",
    component: _a1797608,
    name: "index___it"
  }, {
    path: "/ja",
    component: _a1797608,
    name: "index___ja"
  }, {
    path: "/km",
    component: _a1797608,
    name: "index___km"
  }, {
    path: "/ko",
    component: _a1797608,
    name: "index___ko"
  }, {
    path: "/mobile-apps-download",
    component: _4c8fad5e,
    name: "mobile-apps-download"
  }, {
    path: "/ms",
    component: _a1797608,
    name: "index___ms"
  }, {
    path: "/nb",
    component: _a1797608,
    name: "index___nb"
  }, {
    path: "/nl",
    component: _a1797608,
    name: "index___nl"
  }, {
    path: "/pl",
    component: _a1797608,
    name: "index___pl"
  }, {
    path: "/pt",
    component: _a1797608,
    name: "index___pt"
  }, {
    path: "/redirect",
    component: _55395da5,
    name: "redirect"
  }, {
    path: "/ro",
    component: _a1797608,
    name: "index___ro"
  }, {
    path: "/ru",
    component: _a1797608,
    name: "index___ru"
  }, {
    path: "/sk",
    component: _a1797608,
    name: "index___sk"
  }, {
    path: "/sl",
    component: _a1797608,
    name: "index___sl"
  }, {
    path: "/sr",
    component: _a1797608,
    name: "index___sr"
  }, {
    path: "/sv",
    component: _a1797608,
    name: "index___sv"
  }, {
    path: "/th",
    component: _a1797608,
    name: "index___th"
  }, {
    path: "/tl",
    component: _a1797608,
    name: "index___tl"
  }, {
    path: "/tr",
    component: _a1797608,
    name: "index___tr"
  }, {
    path: "/uk",
    component: _a1797608,
    name: "index___uk"
  }, {
    path: "/vi",
    component: _a1797608,
    name: "index___vi"
  }, {
    path: "/zh",
    component: _a1797608,
    name: "index___zh"
  }, {
    path: "/zh-tw",
    component: _a1797608,
    name: "index___zh-tw"
  }, {
    path: "/bg/account",
    component: _c9b264cc,
    name: "account___bg",
    children: [{
      path: "bind",
      component: _55b147c2,
      name: "account-index-bind___bg"
    }, {
      path: "contact",
      component: _574a1c2b,
      name: "account-index-contact___bg"
    }, {
      path: "order",
      component: _51d988dc,
      children: [{
        path: "",
        component: _b78a0b42,
        name: "account-index-order-index___bg"
      }, {
        path: ":id",
        component: _4e9beef2,
        name: "account-index-order-index-id___bg"
      }]
    }, {
      path: "passenger",
      component: _703c2236,
      name: "account-index-passenger___bg"
    }, {
      path: "password",
      component: _6a3b00a0,
      name: "account-index-password___bg"
    }, {
      path: "profile",
      component: _5f719534,
      name: "account-index-profile___bg"
    }, {
      path: "review",
      component: _4754ee1d,
      name: "account-index-review___bg"
    }]
  }, {
    path: "/bg/app-feedback",
    component: _bd27bb8c,
    name: "app-feedback___bg"
  }, {
    path: "/bg/choose-country",
    component: _5571e029,
    name: "choose-country___bg"
  }, {
    path: "/bg/confirmemail",
    component: _3c3d88b6,
    name: "confirmemail___bg"
  }, {
    path: "/bg/contact",
    component: _1e34d3cd,
    name: "contact___bg"
  }, {
    path: "/bg/covid19",
    component: _4e6e0f37,
    name: "covid19___bg"
  }, {
    path: "/bg/deals",
    component: _0ef4e8f4,
    name: "deals___bg"
  }, {
    path: "/bg/help",
    component: _404701ca,
    name: "help___bg"
  }, {
    path: "/bg/information-ja",
    component: _0119f9be,
    name: "information-ja___bg"
  }, {
    path: "/bg/login",
    component: _47ffd456,
    name: "login___bg"
  }, {
    path: "/bg/order",
    component: _646e91fb,
    children: [{
      path: "",
      component: _3306703e,
      name: "order-index___bg"
    }, {
      path: ":id",
      component: _36dd87ae,
      name: "order-index-id___bg"
    }]
  }, {
    path: "/bg/pay",
    component: _fb3d235c,
    name: "pay___bg",
    children: [{
      path: ":id?/choose",
      component: _5a973a99,
      name: "pay-id-choose___bg"
    }, {
      path: ":id?/confirmation",
      component: _2b4cda37,
      name: "pay-id-confirmation___bg"
    }]
  }, {
    path: "/bg/payment",
    component: _7e9637b0,
    name: "payment___bg",
    children: [{
      path: ":id?/choose",
      component: _7814eb0a,
      name: "payment-id-choose___bg"
    }, {
      path: ":id?/confirmation",
      component: _55b43899,
      name: "payment-id-confirmation___bg"
    }]
  }, {
    path: "/bg/payment-instruction-cebuana",
    component: _97af7734,
    name: "payment-instruction-cebuana___bg"
  }, {
    path: "/bg/pricealert",
    component: _9f866188,
    name: "pricealert___bg"
  }, {
    path: "/bg/privacy-policy",
    component: _136b53dd,
    name: "privacy-policy___bg"
  }, {
    path: "/bg/promo",
    component: _7cca567c,
    name: "promo___bg"
  }, {
    path: "/bg/report-abadoned",
    component: _75091db0,
    name: "report-abadoned___bg"
  }, {
    path: "/bg/reset",
    component: _1d345abc,
    name: "reset___bg"
  }, {
    path: "/bg/retrieve",
    component: _7f0aae05,
    name: "retrieve___bg"
  }, {
    path: "/bg/signup",
    component: _187de13e,
    name: "signup___bg"
  }, {
    path: "/bg/sm-feedback",
    component: _bd27bb8c,
    name: "sm-feedback___bg"
  }, {
    path: "/bg/support",
    component: _404701ca,
    name: "support___bg"
  }, {
    path: "/bn/account",
    component: _c9b264cc,
    name: "account___bn",
    children: [{
      path: "bind",
      component: _55b147c2,
      name: "account-index-bind___bn"
    }, {
      path: "contact",
      component: _574a1c2b,
      name: "account-index-contact___bn"
    }, {
      path: "order",
      component: _51d988dc,
      children: [{
        path: "",
        component: _b78a0b42,
        name: "account-index-order-index___bn"
      }, {
        path: ":id",
        component: _4e9beef2,
        name: "account-index-order-index-id___bn"
      }]
    }, {
      path: "passenger",
      component: _703c2236,
      name: "account-index-passenger___bn"
    }, {
      path: "password",
      component: _6a3b00a0,
      name: "account-index-password___bn"
    }, {
      path: "profile",
      component: _5f719534,
      name: "account-index-profile___bn"
    }, {
      path: "review",
      component: _4754ee1d,
      name: "account-index-review___bn"
    }]
  }, {
    path: "/bn/app-feedback",
    component: _bd27bb8c,
    name: "app-feedback___bn"
  }, {
    path: "/bn/choose-country",
    component: _5571e029,
    name: "choose-country___bn"
  }, {
    path: "/bn/confirmemail",
    component: _3c3d88b6,
    name: "confirmemail___bn"
  }, {
    path: "/bn/contact",
    component: _1e34d3cd,
    name: "contact___bn"
  }, {
    path: "/bn/covid19",
    component: _4e6e0f37,
    name: "covid19___bn"
  }, {
    path: "/bn/deals",
    component: _0ef4e8f4,
    name: "deals___bn"
  }, {
    path: "/bn/help",
    component: _404701ca,
    name: "help___bn"
  }, {
    path: "/bn/information-ja",
    component: _0119f9be,
    name: "information-ja___bn"
  }, {
    path: "/bn/login",
    component: _47ffd456,
    name: "login___bn"
  }, {
    path: "/bn/order",
    component: _646e91fb,
    children: [{
      path: "",
      component: _3306703e,
      name: "order-index___bn"
    }, {
      path: ":id",
      component: _36dd87ae,
      name: "order-index-id___bn"
    }]
  }, {
    path: "/bn/pay",
    component: _fb3d235c,
    name: "pay___bn",
    children: [{
      path: ":id?/choose",
      component: _5a973a99,
      name: "pay-id-choose___bn"
    }, {
      path: ":id?/confirmation",
      component: _2b4cda37,
      name: "pay-id-confirmation___bn"
    }]
  }, {
    path: "/bn/payment",
    component: _7e9637b0,
    name: "payment___bn",
    children: [{
      path: ":id?/choose",
      component: _7814eb0a,
      name: "payment-id-choose___bn"
    }, {
      path: ":id?/confirmation",
      component: _55b43899,
      name: "payment-id-confirmation___bn"
    }]
  }, {
    path: "/bn/payment-instruction-cebuana",
    component: _97af7734,
    name: "payment-instruction-cebuana___bn"
  }, {
    path: "/bn/pricealert",
    component: _9f866188,
    name: "pricealert___bn"
  }, {
    path: "/bn/privacy-policy",
    component: _136b53dd,
    name: "privacy-policy___bn"
  }, {
    path: "/bn/promo",
    component: _7cca567c,
    name: "promo___bn"
  }, {
    path: "/bn/report-abadoned",
    component: _75091db0,
    name: "report-abadoned___bn"
  }, {
    path: "/bn/reset",
    component: _1d345abc,
    name: "reset___bn"
  }, {
    path: "/bn/retrieve",
    component: _7f0aae05,
    name: "retrieve___bn"
  }, {
    path: "/bn/signup",
    component: _187de13e,
    name: "signup___bn"
  }, {
    path: "/bn/sm-feedback",
    component: _bd27bb8c,
    name: "sm-feedback___bn"
  }, {
    path: "/bn/support",
    component: _404701ca,
    name: "support___bn"
  }, {
    path: "/ca/account",
    component: _c9b264cc,
    name: "account___ca",
    children: [{
      path: "bind",
      component: _55b147c2,
      name: "account-index-bind___ca"
    }, {
      path: "contact",
      component: _574a1c2b,
      name: "account-index-contact___ca"
    }, {
      path: "order",
      component: _51d988dc,
      children: [{
        path: "",
        component: _b78a0b42,
        name: "account-index-order-index___ca"
      }, {
        path: ":id",
        component: _4e9beef2,
        name: "account-index-order-index-id___ca"
      }]
    }, {
      path: "passenger",
      component: _703c2236,
      name: "account-index-passenger___ca"
    }, {
      path: "password",
      component: _6a3b00a0,
      name: "account-index-password___ca"
    }, {
      path: "profile",
      component: _5f719534,
      name: "account-index-profile___ca"
    }, {
      path: "review",
      component: _4754ee1d,
      name: "account-index-review___ca"
    }]
  }, {
    path: "/ca/app-feedback",
    component: _bd27bb8c,
    name: "app-feedback___ca"
  }, {
    path: "/ca/choose-country",
    component: _5571e029,
    name: "choose-country___ca"
  }, {
    path: "/ca/confirmemail",
    component: _3c3d88b6,
    name: "confirmemail___ca"
  }, {
    path: "/ca/contact",
    component: _1e34d3cd,
    name: "contact___ca"
  }, {
    path: "/ca/covid19",
    component: _4e6e0f37,
    name: "covid19___ca"
  }, {
    path: "/ca/deals",
    component: _0ef4e8f4,
    name: "deals___ca"
  }, {
    path: "/ca/help",
    component: _404701ca,
    name: "help___ca"
  }, {
    path: "/ca/information-ja",
    component: _0119f9be,
    name: "information-ja___ca"
  }, {
    path: "/ca/login",
    component: _47ffd456,
    name: "login___ca"
  }, {
    path: "/ca/order",
    component: _646e91fb,
    children: [{
      path: "",
      component: _3306703e,
      name: "order-index___ca"
    }, {
      path: ":id",
      component: _36dd87ae,
      name: "order-index-id___ca"
    }]
  }, {
    path: "/ca/pay",
    component: _fb3d235c,
    name: "pay___ca",
    children: [{
      path: ":id?/choose",
      component: _5a973a99,
      name: "pay-id-choose___ca"
    }, {
      path: ":id?/confirmation",
      component: _2b4cda37,
      name: "pay-id-confirmation___ca"
    }]
  }, {
    path: "/ca/payment",
    component: _7e9637b0,
    name: "payment___ca",
    children: [{
      path: ":id?/choose",
      component: _7814eb0a,
      name: "payment-id-choose___ca"
    }, {
      path: ":id?/confirmation",
      component: _55b43899,
      name: "payment-id-confirmation___ca"
    }]
  }, {
    path: "/ca/payment-instruction-cebuana",
    component: _97af7734,
    name: "payment-instruction-cebuana___ca"
  }, {
    path: "/ca/pricealert",
    component: _9f866188,
    name: "pricealert___ca"
  }, {
    path: "/ca/privacy-policy",
    component: _136b53dd,
    name: "privacy-policy___ca"
  }, {
    path: "/ca/promo",
    component: _7cca567c,
    name: "promo___ca"
  }, {
    path: "/ca/report-abadoned",
    component: _75091db0,
    name: "report-abadoned___ca"
  }, {
    path: "/ca/reset",
    component: _1d345abc,
    name: "reset___ca"
  }, {
    path: "/ca/retrieve",
    component: _7f0aae05,
    name: "retrieve___ca"
  }, {
    path: "/ca/signup",
    component: _187de13e,
    name: "signup___ca"
  }, {
    path: "/ca/sm-feedback",
    component: _bd27bb8c,
    name: "sm-feedback___ca"
  }, {
    path: "/ca/support",
    component: _404701ca,
    name: "support___ca"
  }, {
    path: "/cl/dl.php",
    component: _6db09fa2,
    name: "cl-dl.php"
  }, {
    path: "/cl/maybankredirect.php",
    component: _10105327,
    name: "cl-maybankredirect.php"
  }, {
    path: "/cl/tngredirect2.php",
    component: _5b259d3a,
    name: "cl-tngredirect2.php"
  }, {
    path: "/cs/account",
    component: _c9b264cc,
    name: "account___cs",
    children: [{
      path: "bind",
      component: _55b147c2,
      name: "account-index-bind___cs"
    }, {
      path: "contact",
      component: _574a1c2b,
      name: "account-index-contact___cs"
    }, {
      path: "order",
      component: _51d988dc,
      children: [{
        path: "",
        component: _b78a0b42,
        name: "account-index-order-index___cs"
      }, {
        path: ":id",
        component: _4e9beef2,
        name: "account-index-order-index-id___cs"
      }]
    }, {
      path: "passenger",
      component: _703c2236,
      name: "account-index-passenger___cs"
    }, {
      path: "password",
      component: _6a3b00a0,
      name: "account-index-password___cs"
    }, {
      path: "profile",
      component: _5f719534,
      name: "account-index-profile___cs"
    }, {
      path: "review",
      component: _4754ee1d,
      name: "account-index-review___cs"
    }]
  }, {
    path: "/cs/app-feedback",
    component: _bd27bb8c,
    name: "app-feedback___cs"
  }, {
    path: "/cs/choose-country",
    component: _5571e029,
    name: "choose-country___cs"
  }, {
    path: "/cs/confirmemail",
    component: _3c3d88b6,
    name: "confirmemail___cs"
  }, {
    path: "/cs/contact",
    component: _1e34d3cd,
    name: "contact___cs"
  }, {
    path: "/cs/covid19",
    component: _4e6e0f37,
    name: "covid19___cs"
  }, {
    path: "/cs/deals",
    component: _0ef4e8f4,
    name: "deals___cs"
  }, {
    path: "/cs/help",
    component: _404701ca,
    name: "help___cs"
  }, {
    path: "/cs/information-ja",
    component: _0119f9be,
    name: "information-ja___cs"
  }, {
    path: "/cs/login",
    component: _47ffd456,
    name: "login___cs"
  }, {
    path: "/cs/order",
    component: _646e91fb,
    children: [{
      path: "",
      component: _3306703e,
      name: "order-index___cs"
    }, {
      path: ":id",
      component: _36dd87ae,
      name: "order-index-id___cs"
    }]
  }, {
    path: "/cs/pay",
    component: _fb3d235c,
    name: "pay___cs",
    children: [{
      path: ":id?/choose",
      component: _5a973a99,
      name: "pay-id-choose___cs"
    }, {
      path: ":id?/confirmation",
      component: _2b4cda37,
      name: "pay-id-confirmation___cs"
    }]
  }, {
    path: "/cs/payment",
    component: _7e9637b0,
    name: "payment___cs",
    children: [{
      path: ":id?/choose",
      component: _7814eb0a,
      name: "payment-id-choose___cs"
    }, {
      path: ":id?/confirmation",
      component: _55b43899,
      name: "payment-id-confirmation___cs"
    }]
  }, {
    path: "/cs/payment-instruction-cebuana",
    component: _97af7734,
    name: "payment-instruction-cebuana___cs"
  }, {
    path: "/cs/pricealert",
    component: _9f866188,
    name: "pricealert___cs"
  }, {
    path: "/cs/privacy-policy",
    component: _136b53dd,
    name: "privacy-policy___cs"
  }, {
    path: "/cs/promo",
    component: _7cca567c,
    name: "promo___cs"
  }, {
    path: "/cs/report-abadoned",
    component: _75091db0,
    name: "report-abadoned___cs"
  }, {
    path: "/cs/reset",
    component: _1d345abc,
    name: "reset___cs"
  }, {
    path: "/cs/retrieve",
    component: _7f0aae05,
    name: "retrieve___cs"
  }, {
    path: "/cs/signup",
    component: _187de13e,
    name: "signup___cs"
  }, {
    path: "/cs/sm-feedback",
    component: _bd27bb8c,
    name: "sm-feedback___cs"
  }, {
    path: "/cs/support",
    component: _404701ca,
    name: "support___cs"
  }, {
    path: "/da/account",
    component: _c9b264cc,
    name: "account___da",
    children: [{
      path: "bind",
      component: _55b147c2,
      name: "account-index-bind___da"
    }, {
      path: "contact",
      component: _574a1c2b,
      name: "account-index-contact___da"
    }, {
      path: "order",
      component: _51d988dc,
      children: [{
        path: "",
        component: _b78a0b42,
        name: "account-index-order-index___da"
      }, {
        path: ":id",
        component: _4e9beef2,
        name: "account-index-order-index-id___da"
      }]
    }, {
      path: "passenger",
      component: _703c2236,
      name: "account-index-passenger___da"
    }, {
      path: "password",
      component: _6a3b00a0,
      name: "account-index-password___da"
    }, {
      path: "profile",
      component: _5f719534,
      name: "account-index-profile___da"
    }, {
      path: "review",
      component: _4754ee1d,
      name: "account-index-review___da"
    }]
  }, {
    path: "/da/app-feedback",
    component: _bd27bb8c,
    name: "app-feedback___da"
  }, {
    path: "/da/choose-country",
    component: _5571e029,
    name: "choose-country___da"
  }, {
    path: "/da/confirmemail",
    component: _3c3d88b6,
    name: "confirmemail___da"
  }, {
    path: "/da/contact",
    component: _1e34d3cd,
    name: "contact___da"
  }, {
    path: "/da/covid19",
    component: _4e6e0f37,
    name: "covid19___da"
  }, {
    path: "/da/deals",
    component: _0ef4e8f4,
    name: "deals___da"
  }, {
    path: "/da/help",
    component: _404701ca,
    name: "help___da"
  }, {
    path: "/da/information-ja",
    component: _0119f9be,
    name: "information-ja___da"
  }, {
    path: "/da/login",
    component: _47ffd456,
    name: "login___da"
  }, {
    path: "/da/order",
    component: _646e91fb,
    children: [{
      path: "",
      component: _3306703e,
      name: "order-index___da"
    }, {
      path: ":id",
      component: _36dd87ae,
      name: "order-index-id___da"
    }]
  }, {
    path: "/da/pay",
    component: _fb3d235c,
    name: "pay___da",
    children: [{
      path: ":id?/choose",
      component: _5a973a99,
      name: "pay-id-choose___da"
    }, {
      path: ":id?/confirmation",
      component: _2b4cda37,
      name: "pay-id-confirmation___da"
    }]
  }, {
    path: "/da/payment",
    component: _7e9637b0,
    name: "payment___da",
    children: [{
      path: ":id?/choose",
      component: _7814eb0a,
      name: "payment-id-choose___da"
    }, {
      path: ":id?/confirmation",
      component: _55b43899,
      name: "payment-id-confirmation___da"
    }]
  }, {
    path: "/da/payment-instruction-cebuana",
    component: _97af7734,
    name: "payment-instruction-cebuana___da"
  }, {
    path: "/da/pricealert",
    component: _9f866188,
    name: "pricealert___da"
  }, {
    path: "/da/privacy-policy",
    component: _136b53dd,
    name: "privacy-policy___da"
  }, {
    path: "/da/promo",
    component: _7cca567c,
    name: "promo___da"
  }, {
    path: "/da/report-abadoned",
    component: _75091db0,
    name: "report-abadoned___da"
  }, {
    path: "/da/reset",
    component: _1d345abc,
    name: "reset___da"
  }, {
    path: "/da/retrieve",
    component: _7f0aae05,
    name: "retrieve___da"
  }, {
    path: "/da/signup",
    component: _187de13e,
    name: "signup___da"
  }, {
    path: "/da/sm-feedback",
    component: _bd27bb8c,
    name: "sm-feedback___da"
  }, {
    path: "/da/support",
    component: _404701ca,
    name: "support___da"
  }, {
    path: "/de/account",
    component: _c9b264cc,
    name: "account___de",
    children: [{
      path: "bind",
      component: _55b147c2,
      name: "account-index-bind___de"
    }, {
      path: "contact",
      component: _574a1c2b,
      name: "account-index-contact___de"
    }, {
      path: "order",
      component: _51d988dc,
      children: [{
        path: "",
        component: _b78a0b42,
        name: "account-index-order-index___de"
      }, {
        path: ":id",
        component: _4e9beef2,
        name: "account-index-order-index-id___de"
      }]
    }, {
      path: "passenger",
      component: _703c2236,
      name: "account-index-passenger___de"
    }, {
      path: "password",
      component: _6a3b00a0,
      name: "account-index-password___de"
    }, {
      path: "profile",
      component: _5f719534,
      name: "account-index-profile___de"
    }, {
      path: "review",
      component: _4754ee1d,
      name: "account-index-review___de"
    }]
  }, {
    path: "/de/app-feedback",
    component: _bd27bb8c,
    name: "app-feedback___de"
  }, {
    path: "/de/choose-country",
    component: _5571e029,
    name: "choose-country___de"
  }, {
    path: "/de/confirmemail",
    component: _3c3d88b6,
    name: "confirmemail___de"
  }, {
    path: "/de/contact",
    component: _1e34d3cd,
    name: "contact___de"
  }, {
    path: "/de/covid19",
    component: _4e6e0f37,
    name: "covid19___de"
  }, {
    path: "/de/deals",
    component: _0ef4e8f4,
    name: "deals___de"
  }, {
    path: "/de/help",
    component: _404701ca,
    name: "help___de"
  }, {
    path: "/de/information-ja",
    component: _0119f9be,
    name: "information-ja___de"
  }, {
    path: "/de/login",
    component: _47ffd456,
    name: "login___de"
  }, {
    path: "/de/order",
    component: _646e91fb,
    children: [{
      path: "",
      component: _3306703e,
      name: "order-index___de"
    }, {
      path: ":id",
      component: _36dd87ae,
      name: "order-index-id___de"
    }]
  }, {
    path: "/de/pay",
    component: _fb3d235c,
    name: "pay___de",
    children: [{
      path: ":id?/choose",
      component: _5a973a99,
      name: "pay-id-choose___de"
    }, {
      path: ":id?/confirmation",
      component: _2b4cda37,
      name: "pay-id-confirmation___de"
    }]
  }, {
    path: "/de/payment",
    component: _7e9637b0,
    name: "payment___de",
    children: [{
      path: ":id?/choose",
      component: _7814eb0a,
      name: "payment-id-choose___de"
    }, {
      path: ":id?/confirmation",
      component: _55b43899,
      name: "payment-id-confirmation___de"
    }]
  }, {
    path: "/de/payment-instruction-cebuana",
    component: _97af7734,
    name: "payment-instruction-cebuana___de"
  }, {
    path: "/de/pricealert",
    component: _9f866188,
    name: "pricealert___de"
  }, {
    path: "/de/privacy-policy",
    component: _136b53dd,
    name: "privacy-policy___de"
  }, {
    path: "/de/promo",
    component: _7cca567c,
    name: "promo___de"
  }, {
    path: "/de/report-abadoned",
    component: _75091db0,
    name: "report-abadoned___de"
  }, {
    path: "/de/reset",
    component: _1d345abc,
    name: "reset___de"
  }, {
    path: "/de/retrieve",
    component: _7f0aae05,
    name: "retrieve___de"
  }, {
    path: "/de/signup",
    component: _187de13e,
    name: "signup___de"
  }, {
    path: "/de/sm-feedback",
    component: _bd27bb8c,
    name: "sm-feedback___de"
  }, {
    path: "/de/support",
    component: _404701ca,
    name: "support___de"
  }, {
    path: "/el/account",
    component: _c9b264cc,
    name: "account___el",
    children: [{
      path: "bind",
      component: _55b147c2,
      name: "account-index-bind___el"
    }, {
      path: "contact",
      component: _574a1c2b,
      name: "account-index-contact___el"
    }, {
      path: "order",
      component: _51d988dc,
      children: [{
        path: "",
        component: _b78a0b42,
        name: "account-index-order-index___el"
      }, {
        path: ":id",
        component: _4e9beef2,
        name: "account-index-order-index-id___el"
      }]
    }, {
      path: "passenger",
      component: _703c2236,
      name: "account-index-passenger___el"
    }, {
      path: "password",
      component: _6a3b00a0,
      name: "account-index-password___el"
    }, {
      path: "profile",
      component: _5f719534,
      name: "account-index-profile___el"
    }, {
      path: "review",
      component: _4754ee1d,
      name: "account-index-review___el"
    }]
  }, {
    path: "/el/app-feedback",
    component: _bd27bb8c,
    name: "app-feedback___el"
  }, {
    path: "/el/choose-country",
    component: _5571e029,
    name: "choose-country___el"
  }, {
    path: "/el/confirmemail",
    component: _3c3d88b6,
    name: "confirmemail___el"
  }, {
    path: "/el/contact",
    component: _1e34d3cd,
    name: "contact___el"
  }, {
    path: "/el/covid19",
    component: _4e6e0f37,
    name: "covid19___el"
  }, {
    path: "/el/deals",
    component: _0ef4e8f4,
    name: "deals___el"
  }, {
    path: "/el/help",
    component: _404701ca,
    name: "help___el"
  }, {
    path: "/el/information-ja",
    component: _0119f9be,
    name: "information-ja___el"
  }, {
    path: "/el/login",
    component: _47ffd456,
    name: "login___el"
  }, {
    path: "/el/order",
    component: _646e91fb,
    children: [{
      path: "",
      component: _3306703e,
      name: "order-index___el"
    }, {
      path: ":id",
      component: _36dd87ae,
      name: "order-index-id___el"
    }]
  }, {
    path: "/el/pay",
    component: _fb3d235c,
    name: "pay___el",
    children: [{
      path: ":id?/choose",
      component: _5a973a99,
      name: "pay-id-choose___el"
    }, {
      path: ":id?/confirmation",
      component: _2b4cda37,
      name: "pay-id-confirmation___el"
    }]
  }, {
    path: "/el/payment",
    component: _7e9637b0,
    name: "payment___el",
    children: [{
      path: ":id?/choose",
      component: _7814eb0a,
      name: "payment-id-choose___el"
    }, {
      path: ":id?/confirmation",
      component: _55b43899,
      name: "payment-id-confirmation___el"
    }]
  }, {
    path: "/el/payment-instruction-cebuana",
    component: _97af7734,
    name: "payment-instruction-cebuana___el"
  }, {
    path: "/el/pricealert",
    component: _9f866188,
    name: "pricealert___el"
  }, {
    path: "/el/privacy-policy",
    component: _136b53dd,
    name: "privacy-policy___el"
  }, {
    path: "/el/promo",
    component: _7cca567c,
    name: "promo___el"
  }, {
    path: "/el/report-abadoned",
    component: _75091db0,
    name: "report-abadoned___el"
  }, {
    path: "/el/reset",
    component: _1d345abc,
    name: "reset___el"
  }, {
    path: "/el/retrieve",
    component: _7f0aae05,
    name: "retrieve___el"
  }, {
    path: "/el/signup",
    component: _187de13e,
    name: "signup___el"
  }, {
    path: "/el/sm-feedback",
    component: _bd27bb8c,
    name: "sm-feedback___el"
  }, {
    path: "/el/support",
    component: _404701ca,
    name: "support___el"
  }, {
    path: "/en/account",
    component: _c9b264cc,
    name: "account___en",
    children: [{
      path: "bind",
      component: _55b147c2,
      name: "account-index-bind___en"
    }, {
      path: "contact",
      component: _574a1c2b,
      name: "account-index-contact___en"
    }, {
      path: "order",
      component: _51d988dc,
      children: [{
        path: "",
        component: _b78a0b42,
        name: "account-index-order-index___en"
      }, {
        path: ":id",
        component: _4e9beef2,
        name: "account-index-order-index-id___en"
      }]
    }, {
      path: "passenger",
      component: _703c2236,
      name: "account-index-passenger___en"
    }, {
      path: "password",
      component: _6a3b00a0,
      name: "account-index-password___en"
    }, {
      path: "profile",
      component: _5f719534,
      name: "account-index-profile___en"
    }, {
      path: "review",
      component: _4754ee1d,
      name: "account-index-review___en"
    }]
  }, {
    path: "/en/app-feedback",
    component: _bd27bb8c,
    name: "app-feedback___en"
  }, {
    path: "/en/choose-country",
    component: _5571e029,
    name: "choose-country___en"
  }, {
    path: "/en/confirmemail",
    component: _3c3d88b6,
    name: "confirmemail___en"
  }, {
    path: "/en/contact",
    component: _1e34d3cd,
    name: "contact___en"
  }, {
    path: "/en/covid19",
    component: _4e6e0f37,
    name: "covid19___en"
  }, {
    path: "/en/deals",
    component: _0ef4e8f4,
    name: "deals___en"
  }, {
    path: "/en/help",
    component: _404701ca,
    name: "help___en"
  }, {
    path: "/en/information-ja",
    component: _0119f9be,
    name: "information-ja___en"
  }, {
    path: "/en/login",
    component: _47ffd456,
    name: "login___en"
  }, {
    path: "/en/order",
    component: _646e91fb,
    children: [{
      path: "",
      component: _3306703e,
      name: "order-index___en"
    }, {
      path: ":id",
      component: _36dd87ae,
      name: "order-index-id___en"
    }]
  }, {
    path: "/en/pay",
    component: _fb3d235c,
    name: "pay___en",
    children: [{
      path: ":id?/choose",
      component: _5a973a99,
      name: "pay-id-choose___en"
    }, {
      path: ":id?/confirmation",
      component: _2b4cda37,
      name: "pay-id-confirmation___en"
    }]
  }, {
    path: "/en/payment",
    component: _7e9637b0,
    name: "payment___en",
    children: [{
      path: ":id?/choose",
      component: _7814eb0a,
      name: "payment-id-choose___en"
    }, {
      path: ":id?/confirmation",
      component: _55b43899,
      name: "payment-id-confirmation___en"
    }]
  }, {
    path: "/en/payment-instruction-cebuana",
    component: _97af7734,
    name: "payment-instruction-cebuana___en"
  }, {
    path: "/en/pricealert",
    component: _9f866188,
    name: "pricealert___en"
  }, {
    path: "/en/privacy-policy",
    component: _136b53dd,
    name: "privacy-policy___en"
  }, {
    path: "/en/promo",
    component: _7cca567c,
    name: "promo___en"
  }, {
    path: "/en/report-abadoned",
    component: _75091db0,
    name: "report-abadoned___en"
  }, {
    path: "/en/reset",
    component: _1d345abc,
    name: "reset___en"
  }, {
    path: "/en/retrieve",
    component: _7f0aae05,
    name: "retrieve___en"
  }, {
    path: "/en/signup",
    component: _187de13e,
    name: "signup___en"
  }, {
    path: "/en/sm-feedback",
    component: _bd27bb8c,
    name: "sm-feedback___en"
  }, {
    path: "/en/support",
    component: _404701ca,
    name: "support___en"
  }, {
    path: "/es/account",
    component: _c9b264cc,
    name: "account___es",
    children: [{
      path: "bind",
      component: _55b147c2,
      name: "account-index-bind___es"
    }, {
      path: "contact",
      component: _574a1c2b,
      name: "account-index-contact___es"
    }, {
      path: "order",
      component: _51d988dc,
      children: [{
        path: "",
        component: _b78a0b42,
        name: "account-index-order-index___es"
      }, {
        path: ":id",
        component: _4e9beef2,
        name: "account-index-order-index-id___es"
      }]
    }, {
      path: "passenger",
      component: _703c2236,
      name: "account-index-passenger___es"
    }, {
      path: "password",
      component: _6a3b00a0,
      name: "account-index-password___es"
    }, {
      path: "profile",
      component: _5f719534,
      name: "account-index-profile___es"
    }, {
      path: "review",
      component: _4754ee1d,
      name: "account-index-review___es"
    }]
  }, {
    path: "/es/app-feedback",
    component: _bd27bb8c,
    name: "app-feedback___es"
  }, {
    path: "/es/choose-country",
    component: _5571e029,
    name: "choose-country___es"
  }, {
    path: "/es/confirmemail",
    component: _3c3d88b6,
    name: "confirmemail___es"
  }, {
    path: "/es/contact",
    component: _1e34d3cd,
    name: "contact___es"
  }, {
    path: "/es/covid19",
    component: _4e6e0f37,
    name: "covid19___es"
  }, {
    path: "/es/deals",
    component: _0ef4e8f4,
    name: "deals___es"
  }, {
    path: "/es/help",
    component: _404701ca,
    name: "help___es"
  }, {
    path: "/es/information-ja",
    component: _0119f9be,
    name: "information-ja___es"
  }, {
    path: "/es/login",
    component: _47ffd456,
    name: "login___es"
  }, {
    path: "/es/order",
    component: _646e91fb,
    children: [{
      path: "",
      component: _3306703e,
      name: "order-index___es"
    }, {
      path: ":id",
      component: _36dd87ae,
      name: "order-index-id___es"
    }]
  }, {
    path: "/es/pay",
    component: _fb3d235c,
    name: "pay___es",
    children: [{
      path: ":id?/choose",
      component: _5a973a99,
      name: "pay-id-choose___es"
    }, {
      path: ":id?/confirmation",
      component: _2b4cda37,
      name: "pay-id-confirmation___es"
    }]
  }, {
    path: "/es/payment",
    component: _7e9637b0,
    name: "payment___es",
    children: [{
      path: ":id?/choose",
      component: _7814eb0a,
      name: "payment-id-choose___es"
    }, {
      path: ":id?/confirmation",
      component: _55b43899,
      name: "payment-id-confirmation___es"
    }]
  }, {
    path: "/es/payment-instruction-cebuana",
    component: _97af7734,
    name: "payment-instruction-cebuana___es"
  }, {
    path: "/es/pricealert",
    component: _9f866188,
    name: "pricealert___es"
  }, {
    path: "/es/privacy-policy",
    component: _136b53dd,
    name: "privacy-policy___es"
  }, {
    path: "/es/promo",
    component: _7cca567c,
    name: "promo___es"
  }, {
    path: "/es/report-abadoned",
    component: _75091db0,
    name: "report-abadoned___es"
  }, {
    path: "/es/reset",
    component: _1d345abc,
    name: "reset___es"
  }, {
    path: "/es/retrieve",
    component: _7f0aae05,
    name: "retrieve___es"
  }, {
    path: "/es/signup",
    component: _187de13e,
    name: "signup___es"
  }, {
    path: "/es/sm-feedback",
    component: _bd27bb8c,
    name: "sm-feedback___es"
  }, {
    path: "/es/support",
    component: _404701ca,
    name: "support___es"
  }, {
    path: "/fi/account",
    component: _c9b264cc,
    name: "account___fi",
    children: [{
      path: "bind",
      component: _55b147c2,
      name: "account-index-bind___fi"
    }, {
      path: "contact",
      component: _574a1c2b,
      name: "account-index-contact___fi"
    }, {
      path: "order",
      component: _51d988dc,
      children: [{
        path: "",
        component: _b78a0b42,
        name: "account-index-order-index___fi"
      }, {
        path: ":id",
        component: _4e9beef2,
        name: "account-index-order-index-id___fi"
      }]
    }, {
      path: "passenger",
      component: _703c2236,
      name: "account-index-passenger___fi"
    }, {
      path: "password",
      component: _6a3b00a0,
      name: "account-index-password___fi"
    }, {
      path: "profile",
      component: _5f719534,
      name: "account-index-profile___fi"
    }, {
      path: "review",
      component: _4754ee1d,
      name: "account-index-review___fi"
    }]
  }, {
    path: "/fi/app-feedback",
    component: _bd27bb8c,
    name: "app-feedback___fi"
  }, {
    path: "/fi/choose-country",
    component: _5571e029,
    name: "choose-country___fi"
  }, {
    path: "/fi/confirmemail",
    component: _3c3d88b6,
    name: "confirmemail___fi"
  }, {
    path: "/fi/contact",
    component: _1e34d3cd,
    name: "contact___fi"
  }, {
    path: "/fi/covid19",
    component: _4e6e0f37,
    name: "covid19___fi"
  }, {
    path: "/fi/deals",
    component: _0ef4e8f4,
    name: "deals___fi"
  }, {
    path: "/fi/help",
    component: _404701ca,
    name: "help___fi"
  }, {
    path: "/fi/information-ja",
    component: _0119f9be,
    name: "information-ja___fi"
  }, {
    path: "/fi/login",
    component: _47ffd456,
    name: "login___fi"
  }, {
    path: "/fi/order",
    component: _646e91fb,
    children: [{
      path: "",
      component: _3306703e,
      name: "order-index___fi"
    }, {
      path: ":id",
      component: _36dd87ae,
      name: "order-index-id___fi"
    }]
  }, {
    path: "/fi/pay",
    component: _fb3d235c,
    name: "pay___fi",
    children: [{
      path: ":id?/choose",
      component: _5a973a99,
      name: "pay-id-choose___fi"
    }, {
      path: ":id?/confirmation",
      component: _2b4cda37,
      name: "pay-id-confirmation___fi"
    }]
  }, {
    path: "/fi/payment",
    component: _7e9637b0,
    name: "payment___fi",
    children: [{
      path: ":id?/choose",
      component: _7814eb0a,
      name: "payment-id-choose___fi"
    }, {
      path: ":id?/confirmation",
      component: _55b43899,
      name: "payment-id-confirmation___fi"
    }]
  }, {
    path: "/fi/payment-instruction-cebuana",
    component: _97af7734,
    name: "payment-instruction-cebuana___fi"
  }, {
    path: "/fi/pricealert",
    component: _9f866188,
    name: "pricealert___fi"
  }, {
    path: "/fi/privacy-policy",
    component: _136b53dd,
    name: "privacy-policy___fi"
  }, {
    path: "/fi/promo",
    component: _7cca567c,
    name: "promo___fi"
  }, {
    path: "/fi/report-abadoned",
    component: _75091db0,
    name: "report-abadoned___fi"
  }, {
    path: "/fi/reset",
    component: _1d345abc,
    name: "reset___fi"
  }, {
    path: "/fi/retrieve",
    component: _7f0aae05,
    name: "retrieve___fi"
  }, {
    path: "/fi/signup",
    component: _187de13e,
    name: "signup___fi"
  }, {
    path: "/fi/sm-feedback",
    component: _bd27bb8c,
    name: "sm-feedback___fi"
  }, {
    path: "/fi/support",
    component: _404701ca,
    name: "support___fi"
  }, {
    path: "/fr/account",
    component: _c9b264cc,
    name: "account___fr",
    children: [{
      path: "bind",
      component: _55b147c2,
      name: "account-index-bind___fr"
    }, {
      path: "contact",
      component: _574a1c2b,
      name: "account-index-contact___fr"
    }, {
      path: "order",
      component: _51d988dc,
      children: [{
        path: "",
        component: _b78a0b42,
        name: "account-index-order-index___fr"
      }, {
        path: ":id",
        component: _4e9beef2,
        name: "account-index-order-index-id___fr"
      }]
    }, {
      path: "passenger",
      component: _703c2236,
      name: "account-index-passenger___fr"
    }, {
      path: "password",
      component: _6a3b00a0,
      name: "account-index-password___fr"
    }, {
      path: "profile",
      component: _5f719534,
      name: "account-index-profile___fr"
    }, {
      path: "review",
      component: _4754ee1d,
      name: "account-index-review___fr"
    }]
  }, {
    path: "/fr/app-feedback",
    component: _bd27bb8c,
    name: "app-feedback___fr"
  }, {
    path: "/fr/choose-country",
    component: _5571e029,
    name: "choose-country___fr"
  }, {
    path: "/fr/confirmemail",
    component: _3c3d88b6,
    name: "confirmemail___fr"
  }, {
    path: "/fr/contact",
    component: _1e34d3cd,
    name: "contact___fr"
  }, {
    path: "/fr/covid19",
    component: _4e6e0f37,
    name: "covid19___fr"
  }, {
    path: "/fr/deals",
    component: _0ef4e8f4,
    name: "deals___fr"
  }, {
    path: "/fr/help",
    component: _404701ca,
    name: "help___fr"
  }, {
    path: "/fr/information-ja",
    component: _0119f9be,
    name: "information-ja___fr"
  }, {
    path: "/fr/login",
    component: _47ffd456,
    name: "login___fr"
  }, {
    path: "/fr/order",
    component: _646e91fb,
    children: [{
      path: "",
      component: _3306703e,
      name: "order-index___fr"
    }, {
      path: ":id",
      component: _36dd87ae,
      name: "order-index-id___fr"
    }]
  }, {
    path: "/fr/pay",
    component: _fb3d235c,
    name: "pay___fr",
    children: [{
      path: ":id?/choose",
      component: _5a973a99,
      name: "pay-id-choose___fr"
    }, {
      path: ":id?/confirmation",
      component: _2b4cda37,
      name: "pay-id-confirmation___fr"
    }]
  }, {
    path: "/fr/payment",
    component: _7e9637b0,
    name: "payment___fr",
    children: [{
      path: ":id?/choose",
      component: _7814eb0a,
      name: "payment-id-choose___fr"
    }, {
      path: ":id?/confirmation",
      component: _55b43899,
      name: "payment-id-confirmation___fr"
    }]
  }, {
    path: "/fr/payment-instruction-cebuana",
    component: _97af7734,
    name: "payment-instruction-cebuana___fr"
  }, {
    path: "/fr/pricealert",
    component: _9f866188,
    name: "pricealert___fr"
  }, {
    path: "/fr/privacy-policy",
    component: _136b53dd,
    name: "privacy-policy___fr"
  }, {
    path: "/fr/promo",
    component: _7cca567c,
    name: "promo___fr"
  }, {
    path: "/fr/report-abadoned",
    component: _75091db0,
    name: "report-abadoned___fr"
  }, {
    path: "/fr/reset",
    component: _1d345abc,
    name: "reset___fr"
  }, {
    path: "/fr/retrieve",
    component: _7f0aae05,
    name: "retrieve___fr"
  }, {
    path: "/fr/signup",
    component: _187de13e,
    name: "signup___fr"
  }, {
    path: "/fr/sm-feedback",
    component: _bd27bb8c,
    name: "sm-feedback___fr"
  }, {
    path: "/fr/support",
    component: _404701ca,
    name: "support___fr"
  }, {
    path: "/hi/account",
    component: _c9b264cc,
    name: "account___hi",
    children: [{
      path: "bind",
      component: _55b147c2,
      name: "account-index-bind___hi"
    }, {
      path: "contact",
      component: _574a1c2b,
      name: "account-index-contact___hi"
    }, {
      path: "order",
      component: _51d988dc,
      children: [{
        path: "",
        component: _b78a0b42,
        name: "account-index-order-index___hi"
      }, {
        path: ":id",
        component: _4e9beef2,
        name: "account-index-order-index-id___hi"
      }]
    }, {
      path: "passenger",
      component: _703c2236,
      name: "account-index-passenger___hi"
    }, {
      path: "password",
      component: _6a3b00a0,
      name: "account-index-password___hi"
    }, {
      path: "profile",
      component: _5f719534,
      name: "account-index-profile___hi"
    }, {
      path: "review",
      component: _4754ee1d,
      name: "account-index-review___hi"
    }]
  }, {
    path: "/hi/app-feedback",
    component: _bd27bb8c,
    name: "app-feedback___hi"
  }, {
    path: "/hi/choose-country",
    component: _5571e029,
    name: "choose-country___hi"
  }, {
    path: "/hi/confirmemail",
    component: _3c3d88b6,
    name: "confirmemail___hi"
  }, {
    path: "/hi/contact",
    component: _1e34d3cd,
    name: "contact___hi"
  }, {
    path: "/hi/covid19",
    component: _4e6e0f37,
    name: "covid19___hi"
  }, {
    path: "/hi/deals",
    component: _0ef4e8f4,
    name: "deals___hi"
  }, {
    path: "/hi/help",
    component: _404701ca,
    name: "help___hi"
  }, {
    path: "/hi/information-ja",
    component: _0119f9be,
    name: "information-ja___hi"
  }, {
    path: "/hi/login",
    component: _47ffd456,
    name: "login___hi"
  }, {
    path: "/hi/order",
    component: _646e91fb,
    children: [{
      path: "",
      component: _3306703e,
      name: "order-index___hi"
    }, {
      path: ":id",
      component: _36dd87ae,
      name: "order-index-id___hi"
    }]
  }, {
    path: "/hi/pay",
    component: _fb3d235c,
    name: "pay___hi",
    children: [{
      path: ":id?/choose",
      component: _5a973a99,
      name: "pay-id-choose___hi"
    }, {
      path: ":id?/confirmation",
      component: _2b4cda37,
      name: "pay-id-confirmation___hi"
    }]
  }, {
    path: "/hi/payment",
    component: _7e9637b0,
    name: "payment___hi",
    children: [{
      path: ":id?/choose",
      component: _7814eb0a,
      name: "payment-id-choose___hi"
    }, {
      path: ":id?/confirmation",
      component: _55b43899,
      name: "payment-id-confirmation___hi"
    }]
  }, {
    path: "/hi/payment-instruction-cebuana",
    component: _97af7734,
    name: "payment-instruction-cebuana___hi"
  }, {
    path: "/hi/pricealert",
    component: _9f866188,
    name: "pricealert___hi"
  }, {
    path: "/hi/privacy-policy",
    component: _136b53dd,
    name: "privacy-policy___hi"
  }, {
    path: "/hi/promo",
    component: _7cca567c,
    name: "promo___hi"
  }, {
    path: "/hi/report-abadoned",
    component: _75091db0,
    name: "report-abadoned___hi"
  }, {
    path: "/hi/reset",
    component: _1d345abc,
    name: "reset___hi"
  }, {
    path: "/hi/retrieve",
    component: _7f0aae05,
    name: "retrieve___hi"
  }, {
    path: "/hi/signup",
    component: _187de13e,
    name: "signup___hi"
  }, {
    path: "/hi/sm-feedback",
    component: _bd27bb8c,
    name: "sm-feedback___hi"
  }, {
    path: "/hi/support",
    component: _404701ca,
    name: "support___hi"
  }, {
    path: "/hr/account",
    component: _c9b264cc,
    name: "account___hr",
    children: [{
      path: "bind",
      component: _55b147c2,
      name: "account-index-bind___hr"
    }, {
      path: "contact",
      component: _574a1c2b,
      name: "account-index-contact___hr"
    }, {
      path: "order",
      component: _51d988dc,
      children: [{
        path: "",
        component: _b78a0b42,
        name: "account-index-order-index___hr"
      }, {
        path: ":id",
        component: _4e9beef2,
        name: "account-index-order-index-id___hr"
      }]
    }, {
      path: "passenger",
      component: _703c2236,
      name: "account-index-passenger___hr"
    }, {
      path: "password",
      component: _6a3b00a0,
      name: "account-index-password___hr"
    }, {
      path: "profile",
      component: _5f719534,
      name: "account-index-profile___hr"
    }, {
      path: "review",
      component: _4754ee1d,
      name: "account-index-review___hr"
    }]
  }, {
    path: "/hr/app-feedback",
    component: _bd27bb8c,
    name: "app-feedback___hr"
  }, {
    path: "/hr/choose-country",
    component: _5571e029,
    name: "choose-country___hr"
  }, {
    path: "/hr/confirmemail",
    component: _3c3d88b6,
    name: "confirmemail___hr"
  }, {
    path: "/hr/contact",
    component: _1e34d3cd,
    name: "contact___hr"
  }, {
    path: "/hr/covid19",
    component: _4e6e0f37,
    name: "covid19___hr"
  }, {
    path: "/hr/deals",
    component: _0ef4e8f4,
    name: "deals___hr"
  }, {
    path: "/hr/help",
    component: _404701ca,
    name: "help___hr"
  }, {
    path: "/hr/information-ja",
    component: _0119f9be,
    name: "information-ja___hr"
  }, {
    path: "/hr/login",
    component: _47ffd456,
    name: "login___hr"
  }, {
    path: "/hr/order",
    component: _646e91fb,
    children: [{
      path: "",
      component: _3306703e,
      name: "order-index___hr"
    }, {
      path: ":id",
      component: _36dd87ae,
      name: "order-index-id___hr"
    }]
  }, {
    path: "/hr/pay",
    component: _fb3d235c,
    name: "pay___hr",
    children: [{
      path: ":id?/choose",
      component: _5a973a99,
      name: "pay-id-choose___hr"
    }, {
      path: ":id?/confirmation",
      component: _2b4cda37,
      name: "pay-id-confirmation___hr"
    }]
  }, {
    path: "/hr/payment",
    component: _7e9637b0,
    name: "payment___hr",
    children: [{
      path: ":id?/choose",
      component: _7814eb0a,
      name: "payment-id-choose___hr"
    }, {
      path: ":id?/confirmation",
      component: _55b43899,
      name: "payment-id-confirmation___hr"
    }]
  }, {
    path: "/hr/payment-instruction-cebuana",
    component: _97af7734,
    name: "payment-instruction-cebuana___hr"
  }, {
    path: "/hr/pricealert",
    component: _9f866188,
    name: "pricealert___hr"
  }, {
    path: "/hr/privacy-policy",
    component: _136b53dd,
    name: "privacy-policy___hr"
  }, {
    path: "/hr/promo",
    component: _7cca567c,
    name: "promo___hr"
  }, {
    path: "/hr/report-abadoned",
    component: _75091db0,
    name: "report-abadoned___hr"
  }, {
    path: "/hr/reset",
    component: _1d345abc,
    name: "reset___hr"
  }, {
    path: "/hr/retrieve",
    component: _7f0aae05,
    name: "retrieve___hr"
  }, {
    path: "/hr/signup",
    component: _187de13e,
    name: "signup___hr"
  }, {
    path: "/hr/sm-feedback",
    component: _bd27bb8c,
    name: "sm-feedback___hr"
  }, {
    path: "/hr/support",
    component: _404701ca,
    name: "support___hr"
  }, {
    path: "/hu/account",
    component: _c9b264cc,
    name: "account___hu",
    children: [{
      path: "bind",
      component: _55b147c2,
      name: "account-index-bind___hu"
    }, {
      path: "contact",
      component: _574a1c2b,
      name: "account-index-contact___hu"
    }, {
      path: "order",
      component: _51d988dc,
      children: [{
        path: "",
        component: _b78a0b42,
        name: "account-index-order-index___hu"
      }, {
        path: ":id",
        component: _4e9beef2,
        name: "account-index-order-index-id___hu"
      }]
    }, {
      path: "passenger",
      component: _703c2236,
      name: "account-index-passenger___hu"
    }, {
      path: "password",
      component: _6a3b00a0,
      name: "account-index-password___hu"
    }, {
      path: "profile",
      component: _5f719534,
      name: "account-index-profile___hu"
    }, {
      path: "review",
      component: _4754ee1d,
      name: "account-index-review___hu"
    }]
  }, {
    path: "/hu/app-feedback",
    component: _bd27bb8c,
    name: "app-feedback___hu"
  }, {
    path: "/hu/choose-country",
    component: _5571e029,
    name: "choose-country___hu"
  }, {
    path: "/hu/confirmemail",
    component: _3c3d88b6,
    name: "confirmemail___hu"
  }, {
    path: "/hu/contact",
    component: _1e34d3cd,
    name: "contact___hu"
  }, {
    path: "/hu/covid19",
    component: _4e6e0f37,
    name: "covid19___hu"
  }, {
    path: "/hu/deals",
    component: _0ef4e8f4,
    name: "deals___hu"
  }, {
    path: "/hu/help",
    component: _404701ca,
    name: "help___hu"
  }, {
    path: "/hu/information-ja",
    component: _0119f9be,
    name: "information-ja___hu"
  }, {
    path: "/hu/login",
    component: _47ffd456,
    name: "login___hu"
  }, {
    path: "/hu/order",
    component: _646e91fb,
    children: [{
      path: "",
      component: _3306703e,
      name: "order-index___hu"
    }, {
      path: ":id",
      component: _36dd87ae,
      name: "order-index-id___hu"
    }]
  }, {
    path: "/hu/pay",
    component: _fb3d235c,
    name: "pay___hu",
    children: [{
      path: ":id?/choose",
      component: _5a973a99,
      name: "pay-id-choose___hu"
    }, {
      path: ":id?/confirmation",
      component: _2b4cda37,
      name: "pay-id-confirmation___hu"
    }]
  }, {
    path: "/hu/payment",
    component: _7e9637b0,
    name: "payment___hu",
    children: [{
      path: ":id?/choose",
      component: _7814eb0a,
      name: "payment-id-choose___hu"
    }, {
      path: ":id?/confirmation",
      component: _55b43899,
      name: "payment-id-confirmation___hu"
    }]
  }, {
    path: "/hu/payment-instruction-cebuana",
    component: _97af7734,
    name: "payment-instruction-cebuana___hu"
  }, {
    path: "/hu/pricealert",
    component: _9f866188,
    name: "pricealert___hu"
  }, {
    path: "/hu/privacy-policy",
    component: _136b53dd,
    name: "privacy-policy___hu"
  }, {
    path: "/hu/promo",
    component: _7cca567c,
    name: "promo___hu"
  }, {
    path: "/hu/report-abadoned",
    component: _75091db0,
    name: "report-abadoned___hu"
  }, {
    path: "/hu/reset",
    component: _1d345abc,
    name: "reset___hu"
  }, {
    path: "/hu/retrieve",
    component: _7f0aae05,
    name: "retrieve___hu"
  }, {
    path: "/hu/signup",
    component: _187de13e,
    name: "signup___hu"
  }, {
    path: "/hu/sm-feedback",
    component: _bd27bb8c,
    name: "sm-feedback___hu"
  }, {
    path: "/hu/support",
    component: _404701ca,
    name: "support___hu"
  }, {
    path: "/id/account",
    component: _c9b264cc,
    name: "account___id",
    children: [{
      path: "bind",
      component: _55b147c2,
      name: "account-index-bind___id"
    }, {
      path: "contact",
      component: _574a1c2b,
      name: "account-index-contact___id"
    }, {
      path: "order",
      component: _51d988dc,
      children: [{
        path: "",
        component: _b78a0b42,
        name: "account-index-order-index___id"
      }, {
        path: ":id",
        component: _4e9beef2,
        name: "account-index-order-index-id___id"
      }]
    }, {
      path: "passenger",
      component: _703c2236,
      name: "account-index-passenger___id"
    }, {
      path: "password",
      component: _6a3b00a0,
      name: "account-index-password___id"
    }, {
      path: "profile",
      component: _5f719534,
      name: "account-index-profile___id"
    }, {
      path: "review",
      component: _4754ee1d,
      name: "account-index-review___id"
    }]
  }, {
    path: "/id/app-feedback",
    component: _bd27bb8c,
    name: "app-feedback___id"
  }, {
    path: "/id/choose-country",
    component: _5571e029,
    name: "choose-country___id"
  }, {
    path: "/id/confirmemail",
    component: _3c3d88b6,
    name: "confirmemail___id"
  }, {
    path: "/id/contact",
    component: _1e34d3cd,
    name: "contact___id"
  }, {
    path: "/id/covid19",
    component: _4e6e0f37,
    name: "covid19___id"
  }, {
    path: "/id/deals",
    component: _0ef4e8f4,
    name: "deals___id"
  }, {
    path: "/id/help",
    component: _404701ca,
    name: "help___id"
  }, {
    path: "/id/information-ja",
    component: _0119f9be,
    name: "information-ja___id"
  }, {
    path: "/id/login",
    component: _47ffd456,
    name: "login___id"
  }, {
    path: "/id/order",
    component: _646e91fb,
    children: [{
      path: "",
      component: _3306703e,
      name: "order-index___id"
    }, {
      path: ":id",
      component: _36dd87ae,
      name: "order-index-id___id"
    }]
  }, {
    path: "/id/pay",
    component: _fb3d235c,
    name: "pay___id",
    children: [{
      path: ":id?/choose",
      component: _5a973a99,
      name: "pay-id-choose___id"
    }, {
      path: ":id?/confirmation",
      component: _2b4cda37,
      name: "pay-id-confirmation___id"
    }]
  }, {
    path: "/id/payment",
    component: _7e9637b0,
    name: "payment___id",
    children: [{
      path: ":id?/choose",
      component: _7814eb0a,
      name: "payment-id-choose___id"
    }, {
      path: ":id?/confirmation",
      component: _55b43899,
      name: "payment-id-confirmation___id"
    }]
  }, {
    path: "/id/payment-instruction-cebuana",
    component: _97af7734,
    name: "payment-instruction-cebuana___id"
  }, {
    path: "/id/pricealert",
    component: _9f866188,
    name: "pricealert___id"
  }, {
    path: "/id/privacy-policy",
    component: _136b53dd,
    name: "privacy-policy___id"
  }, {
    path: "/id/promo",
    component: _7cca567c,
    name: "promo___id"
  }, {
    path: "/id/report-abadoned",
    component: _75091db0,
    name: "report-abadoned___id"
  }, {
    path: "/id/reset",
    component: _1d345abc,
    name: "reset___id"
  }, {
    path: "/id/retrieve",
    component: _7f0aae05,
    name: "retrieve___id"
  }, {
    path: "/id/signup",
    component: _187de13e,
    name: "signup___id"
  }, {
    path: "/id/sm-feedback",
    component: _bd27bb8c,
    name: "sm-feedback___id"
  }, {
    path: "/id/support",
    component: _404701ca,
    name: "support___id"
  }, {
    path: "/it/account",
    component: _c9b264cc,
    name: "account___it",
    children: [{
      path: "bind",
      component: _55b147c2,
      name: "account-index-bind___it"
    }, {
      path: "contact",
      component: _574a1c2b,
      name: "account-index-contact___it"
    }, {
      path: "order",
      component: _51d988dc,
      children: [{
        path: "",
        component: _b78a0b42,
        name: "account-index-order-index___it"
      }, {
        path: ":id",
        component: _4e9beef2,
        name: "account-index-order-index-id___it"
      }]
    }, {
      path: "passenger",
      component: _703c2236,
      name: "account-index-passenger___it"
    }, {
      path: "password",
      component: _6a3b00a0,
      name: "account-index-password___it"
    }, {
      path: "profile",
      component: _5f719534,
      name: "account-index-profile___it"
    }, {
      path: "review",
      component: _4754ee1d,
      name: "account-index-review___it"
    }]
  }, {
    path: "/it/app-feedback",
    component: _bd27bb8c,
    name: "app-feedback___it"
  }, {
    path: "/it/choose-country",
    component: _5571e029,
    name: "choose-country___it"
  }, {
    path: "/it/confirmemail",
    component: _3c3d88b6,
    name: "confirmemail___it"
  }, {
    path: "/it/contact",
    component: _1e34d3cd,
    name: "contact___it"
  }, {
    path: "/it/covid19",
    component: _4e6e0f37,
    name: "covid19___it"
  }, {
    path: "/it/deals",
    component: _0ef4e8f4,
    name: "deals___it"
  }, {
    path: "/it/help",
    component: _404701ca,
    name: "help___it"
  }, {
    path: "/it/information-ja",
    component: _0119f9be,
    name: "information-ja___it"
  }, {
    path: "/it/login",
    component: _47ffd456,
    name: "login___it"
  }, {
    path: "/it/order",
    component: _646e91fb,
    children: [{
      path: "",
      component: _3306703e,
      name: "order-index___it"
    }, {
      path: ":id",
      component: _36dd87ae,
      name: "order-index-id___it"
    }]
  }, {
    path: "/it/pay",
    component: _fb3d235c,
    name: "pay___it",
    children: [{
      path: ":id?/choose",
      component: _5a973a99,
      name: "pay-id-choose___it"
    }, {
      path: ":id?/confirmation",
      component: _2b4cda37,
      name: "pay-id-confirmation___it"
    }]
  }, {
    path: "/it/payment",
    component: _7e9637b0,
    name: "payment___it",
    children: [{
      path: ":id?/choose",
      component: _7814eb0a,
      name: "payment-id-choose___it"
    }, {
      path: ":id?/confirmation",
      component: _55b43899,
      name: "payment-id-confirmation___it"
    }]
  }, {
    path: "/it/payment-instruction-cebuana",
    component: _97af7734,
    name: "payment-instruction-cebuana___it"
  }, {
    path: "/it/pricealert",
    component: _9f866188,
    name: "pricealert___it"
  }, {
    path: "/it/privacy-policy",
    component: _136b53dd,
    name: "privacy-policy___it"
  }, {
    path: "/it/promo",
    component: _7cca567c,
    name: "promo___it"
  }, {
    path: "/it/report-abadoned",
    component: _75091db0,
    name: "report-abadoned___it"
  }, {
    path: "/it/reset",
    component: _1d345abc,
    name: "reset___it"
  }, {
    path: "/it/retrieve",
    component: _7f0aae05,
    name: "retrieve___it"
  }, {
    path: "/it/signup",
    component: _187de13e,
    name: "signup___it"
  }, {
    path: "/it/sm-feedback",
    component: _bd27bb8c,
    name: "sm-feedback___it"
  }, {
    path: "/it/support",
    component: _404701ca,
    name: "support___it"
  }, {
    path: "/ja/account",
    component: _c9b264cc,
    name: "account___ja",
    children: [{
      path: "bind",
      component: _55b147c2,
      name: "account-index-bind___ja"
    }, {
      path: "contact",
      component: _574a1c2b,
      name: "account-index-contact___ja"
    }, {
      path: "order",
      component: _51d988dc,
      children: [{
        path: "",
        component: _b78a0b42,
        name: "account-index-order-index___ja"
      }, {
        path: ":id",
        component: _4e9beef2,
        name: "account-index-order-index-id___ja"
      }]
    }, {
      path: "passenger",
      component: _703c2236,
      name: "account-index-passenger___ja"
    }, {
      path: "password",
      component: _6a3b00a0,
      name: "account-index-password___ja"
    }, {
      path: "profile",
      component: _5f719534,
      name: "account-index-profile___ja"
    }, {
      path: "review",
      component: _4754ee1d,
      name: "account-index-review___ja"
    }]
  }, {
    path: "/ja/app-feedback",
    component: _bd27bb8c,
    name: "app-feedback___ja"
  }, {
    path: "/ja/choose-country",
    component: _5571e029,
    name: "choose-country___ja"
  }, {
    path: "/ja/confirmemail",
    component: _3c3d88b6,
    name: "confirmemail___ja"
  }, {
    path: "/ja/contact",
    component: _1e34d3cd,
    name: "contact___ja"
  }, {
    path: "/ja/covid19",
    component: _4e6e0f37,
    name: "covid19___ja"
  }, {
    path: "/ja/deals",
    component: _0ef4e8f4,
    name: "deals___ja"
  }, {
    path: "/ja/help",
    component: _404701ca,
    name: "help___ja"
  }, {
    path: "/ja/information-ja",
    component: _0119f9be,
    name: "information-ja___ja"
  }, {
    path: "/ja/login",
    component: _47ffd456,
    name: "login___ja"
  }, {
    path: "/ja/order",
    component: _646e91fb,
    children: [{
      path: "",
      component: _3306703e,
      name: "order-index___ja"
    }, {
      path: ":id",
      component: _36dd87ae,
      name: "order-index-id___ja"
    }]
  }, {
    path: "/ja/pay",
    component: _fb3d235c,
    name: "pay___ja",
    children: [{
      path: ":id?/choose",
      component: _5a973a99,
      name: "pay-id-choose___ja"
    }, {
      path: ":id?/confirmation",
      component: _2b4cda37,
      name: "pay-id-confirmation___ja"
    }]
  }, {
    path: "/ja/payment",
    component: _7e9637b0,
    name: "payment___ja",
    children: [{
      path: ":id?/choose",
      component: _7814eb0a,
      name: "payment-id-choose___ja"
    }, {
      path: ":id?/confirmation",
      component: _55b43899,
      name: "payment-id-confirmation___ja"
    }]
  }, {
    path: "/ja/payment-instruction-cebuana",
    component: _97af7734,
    name: "payment-instruction-cebuana___ja"
  }, {
    path: "/ja/pricealert",
    component: _9f866188,
    name: "pricealert___ja"
  }, {
    path: "/ja/privacy-policy",
    component: _136b53dd,
    name: "privacy-policy___ja"
  }, {
    path: "/ja/promo",
    component: _7cca567c,
    name: "promo___ja"
  }, {
    path: "/ja/report-abadoned",
    component: _75091db0,
    name: "report-abadoned___ja"
  }, {
    path: "/ja/reset",
    component: _1d345abc,
    name: "reset___ja"
  }, {
    path: "/ja/retrieve",
    component: _7f0aae05,
    name: "retrieve___ja"
  }, {
    path: "/ja/signup",
    component: _187de13e,
    name: "signup___ja"
  }, {
    path: "/ja/sm-feedback",
    component: _bd27bb8c,
    name: "sm-feedback___ja"
  }, {
    path: "/ja/support",
    component: _404701ca,
    name: "support___ja"
  }, {
    path: "/km/account",
    component: _c9b264cc,
    name: "account___km",
    children: [{
      path: "bind",
      component: _55b147c2,
      name: "account-index-bind___km"
    }, {
      path: "contact",
      component: _574a1c2b,
      name: "account-index-contact___km"
    }, {
      path: "order",
      component: _51d988dc,
      children: [{
        path: "",
        component: _b78a0b42,
        name: "account-index-order-index___km"
      }, {
        path: ":id",
        component: _4e9beef2,
        name: "account-index-order-index-id___km"
      }]
    }, {
      path: "passenger",
      component: _703c2236,
      name: "account-index-passenger___km"
    }, {
      path: "password",
      component: _6a3b00a0,
      name: "account-index-password___km"
    }, {
      path: "profile",
      component: _5f719534,
      name: "account-index-profile___km"
    }, {
      path: "review",
      component: _4754ee1d,
      name: "account-index-review___km"
    }]
  }, {
    path: "/km/app-feedback",
    component: _bd27bb8c,
    name: "app-feedback___km"
  }, {
    path: "/km/choose-country",
    component: _5571e029,
    name: "choose-country___km"
  }, {
    path: "/km/confirmemail",
    component: _3c3d88b6,
    name: "confirmemail___km"
  }, {
    path: "/km/contact",
    component: _1e34d3cd,
    name: "contact___km"
  }, {
    path: "/km/covid19",
    component: _4e6e0f37,
    name: "covid19___km"
  }, {
    path: "/km/deals",
    component: _0ef4e8f4,
    name: "deals___km"
  }, {
    path: "/km/help",
    component: _404701ca,
    name: "help___km"
  }, {
    path: "/km/information-ja",
    component: _0119f9be,
    name: "information-ja___km"
  }, {
    path: "/km/login",
    component: _47ffd456,
    name: "login___km"
  }, {
    path: "/km/order",
    component: _646e91fb,
    children: [{
      path: "",
      component: _3306703e,
      name: "order-index___km"
    }, {
      path: ":id",
      component: _36dd87ae,
      name: "order-index-id___km"
    }]
  }, {
    path: "/km/pay",
    component: _fb3d235c,
    name: "pay___km",
    children: [{
      path: ":id?/choose",
      component: _5a973a99,
      name: "pay-id-choose___km"
    }, {
      path: ":id?/confirmation",
      component: _2b4cda37,
      name: "pay-id-confirmation___km"
    }]
  }, {
    path: "/km/payment",
    component: _7e9637b0,
    name: "payment___km",
    children: [{
      path: ":id?/choose",
      component: _7814eb0a,
      name: "payment-id-choose___km"
    }, {
      path: ":id?/confirmation",
      component: _55b43899,
      name: "payment-id-confirmation___km"
    }]
  }, {
    path: "/km/payment-instruction-cebuana",
    component: _97af7734,
    name: "payment-instruction-cebuana___km"
  }, {
    path: "/km/pricealert",
    component: _9f866188,
    name: "pricealert___km"
  }, {
    path: "/km/privacy-policy",
    component: _136b53dd,
    name: "privacy-policy___km"
  }, {
    path: "/km/promo",
    component: _7cca567c,
    name: "promo___km"
  }, {
    path: "/km/report-abadoned",
    component: _75091db0,
    name: "report-abadoned___km"
  }, {
    path: "/km/reset",
    component: _1d345abc,
    name: "reset___km"
  }, {
    path: "/km/retrieve",
    component: _7f0aae05,
    name: "retrieve___km"
  }, {
    path: "/km/signup",
    component: _187de13e,
    name: "signup___km"
  }, {
    path: "/km/sm-feedback",
    component: _bd27bb8c,
    name: "sm-feedback___km"
  }, {
    path: "/km/support",
    component: _404701ca,
    name: "support___km"
  }, {
    path: "/ko/account",
    component: _c9b264cc,
    name: "account___ko",
    children: [{
      path: "bind",
      component: _55b147c2,
      name: "account-index-bind___ko"
    }, {
      path: "contact",
      component: _574a1c2b,
      name: "account-index-contact___ko"
    }, {
      path: "order",
      component: _51d988dc,
      children: [{
        path: "",
        component: _b78a0b42,
        name: "account-index-order-index___ko"
      }, {
        path: ":id",
        component: _4e9beef2,
        name: "account-index-order-index-id___ko"
      }]
    }, {
      path: "passenger",
      component: _703c2236,
      name: "account-index-passenger___ko"
    }, {
      path: "password",
      component: _6a3b00a0,
      name: "account-index-password___ko"
    }, {
      path: "profile",
      component: _5f719534,
      name: "account-index-profile___ko"
    }, {
      path: "review",
      component: _4754ee1d,
      name: "account-index-review___ko"
    }]
  }, {
    path: "/ko/app-feedback",
    component: _bd27bb8c,
    name: "app-feedback___ko"
  }, {
    path: "/ko/choose-country",
    component: _5571e029,
    name: "choose-country___ko"
  }, {
    path: "/ko/confirmemail",
    component: _3c3d88b6,
    name: "confirmemail___ko"
  }, {
    path: "/ko/contact",
    component: _1e34d3cd,
    name: "contact___ko"
  }, {
    path: "/ko/covid19",
    component: _4e6e0f37,
    name: "covid19___ko"
  }, {
    path: "/ko/deals",
    component: _0ef4e8f4,
    name: "deals___ko"
  }, {
    path: "/ko/help",
    component: _404701ca,
    name: "help___ko"
  }, {
    path: "/ko/information-ja",
    component: _0119f9be,
    name: "information-ja___ko"
  }, {
    path: "/ko/login",
    component: _47ffd456,
    name: "login___ko"
  }, {
    path: "/ko/order",
    component: _646e91fb,
    children: [{
      path: "",
      component: _3306703e,
      name: "order-index___ko"
    }, {
      path: ":id",
      component: _36dd87ae,
      name: "order-index-id___ko"
    }]
  }, {
    path: "/ko/pay",
    component: _fb3d235c,
    name: "pay___ko",
    children: [{
      path: ":id?/choose",
      component: _5a973a99,
      name: "pay-id-choose___ko"
    }, {
      path: ":id?/confirmation",
      component: _2b4cda37,
      name: "pay-id-confirmation___ko"
    }]
  }, {
    path: "/ko/payment",
    component: _7e9637b0,
    name: "payment___ko",
    children: [{
      path: ":id?/choose",
      component: _7814eb0a,
      name: "payment-id-choose___ko"
    }, {
      path: ":id?/confirmation",
      component: _55b43899,
      name: "payment-id-confirmation___ko"
    }]
  }, {
    path: "/ko/payment-instruction-cebuana",
    component: _97af7734,
    name: "payment-instruction-cebuana___ko"
  }, {
    path: "/ko/pricealert",
    component: _9f866188,
    name: "pricealert___ko"
  }, {
    path: "/ko/privacy-policy",
    component: _136b53dd,
    name: "privacy-policy___ko"
  }, {
    path: "/ko/promo",
    component: _7cca567c,
    name: "promo___ko"
  }, {
    path: "/ko/report-abadoned",
    component: _75091db0,
    name: "report-abadoned___ko"
  }, {
    path: "/ko/reset",
    component: _1d345abc,
    name: "reset___ko"
  }, {
    path: "/ko/retrieve",
    component: _7f0aae05,
    name: "retrieve___ko"
  }, {
    path: "/ko/signup",
    component: _187de13e,
    name: "signup___ko"
  }, {
    path: "/ko/sm-feedback",
    component: _bd27bb8c,
    name: "sm-feedback___ko"
  }, {
    path: "/ko/support",
    component: _404701ca,
    name: "support___ko"
  }, {
    path: "/ms/account",
    component: _c9b264cc,
    name: "account___ms",
    children: [{
      path: "bind",
      component: _55b147c2,
      name: "account-index-bind___ms"
    }, {
      path: "contact",
      component: _574a1c2b,
      name: "account-index-contact___ms"
    }, {
      path: "order",
      component: _51d988dc,
      children: [{
        path: "",
        component: _b78a0b42,
        name: "account-index-order-index___ms"
      }, {
        path: ":id",
        component: _4e9beef2,
        name: "account-index-order-index-id___ms"
      }]
    }, {
      path: "passenger",
      component: _703c2236,
      name: "account-index-passenger___ms"
    }, {
      path: "password",
      component: _6a3b00a0,
      name: "account-index-password___ms"
    }, {
      path: "profile",
      component: _5f719534,
      name: "account-index-profile___ms"
    }, {
      path: "review",
      component: _4754ee1d,
      name: "account-index-review___ms"
    }]
  }, {
    path: "/ms/app-feedback",
    component: _bd27bb8c,
    name: "app-feedback___ms"
  }, {
    path: "/ms/choose-country",
    component: _5571e029,
    name: "choose-country___ms"
  }, {
    path: "/ms/confirmemail",
    component: _3c3d88b6,
    name: "confirmemail___ms"
  }, {
    path: "/ms/contact",
    component: _1e34d3cd,
    name: "contact___ms"
  }, {
    path: "/ms/covid19",
    component: _4e6e0f37,
    name: "covid19___ms"
  }, {
    path: "/ms/deals",
    component: _0ef4e8f4,
    name: "deals___ms"
  }, {
    path: "/ms/help",
    component: _404701ca,
    name: "help___ms"
  }, {
    path: "/ms/information-ja",
    component: _0119f9be,
    name: "information-ja___ms"
  }, {
    path: "/ms/login",
    component: _47ffd456,
    name: "login___ms"
  }, {
    path: "/ms/order",
    component: _646e91fb,
    children: [{
      path: "",
      component: _3306703e,
      name: "order-index___ms"
    }, {
      path: ":id",
      component: _36dd87ae,
      name: "order-index-id___ms"
    }]
  }, {
    path: "/ms/pay",
    component: _fb3d235c,
    name: "pay___ms",
    children: [{
      path: ":id?/choose",
      component: _5a973a99,
      name: "pay-id-choose___ms"
    }, {
      path: ":id?/confirmation",
      component: _2b4cda37,
      name: "pay-id-confirmation___ms"
    }]
  }, {
    path: "/ms/payment",
    component: _7e9637b0,
    name: "payment___ms",
    children: [{
      path: ":id?/choose",
      component: _7814eb0a,
      name: "payment-id-choose___ms"
    }, {
      path: ":id?/confirmation",
      component: _55b43899,
      name: "payment-id-confirmation___ms"
    }]
  }, {
    path: "/ms/payment-instruction-cebuana",
    component: _97af7734,
    name: "payment-instruction-cebuana___ms"
  }, {
    path: "/ms/pricealert",
    component: _9f866188,
    name: "pricealert___ms"
  }, {
    path: "/ms/privacy-policy",
    component: _136b53dd,
    name: "privacy-policy___ms"
  }, {
    path: "/ms/promo",
    component: _7cca567c,
    name: "promo___ms"
  }, {
    path: "/ms/report-abadoned",
    component: _75091db0,
    name: "report-abadoned___ms"
  }, {
    path: "/ms/reset",
    component: _1d345abc,
    name: "reset___ms"
  }, {
    path: "/ms/retrieve",
    component: _7f0aae05,
    name: "retrieve___ms"
  }, {
    path: "/ms/signup",
    component: _187de13e,
    name: "signup___ms"
  }, {
    path: "/ms/sm-feedback",
    component: _bd27bb8c,
    name: "sm-feedback___ms"
  }, {
    path: "/ms/support",
    component: _404701ca,
    name: "support___ms"
  }, {
    path: "/nb/account",
    component: _c9b264cc,
    name: "account___nb",
    children: [{
      path: "bind",
      component: _55b147c2,
      name: "account-index-bind___nb"
    }, {
      path: "contact",
      component: _574a1c2b,
      name: "account-index-contact___nb"
    }, {
      path: "order",
      component: _51d988dc,
      children: [{
        path: "",
        component: _b78a0b42,
        name: "account-index-order-index___nb"
      }, {
        path: ":id",
        component: _4e9beef2,
        name: "account-index-order-index-id___nb"
      }]
    }, {
      path: "passenger",
      component: _703c2236,
      name: "account-index-passenger___nb"
    }, {
      path: "password",
      component: _6a3b00a0,
      name: "account-index-password___nb"
    }, {
      path: "profile",
      component: _5f719534,
      name: "account-index-profile___nb"
    }, {
      path: "review",
      component: _4754ee1d,
      name: "account-index-review___nb"
    }]
  }, {
    path: "/nb/app-feedback",
    component: _bd27bb8c,
    name: "app-feedback___nb"
  }, {
    path: "/nb/choose-country",
    component: _5571e029,
    name: "choose-country___nb"
  }, {
    path: "/nb/confirmemail",
    component: _3c3d88b6,
    name: "confirmemail___nb"
  }, {
    path: "/nb/contact",
    component: _1e34d3cd,
    name: "contact___nb"
  }, {
    path: "/nb/covid19",
    component: _4e6e0f37,
    name: "covid19___nb"
  }, {
    path: "/nb/deals",
    component: _0ef4e8f4,
    name: "deals___nb"
  }, {
    path: "/nb/help",
    component: _404701ca,
    name: "help___nb"
  }, {
    path: "/nb/information-ja",
    component: _0119f9be,
    name: "information-ja___nb"
  }, {
    path: "/nb/login",
    component: _47ffd456,
    name: "login___nb"
  }, {
    path: "/nb/order",
    component: _646e91fb,
    children: [{
      path: "",
      component: _3306703e,
      name: "order-index___nb"
    }, {
      path: ":id",
      component: _36dd87ae,
      name: "order-index-id___nb"
    }]
  }, {
    path: "/nb/pay",
    component: _fb3d235c,
    name: "pay___nb",
    children: [{
      path: ":id?/choose",
      component: _5a973a99,
      name: "pay-id-choose___nb"
    }, {
      path: ":id?/confirmation",
      component: _2b4cda37,
      name: "pay-id-confirmation___nb"
    }]
  }, {
    path: "/nb/payment",
    component: _7e9637b0,
    name: "payment___nb",
    children: [{
      path: ":id?/choose",
      component: _7814eb0a,
      name: "payment-id-choose___nb"
    }, {
      path: ":id?/confirmation",
      component: _55b43899,
      name: "payment-id-confirmation___nb"
    }]
  }, {
    path: "/nb/payment-instruction-cebuana",
    component: _97af7734,
    name: "payment-instruction-cebuana___nb"
  }, {
    path: "/nb/pricealert",
    component: _9f866188,
    name: "pricealert___nb"
  }, {
    path: "/nb/privacy-policy",
    component: _136b53dd,
    name: "privacy-policy___nb"
  }, {
    path: "/nb/promo",
    component: _7cca567c,
    name: "promo___nb"
  }, {
    path: "/nb/report-abadoned",
    component: _75091db0,
    name: "report-abadoned___nb"
  }, {
    path: "/nb/reset",
    component: _1d345abc,
    name: "reset___nb"
  }, {
    path: "/nb/retrieve",
    component: _7f0aae05,
    name: "retrieve___nb"
  }, {
    path: "/nb/signup",
    component: _187de13e,
    name: "signup___nb"
  }, {
    path: "/nb/sm-feedback",
    component: _bd27bb8c,
    name: "sm-feedback___nb"
  }, {
    path: "/nb/support",
    component: _404701ca,
    name: "support___nb"
  }, {
    path: "/nl/account",
    component: _c9b264cc,
    name: "account___nl",
    children: [{
      path: "bind",
      component: _55b147c2,
      name: "account-index-bind___nl"
    }, {
      path: "contact",
      component: _574a1c2b,
      name: "account-index-contact___nl"
    }, {
      path: "order",
      component: _51d988dc,
      children: [{
        path: "",
        component: _b78a0b42,
        name: "account-index-order-index___nl"
      }, {
        path: ":id",
        component: _4e9beef2,
        name: "account-index-order-index-id___nl"
      }]
    }, {
      path: "passenger",
      component: _703c2236,
      name: "account-index-passenger___nl"
    }, {
      path: "password",
      component: _6a3b00a0,
      name: "account-index-password___nl"
    }, {
      path: "profile",
      component: _5f719534,
      name: "account-index-profile___nl"
    }, {
      path: "review",
      component: _4754ee1d,
      name: "account-index-review___nl"
    }]
  }, {
    path: "/nl/app-feedback",
    component: _bd27bb8c,
    name: "app-feedback___nl"
  }, {
    path: "/nl/choose-country",
    component: _5571e029,
    name: "choose-country___nl"
  }, {
    path: "/nl/confirmemail",
    component: _3c3d88b6,
    name: "confirmemail___nl"
  }, {
    path: "/nl/contact",
    component: _1e34d3cd,
    name: "contact___nl"
  }, {
    path: "/nl/covid19",
    component: _4e6e0f37,
    name: "covid19___nl"
  }, {
    path: "/nl/deals",
    component: _0ef4e8f4,
    name: "deals___nl"
  }, {
    path: "/nl/help",
    component: _404701ca,
    name: "help___nl"
  }, {
    path: "/nl/information-ja",
    component: _0119f9be,
    name: "information-ja___nl"
  }, {
    path: "/nl/login",
    component: _47ffd456,
    name: "login___nl"
  }, {
    path: "/nl/order",
    component: _646e91fb,
    children: [{
      path: "",
      component: _3306703e,
      name: "order-index___nl"
    }, {
      path: ":id",
      component: _36dd87ae,
      name: "order-index-id___nl"
    }]
  }, {
    path: "/nl/pay",
    component: _fb3d235c,
    name: "pay___nl",
    children: [{
      path: ":id?/choose",
      component: _5a973a99,
      name: "pay-id-choose___nl"
    }, {
      path: ":id?/confirmation",
      component: _2b4cda37,
      name: "pay-id-confirmation___nl"
    }]
  }, {
    path: "/nl/payment",
    component: _7e9637b0,
    name: "payment___nl",
    children: [{
      path: ":id?/choose",
      component: _7814eb0a,
      name: "payment-id-choose___nl"
    }, {
      path: ":id?/confirmation",
      component: _55b43899,
      name: "payment-id-confirmation___nl"
    }]
  }, {
    path: "/nl/payment-instruction-cebuana",
    component: _97af7734,
    name: "payment-instruction-cebuana___nl"
  }, {
    path: "/nl/pricealert",
    component: _9f866188,
    name: "pricealert___nl"
  }, {
    path: "/nl/privacy-policy",
    component: _136b53dd,
    name: "privacy-policy___nl"
  }, {
    path: "/nl/promo",
    component: _7cca567c,
    name: "promo___nl"
  }, {
    path: "/nl/report-abadoned",
    component: _75091db0,
    name: "report-abadoned___nl"
  }, {
    path: "/nl/reset",
    component: _1d345abc,
    name: "reset___nl"
  }, {
    path: "/nl/retrieve",
    component: _7f0aae05,
    name: "retrieve___nl"
  }, {
    path: "/nl/signup",
    component: _187de13e,
    name: "signup___nl"
  }, {
    path: "/nl/sm-feedback",
    component: _bd27bb8c,
    name: "sm-feedback___nl"
  }, {
    path: "/nl/support",
    component: _404701ca,
    name: "support___nl"
  }, {
    path: "/pl/account",
    component: _c9b264cc,
    name: "account___pl",
    children: [{
      path: "bind",
      component: _55b147c2,
      name: "account-index-bind___pl"
    }, {
      path: "contact",
      component: _574a1c2b,
      name: "account-index-contact___pl"
    }, {
      path: "order",
      component: _51d988dc,
      children: [{
        path: "",
        component: _b78a0b42,
        name: "account-index-order-index___pl"
      }, {
        path: ":id",
        component: _4e9beef2,
        name: "account-index-order-index-id___pl"
      }]
    }, {
      path: "passenger",
      component: _703c2236,
      name: "account-index-passenger___pl"
    }, {
      path: "password",
      component: _6a3b00a0,
      name: "account-index-password___pl"
    }, {
      path: "profile",
      component: _5f719534,
      name: "account-index-profile___pl"
    }, {
      path: "review",
      component: _4754ee1d,
      name: "account-index-review___pl"
    }]
  }, {
    path: "/pl/app-feedback",
    component: _bd27bb8c,
    name: "app-feedback___pl"
  }, {
    path: "/pl/choose-country",
    component: _5571e029,
    name: "choose-country___pl"
  }, {
    path: "/pl/confirmemail",
    component: _3c3d88b6,
    name: "confirmemail___pl"
  }, {
    path: "/pl/contact",
    component: _1e34d3cd,
    name: "contact___pl"
  }, {
    path: "/pl/covid19",
    component: _4e6e0f37,
    name: "covid19___pl"
  }, {
    path: "/pl/deals",
    component: _0ef4e8f4,
    name: "deals___pl"
  }, {
    path: "/pl/help",
    component: _404701ca,
    name: "help___pl"
  }, {
    path: "/pl/information-ja",
    component: _0119f9be,
    name: "information-ja___pl"
  }, {
    path: "/pl/login",
    component: _47ffd456,
    name: "login___pl"
  }, {
    path: "/pl/order",
    component: _646e91fb,
    children: [{
      path: "",
      component: _3306703e,
      name: "order-index___pl"
    }, {
      path: ":id",
      component: _36dd87ae,
      name: "order-index-id___pl"
    }]
  }, {
    path: "/pl/pay",
    component: _fb3d235c,
    name: "pay___pl",
    children: [{
      path: ":id?/choose",
      component: _5a973a99,
      name: "pay-id-choose___pl"
    }, {
      path: ":id?/confirmation",
      component: _2b4cda37,
      name: "pay-id-confirmation___pl"
    }]
  }, {
    path: "/pl/payment",
    component: _7e9637b0,
    name: "payment___pl",
    children: [{
      path: ":id?/choose",
      component: _7814eb0a,
      name: "payment-id-choose___pl"
    }, {
      path: ":id?/confirmation",
      component: _55b43899,
      name: "payment-id-confirmation___pl"
    }]
  }, {
    path: "/pl/payment-instruction-cebuana",
    component: _97af7734,
    name: "payment-instruction-cebuana___pl"
  }, {
    path: "/pl/pricealert",
    component: _9f866188,
    name: "pricealert___pl"
  }, {
    path: "/pl/privacy-policy",
    component: _136b53dd,
    name: "privacy-policy___pl"
  }, {
    path: "/pl/promo",
    component: _7cca567c,
    name: "promo___pl"
  }, {
    path: "/pl/report-abadoned",
    component: _75091db0,
    name: "report-abadoned___pl"
  }, {
    path: "/pl/reset",
    component: _1d345abc,
    name: "reset___pl"
  }, {
    path: "/pl/retrieve",
    component: _7f0aae05,
    name: "retrieve___pl"
  }, {
    path: "/pl/signup",
    component: _187de13e,
    name: "signup___pl"
  }, {
    path: "/pl/sm-feedback",
    component: _bd27bb8c,
    name: "sm-feedback___pl"
  }, {
    path: "/pl/support",
    component: _404701ca,
    name: "support___pl"
  }, {
    path: "/pt/account",
    component: _c9b264cc,
    name: "account___pt",
    children: [{
      path: "bind",
      component: _55b147c2,
      name: "account-index-bind___pt"
    }, {
      path: "contact",
      component: _574a1c2b,
      name: "account-index-contact___pt"
    }, {
      path: "order",
      component: _51d988dc,
      children: [{
        path: "",
        component: _b78a0b42,
        name: "account-index-order-index___pt"
      }, {
        path: ":id",
        component: _4e9beef2,
        name: "account-index-order-index-id___pt"
      }]
    }, {
      path: "passenger",
      component: _703c2236,
      name: "account-index-passenger___pt"
    }, {
      path: "password",
      component: _6a3b00a0,
      name: "account-index-password___pt"
    }, {
      path: "profile",
      component: _5f719534,
      name: "account-index-profile___pt"
    }, {
      path: "review",
      component: _4754ee1d,
      name: "account-index-review___pt"
    }]
  }, {
    path: "/pt/app-feedback",
    component: _bd27bb8c,
    name: "app-feedback___pt"
  }, {
    path: "/pt/choose-country",
    component: _5571e029,
    name: "choose-country___pt"
  }, {
    path: "/pt/confirmemail",
    component: _3c3d88b6,
    name: "confirmemail___pt"
  }, {
    path: "/pt/contact",
    component: _1e34d3cd,
    name: "contact___pt"
  }, {
    path: "/pt/covid19",
    component: _4e6e0f37,
    name: "covid19___pt"
  }, {
    path: "/pt/deals",
    component: _0ef4e8f4,
    name: "deals___pt"
  }, {
    path: "/pt/help",
    component: _404701ca,
    name: "help___pt"
  }, {
    path: "/pt/information-ja",
    component: _0119f9be,
    name: "information-ja___pt"
  }, {
    path: "/pt/login",
    component: _47ffd456,
    name: "login___pt"
  }, {
    path: "/pt/order",
    component: _646e91fb,
    children: [{
      path: "",
      component: _3306703e,
      name: "order-index___pt"
    }, {
      path: ":id",
      component: _36dd87ae,
      name: "order-index-id___pt"
    }]
  }, {
    path: "/pt/pay",
    component: _fb3d235c,
    name: "pay___pt",
    children: [{
      path: ":id?/choose",
      component: _5a973a99,
      name: "pay-id-choose___pt"
    }, {
      path: ":id?/confirmation",
      component: _2b4cda37,
      name: "pay-id-confirmation___pt"
    }]
  }, {
    path: "/pt/payment",
    component: _7e9637b0,
    name: "payment___pt",
    children: [{
      path: ":id?/choose",
      component: _7814eb0a,
      name: "payment-id-choose___pt"
    }, {
      path: ":id?/confirmation",
      component: _55b43899,
      name: "payment-id-confirmation___pt"
    }]
  }, {
    path: "/pt/payment-instruction-cebuana",
    component: _97af7734,
    name: "payment-instruction-cebuana___pt"
  }, {
    path: "/pt/pricealert",
    component: _9f866188,
    name: "pricealert___pt"
  }, {
    path: "/pt/privacy-policy",
    component: _136b53dd,
    name: "privacy-policy___pt"
  }, {
    path: "/pt/promo",
    component: _7cca567c,
    name: "promo___pt"
  }, {
    path: "/pt/report-abadoned",
    component: _75091db0,
    name: "report-abadoned___pt"
  }, {
    path: "/pt/reset",
    component: _1d345abc,
    name: "reset___pt"
  }, {
    path: "/pt/retrieve",
    component: _7f0aae05,
    name: "retrieve___pt"
  }, {
    path: "/pt/signup",
    component: _187de13e,
    name: "signup___pt"
  }, {
    path: "/pt/sm-feedback",
    component: _bd27bb8c,
    name: "sm-feedback___pt"
  }, {
    path: "/pt/support",
    component: _404701ca,
    name: "support___pt"
  }, {
    path: "/ro/account",
    component: _c9b264cc,
    name: "account___ro",
    children: [{
      path: "bind",
      component: _55b147c2,
      name: "account-index-bind___ro"
    }, {
      path: "contact",
      component: _574a1c2b,
      name: "account-index-contact___ro"
    }, {
      path: "order",
      component: _51d988dc,
      children: [{
        path: "",
        component: _b78a0b42,
        name: "account-index-order-index___ro"
      }, {
        path: ":id",
        component: _4e9beef2,
        name: "account-index-order-index-id___ro"
      }]
    }, {
      path: "passenger",
      component: _703c2236,
      name: "account-index-passenger___ro"
    }, {
      path: "password",
      component: _6a3b00a0,
      name: "account-index-password___ro"
    }, {
      path: "profile",
      component: _5f719534,
      name: "account-index-profile___ro"
    }, {
      path: "review",
      component: _4754ee1d,
      name: "account-index-review___ro"
    }]
  }, {
    path: "/ro/app-feedback",
    component: _bd27bb8c,
    name: "app-feedback___ro"
  }, {
    path: "/ro/choose-country",
    component: _5571e029,
    name: "choose-country___ro"
  }, {
    path: "/ro/confirmemail",
    component: _3c3d88b6,
    name: "confirmemail___ro"
  }, {
    path: "/ro/contact",
    component: _1e34d3cd,
    name: "contact___ro"
  }, {
    path: "/ro/covid19",
    component: _4e6e0f37,
    name: "covid19___ro"
  }, {
    path: "/ro/deals",
    component: _0ef4e8f4,
    name: "deals___ro"
  }, {
    path: "/ro/help",
    component: _404701ca,
    name: "help___ro"
  }, {
    path: "/ro/information-ja",
    component: _0119f9be,
    name: "information-ja___ro"
  }, {
    path: "/ro/login",
    component: _47ffd456,
    name: "login___ro"
  }, {
    path: "/ro/order",
    component: _646e91fb,
    children: [{
      path: "",
      component: _3306703e,
      name: "order-index___ro"
    }, {
      path: ":id",
      component: _36dd87ae,
      name: "order-index-id___ro"
    }]
  }, {
    path: "/ro/pay",
    component: _fb3d235c,
    name: "pay___ro",
    children: [{
      path: ":id?/choose",
      component: _5a973a99,
      name: "pay-id-choose___ro"
    }, {
      path: ":id?/confirmation",
      component: _2b4cda37,
      name: "pay-id-confirmation___ro"
    }]
  }, {
    path: "/ro/payment",
    component: _7e9637b0,
    name: "payment___ro",
    children: [{
      path: ":id?/choose",
      component: _7814eb0a,
      name: "payment-id-choose___ro"
    }, {
      path: ":id?/confirmation",
      component: _55b43899,
      name: "payment-id-confirmation___ro"
    }]
  }, {
    path: "/ro/payment-instruction-cebuana",
    component: _97af7734,
    name: "payment-instruction-cebuana___ro"
  }, {
    path: "/ro/pricealert",
    component: _9f866188,
    name: "pricealert___ro"
  }, {
    path: "/ro/privacy-policy",
    component: _136b53dd,
    name: "privacy-policy___ro"
  }, {
    path: "/ro/promo",
    component: _7cca567c,
    name: "promo___ro"
  }, {
    path: "/ro/report-abadoned",
    component: _75091db0,
    name: "report-abadoned___ro"
  }, {
    path: "/ro/reset",
    component: _1d345abc,
    name: "reset___ro"
  }, {
    path: "/ro/retrieve",
    component: _7f0aae05,
    name: "retrieve___ro"
  }, {
    path: "/ro/signup",
    component: _187de13e,
    name: "signup___ro"
  }, {
    path: "/ro/sm-feedback",
    component: _bd27bb8c,
    name: "sm-feedback___ro"
  }, {
    path: "/ro/support",
    component: _404701ca,
    name: "support___ro"
  }, {
    path: "/ru/account",
    component: _c9b264cc,
    name: "account___ru",
    children: [{
      path: "bind",
      component: _55b147c2,
      name: "account-index-bind___ru"
    }, {
      path: "contact",
      component: _574a1c2b,
      name: "account-index-contact___ru"
    }, {
      path: "order",
      component: _51d988dc,
      children: [{
        path: "",
        component: _b78a0b42,
        name: "account-index-order-index___ru"
      }, {
        path: ":id",
        component: _4e9beef2,
        name: "account-index-order-index-id___ru"
      }]
    }, {
      path: "passenger",
      component: _703c2236,
      name: "account-index-passenger___ru"
    }, {
      path: "password",
      component: _6a3b00a0,
      name: "account-index-password___ru"
    }, {
      path: "profile",
      component: _5f719534,
      name: "account-index-profile___ru"
    }, {
      path: "review",
      component: _4754ee1d,
      name: "account-index-review___ru"
    }]
  }, {
    path: "/ru/app-feedback",
    component: _bd27bb8c,
    name: "app-feedback___ru"
  }, {
    path: "/ru/choose-country",
    component: _5571e029,
    name: "choose-country___ru"
  }, {
    path: "/ru/confirmemail",
    component: _3c3d88b6,
    name: "confirmemail___ru"
  }, {
    path: "/ru/contact",
    component: _1e34d3cd,
    name: "contact___ru"
  }, {
    path: "/ru/covid19",
    component: _4e6e0f37,
    name: "covid19___ru"
  }, {
    path: "/ru/deals",
    component: _0ef4e8f4,
    name: "deals___ru"
  }, {
    path: "/ru/help",
    component: _404701ca,
    name: "help___ru"
  }, {
    path: "/ru/information-ja",
    component: _0119f9be,
    name: "information-ja___ru"
  }, {
    path: "/ru/login",
    component: _47ffd456,
    name: "login___ru"
  }, {
    path: "/ru/order",
    component: _646e91fb,
    children: [{
      path: "",
      component: _3306703e,
      name: "order-index___ru"
    }, {
      path: ":id",
      component: _36dd87ae,
      name: "order-index-id___ru"
    }]
  }, {
    path: "/ru/pay",
    component: _fb3d235c,
    name: "pay___ru",
    children: [{
      path: ":id?/choose",
      component: _5a973a99,
      name: "pay-id-choose___ru"
    }, {
      path: ":id?/confirmation",
      component: _2b4cda37,
      name: "pay-id-confirmation___ru"
    }]
  }, {
    path: "/ru/payment",
    component: _7e9637b0,
    name: "payment___ru",
    children: [{
      path: ":id?/choose",
      component: _7814eb0a,
      name: "payment-id-choose___ru"
    }, {
      path: ":id?/confirmation",
      component: _55b43899,
      name: "payment-id-confirmation___ru"
    }]
  }, {
    path: "/ru/payment-instruction-cebuana",
    component: _97af7734,
    name: "payment-instruction-cebuana___ru"
  }, {
    path: "/ru/pricealert",
    component: _9f866188,
    name: "pricealert___ru"
  }, {
    path: "/ru/privacy-policy",
    component: _136b53dd,
    name: "privacy-policy___ru"
  }, {
    path: "/ru/promo",
    component: _7cca567c,
    name: "promo___ru"
  }, {
    path: "/ru/report-abadoned",
    component: _75091db0,
    name: "report-abadoned___ru"
  }, {
    path: "/ru/reset",
    component: _1d345abc,
    name: "reset___ru"
  }, {
    path: "/ru/retrieve",
    component: _7f0aae05,
    name: "retrieve___ru"
  }, {
    path: "/ru/signup",
    component: _187de13e,
    name: "signup___ru"
  }, {
    path: "/ru/sm-feedback",
    component: _bd27bb8c,
    name: "sm-feedback___ru"
  }, {
    path: "/ru/support",
    component: _404701ca,
    name: "support___ru"
  }, {
    path: "/sk/account",
    component: _c9b264cc,
    name: "account___sk",
    children: [{
      path: "bind",
      component: _55b147c2,
      name: "account-index-bind___sk"
    }, {
      path: "contact",
      component: _574a1c2b,
      name: "account-index-contact___sk"
    }, {
      path: "order",
      component: _51d988dc,
      children: [{
        path: "",
        component: _b78a0b42,
        name: "account-index-order-index___sk"
      }, {
        path: ":id",
        component: _4e9beef2,
        name: "account-index-order-index-id___sk"
      }]
    }, {
      path: "passenger",
      component: _703c2236,
      name: "account-index-passenger___sk"
    }, {
      path: "password",
      component: _6a3b00a0,
      name: "account-index-password___sk"
    }, {
      path: "profile",
      component: _5f719534,
      name: "account-index-profile___sk"
    }, {
      path: "review",
      component: _4754ee1d,
      name: "account-index-review___sk"
    }]
  }, {
    path: "/sk/app-feedback",
    component: _bd27bb8c,
    name: "app-feedback___sk"
  }, {
    path: "/sk/choose-country",
    component: _5571e029,
    name: "choose-country___sk"
  }, {
    path: "/sk/confirmemail",
    component: _3c3d88b6,
    name: "confirmemail___sk"
  }, {
    path: "/sk/contact",
    component: _1e34d3cd,
    name: "contact___sk"
  }, {
    path: "/sk/covid19",
    component: _4e6e0f37,
    name: "covid19___sk"
  }, {
    path: "/sk/deals",
    component: _0ef4e8f4,
    name: "deals___sk"
  }, {
    path: "/sk/help",
    component: _404701ca,
    name: "help___sk"
  }, {
    path: "/sk/information-ja",
    component: _0119f9be,
    name: "information-ja___sk"
  }, {
    path: "/sk/login",
    component: _47ffd456,
    name: "login___sk"
  }, {
    path: "/sk/order",
    component: _646e91fb,
    children: [{
      path: "",
      component: _3306703e,
      name: "order-index___sk"
    }, {
      path: ":id",
      component: _36dd87ae,
      name: "order-index-id___sk"
    }]
  }, {
    path: "/sk/pay",
    component: _fb3d235c,
    name: "pay___sk",
    children: [{
      path: ":id?/choose",
      component: _5a973a99,
      name: "pay-id-choose___sk"
    }, {
      path: ":id?/confirmation",
      component: _2b4cda37,
      name: "pay-id-confirmation___sk"
    }]
  }, {
    path: "/sk/payment",
    component: _7e9637b0,
    name: "payment___sk",
    children: [{
      path: ":id?/choose",
      component: _7814eb0a,
      name: "payment-id-choose___sk"
    }, {
      path: ":id?/confirmation",
      component: _55b43899,
      name: "payment-id-confirmation___sk"
    }]
  }, {
    path: "/sk/payment-instruction-cebuana",
    component: _97af7734,
    name: "payment-instruction-cebuana___sk"
  }, {
    path: "/sk/pricealert",
    component: _9f866188,
    name: "pricealert___sk"
  }, {
    path: "/sk/privacy-policy",
    component: _136b53dd,
    name: "privacy-policy___sk"
  }, {
    path: "/sk/promo",
    component: _7cca567c,
    name: "promo___sk"
  }, {
    path: "/sk/report-abadoned",
    component: _75091db0,
    name: "report-abadoned___sk"
  }, {
    path: "/sk/reset",
    component: _1d345abc,
    name: "reset___sk"
  }, {
    path: "/sk/retrieve",
    component: _7f0aae05,
    name: "retrieve___sk"
  }, {
    path: "/sk/signup",
    component: _187de13e,
    name: "signup___sk"
  }, {
    path: "/sk/sm-feedback",
    component: _bd27bb8c,
    name: "sm-feedback___sk"
  }, {
    path: "/sk/support",
    component: _404701ca,
    name: "support___sk"
  }, {
    path: "/sl/account",
    component: _c9b264cc,
    name: "account___sl",
    children: [{
      path: "bind",
      component: _55b147c2,
      name: "account-index-bind___sl"
    }, {
      path: "contact",
      component: _574a1c2b,
      name: "account-index-contact___sl"
    }, {
      path: "order",
      component: _51d988dc,
      children: [{
        path: "",
        component: _b78a0b42,
        name: "account-index-order-index___sl"
      }, {
        path: ":id",
        component: _4e9beef2,
        name: "account-index-order-index-id___sl"
      }]
    }, {
      path: "passenger",
      component: _703c2236,
      name: "account-index-passenger___sl"
    }, {
      path: "password",
      component: _6a3b00a0,
      name: "account-index-password___sl"
    }, {
      path: "profile",
      component: _5f719534,
      name: "account-index-profile___sl"
    }, {
      path: "review",
      component: _4754ee1d,
      name: "account-index-review___sl"
    }]
  }, {
    path: "/sl/app-feedback",
    component: _bd27bb8c,
    name: "app-feedback___sl"
  }, {
    path: "/sl/choose-country",
    component: _5571e029,
    name: "choose-country___sl"
  }, {
    path: "/sl/confirmemail",
    component: _3c3d88b6,
    name: "confirmemail___sl"
  }, {
    path: "/sl/contact",
    component: _1e34d3cd,
    name: "contact___sl"
  }, {
    path: "/sl/covid19",
    component: _4e6e0f37,
    name: "covid19___sl"
  }, {
    path: "/sl/deals",
    component: _0ef4e8f4,
    name: "deals___sl"
  }, {
    path: "/sl/help",
    component: _404701ca,
    name: "help___sl"
  }, {
    path: "/sl/information-ja",
    component: _0119f9be,
    name: "information-ja___sl"
  }, {
    path: "/sl/login",
    component: _47ffd456,
    name: "login___sl"
  }, {
    path: "/sl/order",
    component: _646e91fb,
    children: [{
      path: "",
      component: _3306703e,
      name: "order-index___sl"
    }, {
      path: ":id",
      component: _36dd87ae,
      name: "order-index-id___sl"
    }]
  }, {
    path: "/sl/pay",
    component: _fb3d235c,
    name: "pay___sl",
    children: [{
      path: ":id?/choose",
      component: _5a973a99,
      name: "pay-id-choose___sl"
    }, {
      path: ":id?/confirmation",
      component: _2b4cda37,
      name: "pay-id-confirmation___sl"
    }]
  }, {
    path: "/sl/payment",
    component: _7e9637b0,
    name: "payment___sl",
    children: [{
      path: ":id?/choose",
      component: _7814eb0a,
      name: "payment-id-choose___sl"
    }, {
      path: ":id?/confirmation",
      component: _55b43899,
      name: "payment-id-confirmation___sl"
    }]
  }, {
    path: "/sl/payment-instruction-cebuana",
    component: _97af7734,
    name: "payment-instruction-cebuana___sl"
  }, {
    path: "/sl/pricealert",
    component: _9f866188,
    name: "pricealert___sl"
  }, {
    path: "/sl/privacy-policy",
    component: _136b53dd,
    name: "privacy-policy___sl"
  }, {
    path: "/sl/promo",
    component: _7cca567c,
    name: "promo___sl"
  }, {
    path: "/sl/report-abadoned",
    component: _75091db0,
    name: "report-abadoned___sl"
  }, {
    path: "/sl/reset",
    component: _1d345abc,
    name: "reset___sl"
  }, {
    path: "/sl/retrieve",
    component: _7f0aae05,
    name: "retrieve___sl"
  }, {
    path: "/sl/signup",
    component: _187de13e,
    name: "signup___sl"
  }, {
    path: "/sl/sm-feedback",
    component: _bd27bb8c,
    name: "sm-feedback___sl"
  }, {
    path: "/sl/support",
    component: _404701ca,
    name: "support___sl"
  }, {
    path: "/sr/account",
    component: _c9b264cc,
    name: "account___sr",
    children: [{
      path: "bind",
      component: _55b147c2,
      name: "account-index-bind___sr"
    }, {
      path: "contact",
      component: _574a1c2b,
      name: "account-index-contact___sr"
    }, {
      path: "order",
      component: _51d988dc,
      children: [{
        path: "",
        component: _b78a0b42,
        name: "account-index-order-index___sr"
      }, {
        path: ":id",
        component: _4e9beef2,
        name: "account-index-order-index-id___sr"
      }]
    }, {
      path: "passenger",
      component: _703c2236,
      name: "account-index-passenger___sr"
    }, {
      path: "password",
      component: _6a3b00a0,
      name: "account-index-password___sr"
    }, {
      path: "profile",
      component: _5f719534,
      name: "account-index-profile___sr"
    }, {
      path: "review",
      component: _4754ee1d,
      name: "account-index-review___sr"
    }]
  }, {
    path: "/sr/app-feedback",
    component: _bd27bb8c,
    name: "app-feedback___sr"
  }, {
    path: "/sr/choose-country",
    component: _5571e029,
    name: "choose-country___sr"
  }, {
    path: "/sr/confirmemail",
    component: _3c3d88b6,
    name: "confirmemail___sr"
  }, {
    path: "/sr/contact",
    component: _1e34d3cd,
    name: "contact___sr"
  }, {
    path: "/sr/covid19",
    component: _4e6e0f37,
    name: "covid19___sr"
  }, {
    path: "/sr/deals",
    component: _0ef4e8f4,
    name: "deals___sr"
  }, {
    path: "/sr/help",
    component: _404701ca,
    name: "help___sr"
  }, {
    path: "/sr/information-ja",
    component: _0119f9be,
    name: "information-ja___sr"
  }, {
    path: "/sr/login",
    component: _47ffd456,
    name: "login___sr"
  }, {
    path: "/sr/order",
    component: _646e91fb,
    children: [{
      path: "",
      component: _3306703e,
      name: "order-index___sr"
    }, {
      path: ":id",
      component: _36dd87ae,
      name: "order-index-id___sr"
    }]
  }, {
    path: "/sr/pay",
    component: _fb3d235c,
    name: "pay___sr",
    children: [{
      path: ":id?/choose",
      component: _5a973a99,
      name: "pay-id-choose___sr"
    }, {
      path: ":id?/confirmation",
      component: _2b4cda37,
      name: "pay-id-confirmation___sr"
    }]
  }, {
    path: "/sr/payment",
    component: _7e9637b0,
    name: "payment___sr",
    children: [{
      path: ":id?/choose",
      component: _7814eb0a,
      name: "payment-id-choose___sr"
    }, {
      path: ":id?/confirmation",
      component: _55b43899,
      name: "payment-id-confirmation___sr"
    }]
  }, {
    path: "/sr/payment-instruction-cebuana",
    component: _97af7734,
    name: "payment-instruction-cebuana___sr"
  }, {
    path: "/sr/pricealert",
    component: _9f866188,
    name: "pricealert___sr"
  }, {
    path: "/sr/privacy-policy",
    component: _136b53dd,
    name: "privacy-policy___sr"
  }, {
    path: "/sr/promo",
    component: _7cca567c,
    name: "promo___sr"
  }, {
    path: "/sr/report-abadoned",
    component: _75091db0,
    name: "report-abadoned___sr"
  }, {
    path: "/sr/reset",
    component: _1d345abc,
    name: "reset___sr"
  }, {
    path: "/sr/retrieve",
    component: _7f0aae05,
    name: "retrieve___sr"
  }, {
    path: "/sr/signup",
    component: _187de13e,
    name: "signup___sr"
  }, {
    path: "/sr/sm-feedback",
    component: _bd27bb8c,
    name: "sm-feedback___sr"
  }, {
    path: "/sr/support",
    component: _404701ca,
    name: "support___sr"
  }, {
    path: "/sv/account",
    component: _c9b264cc,
    name: "account___sv",
    children: [{
      path: "bind",
      component: _55b147c2,
      name: "account-index-bind___sv"
    }, {
      path: "contact",
      component: _574a1c2b,
      name: "account-index-contact___sv"
    }, {
      path: "order",
      component: _51d988dc,
      children: [{
        path: "",
        component: _b78a0b42,
        name: "account-index-order-index___sv"
      }, {
        path: ":id",
        component: _4e9beef2,
        name: "account-index-order-index-id___sv"
      }]
    }, {
      path: "passenger",
      component: _703c2236,
      name: "account-index-passenger___sv"
    }, {
      path: "password",
      component: _6a3b00a0,
      name: "account-index-password___sv"
    }, {
      path: "profile",
      component: _5f719534,
      name: "account-index-profile___sv"
    }, {
      path: "review",
      component: _4754ee1d,
      name: "account-index-review___sv"
    }]
  }, {
    path: "/sv/app-feedback",
    component: _bd27bb8c,
    name: "app-feedback___sv"
  }, {
    path: "/sv/choose-country",
    component: _5571e029,
    name: "choose-country___sv"
  }, {
    path: "/sv/confirmemail",
    component: _3c3d88b6,
    name: "confirmemail___sv"
  }, {
    path: "/sv/contact",
    component: _1e34d3cd,
    name: "contact___sv"
  }, {
    path: "/sv/covid19",
    component: _4e6e0f37,
    name: "covid19___sv"
  }, {
    path: "/sv/deals",
    component: _0ef4e8f4,
    name: "deals___sv"
  }, {
    path: "/sv/help",
    component: _404701ca,
    name: "help___sv"
  }, {
    path: "/sv/information-ja",
    component: _0119f9be,
    name: "information-ja___sv"
  }, {
    path: "/sv/login",
    component: _47ffd456,
    name: "login___sv"
  }, {
    path: "/sv/order",
    component: _646e91fb,
    children: [{
      path: "",
      component: _3306703e,
      name: "order-index___sv"
    }, {
      path: ":id",
      component: _36dd87ae,
      name: "order-index-id___sv"
    }]
  }, {
    path: "/sv/pay",
    component: _fb3d235c,
    name: "pay___sv",
    children: [{
      path: ":id?/choose",
      component: _5a973a99,
      name: "pay-id-choose___sv"
    }, {
      path: ":id?/confirmation",
      component: _2b4cda37,
      name: "pay-id-confirmation___sv"
    }]
  }, {
    path: "/sv/payment",
    component: _7e9637b0,
    name: "payment___sv",
    children: [{
      path: ":id?/choose",
      component: _7814eb0a,
      name: "payment-id-choose___sv"
    }, {
      path: ":id?/confirmation",
      component: _55b43899,
      name: "payment-id-confirmation___sv"
    }]
  }, {
    path: "/sv/payment-instruction-cebuana",
    component: _97af7734,
    name: "payment-instruction-cebuana___sv"
  }, {
    path: "/sv/pricealert",
    component: _9f866188,
    name: "pricealert___sv"
  }, {
    path: "/sv/privacy-policy",
    component: _136b53dd,
    name: "privacy-policy___sv"
  }, {
    path: "/sv/promo",
    component: _7cca567c,
    name: "promo___sv"
  }, {
    path: "/sv/report-abadoned",
    component: _75091db0,
    name: "report-abadoned___sv"
  }, {
    path: "/sv/reset",
    component: _1d345abc,
    name: "reset___sv"
  }, {
    path: "/sv/retrieve",
    component: _7f0aae05,
    name: "retrieve___sv"
  }, {
    path: "/sv/signup",
    component: _187de13e,
    name: "signup___sv"
  }, {
    path: "/sv/sm-feedback",
    component: _bd27bb8c,
    name: "sm-feedback___sv"
  }, {
    path: "/sv/support",
    component: _404701ca,
    name: "support___sv"
  }, {
    path: "/th/account",
    component: _c9b264cc,
    name: "account___th",
    children: [{
      path: "bind",
      component: _55b147c2,
      name: "account-index-bind___th"
    }, {
      path: "contact",
      component: _574a1c2b,
      name: "account-index-contact___th"
    }, {
      path: "order",
      component: _51d988dc,
      children: [{
        path: "",
        component: _b78a0b42,
        name: "account-index-order-index___th"
      }, {
        path: ":id",
        component: _4e9beef2,
        name: "account-index-order-index-id___th"
      }]
    }, {
      path: "passenger",
      component: _703c2236,
      name: "account-index-passenger___th"
    }, {
      path: "password",
      component: _6a3b00a0,
      name: "account-index-password___th"
    }, {
      path: "profile",
      component: _5f719534,
      name: "account-index-profile___th"
    }, {
      path: "review",
      component: _4754ee1d,
      name: "account-index-review___th"
    }]
  }, {
    path: "/th/app-feedback",
    component: _bd27bb8c,
    name: "app-feedback___th"
  }, {
    path: "/th/choose-country",
    component: _5571e029,
    name: "choose-country___th"
  }, {
    path: "/th/confirmemail",
    component: _3c3d88b6,
    name: "confirmemail___th"
  }, {
    path: "/th/contact",
    component: _1e34d3cd,
    name: "contact___th"
  }, {
    path: "/th/covid19",
    component: _4e6e0f37,
    name: "covid19___th"
  }, {
    path: "/th/deals",
    component: _0ef4e8f4,
    name: "deals___th"
  }, {
    path: "/th/help",
    component: _404701ca,
    name: "help___th"
  }, {
    path: "/th/information-ja",
    component: _0119f9be,
    name: "information-ja___th"
  }, {
    path: "/th/login",
    component: _47ffd456,
    name: "login___th"
  }, {
    path: "/th/order",
    component: _646e91fb,
    children: [{
      path: "",
      component: _3306703e,
      name: "order-index___th"
    }, {
      path: ":id",
      component: _36dd87ae,
      name: "order-index-id___th"
    }]
  }, {
    path: "/th/pay",
    component: _fb3d235c,
    name: "pay___th",
    children: [{
      path: ":id?/choose",
      component: _5a973a99,
      name: "pay-id-choose___th"
    }, {
      path: ":id?/confirmation",
      component: _2b4cda37,
      name: "pay-id-confirmation___th"
    }]
  }, {
    path: "/th/payment",
    component: _7e9637b0,
    name: "payment___th",
    children: [{
      path: ":id?/choose",
      component: _7814eb0a,
      name: "payment-id-choose___th"
    }, {
      path: ":id?/confirmation",
      component: _55b43899,
      name: "payment-id-confirmation___th"
    }]
  }, {
    path: "/th/payment-instruction-cebuana",
    component: _97af7734,
    name: "payment-instruction-cebuana___th"
  }, {
    path: "/th/pricealert",
    component: _9f866188,
    name: "pricealert___th"
  }, {
    path: "/th/privacy-policy",
    component: _136b53dd,
    name: "privacy-policy___th"
  }, {
    path: "/th/promo",
    component: _7cca567c,
    name: "promo___th"
  }, {
    path: "/th/report-abadoned",
    component: _75091db0,
    name: "report-abadoned___th"
  }, {
    path: "/th/reset",
    component: _1d345abc,
    name: "reset___th"
  }, {
    path: "/th/retrieve",
    component: _7f0aae05,
    name: "retrieve___th"
  }, {
    path: "/th/signup",
    component: _187de13e,
    name: "signup___th"
  }, {
    path: "/th/sm-feedback",
    component: _bd27bb8c,
    name: "sm-feedback___th"
  }, {
    path: "/th/support",
    component: _404701ca,
    name: "support___th"
  }, {
    path: "/tl/account",
    component: _c9b264cc,
    name: "account___tl",
    children: [{
      path: "bind",
      component: _55b147c2,
      name: "account-index-bind___tl"
    }, {
      path: "contact",
      component: _574a1c2b,
      name: "account-index-contact___tl"
    }, {
      path: "order",
      component: _51d988dc,
      children: [{
        path: "",
        component: _b78a0b42,
        name: "account-index-order-index___tl"
      }, {
        path: ":id",
        component: _4e9beef2,
        name: "account-index-order-index-id___tl"
      }]
    }, {
      path: "passenger",
      component: _703c2236,
      name: "account-index-passenger___tl"
    }, {
      path: "password",
      component: _6a3b00a0,
      name: "account-index-password___tl"
    }, {
      path: "profile",
      component: _5f719534,
      name: "account-index-profile___tl"
    }, {
      path: "review",
      component: _4754ee1d,
      name: "account-index-review___tl"
    }]
  }, {
    path: "/tl/app-feedback",
    component: _bd27bb8c,
    name: "app-feedback___tl"
  }, {
    path: "/tl/choose-country",
    component: _5571e029,
    name: "choose-country___tl"
  }, {
    path: "/tl/confirmemail",
    component: _3c3d88b6,
    name: "confirmemail___tl"
  }, {
    path: "/tl/contact",
    component: _1e34d3cd,
    name: "contact___tl"
  }, {
    path: "/tl/covid19",
    component: _4e6e0f37,
    name: "covid19___tl"
  }, {
    path: "/tl/deals",
    component: _0ef4e8f4,
    name: "deals___tl"
  }, {
    path: "/tl/help",
    component: _404701ca,
    name: "help___tl"
  }, {
    path: "/tl/information-ja",
    component: _0119f9be,
    name: "information-ja___tl"
  }, {
    path: "/tl/login",
    component: _47ffd456,
    name: "login___tl"
  }, {
    path: "/tl/order",
    component: _646e91fb,
    children: [{
      path: "",
      component: _3306703e,
      name: "order-index___tl"
    }, {
      path: ":id",
      component: _36dd87ae,
      name: "order-index-id___tl"
    }]
  }, {
    path: "/tl/pay",
    component: _fb3d235c,
    name: "pay___tl",
    children: [{
      path: ":id?/choose",
      component: _5a973a99,
      name: "pay-id-choose___tl"
    }, {
      path: ":id?/confirmation",
      component: _2b4cda37,
      name: "pay-id-confirmation___tl"
    }]
  }, {
    path: "/tl/payment",
    component: _7e9637b0,
    name: "payment___tl",
    children: [{
      path: ":id?/choose",
      component: _7814eb0a,
      name: "payment-id-choose___tl"
    }, {
      path: ":id?/confirmation",
      component: _55b43899,
      name: "payment-id-confirmation___tl"
    }]
  }, {
    path: "/tl/payment-instruction-cebuana",
    component: _97af7734,
    name: "payment-instruction-cebuana___tl"
  }, {
    path: "/tl/pricealert",
    component: _9f866188,
    name: "pricealert___tl"
  }, {
    path: "/tl/privacy-policy",
    component: _136b53dd,
    name: "privacy-policy___tl"
  }, {
    path: "/tl/promo",
    component: _7cca567c,
    name: "promo___tl"
  }, {
    path: "/tl/report-abadoned",
    component: _75091db0,
    name: "report-abadoned___tl"
  }, {
    path: "/tl/reset",
    component: _1d345abc,
    name: "reset___tl"
  }, {
    path: "/tl/retrieve",
    component: _7f0aae05,
    name: "retrieve___tl"
  }, {
    path: "/tl/signup",
    component: _187de13e,
    name: "signup___tl"
  }, {
    path: "/tl/sm-feedback",
    component: _bd27bb8c,
    name: "sm-feedback___tl"
  }, {
    path: "/tl/support",
    component: _404701ca,
    name: "support___tl"
  }, {
    path: "/tr/account",
    component: _c9b264cc,
    name: "account___tr",
    children: [{
      path: "bind",
      component: _55b147c2,
      name: "account-index-bind___tr"
    }, {
      path: "contact",
      component: _574a1c2b,
      name: "account-index-contact___tr"
    }, {
      path: "order",
      component: _51d988dc,
      children: [{
        path: "",
        component: _b78a0b42,
        name: "account-index-order-index___tr"
      }, {
        path: ":id",
        component: _4e9beef2,
        name: "account-index-order-index-id___tr"
      }]
    }, {
      path: "passenger",
      component: _703c2236,
      name: "account-index-passenger___tr"
    }, {
      path: "password",
      component: _6a3b00a0,
      name: "account-index-password___tr"
    }, {
      path: "profile",
      component: _5f719534,
      name: "account-index-profile___tr"
    }, {
      path: "review",
      component: _4754ee1d,
      name: "account-index-review___tr"
    }]
  }, {
    path: "/tr/app-feedback",
    component: _bd27bb8c,
    name: "app-feedback___tr"
  }, {
    path: "/tr/choose-country",
    component: _5571e029,
    name: "choose-country___tr"
  }, {
    path: "/tr/confirmemail",
    component: _3c3d88b6,
    name: "confirmemail___tr"
  }, {
    path: "/tr/contact",
    component: _1e34d3cd,
    name: "contact___tr"
  }, {
    path: "/tr/covid19",
    component: _4e6e0f37,
    name: "covid19___tr"
  }, {
    path: "/tr/deals",
    component: _0ef4e8f4,
    name: "deals___tr"
  }, {
    path: "/tr/help",
    component: _404701ca,
    name: "help___tr"
  }, {
    path: "/tr/information-ja",
    component: _0119f9be,
    name: "information-ja___tr"
  }, {
    path: "/tr/login",
    component: _47ffd456,
    name: "login___tr"
  }, {
    path: "/tr/order",
    component: _646e91fb,
    children: [{
      path: "",
      component: _3306703e,
      name: "order-index___tr"
    }, {
      path: ":id",
      component: _36dd87ae,
      name: "order-index-id___tr"
    }]
  }, {
    path: "/tr/pay",
    component: _fb3d235c,
    name: "pay___tr",
    children: [{
      path: ":id?/choose",
      component: _5a973a99,
      name: "pay-id-choose___tr"
    }, {
      path: ":id?/confirmation",
      component: _2b4cda37,
      name: "pay-id-confirmation___tr"
    }]
  }, {
    path: "/tr/payment",
    component: _7e9637b0,
    name: "payment___tr",
    children: [{
      path: ":id?/choose",
      component: _7814eb0a,
      name: "payment-id-choose___tr"
    }, {
      path: ":id?/confirmation",
      component: _55b43899,
      name: "payment-id-confirmation___tr"
    }]
  }, {
    path: "/tr/payment-instruction-cebuana",
    component: _97af7734,
    name: "payment-instruction-cebuana___tr"
  }, {
    path: "/tr/pricealert",
    component: _9f866188,
    name: "pricealert___tr"
  }, {
    path: "/tr/privacy-policy",
    component: _136b53dd,
    name: "privacy-policy___tr"
  }, {
    path: "/tr/promo",
    component: _7cca567c,
    name: "promo___tr"
  }, {
    path: "/tr/report-abadoned",
    component: _75091db0,
    name: "report-abadoned___tr"
  }, {
    path: "/tr/reset",
    component: _1d345abc,
    name: "reset___tr"
  }, {
    path: "/tr/retrieve",
    component: _7f0aae05,
    name: "retrieve___tr"
  }, {
    path: "/tr/signup",
    component: _187de13e,
    name: "signup___tr"
  }, {
    path: "/tr/sm-feedback",
    component: _bd27bb8c,
    name: "sm-feedback___tr"
  }, {
    path: "/tr/support",
    component: _404701ca,
    name: "support___tr"
  }, {
    path: "/uk/account",
    component: _c9b264cc,
    name: "account___uk",
    children: [{
      path: "bind",
      component: _55b147c2,
      name: "account-index-bind___uk"
    }, {
      path: "contact",
      component: _574a1c2b,
      name: "account-index-contact___uk"
    }, {
      path: "order",
      component: _51d988dc,
      children: [{
        path: "",
        component: _b78a0b42,
        name: "account-index-order-index___uk"
      }, {
        path: ":id",
        component: _4e9beef2,
        name: "account-index-order-index-id___uk"
      }]
    }, {
      path: "passenger",
      component: _703c2236,
      name: "account-index-passenger___uk"
    }, {
      path: "password",
      component: _6a3b00a0,
      name: "account-index-password___uk"
    }, {
      path: "profile",
      component: _5f719534,
      name: "account-index-profile___uk"
    }, {
      path: "review",
      component: _4754ee1d,
      name: "account-index-review___uk"
    }]
  }, {
    path: "/uk/app-feedback",
    component: _bd27bb8c,
    name: "app-feedback___uk"
  }, {
    path: "/uk/choose-country",
    component: _5571e029,
    name: "choose-country___uk"
  }, {
    path: "/uk/confirmemail",
    component: _3c3d88b6,
    name: "confirmemail___uk"
  }, {
    path: "/uk/contact",
    component: _1e34d3cd,
    name: "contact___uk"
  }, {
    path: "/uk/covid19",
    component: _4e6e0f37,
    name: "covid19___uk"
  }, {
    path: "/uk/deals",
    component: _0ef4e8f4,
    name: "deals___uk"
  }, {
    path: "/uk/help",
    component: _404701ca,
    name: "help___uk"
  }, {
    path: "/uk/information-ja",
    component: _0119f9be,
    name: "information-ja___uk"
  }, {
    path: "/uk/login",
    component: _47ffd456,
    name: "login___uk"
  }, {
    path: "/uk/order",
    component: _646e91fb,
    children: [{
      path: "",
      component: _3306703e,
      name: "order-index___uk"
    }, {
      path: ":id",
      component: _36dd87ae,
      name: "order-index-id___uk"
    }]
  }, {
    path: "/uk/pay",
    component: _fb3d235c,
    name: "pay___uk",
    children: [{
      path: ":id?/choose",
      component: _5a973a99,
      name: "pay-id-choose___uk"
    }, {
      path: ":id?/confirmation",
      component: _2b4cda37,
      name: "pay-id-confirmation___uk"
    }]
  }, {
    path: "/uk/payment",
    component: _7e9637b0,
    name: "payment___uk",
    children: [{
      path: ":id?/choose",
      component: _7814eb0a,
      name: "payment-id-choose___uk"
    }, {
      path: ":id?/confirmation",
      component: _55b43899,
      name: "payment-id-confirmation___uk"
    }]
  }, {
    path: "/uk/payment-instruction-cebuana",
    component: _97af7734,
    name: "payment-instruction-cebuana___uk"
  }, {
    path: "/uk/pricealert",
    component: _9f866188,
    name: "pricealert___uk"
  }, {
    path: "/uk/privacy-policy",
    component: _136b53dd,
    name: "privacy-policy___uk"
  }, {
    path: "/uk/promo",
    component: _7cca567c,
    name: "promo___uk"
  }, {
    path: "/uk/report-abadoned",
    component: _75091db0,
    name: "report-abadoned___uk"
  }, {
    path: "/uk/reset",
    component: _1d345abc,
    name: "reset___uk"
  }, {
    path: "/uk/retrieve",
    component: _7f0aae05,
    name: "retrieve___uk"
  }, {
    path: "/uk/signup",
    component: _187de13e,
    name: "signup___uk"
  }, {
    path: "/uk/sm-feedback",
    component: _bd27bb8c,
    name: "sm-feedback___uk"
  }, {
    path: "/uk/support",
    component: _404701ca,
    name: "support___uk"
  }, {
    path: "/vi/account",
    component: _c9b264cc,
    name: "account___vi",
    children: [{
      path: "bind",
      component: _55b147c2,
      name: "account-index-bind___vi"
    }, {
      path: "contact",
      component: _574a1c2b,
      name: "account-index-contact___vi"
    }, {
      path: "order",
      component: _51d988dc,
      children: [{
        path: "",
        component: _b78a0b42,
        name: "account-index-order-index___vi"
      }, {
        path: ":id",
        component: _4e9beef2,
        name: "account-index-order-index-id___vi"
      }]
    }, {
      path: "passenger",
      component: _703c2236,
      name: "account-index-passenger___vi"
    }, {
      path: "password",
      component: _6a3b00a0,
      name: "account-index-password___vi"
    }, {
      path: "profile",
      component: _5f719534,
      name: "account-index-profile___vi"
    }, {
      path: "review",
      component: _4754ee1d,
      name: "account-index-review___vi"
    }]
  }, {
    path: "/vi/app-feedback",
    component: _bd27bb8c,
    name: "app-feedback___vi"
  }, {
    path: "/vi/choose-country",
    component: _5571e029,
    name: "choose-country___vi"
  }, {
    path: "/vi/confirmemail",
    component: _3c3d88b6,
    name: "confirmemail___vi"
  }, {
    path: "/vi/contact",
    component: _1e34d3cd,
    name: "contact___vi"
  }, {
    path: "/vi/covid19",
    component: _4e6e0f37,
    name: "covid19___vi"
  }, {
    path: "/vi/deals",
    component: _0ef4e8f4,
    name: "deals___vi"
  }, {
    path: "/vi/help",
    component: _404701ca,
    name: "help___vi"
  }, {
    path: "/vi/information-ja",
    component: _0119f9be,
    name: "information-ja___vi"
  }, {
    path: "/vi/login",
    component: _47ffd456,
    name: "login___vi"
  }, {
    path: "/vi/order",
    component: _646e91fb,
    children: [{
      path: "",
      component: _3306703e,
      name: "order-index___vi"
    }, {
      path: ":id",
      component: _36dd87ae,
      name: "order-index-id___vi"
    }]
  }, {
    path: "/vi/pay",
    component: _fb3d235c,
    name: "pay___vi",
    children: [{
      path: ":id?/choose",
      component: _5a973a99,
      name: "pay-id-choose___vi"
    }, {
      path: ":id?/confirmation",
      component: _2b4cda37,
      name: "pay-id-confirmation___vi"
    }]
  }, {
    path: "/vi/payment",
    component: _7e9637b0,
    name: "payment___vi",
    children: [{
      path: ":id?/choose",
      component: _7814eb0a,
      name: "payment-id-choose___vi"
    }, {
      path: ":id?/confirmation",
      component: _55b43899,
      name: "payment-id-confirmation___vi"
    }]
  }, {
    path: "/vi/payment-instruction-cebuana",
    component: _97af7734,
    name: "payment-instruction-cebuana___vi"
  }, {
    path: "/vi/pricealert",
    component: _9f866188,
    name: "pricealert___vi"
  }, {
    path: "/vi/privacy-policy",
    component: _136b53dd,
    name: "privacy-policy___vi"
  }, {
    path: "/vi/promo",
    component: _7cca567c,
    name: "promo___vi"
  }, {
    path: "/vi/report-abadoned",
    component: _75091db0,
    name: "report-abadoned___vi"
  }, {
    path: "/vi/reset",
    component: _1d345abc,
    name: "reset___vi"
  }, {
    path: "/vi/retrieve",
    component: _7f0aae05,
    name: "retrieve___vi"
  }, {
    path: "/vi/signup",
    component: _187de13e,
    name: "signup___vi"
  }, {
    path: "/vi/sm-feedback",
    component: _bd27bb8c,
    name: "sm-feedback___vi"
  }, {
    path: "/vi/support",
    component: _404701ca,
    name: "support___vi"
  }, {
    path: "/zh-tw/account",
    component: _c9b264cc,
    name: "account___zh-tw",
    children: [{
      path: "bind",
      component: _55b147c2,
      name: "account-index-bind___zh-tw"
    }, {
      path: "contact",
      component: _574a1c2b,
      name: "account-index-contact___zh-tw"
    }, {
      path: "order",
      component: _51d988dc,
      children: [{
        path: "",
        component: _b78a0b42,
        name: "account-index-order-index___zh-tw"
      }, {
        path: ":id",
        component: _4e9beef2,
        name: "account-index-order-index-id___zh-tw"
      }]
    }, {
      path: "passenger",
      component: _703c2236,
      name: "account-index-passenger___zh-tw"
    }, {
      path: "password",
      component: _6a3b00a0,
      name: "account-index-password___zh-tw"
    }, {
      path: "profile",
      component: _5f719534,
      name: "account-index-profile___zh-tw"
    }, {
      path: "review",
      component: _4754ee1d,
      name: "account-index-review___zh-tw"
    }]
  }, {
    path: "/zh-tw/app-feedback",
    component: _bd27bb8c,
    name: "app-feedback___zh-tw"
  }, {
    path: "/zh-tw/choose-country",
    component: _5571e029,
    name: "choose-country___zh-tw"
  }, {
    path: "/zh-tw/confirmemail",
    component: _3c3d88b6,
    name: "confirmemail___zh-tw"
  }, {
    path: "/zh-tw/contact",
    component: _1e34d3cd,
    name: "contact___zh-tw"
  }, {
    path: "/zh-tw/covid19",
    component: _4e6e0f37,
    name: "covid19___zh-tw"
  }, {
    path: "/zh-tw/deals",
    component: _0ef4e8f4,
    name: "deals___zh-tw"
  }, {
    path: "/zh-tw/help",
    component: _404701ca,
    name: "help___zh-tw"
  }, {
    path: "/zh-tw/information-ja",
    component: _0119f9be,
    name: "information-ja___zh-tw"
  }, {
    path: "/zh-tw/login",
    component: _47ffd456,
    name: "login___zh-tw"
  }, {
    path: "/zh-tw/order",
    component: _646e91fb,
    children: [{
      path: "",
      component: _3306703e,
      name: "order-index___zh-tw"
    }, {
      path: ":id",
      component: _36dd87ae,
      name: "order-index-id___zh-tw"
    }]
  }, {
    path: "/zh-tw/pay",
    component: _fb3d235c,
    name: "pay___zh-tw",
    children: [{
      path: ":id?/choose",
      component: _5a973a99,
      name: "pay-id-choose___zh-tw"
    }, {
      path: ":id?/confirmation",
      component: _2b4cda37,
      name: "pay-id-confirmation___zh-tw"
    }]
  }, {
    path: "/zh-tw/payment",
    component: _7e9637b0,
    name: "payment___zh-tw",
    children: [{
      path: ":id?/choose",
      component: _7814eb0a,
      name: "payment-id-choose___zh-tw"
    }, {
      path: ":id?/confirmation",
      component: _55b43899,
      name: "payment-id-confirmation___zh-tw"
    }]
  }, {
    path: "/zh-tw/payment-instruction-cebuana",
    component: _97af7734,
    name: "payment-instruction-cebuana___zh-tw"
  }, {
    path: "/zh-tw/pricealert",
    component: _9f866188,
    name: "pricealert___zh-tw"
  }, {
    path: "/zh-tw/privacy-policy",
    component: _136b53dd,
    name: "privacy-policy___zh-tw"
  }, {
    path: "/zh-tw/promo",
    component: _7cca567c,
    name: "promo___zh-tw"
  }, {
    path: "/zh-tw/report-abadoned",
    component: _75091db0,
    name: "report-abadoned___zh-tw"
  }, {
    path: "/zh-tw/reset",
    component: _1d345abc,
    name: "reset___zh-tw"
  }, {
    path: "/zh-tw/retrieve",
    component: _7f0aae05,
    name: "retrieve___zh-tw"
  }, {
    path: "/zh-tw/signup",
    component: _187de13e,
    name: "signup___zh-tw"
  }, {
    path: "/zh-tw/sm-feedback",
    component: _bd27bb8c,
    name: "sm-feedback___zh-tw"
  }, {
    path: "/zh-tw/support",
    component: _404701ca,
    name: "support___zh-tw"
  }, {
    path: "/zh/account",
    component: _c9b264cc,
    name: "account___zh",
    children: [{
      path: "bind",
      component: _55b147c2,
      name: "account-index-bind___zh"
    }, {
      path: "contact",
      component: _574a1c2b,
      name: "account-index-contact___zh"
    }, {
      path: "order",
      component: _51d988dc,
      children: [{
        path: "",
        component: _b78a0b42,
        name: "account-index-order-index___zh"
      }, {
        path: ":id",
        component: _4e9beef2,
        name: "account-index-order-index-id___zh"
      }]
    }, {
      path: "passenger",
      component: _703c2236,
      name: "account-index-passenger___zh"
    }, {
      path: "password",
      component: _6a3b00a0,
      name: "account-index-password___zh"
    }, {
      path: "profile",
      component: _5f719534,
      name: "account-index-profile___zh"
    }, {
      path: "review",
      component: _4754ee1d,
      name: "account-index-review___zh"
    }]
  }, {
    path: "/zh/app-feedback",
    component: _bd27bb8c,
    name: "app-feedback___zh"
  }, {
    path: "/zh/choose-country",
    component: _5571e029,
    name: "choose-country___zh"
  }, {
    path: "/zh/confirmemail",
    component: _3c3d88b6,
    name: "confirmemail___zh"
  }, {
    path: "/zh/contact",
    component: _1e34d3cd,
    name: "contact___zh"
  }, {
    path: "/zh/covid19",
    component: _4e6e0f37,
    name: "covid19___zh"
  }, {
    path: "/zh/deals",
    component: _0ef4e8f4,
    name: "deals___zh"
  }, {
    path: "/zh/help",
    component: _404701ca,
    name: "help___zh"
  }, {
    path: "/zh/information-ja",
    component: _0119f9be,
    name: "information-ja___zh"
  }, {
    path: "/zh/login",
    component: _47ffd456,
    name: "login___zh"
  }, {
    path: "/zh/order",
    component: _646e91fb,
    children: [{
      path: "",
      component: _3306703e,
      name: "order-index___zh"
    }, {
      path: ":id",
      component: _36dd87ae,
      name: "order-index-id___zh"
    }]
  }, {
    path: "/zh/pay",
    component: _fb3d235c,
    name: "pay___zh",
    children: [{
      path: ":id?/choose",
      component: _5a973a99,
      name: "pay-id-choose___zh"
    }, {
      path: ":id?/confirmation",
      component: _2b4cda37,
      name: "pay-id-confirmation___zh"
    }]
  }, {
    path: "/zh/payment",
    component: _7e9637b0,
    name: "payment___zh",
    children: [{
      path: ":id?/choose",
      component: _7814eb0a,
      name: "payment-id-choose___zh"
    }, {
      path: ":id?/confirmation",
      component: _55b43899,
      name: "payment-id-confirmation___zh"
    }]
  }, {
    path: "/zh/payment-instruction-cebuana",
    component: _97af7734,
    name: "payment-instruction-cebuana___zh"
  }, {
    path: "/zh/pricealert",
    component: _9f866188,
    name: "pricealert___zh"
  }, {
    path: "/zh/privacy-policy",
    component: _136b53dd,
    name: "privacy-policy___zh"
  }, {
    path: "/zh/promo",
    component: _7cca567c,
    name: "promo___zh"
  }, {
    path: "/zh/report-abadoned",
    component: _75091db0,
    name: "report-abadoned___zh"
  }, {
    path: "/zh/reset",
    component: _1d345abc,
    name: "reset___zh"
  }, {
    path: "/zh/retrieve",
    component: _7f0aae05,
    name: "retrieve___zh"
  }, {
    path: "/zh/signup",
    component: _187de13e,
    name: "signup___zh"
  }, {
    path: "/zh/sm-feedback",
    component: _bd27bb8c,
    name: "sm-feedback___zh"
  }, {
    path: "/zh/support",
    component: _404701ca,
    name: "support___zh"
  }, {
    path: "/bg/flight/confirm",
    component: _dcb6d832,
    name: "flight-confirm___bg"
  }, {
    path: "/bg/flight/search",
    component: _44fa9bd1,
    name: "flight-search___bg"
  }, {
    path: "/bg/help/search",
    component: _745810e0,
    name: "help-search___bg"
  }, {
    path: "/bg/hotel/pay",
    component: _c09f3912,
    name: "hotel-pay___bg",
    children: [{
      path: ":id?/choose",
      component: _bf0be758,
      name: "hotel-pay-id-choose___bg"
    }, {
      path: ":id?/confirmation",
      component: _cb87989c,
      name: "hotel-pay-id-confirmation___bg"
    }]
  }, {
    path: "/bg/hotel/payment",
    component: _7927db56,
    name: "hotel-payment___bg",
    children: [{
      path: ":id?/choose",
      component: _5759f294,
      name: "hotel-payment-id-choose___bg"
    }, {
      path: ":id?/confirmation",
      component: _bbd746d8,
      name: "hotel-payment-id-confirmation___bg"
    }]
  }, {
    path: "/bg/hotel/search",
    component: _e7741eae,
    name: "hotel-search___bg"
  }, {
    path: "/bg/member/login",
    component: _665d5706,
    name: "member-login___bg"
  }, {
    path: "/bg/newsletter/change-language",
    component: _0436fe08,
    name: "newsletter-change-language___bg"
  }, {
    path: "/bg/newsletter/choose-language",
    component: _309e9c41,
    name: "newsletter-choose-language___bg"
  }, {
    path: "/bg/newsletter/confirm",
    component: _90612b9a,
    name: "newsletter-confirm___bg"
  }, {
    path: "/bg/newsletter/unsubscribe",
    component: _033fa104,
    name: "newsletter-unsubscribe___bg"
  }, {
    path: "/bg/reset/confirmation",
    component: _1ea443bb,
    name: "reset-confirmation___bg"
  }, {
    path: "/bg/retrieve/list",
    component: _4d88f93b,
    name: "retrieve-list___bg"
  }, {
    path: "/bg/signup/verify",
    component: _58766906,
    name: "signup-verify___bg"
  }, {
    path: "/bg/terms/condition",
    component: _21b34880,
    name: "terms-condition___bg"
  }, {
    path: "/bg/terms/uses",
    component: _8de6e8c6,
    name: "terms-uses___bg"
  }, {
    path: "/bn/flight/confirm",
    component: _dcb6d832,
    name: "flight-confirm___bn"
  }, {
    path: "/bn/flight/search",
    component: _44fa9bd1,
    name: "flight-search___bn"
  }, {
    path: "/bn/help/search",
    component: _745810e0,
    name: "help-search___bn"
  }, {
    path: "/bn/hotel/pay",
    component: _c09f3912,
    name: "hotel-pay___bn",
    children: [{
      path: ":id?/choose",
      component: _bf0be758,
      name: "hotel-pay-id-choose___bn"
    }, {
      path: ":id?/confirmation",
      component: _cb87989c,
      name: "hotel-pay-id-confirmation___bn"
    }]
  }, {
    path: "/bn/hotel/payment",
    component: _7927db56,
    name: "hotel-payment___bn",
    children: [{
      path: ":id?/choose",
      component: _5759f294,
      name: "hotel-payment-id-choose___bn"
    }, {
      path: ":id?/confirmation",
      component: _bbd746d8,
      name: "hotel-payment-id-confirmation___bn"
    }]
  }, {
    path: "/bn/hotel/search",
    component: _e7741eae,
    name: "hotel-search___bn"
  }, {
    path: "/bn/member/login",
    component: _665d5706,
    name: "member-login___bn"
  }, {
    path: "/bn/newsletter/change-language",
    component: _0436fe08,
    name: "newsletter-change-language___bn"
  }, {
    path: "/bn/newsletter/choose-language",
    component: _309e9c41,
    name: "newsletter-choose-language___bn"
  }, {
    path: "/bn/newsletter/confirm",
    component: _90612b9a,
    name: "newsletter-confirm___bn"
  }, {
    path: "/bn/newsletter/unsubscribe",
    component: _033fa104,
    name: "newsletter-unsubscribe___bn"
  }, {
    path: "/bn/reset/confirmation",
    component: _1ea443bb,
    name: "reset-confirmation___bn"
  }, {
    path: "/bn/retrieve/list",
    component: _4d88f93b,
    name: "retrieve-list___bn"
  }, {
    path: "/bn/signup/verify",
    component: _58766906,
    name: "signup-verify___bn"
  }, {
    path: "/bn/terms/condition",
    component: _21b34880,
    name: "terms-condition___bn"
  }, {
    path: "/bn/terms/uses",
    component: _8de6e8c6,
    name: "terms-uses___bn"
  }, {
    path: "/ca/flight/confirm",
    component: _dcb6d832,
    name: "flight-confirm___ca"
  }, {
    path: "/ca/flight/search",
    component: _44fa9bd1,
    name: "flight-search___ca"
  }, {
    path: "/ca/help/search",
    component: _745810e0,
    name: "help-search___ca"
  }, {
    path: "/ca/hotel/pay",
    component: _c09f3912,
    name: "hotel-pay___ca",
    children: [{
      path: ":id?/choose",
      component: _bf0be758,
      name: "hotel-pay-id-choose___ca"
    }, {
      path: ":id?/confirmation",
      component: _cb87989c,
      name: "hotel-pay-id-confirmation___ca"
    }]
  }, {
    path: "/ca/hotel/payment",
    component: _7927db56,
    name: "hotel-payment___ca",
    children: [{
      path: ":id?/choose",
      component: _5759f294,
      name: "hotel-payment-id-choose___ca"
    }, {
      path: ":id?/confirmation",
      component: _bbd746d8,
      name: "hotel-payment-id-confirmation___ca"
    }]
  }, {
    path: "/ca/hotel/search",
    component: _e7741eae,
    name: "hotel-search___ca"
  }, {
    path: "/ca/member/login",
    component: _665d5706,
    name: "member-login___ca"
  }, {
    path: "/ca/newsletter/change-language",
    component: _0436fe08,
    name: "newsletter-change-language___ca"
  }, {
    path: "/ca/newsletter/choose-language",
    component: _309e9c41,
    name: "newsletter-choose-language___ca"
  }, {
    path: "/ca/newsletter/confirm",
    component: _90612b9a,
    name: "newsletter-confirm___ca"
  }, {
    path: "/ca/newsletter/unsubscribe",
    component: _033fa104,
    name: "newsletter-unsubscribe___ca"
  }, {
    path: "/ca/reset/confirmation",
    component: _1ea443bb,
    name: "reset-confirmation___ca"
  }, {
    path: "/ca/retrieve/list",
    component: _4d88f93b,
    name: "retrieve-list___ca"
  }, {
    path: "/ca/signup/verify",
    component: _58766906,
    name: "signup-verify___ca"
  }, {
    path: "/ca/terms/condition",
    component: _21b34880,
    name: "terms-condition___ca"
  }, {
    path: "/ca/terms/uses",
    component: _8de6e8c6,
    name: "terms-uses___ca"
  }, {
    path: "/cs/flight/confirm",
    component: _dcb6d832,
    name: "flight-confirm___cs"
  }, {
    path: "/cs/flight/search",
    component: _44fa9bd1,
    name: "flight-search___cs"
  }, {
    path: "/cs/help/search",
    component: _745810e0,
    name: "help-search___cs"
  }, {
    path: "/cs/hotel/pay",
    component: _c09f3912,
    name: "hotel-pay___cs",
    children: [{
      path: ":id?/choose",
      component: _bf0be758,
      name: "hotel-pay-id-choose___cs"
    }, {
      path: ":id?/confirmation",
      component: _cb87989c,
      name: "hotel-pay-id-confirmation___cs"
    }]
  }, {
    path: "/cs/hotel/payment",
    component: _7927db56,
    name: "hotel-payment___cs",
    children: [{
      path: ":id?/choose",
      component: _5759f294,
      name: "hotel-payment-id-choose___cs"
    }, {
      path: ":id?/confirmation",
      component: _bbd746d8,
      name: "hotel-payment-id-confirmation___cs"
    }]
  }, {
    path: "/cs/hotel/search",
    component: _e7741eae,
    name: "hotel-search___cs"
  }, {
    path: "/cs/member/login",
    component: _665d5706,
    name: "member-login___cs"
  }, {
    path: "/cs/newsletter/change-language",
    component: _0436fe08,
    name: "newsletter-change-language___cs"
  }, {
    path: "/cs/newsletter/choose-language",
    component: _309e9c41,
    name: "newsletter-choose-language___cs"
  }, {
    path: "/cs/newsletter/confirm",
    component: _90612b9a,
    name: "newsletter-confirm___cs"
  }, {
    path: "/cs/newsletter/unsubscribe",
    component: _033fa104,
    name: "newsletter-unsubscribe___cs"
  }, {
    path: "/cs/reset/confirmation",
    component: _1ea443bb,
    name: "reset-confirmation___cs"
  }, {
    path: "/cs/retrieve/list",
    component: _4d88f93b,
    name: "retrieve-list___cs"
  }, {
    path: "/cs/signup/verify",
    component: _58766906,
    name: "signup-verify___cs"
  }, {
    path: "/cs/terms/condition",
    component: _21b34880,
    name: "terms-condition___cs"
  }, {
    path: "/cs/terms/uses",
    component: _8de6e8c6,
    name: "terms-uses___cs"
  }, {
    path: "/da/flight/confirm",
    component: _dcb6d832,
    name: "flight-confirm___da"
  }, {
    path: "/da/flight/search",
    component: _44fa9bd1,
    name: "flight-search___da"
  }, {
    path: "/da/help/search",
    component: _745810e0,
    name: "help-search___da"
  }, {
    path: "/da/hotel/pay",
    component: _c09f3912,
    name: "hotel-pay___da",
    children: [{
      path: ":id?/choose",
      component: _bf0be758,
      name: "hotel-pay-id-choose___da"
    }, {
      path: ":id?/confirmation",
      component: _cb87989c,
      name: "hotel-pay-id-confirmation___da"
    }]
  }, {
    path: "/da/hotel/payment",
    component: _7927db56,
    name: "hotel-payment___da",
    children: [{
      path: ":id?/choose",
      component: _5759f294,
      name: "hotel-payment-id-choose___da"
    }, {
      path: ":id?/confirmation",
      component: _bbd746d8,
      name: "hotel-payment-id-confirmation___da"
    }]
  }, {
    path: "/da/hotel/search",
    component: _e7741eae,
    name: "hotel-search___da"
  }, {
    path: "/da/member/login",
    component: _665d5706,
    name: "member-login___da"
  }, {
    path: "/da/newsletter/change-language",
    component: _0436fe08,
    name: "newsletter-change-language___da"
  }, {
    path: "/da/newsletter/choose-language",
    component: _309e9c41,
    name: "newsletter-choose-language___da"
  }, {
    path: "/da/newsletter/confirm",
    component: _90612b9a,
    name: "newsletter-confirm___da"
  }, {
    path: "/da/newsletter/unsubscribe",
    component: _033fa104,
    name: "newsletter-unsubscribe___da"
  }, {
    path: "/da/reset/confirmation",
    component: _1ea443bb,
    name: "reset-confirmation___da"
  }, {
    path: "/da/retrieve/list",
    component: _4d88f93b,
    name: "retrieve-list___da"
  }, {
    path: "/da/signup/verify",
    component: _58766906,
    name: "signup-verify___da"
  }, {
    path: "/da/terms/condition",
    component: _21b34880,
    name: "terms-condition___da"
  }, {
    path: "/da/terms/uses",
    component: _8de6e8c6,
    name: "terms-uses___da"
  }, {
    path: "/de/flight/confirm",
    component: _dcb6d832,
    name: "flight-confirm___de"
  }, {
    path: "/de/flight/search",
    component: _44fa9bd1,
    name: "flight-search___de"
  }, {
    path: "/de/help/search",
    component: _745810e0,
    name: "help-search___de"
  }, {
    path: "/de/hotel/pay",
    component: _c09f3912,
    name: "hotel-pay___de",
    children: [{
      path: ":id?/choose",
      component: _bf0be758,
      name: "hotel-pay-id-choose___de"
    }, {
      path: ":id?/confirmation",
      component: _cb87989c,
      name: "hotel-pay-id-confirmation___de"
    }]
  }, {
    path: "/de/hotel/payment",
    component: _7927db56,
    name: "hotel-payment___de",
    children: [{
      path: ":id?/choose",
      component: _5759f294,
      name: "hotel-payment-id-choose___de"
    }, {
      path: ":id?/confirmation",
      component: _bbd746d8,
      name: "hotel-payment-id-confirmation___de"
    }]
  }, {
    path: "/de/hotel/search",
    component: _e7741eae,
    name: "hotel-search___de"
  }, {
    path: "/de/member/login",
    component: _665d5706,
    name: "member-login___de"
  }, {
    path: "/de/newsletter/change-language",
    component: _0436fe08,
    name: "newsletter-change-language___de"
  }, {
    path: "/de/newsletter/choose-language",
    component: _309e9c41,
    name: "newsletter-choose-language___de"
  }, {
    path: "/de/newsletter/confirm",
    component: _90612b9a,
    name: "newsletter-confirm___de"
  }, {
    path: "/de/newsletter/unsubscribe",
    component: _033fa104,
    name: "newsletter-unsubscribe___de"
  }, {
    path: "/de/reset/confirmation",
    component: _1ea443bb,
    name: "reset-confirmation___de"
  }, {
    path: "/de/retrieve/list",
    component: _4d88f93b,
    name: "retrieve-list___de"
  }, {
    path: "/de/signup/verify",
    component: _58766906,
    name: "signup-verify___de"
  }, {
    path: "/de/terms/condition",
    component: _21b34880,
    name: "terms-condition___de"
  }, {
    path: "/de/terms/uses",
    component: _8de6e8c6,
    name: "terms-uses___de"
  }, {
    path: "/el/flight/confirm",
    component: _dcb6d832,
    name: "flight-confirm___el"
  }, {
    path: "/el/flight/search",
    component: _44fa9bd1,
    name: "flight-search___el"
  }, {
    path: "/el/help/search",
    component: _745810e0,
    name: "help-search___el"
  }, {
    path: "/el/hotel/pay",
    component: _c09f3912,
    name: "hotel-pay___el",
    children: [{
      path: ":id?/choose",
      component: _bf0be758,
      name: "hotel-pay-id-choose___el"
    }, {
      path: ":id?/confirmation",
      component: _cb87989c,
      name: "hotel-pay-id-confirmation___el"
    }]
  }, {
    path: "/el/hotel/payment",
    component: _7927db56,
    name: "hotel-payment___el",
    children: [{
      path: ":id?/choose",
      component: _5759f294,
      name: "hotel-payment-id-choose___el"
    }, {
      path: ":id?/confirmation",
      component: _bbd746d8,
      name: "hotel-payment-id-confirmation___el"
    }]
  }, {
    path: "/el/hotel/search",
    component: _e7741eae,
    name: "hotel-search___el"
  }, {
    path: "/el/member/login",
    component: _665d5706,
    name: "member-login___el"
  }, {
    path: "/el/newsletter/change-language",
    component: _0436fe08,
    name: "newsletter-change-language___el"
  }, {
    path: "/el/newsletter/choose-language",
    component: _309e9c41,
    name: "newsletter-choose-language___el"
  }, {
    path: "/el/newsletter/confirm",
    component: _90612b9a,
    name: "newsletter-confirm___el"
  }, {
    path: "/el/newsletter/unsubscribe",
    component: _033fa104,
    name: "newsletter-unsubscribe___el"
  }, {
    path: "/el/reset/confirmation",
    component: _1ea443bb,
    name: "reset-confirmation___el"
  }, {
    path: "/el/retrieve/list",
    component: _4d88f93b,
    name: "retrieve-list___el"
  }, {
    path: "/el/signup/verify",
    component: _58766906,
    name: "signup-verify___el"
  }, {
    path: "/el/terms/condition",
    component: _21b34880,
    name: "terms-condition___el"
  }, {
    path: "/el/terms/uses",
    component: _8de6e8c6,
    name: "terms-uses___el"
  }, {
    path: "/en/flight/confirm",
    component: _dcb6d832,
    name: "flight-confirm___en"
  }, {
    path: "/en/flight/search",
    component: _44fa9bd1,
    name: "flight-search___en"
  }, {
    path: "/en/help/search",
    component: _745810e0,
    name: "help-search___en"
  }, {
    path: "/en/hotel/pay",
    component: _c09f3912,
    name: "hotel-pay___en",
    children: [{
      path: ":id?/choose",
      component: _bf0be758,
      name: "hotel-pay-id-choose___en"
    }, {
      path: ":id?/confirmation",
      component: _cb87989c,
      name: "hotel-pay-id-confirmation___en"
    }]
  }, {
    path: "/en/hotel/payment",
    component: _7927db56,
    name: "hotel-payment___en",
    children: [{
      path: ":id?/choose",
      component: _5759f294,
      name: "hotel-payment-id-choose___en"
    }, {
      path: ":id?/confirmation",
      component: _bbd746d8,
      name: "hotel-payment-id-confirmation___en"
    }]
  }, {
    path: "/en/hotel/search",
    component: _e7741eae,
    name: "hotel-search___en"
  }, {
    path: "/en/member/login",
    component: _665d5706,
    name: "member-login___en"
  }, {
    path: "/en/newsletter/change-language",
    component: _0436fe08,
    name: "newsletter-change-language___en"
  }, {
    path: "/en/newsletter/choose-language",
    component: _309e9c41,
    name: "newsletter-choose-language___en"
  }, {
    path: "/en/newsletter/confirm",
    component: _90612b9a,
    name: "newsletter-confirm___en"
  }, {
    path: "/en/newsletter/unsubscribe",
    component: _033fa104,
    name: "newsletter-unsubscribe___en"
  }, {
    path: "/en/reset/confirmation",
    component: _1ea443bb,
    name: "reset-confirmation___en"
  }, {
    path: "/en/retrieve/list",
    component: _4d88f93b,
    name: "retrieve-list___en"
  }, {
    path: "/en/signup/verify",
    component: _58766906,
    name: "signup-verify___en"
  }, {
    path: "/en/terms/condition",
    component: _21b34880,
    name: "terms-condition___en"
  }, {
    path: "/en/terms/uses",
    component: _8de6e8c6,
    name: "terms-uses___en"
  }, {
    path: "/es/flight/confirm",
    component: _dcb6d832,
    name: "flight-confirm___es"
  }, {
    path: "/es/flight/search",
    component: _44fa9bd1,
    name: "flight-search___es"
  }, {
    path: "/es/help/search",
    component: _745810e0,
    name: "help-search___es"
  }, {
    path: "/es/hotel/pay",
    component: _c09f3912,
    name: "hotel-pay___es",
    children: [{
      path: ":id?/choose",
      component: _bf0be758,
      name: "hotel-pay-id-choose___es"
    }, {
      path: ":id?/confirmation",
      component: _cb87989c,
      name: "hotel-pay-id-confirmation___es"
    }]
  }, {
    path: "/es/hotel/payment",
    component: _7927db56,
    name: "hotel-payment___es",
    children: [{
      path: ":id?/choose",
      component: _5759f294,
      name: "hotel-payment-id-choose___es"
    }, {
      path: ":id?/confirmation",
      component: _bbd746d8,
      name: "hotel-payment-id-confirmation___es"
    }]
  }, {
    path: "/es/hotel/search",
    component: _e7741eae,
    name: "hotel-search___es"
  }, {
    path: "/es/member/login",
    component: _665d5706,
    name: "member-login___es"
  }, {
    path: "/es/newsletter/change-language",
    component: _0436fe08,
    name: "newsletter-change-language___es"
  }, {
    path: "/es/newsletter/choose-language",
    component: _309e9c41,
    name: "newsletter-choose-language___es"
  }, {
    path: "/es/newsletter/confirm",
    component: _90612b9a,
    name: "newsletter-confirm___es"
  }, {
    path: "/es/newsletter/unsubscribe",
    component: _033fa104,
    name: "newsletter-unsubscribe___es"
  }, {
    path: "/es/reset/confirmation",
    component: _1ea443bb,
    name: "reset-confirmation___es"
  }, {
    path: "/es/retrieve/list",
    component: _4d88f93b,
    name: "retrieve-list___es"
  }, {
    path: "/es/signup/verify",
    component: _58766906,
    name: "signup-verify___es"
  }, {
    path: "/es/terms/condition",
    component: _21b34880,
    name: "terms-condition___es"
  }, {
    path: "/es/terms/uses",
    component: _8de6e8c6,
    name: "terms-uses___es"
  }, {
    path: "/fi/flight/confirm",
    component: _dcb6d832,
    name: "flight-confirm___fi"
  }, {
    path: "/fi/flight/search",
    component: _44fa9bd1,
    name: "flight-search___fi"
  }, {
    path: "/fi/help/search",
    component: _745810e0,
    name: "help-search___fi"
  }, {
    path: "/fi/hotel/pay",
    component: _c09f3912,
    name: "hotel-pay___fi",
    children: [{
      path: ":id?/choose",
      component: _bf0be758,
      name: "hotel-pay-id-choose___fi"
    }, {
      path: ":id?/confirmation",
      component: _cb87989c,
      name: "hotel-pay-id-confirmation___fi"
    }]
  }, {
    path: "/fi/hotel/payment",
    component: _7927db56,
    name: "hotel-payment___fi",
    children: [{
      path: ":id?/choose",
      component: _5759f294,
      name: "hotel-payment-id-choose___fi"
    }, {
      path: ":id?/confirmation",
      component: _bbd746d8,
      name: "hotel-payment-id-confirmation___fi"
    }]
  }, {
    path: "/fi/hotel/search",
    component: _e7741eae,
    name: "hotel-search___fi"
  }, {
    path: "/fi/member/login",
    component: _665d5706,
    name: "member-login___fi"
  }, {
    path: "/fi/newsletter/change-language",
    component: _0436fe08,
    name: "newsletter-change-language___fi"
  }, {
    path: "/fi/newsletter/choose-language",
    component: _309e9c41,
    name: "newsletter-choose-language___fi"
  }, {
    path: "/fi/newsletter/confirm",
    component: _90612b9a,
    name: "newsletter-confirm___fi"
  }, {
    path: "/fi/newsletter/unsubscribe",
    component: _033fa104,
    name: "newsletter-unsubscribe___fi"
  }, {
    path: "/fi/reset/confirmation",
    component: _1ea443bb,
    name: "reset-confirmation___fi"
  }, {
    path: "/fi/retrieve/list",
    component: _4d88f93b,
    name: "retrieve-list___fi"
  }, {
    path: "/fi/signup/verify",
    component: _58766906,
    name: "signup-verify___fi"
  }, {
    path: "/fi/terms/condition",
    component: _21b34880,
    name: "terms-condition___fi"
  }, {
    path: "/fi/terms/uses",
    component: _8de6e8c6,
    name: "terms-uses___fi"
  }, {
    path: "/fr/flight/confirm",
    component: _dcb6d832,
    name: "flight-confirm___fr"
  }, {
    path: "/fr/flight/search",
    component: _44fa9bd1,
    name: "flight-search___fr"
  }, {
    path: "/fr/help/search",
    component: _745810e0,
    name: "help-search___fr"
  }, {
    path: "/fr/hotel/pay",
    component: _c09f3912,
    name: "hotel-pay___fr",
    children: [{
      path: ":id?/choose",
      component: _bf0be758,
      name: "hotel-pay-id-choose___fr"
    }, {
      path: ":id?/confirmation",
      component: _cb87989c,
      name: "hotel-pay-id-confirmation___fr"
    }]
  }, {
    path: "/fr/hotel/payment",
    component: _7927db56,
    name: "hotel-payment___fr",
    children: [{
      path: ":id?/choose",
      component: _5759f294,
      name: "hotel-payment-id-choose___fr"
    }, {
      path: ":id?/confirmation",
      component: _bbd746d8,
      name: "hotel-payment-id-confirmation___fr"
    }]
  }, {
    path: "/fr/hotel/search",
    component: _e7741eae,
    name: "hotel-search___fr"
  }, {
    path: "/fr/member/login",
    component: _665d5706,
    name: "member-login___fr"
  }, {
    path: "/fr/newsletter/change-language",
    component: _0436fe08,
    name: "newsletter-change-language___fr"
  }, {
    path: "/fr/newsletter/choose-language",
    component: _309e9c41,
    name: "newsletter-choose-language___fr"
  }, {
    path: "/fr/newsletter/confirm",
    component: _90612b9a,
    name: "newsletter-confirm___fr"
  }, {
    path: "/fr/newsletter/unsubscribe",
    component: _033fa104,
    name: "newsletter-unsubscribe___fr"
  }, {
    path: "/fr/reset/confirmation",
    component: _1ea443bb,
    name: "reset-confirmation___fr"
  }, {
    path: "/fr/retrieve/list",
    component: _4d88f93b,
    name: "retrieve-list___fr"
  }, {
    path: "/fr/signup/verify",
    component: _58766906,
    name: "signup-verify___fr"
  }, {
    path: "/fr/terms/condition",
    component: _21b34880,
    name: "terms-condition___fr"
  }, {
    path: "/fr/terms/uses",
    component: _8de6e8c6,
    name: "terms-uses___fr"
  }, {
    path: "/hi/flight/confirm",
    component: _dcb6d832,
    name: "flight-confirm___hi"
  }, {
    path: "/hi/flight/search",
    component: _44fa9bd1,
    name: "flight-search___hi"
  }, {
    path: "/hi/help/search",
    component: _745810e0,
    name: "help-search___hi"
  }, {
    path: "/hi/hotel/pay",
    component: _c09f3912,
    name: "hotel-pay___hi",
    children: [{
      path: ":id?/choose",
      component: _bf0be758,
      name: "hotel-pay-id-choose___hi"
    }, {
      path: ":id?/confirmation",
      component: _cb87989c,
      name: "hotel-pay-id-confirmation___hi"
    }]
  }, {
    path: "/hi/hotel/payment",
    component: _7927db56,
    name: "hotel-payment___hi",
    children: [{
      path: ":id?/choose",
      component: _5759f294,
      name: "hotel-payment-id-choose___hi"
    }, {
      path: ":id?/confirmation",
      component: _bbd746d8,
      name: "hotel-payment-id-confirmation___hi"
    }]
  }, {
    path: "/hi/hotel/search",
    component: _e7741eae,
    name: "hotel-search___hi"
  }, {
    path: "/hi/member/login",
    component: _665d5706,
    name: "member-login___hi"
  }, {
    path: "/hi/newsletter/change-language",
    component: _0436fe08,
    name: "newsletter-change-language___hi"
  }, {
    path: "/hi/newsletter/choose-language",
    component: _309e9c41,
    name: "newsletter-choose-language___hi"
  }, {
    path: "/hi/newsletter/confirm",
    component: _90612b9a,
    name: "newsletter-confirm___hi"
  }, {
    path: "/hi/newsletter/unsubscribe",
    component: _033fa104,
    name: "newsletter-unsubscribe___hi"
  }, {
    path: "/hi/reset/confirmation",
    component: _1ea443bb,
    name: "reset-confirmation___hi"
  }, {
    path: "/hi/retrieve/list",
    component: _4d88f93b,
    name: "retrieve-list___hi"
  }, {
    path: "/hi/signup/verify",
    component: _58766906,
    name: "signup-verify___hi"
  }, {
    path: "/hi/terms/condition",
    component: _21b34880,
    name: "terms-condition___hi"
  }, {
    path: "/hi/terms/uses",
    component: _8de6e8c6,
    name: "terms-uses___hi"
  }, {
    path: "/hr/flight/confirm",
    component: _dcb6d832,
    name: "flight-confirm___hr"
  }, {
    path: "/hr/flight/search",
    component: _44fa9bd1,
    name: "flight-search___hr"
  }, {
    path: "/hr/help/search",
    component: _745810e0,
    name: "help-search___hr"
  }, {
    path: "/hr/hotel/pay",
    component: _c09f3912,
    name: "hotel-pay___hr",
    children: [{
      path: ":id?/choose",
      component: _bf0be758,
      name: "hotel-pay-id-choose___hr"
    }, {
      path: ":id?/confirmation",
      component: _cb87989c,
      name: "hotel-pay-id-confirmation___hr"
    }]
  }, {
    path: "/hr/hotel/payment",
    component: _7927db56,
    name: "hotel-payment___hr",
    children: [{
      path: ":id?/choose",
      component: _5759f294,
      name: "hotel-payment-id-choose___hr"
    }, {
      path: ":id?/confirmation",
      component: _bbd746d8,
      name: "hotel-payment-id-confirmation___hr"
    }]
  }, {
    path: "/hr/hotel/search",
    component: _e7741eae,
    name: "hotel-search___hr"
  }, {
    path: "/hr/member/login",
    component: _665d5706,
    name: "member-login___hr"
  }, {
    path: "/hr/newsletter/change-language",
    component: _0436fe08,
    name: "newsletter-change-language___hr"
  }, {
    path: "/hr/newsletter/choose-language",
    component: _309e9c41,
    name: "newsletter-choose-language___hr"
  }, {
    path: "/hr/newsletter/confirm",
    component: _90612b9a,
    name: "newsletter-confirm___hr"
  }, {
    path: "/hr/newsletter/unsubscribe",
    component: _033fa104,
    name: "newsletter-unsubscribe___hr"
  }, {
    path: "/hr/reset/confirmation",
    component: _1ea443bb,
    name: "reset-confirmation___hr"
  }, {
    path: "/hr/retrieve/list",
    component: _4d88f93b,
    name: "retrieve-list___hr"
  }, {
    path: "/hr/signup/verify",
    component: _58766906,
    name: "signup-verify___hr"
  }, {
    path: "/hr/terms/condition",
    component: _21b34880,
    name: "terms-condition___hr"
  }, {
    path: "/hr/terms/uses",
    component: _8de6e8c6,
    name: "terms-uses___hr"
  }, {
    path: "/hu/flight/confirm",
    component: _dcb6d832,
    name: "flight-confirm___hu"
  }, {
    path: "/hu/flight/search",
    component: _44fa9bd1,
    name: "flight-search___hu"
  }, {
    path: "/hu/help/search",
    component: _745810e0,
    name: "help-search___hu"
  }, {
    path: "/hu/hotel/pay",
    component: _c09f3912,
    name: "hotel-pay___hu",
    children: [{
      path: ":id?/choose",
      component: _bf0be758,
      name: "hotel-pay-id-choose___hu"
    }, {
      path: ":id?/confirmation",
      component: _cb87989c,
      name: "hotel-pay-id-confirmation___hu"
    }]
  }, {
    path: "/hu/hotel/payment",
    component: _7927db56,
    name: "hotel-payment___hu",
    children: [{
      path: ":id?/choose",
      component: _5759f294,
      name: "hotel-payment-id-choose___hu"
    }, {
      path: ":id?/confirmation",
      component: _bbd746d8,
      name: "hotel-payment-id-confirmation___hu"
    }]
  }, {
    path: "/hu/hotel/search",
    component: _e7741eae,
    name: "hotel-search___hu"
  }, {
    path: "/hu/member/login",
    component: _665d5706,
    name: "member-login___hu"
  }, {
    path: "/hu/newsletter/change-language",
    component: _0436fe08,
    name: "newsletter-change-language___hu"
  }, {
    path: "/hu/newsletter/choose-language",
    component: _309e9c41,
    name: "newsletter-choose-language___hu"
  }, {
    path: "/hu/newsletter/confirm",
    component: _90612b9a,
    name: "newsletter-confirm___hu"
  }, {
    path: "/hu/newsletter/unsubscribe",
    component: _033fa104,
    name: "newsletter-unsubscribe___hu"
  }, {
    path: "/hu/reset/confirmation",
    component: _1ea443bb,
    name: "reset-confirmation___hu"
  }, {
    path: "/hu/retrieve/list",
    component: _4d88f93b,
    name: "retrieve-list___hu"
  }, {
    path: "/hu/signup/verify",
    component: _58766906,
    name: "signup-verify___hu"
  }, {
    path: "/hu/terms/condition",
    component: _21b34880,
    name: "terms-condition___hu"
  }, {
    path: "/hu/terms/uses",
    component: _8de6e8c6,
    name: "terms-uses___hu"
  }, {
    path: "/id/flight/confirm",
    component: _dcb6d832,
    name: "flight-confirm___id"
  }, {
    path: "/id/flight/search",
    component: _44fa9bd1,
    name: "flight-search___id"
  }, {
    path: "/id/help/search",
    component: _745810e0,
    name: "help-search___id"
  }, {
    path: "/id/hotel/pay",
    component: _c09f3912,
    name: "hotel-pay___id",
    children: [{
      path: ":id?/choose",
      component: _bf0be758,
      name: "hotel-pay-id-choose___id"
    }, {
      path: ":id?/confirmation",
      component: _cb87989c,
      name: "hotel-pay-id-confirmation___id"
    }]
  }, {
    path: "/id/hotel/payment",
    component: _7927db56,
    name: "hotel-payment___id",
    children: [{
      path: ":id?/choose",
      component: _5759f294,
      name: "hotel-payment-id-choose___id"
    }, {
      path: ":id?/confirmation",
      component: _bbd746d8,
      name: "hotel-payment-id-confirmation___id"
    }]
  }, {
    path: "/id/hotel/search",
    component: _e7741eae,
    name: "hotel-search___id"
  }, {
    path: "/id/member/login",
    component: _665d5706,
    name: "member-login___id"
  }, {
    path: "/id/newsletter/change-language",
    component: _0436fe08,
    name: "newsletter-change-language___id"
  }, {
    path: "/id/newsletter/choose-language",
    component: _309e9c41,
    name: "newsletter-choose-language___id"
  }, {
    path: "/id/newsletter/confirm",
    component: _90612b9a,
    name: "newsletter-confirm___id"
  }, {
    path: "/id/newsletter/unsubscribe",
    component: _033fa104,
    name: "newsletter-unsubscribe___id"
  }, {
    path: "/id/reset/confirmation",
    component: _1ea443bb,
    name: "reset-confirmation___id"
  }, {
    path: "/id/retrieve/list",
    component: _4d88f93b,
    name: "retrieve-list___id"
  }, {
    path: "/id/signup/verify",
    component: _58766906,
    name: "signup-verify___id"
  }, {
    path: "/id/terms/condition",
    component: _21b34880,
    name: "terms-condition___id"
  }, {
    path: "/id/terms/uses",
    component: _8de6e8c6,
    name: "terms-uses___id"
  }, {
    path: "/it/flight/confirm",
    component: _dcb6d832,
    name: "flight-confirm___it"
  }, {
    path: "/it/flight/search",
    component: _44fa9bd1,
    name: "flight-search___it"
  }, {
    path: "/it/help/search",
    component: _745810e0,
    name: "help-search___it"
  }, {
    path: "/it/hotel/pay",
    component: _c09f3912,
    name: "hotel-pay___it",
    children: [{
      path: ":id?/choose",
      component: _bf0be758,
      name: "hotel-pay-id-choose___it"
    }, {
      path: ":id?/confirmation",
      component: _cb87989c,
      name: "hotel-pay-id-confirmation___it"
    }]
  }, {
    path: "/it/hotel/payment",
    component: _7927db56,
    name: "hotel-payment___it",
    children: [{
      path: ":id?/choose",
      component: _5759f294,
      name: "hotel-payment-id-choose___it"
    }, {
      path: ":id?/confirmation",
      component: _bbd746d8,
      name: "hotel-payment-id-confirmation___it"
    }]
  }, {
    path: "/it/hotel/search",
    component: _e7741eae,
    name: "hotel-search___it"
  }, {
    path: "/it/member/login",
    component: _665d5706,
    name: "member-login___it"
  }, {
    path: "/it/newsletter/change-language",
    component: _0436fe08,
    name: "newsletter-change-language___it"
  }, {
    path: "/it/newsletter/choose-language",
    component: _309e9c41,
    name: "newsletter-choose-language___it"
  }, {
    path: "/it/newsletter/confirm",
    component: _90612b9a,
    name: "newsletter-confirm___it"
  }, {
    path: "/it/newsletter/unsubscribe",
    component: _033fa104,
    name: "newsletter-unsubscribe___it"
  }, {
    path: "/it/reset/confirmation",
    component: _1ea443bb,
    name: "reset-confirmation___it"
  }, {
    path: "/it/retrieve/list",
    component: _4d88f93b,
    name: "retrieve-list___it"
  }, {
    path: "/it/signup/verify",
    component: _58766906,
    name: "signup-verify___it"
  }, {
    path: "/it/terms/condition",
    component: _21b34880,
    name: "terms-condition___it"
  }, {
    path: "/it/terms/uses",
    component: _8de6e8c6,
    name: "terms-uses___it"
  }, {
    path: "/ja/flight/confirm",
    component: _dcb6d832,
    name: "flight-confirm___ja"
  }, {
    path: "/ja/flight/search",
    component: _44fa9bd1,
    name: "flight-search___ja"
  }, {
    path: "/ja/help/search",
    component: _745810e0,
    name: "help-search___ja"
  }, {
    path: "/ja/hotel/pay",
    component: _c09f3912,
    name: "hotel-pay___ja",
    children: [{
      path: ":id?/choose",
      component: _bf0be758,
      name: "hotel-pay-id-choose___ja"
    }, {
      path: ":id?/confirmation",
      component: _cb87989c,
      name: "hotel-pay-id-confirmation___ja"
    }]
  }, {
    path: "/ja/hotel/payment",
    component: _7927db56,
    name: "hotel-payment___ja",
    children: [{
      path: ":id?/choose",
      component: _5759f294,
      name: "hotel-payment-id-choose___ja"
    }, {
      path: ":id?/confirmation",
      component: _bbd746d8,
      name: "hotel-payment-id-confirmation___ja"
    }]
  }, {
    path: "/ja/hotel/search",
    component: _e7741eae,
    name: "hotel-search___ja"
  }, {
    path: "/ja/member/login",
    component: _665d5706,
    name: "member-login___ja"
  }, {
    path: "/ja/newsletter/change-language",
    component: _0436fe08,
    name: "newsletter-change-language___ja"
  }, {
    path: "/ja/newsletter/choose-language",
    component: _309e9c41,
    name: "newsletter-choose-language___ja"
  }, {
    path: "/ja/newsletter/confirm",
    component: _90612b9a,
    name: "newsletter-confirm___ja"
  }, {
    path: "/ja/newsletter/unsubscribe",
    component: _033fa104,
    name: "newsletter-unsubscribe___ja"
  }, {
    path: "/ja/reset/confirmation",
    component: _1ea443bb,
    name: "reset-confirmation___ja"
  }, {
    path: "/ja/retrieve/list",
    component: _4d88f93b,
    name: "retrieve-list___ja"
  }, {
    path: "/ja/signup/verify",
    component: _58766906,
    name: "signup-verify___ja"
  }, {
    path: "/ja/terms/condition",
    component: _21b34880,
    name: "terms-condition___ja"
  }, {
    path: "/ja/terms/uses",
    component: _8de6e8c6,
    name: "terms-uses___ja"
  }, {
    path: "/km/flight/confirm",
    component: _dcb6d832,
    name: "flight-confirm___km"
  }, {
    path: "/km/flight/search",
    component: _44fa9bd1,
    name: "flight-search___km"
  }, {
    path: "/km/help/search",
    component: _745810e0,
    name: "help-search___km"
  }, {
    path: "/km/hotel/pay",
    component: _c09f3912,
    name: "hotel-pay___km",
    children: [{
      path: ":id?/choose",
      component: _bf0be758,
      name: "hotel-pay-id-choose___km"
    }, {
      path: ":id?/confirmation",
      component: _cb87989c,
      name: "hotel-pay-id-confirmation___km"
    }]
  }, {
    path: "/km/hotel/payment",
    component: _7927db56,
    name: "hotel-payment___km",
    children: [{
      path: ":id?/choose",
      component: _5759f294,
      name: "hotel-payment-id-choose___km"
    }, {
      path: ":id?/confirmation",
      component: _bbd746d8,
      name: "hotel-payment-id-confirmation___km"
    }]
  }, {
    path: "/km/hotel/search",
    component: _e7741eae,
    name: "hotel-search___km"
  }, {
    path: "/km/member/login",
    component: _665d5706,
    name: "member-login___km"
  }, {
    path: "/km/newsletter/change-language",
    component: _0436fe08,
    name: "newsletter-change-language___km"
  }, {
    path: "/km/newsletter/choose-language",
    component: _309e9c41,
    name: "newsletter-choose-language___km"
  }, {
    path: "/km/newsletter/confirm",
    component: _90612b9a,
    name: "newsletter-confirm___km"
  }, {
    path: "/km/newsletter/unsubscribe",
    component: _033fa104,
    name: "newsletter-unsubscribe___km"
  }, {
    path: "/km/reset/confirmation",
    component: _1ea443bb,
    name: "reset-confirmation___km"
  }, {
    path: "/km/retrieve/list",
    component: _4d88f93b,
    name: "retrieve-list___km"
  }, {
    path: "/km/signup/verify",
    component: _58766906,
    name: "signup-verify___km"
  }, {
    path: "/km/terms/condition",
    component: _21b34880,
    name: "terms-condition___km"
  }, {
    path: "/km/terms/uses",
    component: _8de6e8c6,
    name: "terms-uses___km"
  }, {
    path: "/ko/flight/confirm",
    component: _dcb6d832,
    name: "flight-confirm___ko"
  }, {
    path: "/ko/flight/search",
    component: _44fa9bd1,
    name: "flight-search___ko"
  }, {
    path: "/ko/help/search",
    component: _745810e0,
    name: "help-search___ko"
  }, {
    path: "/ko/hotel/pay",
    component: _c09f3912,
    name: "hotel-pay___ko",
    children: [{
      path: ":id?/choose",
      component: _bf0be758,
      name: "hotel-pay-id-choose___ko"
    }, {
      path: ":id?/confirmation",
      component: _cb87989c,
      name: "hotel-pay-id-confirmation___ko"
    }]
  }, {
    path: "/ko/hotel/payment",
    component: _7927db56,
    name: "hotel-payment___ko",
    children: [{
      path: ":id?/choose",
      component: _5759f294,
      name: "hotel-payment-id-choose___ko"
    }, {
      path: ":id?/confirmation",
      component: _bbd746d8,
      name: "hotel-payment-id-confirmation___ko"
    }]
  }, {
    path: "/ko/hotel/search",
    component: _e7741eae,
    name: "hotel-search___ko"
  }, {
    path: "/ko/member/login",
    component: _665d5706,
    name: "member-login___ko"
  }, {
    path: "/ko/newsletter/change-language",
    component: _0436fe08,
    name: "newsletter-change-language___ko"
  }, {
    path: "/ko/newsletter/choose-language",
    component: _309e9c41,
    name: "newsletter-choose-language___ko"
  }, {
    path: "/ko/newsletter/confirm",
    component: _90612b9a,
    name: "newsletter-confirm___ko"
  }, {
    path: "/ko/newsletter/unsubscribe",
    component: _033fa104,
    name: "newsletter-unsubscribe___ko"
  }, {
    path: "/ko/reset/confirmation",
    component: _1ea443bb,
    name: "reset-confirmation___ko"
  }, {
    path: "/ko/retrieve/list",
    component: _4d88f93b,
    name: "retrieve-list___ko"
  }, {
    path: "/ko/signup/verify",
    component: _58766906,
    name: "signup-verify___ko"
  }, {
    path: "/ko/terms/condition",
    component: _21b34880,
    name: "terms-condition___ko"
  }, {
    path: "/ko/terms/uses",
    component: _8de6e8c6,
    name: "terms-uses___ko"
  }, {
    path: "/ms/flight/confirm",
    component: _dcb6d832,
    name: "flight-confirm___ms"
  }, {
    path: "/ms/flight/search",
    component: _44fa9bd1,
    name: "flight-search___ms"
  }, {
    path: "/ms/help/search",
    component: _745810e0,
    name: "help-search___ms"
  }, {
    path: "/ms/hotel/pay",
    component: _c09f3912,
    name: "hotel-pay___ms",
    children: [{
      path: ":id?/choose",
      component: _bf0be758,
      name: "hotel-pay-id-choose___ms"
    }, {
      path: ":id?/confirmation",
      component: _cb87989c,
      name: "hotel-pay-id-confirmation___ms"
    }]
  }, {
    path: "/ms/hotel/payment",
    component: _7927db56,
    name: "hotel-payment___ms",
    children: [{
      path: ":id?/choose",
      component: _5759f294,
      name: "hotel-payment-id-choose___ms"
    }, {
      path: ":id?/confirmation",
      component: _bbd746d8,
      name: "hotel-payment-id-confirmation___ms"
    }]
  }, {
    path: "/ms/hotel/search",
    component: _e7741eae,
    name: "hotel-search___ms"
  }, {
    path: "/ms/member/login",
    component: _665d5706,
    name: "member-login___ms"
  }, {
    path: "/ms/newsletter/change-language",
    component: _0436fe08,
    name: "newsletter-change-language___ms"
  }, {
    path: "/ms/newsletter/choose-language",
    component: _309e9c41,
    name: "newsletter-choose-language___ms"
  }, {
    path: "/ms/newsletter/confirm",
    component: _90612b9a,
    name: "newsletter-confirm___ms"
  }, {
    path: "/ms/newsletter/unsubscribe",
    component: _033fa104,
    name: "newsletter-unsubscribe___ms"
  }, {
    path: "/ms/reset/confirmation",
    component: _1ea443bb,
    name: "reset-confirmation___ms"
  }, {
    path: "/ms/retrieve/list",
    component: _4d88f93b,
    name: "retrieve-list___ms"
  }, {
    path: "/ms/signup/verify",
    component: _58766906,
    name: "signup-verify___ms"
  }, {
    path: "/ms/terms/condition",
    component: _21b34880,
    name: "terms-condition___ms"
  }, {
    path: "/ms/terms/uses",
    component: _8de6e8c6,
    name: "terms-uses___ms"
  }, {
    path: "/nb/flight/confirm",
    component: _dcb6d832,
    name: "flight-confirm___nb"
  }, {
    path: "/nb/flight/search",
    component: _44fa9bd1,
    name: "flight-search___nb"
  }, {
    path: "/nb/help/search",
    component: _745810e0,
    name: "help-search___nb"
  }, {
    path: "/nb/hotel/pay",
    component: _c09f3912,
    name: "hotel-pay___nb",
    children: [{
      path: ":id?/choose",
      component: _bf0be758,
      name: "hotel-pay-id-choose___nb"
    }, {
      path: ":id?/confirmation",
      component: _cb87989c,
      name: "hotel-pay-id-confirmation___nb"
    }]
  }, {
    path: "/nb/hotel/payment",
    component: _7927db56,
    name: "hotel-payment___nb",
    children: [{
      path: ":id?/choose",
      component: _5759f294,
      name: "hotel-payment-id-choose___nb"
    }, {
      path: ":id?/confirmation",
      component: _bbd746d8,
      name: "hotel-payment-id-confirmation___nb"
    }]
  }, {
    path: "/nb/hotel/search",
    component: _e7741eae,
    name: "hotel-search___nb"
  }, {
    path: "/nb/member/login",
    component: _665d5706,
    name: "member-login___nb"
  }, {
    path: "/nb/newsletter/change-language",
    component: _0436fe08,
    name: "newsletter-change-language___nb"
  }, {
    path: "/nb/newsletter/choose-language",
    component: _309e9c41,
    name: "newsletter-choose-language___nb"
  }, {
    path: "/nb/newsletter/confirm",
    component: _90612b9a,
    name: "newsletter-confirm___nb"
  }, {
    path: "/nb/newsletter/unsubscribe",
    component: _033fa104,
    name: "newsletter-unsubscribe___nb"
  }, {
    path: "/nb/reset/confirmation",
    component: _1ea443bb,
    name: "reset-confirmation___nb"
  }, {
    path: "/nb/retrieve/list",
    component: _4d88f93b,
    name: "retrieve-list___nb"
  }, {
    path: "/nb/signup/verify",
    component: _58766906,
    name: "signup-verify___nb"
  }, {
    path: "/nb/terms/condition",
    component: _21b34880,
    name: "terms-condition___nb"
  }, {
    path: "/nb/terms/uses",
    component: _8de6e8c6,
    name: "terms-uses___nb"
  }, {
    path: "/nl/flight/confirm",
    component: _dcb6d832,
    name: "flight-confirm___nl"
  }, {
    path: "/nl/flight/search",
    component: _44fa9bd1,
    name: "flight-search___nl"
  }, {
    path: "/nl/help/search",
    component: _745810e0,
    name: "help-search___nl"
  }, {
    path: "/nl/hotel/pay",
    component: _c09f3912,
    name: "hotel-pay___nl",
    children: [{
      path: ":id?/choose",
      component: _bf0be758,
      name: "hotel-pay-id-choose___nl"
    }, {
      path: ":id?/confirmation",
      component: _cb87989c,
      name: "hotel-pay-id-confirmation___nl"
    }]
  }, {
    path: "/nl/hotel/payment",
    component: _7927db56,
    name: "hotel-payment___nl",
    children: [{
      path: ":id?/choose",
      component: _5759f294,
      name: "hotel-payment-id-choose___nl"
    }, {
      path: ":id?/confirmation",
      component: _bbd746d8,
      name: "hotel-payment-id-confirmation___nl"
    }]
  }, {
    path: "/nl/hotel/search",
    component: _e7741eae,
    name: "hotel-search___nl"
  }, {
    path: "/nl/member/login",
    component: _665d5706,
    name: "member-login___nl"
  }, {
    path: "/nl/newsletter/change-language",
    component: _0436fe08,
    name: "newsletter-change-language___nl"
  }, {
    path: "/nl/newsletter/choose-language",
    component: _309e9c41,
    name: "newsletter-choose-language___nl"
  }, {
    path: "/nl/newsletter/confirm",
    component: _90612b9a,
    name: "newsletter-confirm___nl"
  }, {
    path: "/nl/newsletter/unsubscribe",
    component: _033fa104,
    name: "newsletter-unsubscribe___nl"
  }, {
    path: "/nl/reset/confirmation",
    component: _1ea443bb,
    name: "reset-confirmation___nl"
  }, {
    path: "/nl/retrieve/list",
    component: _4d88f93b,
    name: "retrieve-list___nl"
  }, {
    path: "/nl/signup/verify",
    component: _58766906,
    name: "signup-verify___nl"
  }, {
    path: "/nl/terms/condition",
    component: _21b34880,
    name: "terms-condition___nl"
  }, {
    path: "/nl/terms/uses",
    component: _8de6e8c6,
    name: "terms-uses___nl"
  }, {
    path: "/pl/flight/confirm",
    component: _dcb6d832,
    name: "flight-confirm___pl"
  }, {
    path: "/pl/flight/search",
    component: _44fa9bd1,
    name: "flight-search___pl"
  }, {
    path: "/pl/help/search",
    component: _745810e0,
    name: "help-search___pl"
  }, {
    path: "/pl/hotel/pay",
    component: _c09f3912,
    name: "hotel-pay___pl",
    children: [{
      path: ":id?/choose",
      component: _bf0be758,
      name: "hotel-pay-id-choose___pl"
    }, {
      path: ":id?/confirmation",
      component: _cb87989c,
      name: "hotel-pay-id-confirmation___pl"
    }]
  }, {
    path: "/pl/hotel/payment",
    component: _7927db56,
    name: "hotel-payment___pl",
    children: [{
      path: ":id?/choose",
      component: _5759f294,
      name: "hotel-payment-id-choose___pl"
    }, {
      path: ":id?/confirmation",
      component: _bbd746d8,
      name: "hotel-payment-id-confirmation___pl"
    }]
  }, {
    path: "/pl/hotel/search",
    component: _e7741eae,
    name: "hotel-search___pl"
  }, {
    path: "/pl/member/login",
    component: _665d5706,
    name: "member-login___pl"
  }, {
    path: "/pl/newsletter/change-language",
    component: _0436fe08,
    name: "newsletter-change-language___pl"
  }, {
    path: "/pl/newsletter/choose-language",
    component: _309e9c41,
    name: "newsletter-choose-language___pl"
  }, {
    path: "/pl/newsletter/confirm",
    component: _90612b9a,
    name: "newsletter-confirm___pl"
  }, {
    path: "/pl/newsletter/unsubscribe",
    component: _033fa104,
    name: "newsletter-unsubscribe___pl"
  }, {
    path: "/pl/reset/confirmation",
    component: _1ea443bb,
    name: "reset-confirmation___pl"
  }, {
    path: "/pl/retrieve/list",
    component: _4d88f93b,
    name: "retrieve-list___pl"
  }, {
    path: "/pl/signup/verify",
    component: _58766906,
    name: "signup-verify___pl"
  }, {
    path: "/pl/terms/condition",
    component: _21b34880,
    name: "terms-condition___pl"
  }, {
    path: "/pl/terms/uses",
    component: _8de6e8c6,
    name: "terms-uses___pl"
  }, {
    path: "/pt/flight/confirm",
    component: _dcb6d832,
    name: "flight-confirm___pt"
  }, {
    path: "/pt/flight/search",
    component: _44fa9bd1,
    name: "flight-search___pt"
  }, {
    path: "/pt/help/search",
    component: _745810e0,
    name: "help-search___pt"
  }, {
    path: "/pt/hotel/pay",
    component: _c09f3912,
    name: "hotel-pay___pt",
    children: [{
      path: ":id?/choose",
      component: _bf0be758,
      name: "hotel-pay-id-choose___pt"
    }, {
      path: ":id?/confirmation",
      component: _cb87989c,
      name: "hotel-pay-id-confirmation___pt"
    }]
  }, {
    path: "/pt/hotel/payment",
    component: _7927db56,
    name: "hotel-payment___pt",
    children: [{
      path: ":id?/choose",
      component: _5759f294,
      name: "hotel-payment-id-choose___pt"
    }, {
      path: ":id?/confirmation",
      component: _bbd746d8,
      name: "hotel-payment-id-confirmation___pt"
    }]
  }, {
    path: "/pt/hotel/search",
    component: _e7741eae,
    name: "hotel-search___pt"
  }, {
    path: "/pt/member/login",
    component: _665d5706,
    name: "member-login___pt"
  }, {
    path: "/pt/newsletter/change-language",
    component: _0436fe08,
    name: "newsletter-change-language___pt"
  }, {
    path: "/pt/newsletter/choose-language",
    component: _309e9c41,
    name: "newsletter-choose-language___pt"
  }, {
    path: "/pt/newsletter/confirm",
    component: _90612b9a,
    name: "newsletter-confirm___pt"
  }, {
    path: "/pt/newsletter/unsubscribe",
    component: _033fa104,
    name: "newsletter-unsubscribe___pt"
  }, {
    path: "/pt/reset/confirmation",
    component: _1ea443bb,
    name: "reset-confirmation___pt"
  }, {
    path: "/pt/retrieve/list",
    component: _4d88f93b,
    name: "retrieve-list___pt"
  }, {
    path: "/pt/signup/verify",
    component: _58766906,
    name: "signup-verify___pt"
  }, {
    path: "/pt/terms/condition",
    component: _21b34880,
    name: "terms-condition___pt"
  }, {
    path: "/pt/terms/uses",
    component: _8de6e8c6,
    name: "terms-uses___pt"
  }, {
    path: "/ro/flight/confirm",
    component: _dcb6d832,
    name: "flight-confirm___ro"
  }, {
    path: "/ro/flight/search",
    component: _44fa9bd1,
    name: "flight-search___ro"
  }, {
    path: "/ro/help/search",
    component: _745810e0,
    name: "help-search___ro"
  }, {
    path: "/ro/hotel/pay",
    component: _c09f3912,
    name: "hotel-pay___ro",
    children: [{
      path: ":id?/choose",
      component: _bf0be758,
      name: "hotel-pay-id-choose___ro"
    }, {
      path: ":id?/confirmation",
      component: _cb87989c,
      name: "hotel-pay-id-confirmation___ro"
    }]
  }, {
    path: "/ro/hotel/payment",
    component: _7927db56,
    name: "hotel-payment___ro",
    children: [{
      path: ":id?/choose",
      component: _5759f294,
      name: "hotel-payment-id-choose___ro"
    }, {
      path: ":id?/confirmation",
      component: _bbd746d8,
      name: "hotel-payment-id-confirmation___ro"
    }]
  }, {
    path: "/ro/hotel/search",
    component: _e7741eae,
    name: "hotel-search___ro"
  }, {
    path: "/ro/member/login",
    component: _665d5706,
    name: "member-login___ro"
  }, {
    path: "/ro/newsletter/change-language",
    component: _0436fe08,
    name: "newsletter-change-language___ro"
  }, {
    path: "/ro/newsletter/choose-language",
    component: _309e9c41,
    name: "newsletter-choose-language___ro"
  }, {
    path: "/ro/newsletter/confirm",
    component: _90612b9a,
    name: "newsletter-confirm___ro"
  }, {
    path: "/ro/newsletter/unsubscribe",
    component: _033fa104,
    name: "newsletter-unsubscribe___ro"
  }, {
    path: "/ro/reset/confirmation",
    component: _1ea443bb,
    name: "reset-confirmation___ro"
  }, {
    path: "/ro/retrieve/list",
    component: _4d88f93b,
    name: "retrieve-list___ro"
  }, {
    path: "/ro/signup/verify",
    component: _58766906,
    name: "signup-verify___ro"
  }, {
    path: "/ro/terms/condition",
    component: _21b34880,
    name: "terms-condition___ro"
  }, {
    path: "/ro/terms/uses",
    component: _8de6e8c6,
    name: "terms-uses___ro"
  }, {
    path: "/ru/flight/confirm",
    component: _dcb6d832,
    name: "flight-confirm___ru"
  }, {
    path: "/ru/flight/search",
    component: _44fa9bd1,
    name: "flight-search___ru"
  }, {
    path: "/ru/help/search",
    component: _745810e0,
    name: "help-search___ru"
  }, {
    path: "/ru/hotel/pay",
    component: _c09f3912,
    name: "hotel-pay___ru",
    children: [{
      path: ":id?/choose",
      component: _bf0be758,
      name: "hotel-pay-id-choose___ru"
    }, {
      path: ":id?/confirmation",
      component: _cb87989c,
      name: "hotel-pay-id-confirmation___ru"
    }]
  }, {
    path: "/ru/hotel/payment",
    component: _7927db56,
    name: "hotel-payment___ru",
    children: [{
      path: ":id?/choose",
      component: _5759f294,
      name: "hotel-payment-id-choose___ru"
    }, {
      path: ":id?/confirmation",
      component: _bbd746d8,
      name: "hotel-payment-id-confirmation___ru"
    }]
  }, {
    path: "/ru/hotel/search",
    component: _e7741eae,
    name: "hotel-search___ru"
  }, {
    path: "/ru/member/login",
    component: _665d5706,
    name: "member-login___ru"
  }, {
    path: "/ru/newsletter/change-language",
    component: _0436fe08,
    name: "newsletter-change-language___ru"
  }, {
    path: "/ru/newsletter/choose-language",
    component: _309e9c41,
    name: "newsletter-choose-language___ru"
  }, {
    path: "/ru/newsletter/confirm",
    component: _90612b9a,
    name: "newsletter-confirm___ru"
  }, {
    path: "/ru/newsletter/unsubscribe",
    component: _033fa104,
    name: "newsletter-unsubscribe___ru"
  }, {
    path: "/ru/reset/confirmation",
    component: _1ea443bb,
    name: "reset-confirmation___ru"
  }, {
    path: "/ru/retrieve/list",
    component: _4d88f93b,
    name: "retrieve-list___ru"
  }, {
    path: "/ru/signup/verify",
    component: _58766906,
    name: "signup-verify___ru"
  }, {
    path: "/ru/terms/condition",
    component: _21b34880,
    name: "terms-condition___ru"
  }, {
    path: "/ru/terms/uses",
    component: _8de6e8c6,
    name: "terms-uses___ru"
  }, {
    path: "/sk/flight/confirm",
    component: _dcb6d832,
    name: "flight-confirm___sk"
  }, {
    path: "/sk/flight/search",
    component: _44fa9bd1,
    name: "flight-search___sk"
  }, {
    path: "/sk/help/search",
    component: _745810e0,
    name: "help-search___sk"
  }, {
    path: "/sk/hotel/pay",
    component: _c09f3912,
    name: "hotel-pay___sk",
    children: [{
      path: ":id?/choose",
      component: _bf0be758,
      name: "hotel-pay-id-choose___sk"
    }, {
      path: ":id?/confirmation",
      component: _cb87989c,
      name: "hotel-pay-id-confirmation___sk"
    }]
  }, {
    path: "/sk/hotel/payment",
    component: _7927db56,
    name: "hotel-payment___sk",
    children: [{
      path: ":id?/choose",
      component: _5759f294,
      name: "hotel-payment-id-choose___sk"
    }, {
      path: ":id?/confirmation",
      component: _bbd746d8,
      name: "hotel-payment-id-confirmation___sk"
    }]
  }, {
    path: "/sk/hotel/search",
    component: _e7741eae,
    name: "hotel-search___sk"
  }, {
    path: "/sk/member/login",
    component: _665d5706,
    name: "member-login___sk"
  }, {
    path: "/sk/newsletter/change-language",
    component: _0436fe08,
    name: "newsletter-change-language___sk"
  }, {
    path: "/sk/newsletter/choose-language",
    component: _309e9c41,
    name: "newsletter-choose-language___sk"
  }, {
    path: "/sk/newsletter/confirm",
    component: _90612b9a,
    name: "newsletter-confirm___sk"
  }, {
    path: "/sk/newsletter/unsubscribe",
    component: _033fa104,
    name: "newsletter-unsubscribe___sk"
  }, {
    path: "/sk/reset/confirmation",
    component: _1ea443bb,
    name: "reset-confirmation___sk"
  }, {
    path: "/sk/retrieve/list",
    component: _4d88f93b,
    name: "retrieve-list___sk"
  }, {
    path: "/sk/signup/verify",
    component: _58766906,
    name: "signup-verify___sk"
  }, {
    path: "/sk/terms/condition",
    component: _21b34880,
    name: "terms-condition___sk"
  }, {
    path: "/sk/terms/uses",
    component: _8de6e8c6,
    name: "terms-uses___sk"
  }, {
    path: "/sl/flight/confirm",
    component: _dcb6d832,
    name: "flight-confirm___sl"
  }, {
    path: "/sl/flight/search",
    component: _44fa9bd1,
    name: "flight-search___sl"
  }, {
    path: "/sl/help/search",
    component: _745810e0,
    name: "help-search___sl"
  }, {
    path: "/sl/hotel/pay",
    component: _c09f3912,
    name: "hotel-pay___sl",
    children: [{
      path: ":id?/choose",
      component: _bf0be758,
      name: "hotel-pay-id-choose___sl"
    }, {
      path: ":id?/confirmation",
      component: _cb87989c,
      name: "hotel-pay-id-confirmation___sl"
    }]
  }, {
    path: "/sl/hotel/payment",
    component: _7927db56,
    name: "hotel-payment___sl",
    children: [{
      path: ":id?/choose",
      component: _5759f294,
      name: "hotel-payment-id-choose___sl"
    }, {
      path: ":id?/confirmation",
      component: _bbd746d8,
      name: "hotel-payment-id-confirmation___sl"
    }]
  }, {
    path: "/sl/hotel/search",
    component: _e7741eae,
    name: "hotel-search___sl"
  }, {
    path: "/sl/member/login",
    component: _665d5706,
    name: "member-login___sl"
  }, {
    path: "/sl/newsletter/change-language",
    component: _0436fe08,
    name: "newsletter-change-language___sl"
  }, {
    path: "/sl/newsletter/choose-language",
    component: _309e9c41,
    name: "newsletter-choose-language___sl"
  }, {
    path: "/sl/newsletter/confirm",
    component: _90612b9a,
    name: "newsletter-confirm___sl"
  }, {
    path: "/sl/newsletter/unsubscribe",
    component: _033fa104,
    name: "newsletter-unsubscribe___sl"
  }, {
    path: "/sl/reset/confirmation",
    component: _1ea443bb,
    name: "reset-confirmation___sl"
  }, {
    path: "/sl/retrieve/list",
    component: _4d88f93b,
    name: "retrieve-list___sl"
  }, {
    path: "/sl/signup/verify",
    component: _58766906,
    name: "signup-verify___sl"
  }, {
    path: "/sl/terms/condition",
    component: _21b34880,
    name: "terms-condition___sl"
  }, {
    path: "/sl/terms/uses",
    component: _8de6e8c6,
    name: "terms-uses___sl"
  }, {
    path: "/sr/flight/confirm",
    component: _dcb6d832,
    name: "flight-confirm___sr"
  }, {
    path: "/sr/flight/search",
    component: _44fa9bd1,
    name: "flight-search___sr"
  }, {
    path: "/sr/help/search",
    component: _745810e0,
    name: "help-search___sr"
  }, {
    path: "/sr/hotel/pay",
    component: _c09f3912,
    name: "hotel-pay___sr",
    children: [{
      path: ":id?/choose",
      component: _bf0be758,
      name: "hotel-pay-id-choose___sr"
    }, {
      path: ":id?/confirmation",
      component: _cb87989c,
      name: "hotel-pay-id-confirmation___sr"
    }]
  }, {
    path: "/sr/hotel/payment",
    component: _7927db56,
    name: "hotel-payment___sr",
    children: [{
      path: ":id?/choose",
      component: _5759f294,
      name: "hotel-payment-id-choose___sr"
    }, {
      path: ":id?/confirmation",
      component: _bbd746d8,
      name: "hotel-payment-id-confirmation___sr"
    }]
  }, {
    path: "/sr/hotel/search",
    component: _e7741eae,
    name: "hotel-search___sr"
  }, {
    path: "/sr/member/login",
    component: _665d5706,
    name: "member-login___sr"
  }, {
    path: "/sr/newsletter/change-language",
    component: _0436fe08,
    name: "newsletter-change-language___sr"
  }, {
    path: "/sr/newsletter/choose-language",
    component: _309e9c41,
    name: "newsletter-choose-language___sr"
  }, {
    path: "/sr/newsletter/confirm",
    component: _90612b9a,
    name: "newsletter-confirm___sr"
  }, {
    path: "/sr/newsletter/unsubscribe",
    component: _033fa104,
    name: "newsletter-unsubscribe___sr"
  }, {
    path: "/sr/reset/confirmation",
    component: _1ea443bb,
    name: "reset-confirmation___sr"
  }, {
    path: "/sr/retrieve/list",
    component: _4d88f93b,
    name: "retrieve-list___sr"
  }, {
    path: "/sr/signup/verify",
    component: _58766906,
    name: "signup-verify___sr"
  }, {
    path: "/sr/terms/condition",
    component: _21b34880,
    name: "terms-condition___sr"
  }, {
    path: "/sr/terms/uses",
    component: _8de6e8c6,
    name: "terms-uses___sr"
  }, {
    path: "/sv/flight/confirm",
    component: _dcb6d832,
    name: "flight-confirm___sv"
  }, {
    path: "/sv/flight/search",
    component: _44fa9bd1,
    name: "flight-search___sv"
  }, {
    path: "/sv/help/search",
    component: _745810e0,
    name: "help-search___sv"
  }, {
    path: "/sv/hotel/pay",
    component: _c09f3912,
    name: "hotel-pay___sv",
    children: [{
      path: ":id?/choose",
      component: _bf0be758,
      name: "hotel-pay-id-choose___sv"
    }, {
      path: ":id?/confirmation",
      component: _cb87989c,
      name: "hotel-pay-id-confirmation___sv"
    }]
  }, {
    path: "/sv/hotel/payment",
    component: _7927db56,
    name: "hotel-payment___sv",
    children: [{
      path: ":id?/choose",
      component: _5759f294,
      name: "hotel-payment-id-choose___sv"
    }, {
      path: ":id?/confirmation",
      component: _bbd746d8,
      name: "hotel-payment-id-confirmation___sv"
    }]
  }, {
    path: "/sv/hotel/search",
    component: _e7741eae,
    name: "hotel-search___sv"
  }, {
    path: "/sv/member/login",
    component: _665d5706,
    name: "member-login___sv"
  }, {
    path: "/sv/newsletter/change-language",
    component: _0436fe08,
    name: "newsletter-change-language___sv"
  }, {
    path: "/sv/newsletter/choose-language",
    component: _309e9c41,
    name: "newsletter-choose-language___sv"
  }, {
    path: "/sv/newsletter/confirm",
    component: _90612b9a,
    name: "newsletter-confirm___sv"
  }, {
    path: "/sv/newsletter/unsubscribe",
    component: _033fa104,
    name: "newsletter-unsubscribe___sv"
  }, {
    path: "/sv/reset/confirmation",
    component: _1ea443bb,
    name: "reset-confirmation___sv"
  }, {
    path: "/sv/retrieve/list",
    component: _4d88f93b,
    name: "retrieve-list___sv"
  }, {
    path: "/sv/signup/verify",
    component: _58766906,
    name: "signup-verify___sv"
  }, {
    path: "/sv/terms/condition",
    component: _21b34880,
    name: "terms-condition___sv"
  }, {
    path: "/sv/terms/uses",
    component: _8de6e8c6,
    name: "terms-uses___sv"
  }, {
    path: "/th/flight/confirm",
    component: _dcb6d832,
    name: "flight-confirm___th"
  }, {
    path: "/th/flight/search",
    component: _44fa9bd1,
    name: "flight-search___th"
  }, {
    path: "/th/help/search",
    component: _745810e0,
    name: "help-search___th"
  }, {
    path: "/th/hotel/pay",
    component: _c09f3912,
    name: "hotel-pay___th",
    children: [{
      path: ":id?/choose",
      component: _bf0be758,
      name: "hotel-pay-id-choose___th"
    }, {
      path: ":id?/confirmation",
      component: _cb87989c,
      name: "hotel-pay-id-confirmation___th"
    }]
  }, {
    path: "/th/hotel/payment",
    component: _7927db56,
    name: "hotel-payment___th",
    children: [{
      path: ":id?/choose",
      component: _5759f294,
      name: "hotel-payment-id-choose___th"
    }, {
      path: ":id?/confirmation",
      component: _bbd746d8,
      name: "hotel-payment-id-confirmation___th"
    }]
  }, {
    path: "/th/hotel/search",
    component: _e7741eae,
    name: "hotel-search___th"
  }, {
    path: "/th/member/login",
    component: _665d5706,
    name: "member-login___th"
  }, {
    path: "/th/newsletter/change-language",
    component: _0436fe08,
    name: "newsletter-change-language___th"
  }, {
    path: "/th/newsletter/choose-language",
    component: _309e9c41,
    name: "newsletter-choose-language___th"
  }, {
    path: "/th/newsletter/confirm",
    component: _90612b9a,
    name: "newsletter-confirm___th"
  }, {
    path: "/th/newsletter/unsubscribe",
    component: _033fa104,
    name: "newsletter-unsubscribe___th"
  }, {
    path: "/th/reset/confirmation",
    component: _1ea443bb,
    name: "reset-confirmation___th"
  }, {
    path: "/th/retrieve/list",
    component: _4d88f93b,
    name: "retrieve-list___th"
  }, {
    path: "/th/signup/verify",
    component: _58766906,
    name: "signup-verify___th"
  }, {
    path: "/th/terms/condition",
    component: _21b34880,
    name: "terms-condition___th"
  }, {
    path: "/th/terms/uses",
    component: _8de6e8c6,
    name: "terms-uses___th"
  }, {
    path: "/tl/flight/confirm",
    component: _dcb6d832,
    name: "flight-confirm___tl"
  }, {
    path: "/tl/flight/search",
    component: _44fa9bd1,
    name: "flight-search___tl"
  }, {
    path: "/tl/help/search",
    component: _745810e0,
    name: "help-search___tl"
  }, {
    path: "/tl/hotel/pay",
    component: _c09f3912,
    name: "hotel-pay___tl",
    children: [{
      path: ":id?/choose",
      component: _bf0be758,
      name: "hotel-pay-id-choose___tl"
    }, {
      path: ":id?/confirmation",
      component: _cb87989c,
      name: "hotel-pay-id-confirmation___tl"
    }]
  }, {
    path: "/tl/hotel/payment",
    component: _7927db56,
    name: "hotel-payment___tl",
    children: [{
      path: ":id?/choose",
      component: _5759f294,
      name: "hotel-payment-id-choose___tl"
    }, {
      path: ":id?/confirmation",
      component: _bbd746d8,
      name: "hotel-payment-id-confirmation___tl"
    }]
  }, {
    path: "/tl/hotel/search",
    component: _e7741eae,
    name: "hotel-search___tl"
  }, {
    path: "/tl/member/login",
    component: _665d5706,
    name: "member-login___tl"
  }, {
    path: "/tl/newsletter/change-language",
    component: _0436fe08,
    name: "newsletter-change-language___tl"
  }, {
    path: "/tl/newsletter/choose-language",
    component: _309e9c41,
    name: "newsletter-choose-language___tl"
  }, {
    path: "/tl/newsletter/confirm",
    component: _90612b9a,
    name: "newsletter-confirm___tl"
  }, {
    path: "/tl/newsletter/unsubscribe",
    component: _033fa104,
    name: "newsletter-unsubscribe___tl"
  }, {
    path: "/tl/reset/confirmation",
    component: _1ea443bb,
    name: "reset-confirmation___tl"
  }, {
    path: "/tl/retrieve/list",
    component: _4d88f93b,
    name: "retrieve-list___tl"
  }, {
    path: "/tl/signup/verify",
    component: _58766906,
    name: "signup-verify___tl"
  }, {
    path: "/tl/terms/condition",
    component: _21b34880,
    name: "terms-condition___tl"
  }, {
    path: "/tl/terms/uses",
    component: _8de6e8c6,
    name: "terms-uses___tl"
  }, {
    path: "/tr/flight/confirm",
    component: _dcb6d832,
    name: "flight-confirm___tr"
  }, {
    path: "/tr/flight/search",
    component: _44fa9bd1,
    name: "flight-search___tr"
  }, {
    path: "/tr/help/search",
    component: _745810e0,
    name: "help-search___tr"
  }, {
    path: "/tr/hotel/pay",
    component: _c09f3912,
    name: "hotel-pay___tr",
    children: [{
      path: ":id?/choose",
      component: _bf0be758,
      name: "hotel-pay-id-choose___tr"
    }, {
      path: ":id?/confirmation",
      component: _cb87989c,
      name: "hotel-pay-id-confirmation___tr"
    }]
  }, {
    path: "/tr/hotel/payment",
    component: _7927db56,
    name: "hotel-payment___tr",
    children: [{
      path: ":id?/choose",
      component: _5759f294,
      name: "hotel-payment-id-choose___tr"
    }, {
      path: ":id?/confirmation",
      component: _bbd746d8,
      name: "hotel-payment-id-confirmation___tr"
    }]
  }, {
    path: "/tr/hotel/search",
    component: _e7741eae,
    name: "hotel-search___tr"
  }, {
    path: "/tr/member/login",
    component: _665d5706,
    name: "member-login___tr"
  }, {
    path: "/tr/newsletter/change-language",
    component: _0436fe08,
    name: "newsletter-change-language___tr"
  }, {
    path: "/tr/newsletter/choose-language",
    component: _309e9c41,
    name: "newsletter-choose-language___tr"
  }, {
    path: "/tr/newsletter/confirm",
    component: _90612b9a,
    name: "newsletter-confirm___tr"
  }, {
    path: "/tr/newsletter/unsubscribe",
    component: _033fa104,
    name: "newsletter-unsubscribe___tr"
  }, {
    path: "/tr/reset/confirmation",
    component: _1ea443bb,
    name: "reset-confirmation___tr"
  }, {
    path: "/tr/retrieve/list",
    component: _4d88f93b,
    name: "retrieve-list___tr"
  }, {
    path: "/tr/signup/verify",
    component: _58766906,
    name: "signup-verify___tr"
  }, {
    path: "/tr/terms/condition",
    component: _21b34880,
    name: "terms-condition___tr"
  }, {
    path: "/tr/terms/uses",
    component: _8de6e8c6,
    name: "terms-uses___tr"
  }, {
    path: "/uk/flight/confirm",
    component: _dcb6d832,
    name: "flight-confirm___uk"
  }, {
    path: "/uk/flight/search",
    component: _44fa9bd1,
    name: "flight-search___uk"
  }, {
    path: "/uk/help/search",
    component: _745810e0,
    name: "help-search___uk"
  }, {
    path: "/uk/hotel/pay",
    component: _c09f3912,
    name: "hotel-pay___uk",
    children: [{
      path: ":id?/choose",
      component: _bf0be758,
      name: "hotel-pay-id-choose___uk"
    }, {
      path: ":id?/confirmation",
      component: _cb87989c,
      name: "hotel-pay-id-confirmation___uk"
    }]
  }, {
    path: "/uk/hotel/payment",
    component: _7927db56,
    name: "hotel-payment___uk",
    children: [{
      path: ":id?/choose",
      component: _5759f294,
      name: "hotel-payment-id-choose___uk"
    }, {
      path: ":id?/confirmation",
      component: _bbd746d8,
      name: "hotel-payment-id-confirmation___uk"
    }]
  }, {
    path: "/uk/hotel/search",
    component: _e7741eae,
    name: "hotel-search___uk"
  }, {
    path: "/uk/member/login",
    component: _665d5706,
    name: "member-login___uk"
  }, {
    path: "/uk/newsletter/change-language",
    component: _0436fe08,
    name: "newsletter-change-language___uk"
  }, {
    path: "/uk/newsletter/choose-language",
    component: _309e9c41,
    name: "newsletter-choose-language___uk"
  }, {
    path: "/uk/newsletter/confirm",
    component: _90612b9a,
    name: "newsletter-confirm___uk"
  }, {
    path: "/uk/newsletter/unsubscribe",
    component: _033fa104,
    name: "newsletter-unsubscribe___uk"
  }, {
    path: "/uk/reset/confirmation",
    component: _1ea443bb,
    name: "reset-confirmation___uk"
  }, {
    path: "/uk/retrieve/list",
    component: _4d88f93b,
    name: "retrieve-list___uk"
  }, {
    path: "/uk/signup/verify",
    component: _58766906,
    name: "signup-verify___uk"
  }, {
    path: "/uk/terms/condition",
    component: _21b34880,
    name: "terms-condition___uk"
  }, {
    path: "/uk/terms/uses",
    component: _8de6e8c6,
    name: "terms-uses___uk"
  }, {
    path: "/vi/flight/confirm",
    component: _dcb6d832,
    name: "flight-confirm___vi"
  }, {
    path: "/vi/flight/search",
    component: _44fa9bd1,
    name: "flight-search___vi"
  }, {
    path: "/vi/help/search",
    component: _745810e0,
    name: "help-search___vi"
  }, {
    path: "/vi/hotel/pay",
    component: _c09f3912,
    name: "hotel-pay___vi",
    children: [{
      path: ":id?/choose",
      component: _bf0be758,
      name: "hotel-pay-id-choose___vi"
    }, {
      path: ":id?/confirmation",
      component: _cb87989c,
      name: "hotel-pay-id-confirmation___vi"
    }]
  }, {
    path: "/vi/hotel/payment",
    component: _7927db56,
    name: "hotel-payment___vi",
    children: [{
      path: ":id?/choose",
      component: _5759f294,
      name: "hotel-payment-id-choose___vi"
    }, {
      path: ":id?/confirmation",
      component: _bbd746d8,
      name: "hotel-payment-id-confirmation___vi"
    }]
  }, {
    path: "/vi/hotel/search",
    component: _e7741eae,
    name: "hotel-search___vi"
  }, {
    path: "/vi/member/login",
    component: _665d5706,
    name: "member-login___vi"
  }, {
    path: "/vi/newsletter/change-language",
    component: _0436fe08,
    name: "newsletter-change-language___vi"
  }, {
    path: "/vi/newsletter/choose-language",
    component: _309e9c41,
    name: "newsletter-choose-language___vi"
  }, {
    path: "/vi/newsletter/confirm",
    component: _90612b9a,
    name: "newsletter-confirm___vi"
  }, {
    path: "/vi/newsletter/unsubscribe",
    component: _033fa104,
    name: "newsletter-unsubscribe___vi"
  }, {
    path: "/vi/reset/confirmation",
    component: _1ea443bb,
    name: "reset-confirmation___vi"
  }, {
    path: "/vi/retrieve/list",
    component: _4d88f93b,
    name: "retrieve-list___vi"
  }, {
    path: "/vi/signup/verify",
    component: _58766906,
    name: "signup-verify___vi"
  }, {
    path: "/vi/terms/condition",
    component: _21b34880,
    name: "terms-condition___vi"
  }, {
    path: "/vi/terms/uses",
    component: _8de6e8c6,
    name: "terms-uses___vi"
  }, {
    path: "/zh-tw/flight/confirm",
    component: _dcb6d832,
    name: "flight-confirm___zh-tw"
  }, {
    path: "/zh-tw/flight/search",
    component: _44fa9bd1,
    name: "flight-search___zh-tw"
  }, {
    path: "/zh-tw/help/search",
    component: _745810e0,
    name: "help-search___zh-tw"
  }, {
    path: "/zh-tw/hotel/pay",
    component: _c09f3912,
    name: "hotel-pay___zh-tw",
    children: [{
      path: ":id?/choose",
      component: _bf0be758,
      name: "hotel-pay-id-choose___zh-tw"
    }, {
      path: ":id?/confirmation",
      component: _cb87989c,
      name: "hotel-pay-id-confirmation___zh-tw"
    }]
  }, {
    path: "/zh-tw/hotel/payment",
    component: _7927db56,
    name: "hotel-payment___zh-tw",
    children: [{
      path: ":id?/choose",
      component: _5759f294,
      name: "hotel-payment-id-choose___zh-tw"
    }, {
      path: ":id?/confirmation",
      component: _bbd746d8,
      name: "hotel-payment-id-confirmation___zh-tw"
    }]
  }, {
    path: "/zh-tw/hotel/search",
    component: _e7741eae,
    name: "hotel-search___zh-tw"
  }, {
    path: "/zh-tw/member/login",
    component: _665d5706,
    name: "member-login___zh-tw"
  }, {
    path: "/zh-tw/newsletter/change-language",
    component: _0436fe08,
    name: "newsletter-change-language___zh-tw"
  }, {
    path: "/zh-tw/newsletter/choose-language",
    component: _309e9c41,
    name: "newsletter-choose-language___zh-tw"
  }, {
    path: "/zh-tw/newsletter/confirm",
    component: _90612b9a,
    name: "newsletter-confirm___zh-tw"
  }, {
    path: "/zh-tw/newsletter/unsubscribe",
    component: _033fa104,
    name: "newsletter-unsubscribe___zh-tw"
  }, {
    path: "/zh-tw/reset/confirmation",
    component: _1ea443bb,
    name: "reset-confirmation___zh-tw"
  }, {
    path: "/zh-tw/retrieve/list",
    component: _4d88f93b,
    name: "retrieve-list___zh-tw"
  }, {
    path: "/zh-tw/signup/verify",
    component: _58766906,
    name: "signup-verify___zh-tw"
  }, {
    path: "/zh-tw/terms/condition",
    component: _21b34880,
    name: "terms-condition___zh-tw"
  }, {
    path: "/zh-tw/terms/uses",
    component: _8de6e8c6,
    name: "terms-uses___zh-tw"
  }, {
    path: "/zh/flight/confirm",
    component: _dcb6d832,
    name: "flight-confirm___zh"
  }, {
    path: "/zh/flight/search",
    component: _44fa9bd1,
    name: "flight-search___zh"
  }, {
    path: "/zh/help/search",
    component: _745810e0,
    name: "help-search___zh"
  }, {
    path: "/zh/hotel/pay",
    component: _c09f3912,
    name: "hotel-pay___zh",
    children: [{
      path: ":id?/choose",
      component: _bf0be758,
      name: "hotel-pay-id-choose___zh"
    }, {
      path: ":id?/confirmation",
      component: _cb87989c,
      name: "hotel-pay-id-confirmation___zh"
    }]
  }, {
    path: "/zh/hotel/payment",
    component: _7927db56,
    name: "hotel-payment___zh",
    children: [{
      path: ":id?/choose",
      component: _5759f294,
      name: "hotel-payment-id-choose___zh"
    }, {
      path: ":id?/confirmation",
      component: _bbd746d8,
      name: "hotel-payment-id-confirmation___zh"
    }]
  }, {
    path: "/zh/hotel/search",
    component: _e7741eae,
    name: "hotel-search___zh"
  }, {
    path: "/zh/member/login",
    component: _665d5706,
    name: "member-login___zh"
  }, {
    path: "/zh/newsletter/change-language",
    component: _0436fe08,
    name: "newsletter-change-language___zh"
  }, {
    path: "/zh/newsletter/choose-language",
    component: _309e9c41,
    name: "newsletter-choose-language___zh"
  }, {
    path: "/zh/newsletter/confirm",
    component: _90612b9a,
    name: "newsletter-confirm___zh"
  }, {
    path: "/zh/newsletter/unsubscribe",
    component: _033fa104,
    name: "newsletter-unsubscribe___zh"
  }, {
    path: "/zh/reset/confirmation",
    component: _1ea443bb,
    name: "reset-confirmation___zh"
  }, {
    path: "/zh/retrieve/list",
    component: _4d88f93b,
    name: "retrieve-list___zh"
  }, {
    path: "/zh/signup/verify",
    component: _58766906,
    name: "signup-verify___zh"
  }, {
    path: "/zh/terms/condition",
    component: _21b34880,
    name: "terms-condition___zh"
  }, {
    path: "/zh/terms/uses",
    component: _8de6e8c6,
    name: "terms-uses___zh"
  }, {
    path: "/bg/aftersales/add-baggage/detail/:afsId",
    component: _66d6c3d2,
    name: "aftersales-add-baggage-detail-afsId___bg"
  }, {
    path: "/bg/aftersales/add-baggage/submission/:id",
    component: _6a71517e,
    name: "aftersales-add-baggage-submission-id___bg"
  }, {
    path: "/bg/aftersales/cancellation/detail/:afsId",
    component: _2f02b848,
    name: "aftersales-cancellation-detail-afsId___bg"
  }, {
    path: "/bg/aftersales/cancellation/submission/:id",
    component: _55c67d6d,
    name: "aftersales-cancellation-submission-id___bg"
  }, {
    path: "/bg/aftersales/change-flight/detail/:afsId",
    component: _377c5270,
    name: "aftersales-change-flight-detail-afsId___bg"
  }, {
    path: "/bg/aftersales/change-flight/submission/:id",
    component: _5c802845,
    name: "aftersales-change-flight-submission-id___bg"
  }, {
    path: "/bg/aftersales/change-passenger/detail/:afsId",
    component: _77639f82,
    name: "aftersales-change-passenger-detail-afsId___bg"
  }, {
    path: "/bg/aftersales/change-passenger/submission/:id",
    component: _19827d73,
    name: "aftersales-change-passenger-submission-id___bg"
  }, {
    path: "/bg/order/manage-hotel/cancel/:id",
    component: _dd6cbe18,
    name: "order-manage-hotel-cancel-id___bg"
  }, {
    path: "/bg/order/manage-hotel/change-data/:id",
    component: _93aae60e,
    name: "order-manage-hotel-change-data-id___bg"
  }, {
    path: "/bn/aftersales/add-baggage/detail/:afsId",
    component: _66d6c3d2,
    name: "aftersales-add-baggage-detail-afsId___bn"
  }, {
    path: "/bn/aftersales/add-baggage/submission/:id",
    component: _6a71517e,
    name: "aftersales-add-baggage-submission-id___bn"
  }, {
    path: "/bn/aftersales/cancellation/detail/:afsId",
    component: _2f02b848,
    name: "aftersales-cancellation-detail-afsId___bn"
  }, {
    path: "/bn/aftersales/cancellation/submission/:id",
    component: _55c67d6d,
    name: "aftersales-cancellation-submission-id___bn"
  }, {
    path: "/bn/aftersales/change-flight/detail/:afsId",
    component: _377c5270,
    name: "aftersales-change-flight-detail-afsId___bn"
  }, {
    path: "/bn/aftersales/change-flight/submission/:id",
    component: _5c802845,
    name: "aftersales-change-flight-submission-id___bn"
  }, {
    path: "/bn/aftersales/change-passenger/detail/:afsId",
    component: _77639f82,
    name: "aftersales-change-passenger-detail-afsId___bn"
  }, {
    path: "/bn/aftersales/change-passenger/submission/:id",
    component: _19827d73,
    name: "aftersales-change-passenger-submission-id___bn"
  }, {
    path: "/bn/order/manage-hotel/cancel/:id",
    component: _dd6cbe18,
    name: "order-manage-hotel-cancel-id___bn"
  }, {
    path: "/bn/order/manage-hotel/change-data/:id",
    component: _93aae60e,
    name: "order-manage-hotel-change-data-id___bn"
  }, {
    path: "/ca/aftersales/add-baggage/detail/:afsId",
    component: _66d6c3d2,
    name: "aftersales-add-baggage-detail-afsId___ca"
  }, {
    path: "/ca/aftersales/add-baggage/submission/:id",
    component: _6a71517e,
    name: "aftersales-add-baggage-submission-id___ca"
  }, {
    path: "/ca/aftersales/cancellation/detail/:afsId",
    component: _2f02b848,
    name: "aftersales-cancellation-detail-afsId___ca"
  }, {
    path: "/ca/aftersales/cancellation/submission/:id",
    component: _55c67d6d,
    name: "aftersales-cancellation-submission-id___ca"
  }, {
    path: "/ca/aftersales/change-flight/detail/:afsId",
    component: _377c5270,
    name: "aftersales-change-flight-detail-afsId___ca"
  }, {
    path: "/ca/aftersales/change-flight/submission/:id",
    component: _5c802845,
    name: "aftersales-change-flight-submission-id___ca"
  }, {
    path: "/ca/aftersales/change-passenger/detail/:afsId",
    component: _77639f82,
    name: "aftersales-change-passenger-detail-afsId___ca"
  }, {
    path: "/ca/aftersales/change-passenger/submission/:id",
    component: _19827d73,
    name: "aftersales-change-passenger-submission-id___ca"
  }, {
    path: "/ca/order/manage-hotel/cancel/:id",
    component: _dd6cbe18,
    name: "order-manage-hotel-cancel-id___ca"
  }, {
    path: "/ca/order/manage-hotel/change-data/:id",
    component: _93aae60e,
    name: "order-manage-hotel-change-data-id___ca"
  }, {
    path: "/cs/aftersales/add-baggage/detail/:afsId",
    component: _66d6c3d2,
    name: "aftersales-add-baggage-detail-afsId___cs"
  }, {
    path: "/cs/aftersales/add-baggage/submission/:id",
    component: _6a71517e,
    name: "aftersales-add-baggage-submission-id___cs"
  }, {
    path: "/cs/aftersales/cancellation/detail/:afsId",
    component: _2f02b848,
    name: "aftersales-cancellation-detail-afsId___cs"
  }, {
    path: "/cs/aftersales/cancellation/submission/:id",
    component: _55c67d6d,
    name: "aftersales-cancellation-submission-id___cs"
  }, {
    path: "/cs/aftersales/change-flight/detail/:afsId",
    component: _377c5270,
    name: "aftersales-change-flight-detail-afsId___cs"
  }, {
    path: "/cs/aftersales/change-flight/submission/:id",
    component: _5c802845,
    name: "aftersales-change-flight-submission-id___cs"
  }, {
    path: "/cs/aftersales/change-passenger/detail/:afsId",
    component: _77639f82,
    name: "aftersales-change-passenger-detail-afsId___cs"
  }, {
    path: "/cs/aftersales/change-passenger/submission/:id",
    component: _19827d73,
    name: "aftersales-change-passenger-submission-id___cs"
  }, {
    path: "/cs/order/manage-hotel/cancel/:id",
    component: _dd6cbe18,
    name: "order-manage-hotel-cancel-id___cs"
  }, {
    path: "/cs/order/manage-hotel/change-data/:id",
    component: _93aae60e,
    name: "order-manage-hotel-change-data-id___cs"
  }, {
    path: "/da/aftersales/add-baggage/detail/:afsId",
    component: _66d6c3d2,
    name: "aftersales-add-baggage-detail-afsId___da"
  }, {
    path: "/da/aftersales/add-baggage/submission/:id",
    component: _6a71517e,
    name: "aftersales-add-baggage-submission-id___da"
  }, {
    path: "/da/aftersales/cancellation/detail/:afsId",
    component: _2f02b848,
    name: "aftersales-cancellation-detail-afsId___da"
  }, {
    path: "/da/aftersales/cancellation/submission/:id",
    component: _55c67d6d,
    name: "aftersales-cancellation-submission-id___da"
  }, {
    path: "/da/aftersales/change-flight/detail/:afsId",
    component: _377c5270,
    name: "aftersales-change-flight-detail-afsId___da"
  }, {
    path: "/da/aftersales/change-flight/submission/:id",
    component: _5c802845,
    name: "aftersales-change-flight-submission-id___da"
  }, {
    path: "/da/aftersales/change-passenger/detail/:afsId",
    component: _77639f82,
    name: "aftersales-change-passenger-detail-afsId___da"
  }, {
    path: "/da/aftersales/change-passenger/submission/:id",
    component: _19827d73,
    name: "aftersales-change-passenger-submission-id___da"
  }, {
    path: "/da/order/manage-hotel/cancel/:id",
    component: _dd6cbe18,
    name: "order-manage-hotel-cancel-id___da"
  }, {
    path: "/da/order/manage-hotel/change-data/:id",
    component: _93aae60e,
    name: "order-manage-hotel-change-data-id___da"
  }, {
    path: "/de/aftersales/add-baggage/detail/:afsId",
    component: _66d6c3d2,
    name: "aftersales-add-baggage-detail-afsId___de"
  }, {
    path: "/de/aftersales/add-baggage/submission/:id",
    component: _6a71517e,
    name: "aftersales-add-baggage-submission-id___de"
  }, {
    path: "/de/aftersales/cancellation/detail/:afsId",
    component: _2f02b848,
    name: "aftersales-cancellation-detail-afsId___de"
  }, {
    path: "/de/aftersales/cancellation/submission/:id",
    component: _55c67d6d,
    name: "aftersales-cancellation-submission-id___de"
  }, {
    path: "/de/aftersales/change-flight/detail/:afsId",
    component: _377c5270,
    name: "aftersales-change-flight-detail-afsId___de"
  }, {
    path: "/de/aftersales/change-flight/submission/:id",
    component: _5c802845,
    name: "aftersales-change-flight-submission-id___de"
  }, {
    path: "/de/aftersales/change-passenger/detail/:afsId",
    component: _77639f82,
    name: "aftersales-change-passenger-detail-afsId___de"
  }, {
    path: "/de/aftersales/change-passenger/submission/:id",
    component: _19827d73,
    name: "aftersales-change-passenger-submission-id___de"
  }, {
    path: "/de/order/manage-hotel/cancel/:id",
    component: _dd6cbe18,
    name: "order-manage-hotel-cancel-id___de"
  }, {
    path: "/de/order/manage-hotel/change-data/:id",
    component: _93aae60e,
    name: "order-manage-hotel-change-data-id___de"
  }, {
    path: "/el/aftersales/add-baggage/detail/:afsId",
    component: _66d6c3d2,
    name: "aftersales-add-baggage-detail-afsId___el"
  }, {
    path: "/el/aftersales/add-baggage/submission/:id",
    component: _6a71517e,
    name: "aftersales-add-baggage-submission-id___el"
  }, {
    path: "/el/aftersales/cancellation/detail/:afsId",
    component: _2f02b848,
    name: "aftersales-cancellation-detail-afsId___el"
  }, {
    path: "/el/aftersales/cancellation/submission/:id",
    component: _55c67d6d,
    name: "aftersales-cancellation-submission-id___el"
  }, {
    path: "/el/aftersales/change-flight/detail/:afsId",
    component: _377c5270,
    name: "aftersales-change-flight-detail-afsId___el"
  }, {
    path: "/el/aftersales/change-flight/submission/:id",
    component: _5c802845,
    name: "aftersales-change-flight-submission-id___el"
  }, {
    path: "/el/aftersales/change-passenger/detail/:afsId",
    component: _77639f82,
    name: "aftersales-change-passenger-detail-afsId___el"
  }, {
    path: "/el/aftersales/change-passenger/submission/:id",
    component: _19827d73,
    name: "aftersales-change-passenger-submission-id___el"
  }, {
    path: "/el/order/manage-hotel/cancel/:id",
    component: _dd6cbe18,
    name: "order-manage-hotel-cancel-id___el"
  }, {
    path: "/el/order/manage-hotel/change-data/:id",
    component: _93aae60e,
    name: "order-manage-hotel-change-data-id___el"
  }, {
    path: "/en/aftersales/add-baggage/detail/:afsId",
    component: _66d6c3d2,
    name: "aftersales-add-baggage-detail-afsId___en"
  }, {
    path: "/en/aftersales/add-baggage/submission/:id",
    component: _6a71517e,
    name: "aftersales-add-baggage-submission-id___en"
  }, {
    path: "/en/aftersales/cancellation/detail/:afsId",
    component: _2f02b848,
    name: "aftersales-cancellation-detail-afsId___en"
  }, {
    path: "/en/aftersales/cancellation/submission/:id",
    component: _55c67d6d,
    name: "aftersales-cancellation-submission-id___en"
  }, {
    path: "/en/aftersales/change-flight/detail/:afsId",
    component: _377c5270,
    name: "aftersales-change-flight-detail-afsId___en"
  }, {
    path: "/en/aftersales/change-flight/submission/:id",
    component: _5c802845,
    name: "aftersales-change-flight-submission-id___en"
  }, {
    path: "/en/aftersales/change-passenger/detail/:afsId",
    component: _77639f82,
    name: "aftersales-change-passenger-detail-afsId___en"
  }, {
    path: "/en/aftersales/change-passenger/submission/:id",
    component: _19827d73,
    name: "aftersales-change-passenger-submission-id___en"
  }, {
    path: "/en/order/manage-hotel/cancel/:id",
    component: _dd6cbe18,
    name: "order-manage-hotel-cancel-id___en"
  }, {
    path: "/en/order/manage-hotel/change-data/:id",
    component: _93aae60e,
    name: "order-manage-hotel-change-data-id___en"
  }, {
    path: "/es/aftersales/add-baggage/detail/:afsId",
    component: _66d6c3d2,
    name: "aftersales-add-baggage-detail-afsId___es"
  }, {
    path: "/es/aftersales/add-baggage/submission/:id",
    component: _6a71517e,
    name: "aftersales-add-baggage-submission-id___es"
  }, {
    path: "/es/aftersales/cancellation/detail/:afsId",
    component: _2f02b848,
    name: "aftersales-cancellation-detail-afsId___es"
  }, {
    path: "/es/aftersales/cancellation/submission/:id",
    component: _55c67d6d,
    name: "aftersales-cancellation-submission-id___es"
  }, {
    path: "/es/aftersales/change-flight/detail/:afsId",
    component: _377c5270,
    name: "aftersales-change-flight-detail-afsId___es"
  }, {
    path: "/es/aftersales/change-flight/submission/:id",
    component: _5c802845,
    name: "aftersales-change-flight-submission-id___es"
  }, {
    path: "/es/aftersales/change-passenger/detail/:afsId",
    component: _77639f82,
    name: "aftersales-change-passenger-detail-afsId___es"
  }, {
    path: "/es/aftersales/change-passenger/submission/:id",
    component: _19827d73,
    name: "aftersales-change-passenger-submission-id___es"
  }, {
    path: "/es/order/manage-hotel/cancel/:id",
    component: _dd6cbe18,
    name: "order-manage-hotel-cancel-id___es"
  }, {
    path: "/es/order/manage-hotel/change-data/:id",
    component: _93aae60e,
    name: "order-manage-hotel-change-data-id___es"
  }, {
    path: "/fi/aftersales/add-baggage/detail/:afsId",
    component: _66d6c3d2,
    name: "aftersales-add-baggage-detail-afsId___fi"
  }, {
    path: "/fi/aftersales/add-baggage/submission/:id",
    component: _6a71517e,
    name: "aftersales-add-baggage-submission-id___fi"
  }, {
    path: "/fi/aftersales/cancellation/detail/:afsId",
    component: _2f02b848,
    name: "aftersales-cancellation-detail-afsId___fi"
  }, {
    path: "/fi/aftersales/cancellation/submission/:id",
    component: _55c67d6d,
    name: "aftersales-cancellation-submission-id___fi"
  }, {
    path: "/fi/aftersales/change-flight/detail/:afsId",
    component: _377c5270,
    name: "aftersales-change-flight-detail-afsId___fi"
  }, {
    path: "/fi/aftersales/change-flight/submission/:id",
    component: _5c802845,
    name: "aftersales-change-flight-submission-id___fi"
  }, {
    path: "/fi/aftersales/change-passenger/detail/:afsId",
    component: _77639f82,
    name: "aftersales-change-passenger-detail-afsId___fi"
  }, {
    path: "/fi/aftersales/change-passenger/submission/:id",
    component: _19827d73,
    name: "aftersales-change-passenger-submission-id___fi"
  }, {
    path: "/fi/order/manage-hotel/cancel/:id",
    component: _dd6cbe18,
    name: "order-manage-hotel-cancel-id___fi"
  }, {
    path: "/fi/order/manage-hotel/change-data/:id",
    component: _93aae60e,
    name: "order-manage-hotel-change-data-id___fi"
  }, {
    path: "/fr/aftersales/add-baggage/detail/:afsId",
    component: _66d6c3d2,
    name: "aftersales-add-baggage-detail-afsId___fr"
  }, {
    path: "/fr/aftersales/add-baggage/submission/:id",
    component: _6a71517e,
    name: "aftersales-add-baggage-submission-id___fr"
  }, {
    path: "/fr/aftersales/cancellation/detail/:afsId",
    component: _2f02b848,
    name: "aftersales-cancellation-detail-afsId___fr"
  }, {
    path: "/fr/aftersales/cancellation/submission/:id",
    component: _55c67d6d,
    name: "aftersales-cancellation-submission-id___fr"
  }, {
    path: "/fr/aftersales/change-flight/detail/:afsId",
    component: _377c5270,
    name: "aftersales-change-flight-detail-afsId___fr"
  }, {
    path: "/fr/aftersales/change-flight/submission/:id",
    component: _5c802845,
    name: "aftersales-change-flight-submission-id___fr"
  }, {
    path: "/fr/aftersales/change-passenger/detail/:afsId",
    component: _77639f82,
    name: "aftersales-change-passenger-detail-afsId___fr"
  }, {
    path: "/fr/aftersales/change-passenger/submission/:id",
    component: _19827d73,
    name: "aftersales-change-passenger-submission-id___fr"
  }, {
    path: "/fr/order/manage-hotel/cancel/:id",
    component: _dd6cbe18,
    name: "order-manage-hotel-cancel-id___fr"
  }, {
    path: "/fr/order/manage-hotel/change-data/:id",
    component: _93aae60e,
    name: "order-manage-hotel-change-data-id___fr"
  }, {
    path: "/hi/aftersales/add-baggage/detail/:afsId",
    component: _66d6c3d2,
    name: "aftersales-add-baggage-detail-afsId___hi"
  }, {
    path: "/hi/aftersales/add-baggage/submission/:id",
    component: _6a71517e,
    name: "aftersales-add-baggage-submission-id___hi"
  }, {
    path: "/hi/aftersales/cancellation/detail/:afsId",
    component: _2f02b848,
    name: "aftersales-cancellation-detail-afsId___hi"
  }, {
    path: "/hi/aftersales/cancellation/submission/:id",
    component: _55c67d6d,
    name: "aftersales-cancellation-submission-id___hi"
  }, {
    path: "/hi/aftersales/change-flight/detail/:afsId",
    component: _377c5270,
    name: "aftersales-change-flight-detail-afsId___hi"
  }, {
    path: "/hi/aftersales/change-flight/submission/:id",
    component: _5c802845,
    name: "aftersales-change-flight-submission-id___hi"
  }, {
    path: "/hi/aftersales/change-passenger/detail/:afsId",
    component: _77639f82,
    name: "aftersales-change-passenger-detail-afsId___hi"
  }, {
    path: "/hi/aftersales/change-passenger/submission/:id",
    component: _19827d73,
    name: "aftersales-change-passenger-submission-id___hi"
  }, {
    path: "/hi/order/manage-hotel/cancel/:id",
    component: _dd6cbe18,
    name: "order-manage-hotel-cancel-id___hi"
  }, {
    path: "/hi/order/manage-hotel/change-data/:id",
    component: _93aae60e,
    name: "order-manage-hotel-change-data-id___hi"
  }, {
    path: "/hr/aftersales/add-baggage/detail/:afsId",
    component: _66d6c3d2,
    name: "aftersales-add-baggage-detail-afsId___hr"
  }, {
    path: "/hr/aftersales/add-baggage/submission/:id",
    component: _6a71517e,
    name: "aftersales-add-baggage-submission-id___hr"
  }, {
    path: "/hr/aftersales/cancellation/detail/:afsId",
    component: _2f02b848,
    name: "aftersales-cancellation-detail-afsId___hr"
  }, {
    path: "/hr/aftersales/cancellation/submission/:id",
    component: _55c67d6d,
    name: "aftersales-cancellation-submission-id___hr"
  }, {
    path: "/hr/aftersales/change-flight/detail/:afsId",
    component: _377c5270,
    name: "aftersales-change-flight-detail-afsId___hr"
  }, {
    path: "/hr/aftersales/change-flight/submission/:id",
    component: _5c802845,
    name: "aftersales-change-flight-submission-id___hr"
  }, {
    path: "/hr/aftersales/change-passenger/detail/:afsId",
    component: _77639f82,
    name: "aftersales-change-passenger-detail-afsId___hr"
  }, {
    path: "/hr/aftersales/change-passenger/submission/:id",
    component: _19827d73,
    name: "aftersales-change-passenger-submission-id___hr"
  }, {
    path: "/hr/order/manage-hotel/cancel/:id",
    component: _dd6cbe18,
    name: "order-manage-hotel-cancel-id___hr"
  }, {
    path: "/hr/order/manage-hotel/change-data/:id",
    component: _93aae60e,
    name: "order-manage-hotel-change-data-id___hr"
  }, {
    path: "/hu/aftersales/add-baggage/detail/:afsId",
    component: _66d6c3d2,
    name: "aftersales-add-baggage-detail-afsId___hu"
  }, {
    path: "/hu/aftersales/add-baggage/submission/:id",
    component: _6a71517e,
    name: "aftersales-add-baggage-submission-id___hu"
  }, {
    path: "/hu/aftersales/cancellation/detail/:afsId",
    component: _2f02b848,
    name: "aftersales-cancellation-detail-afsId___hu"
  }, {
    path: "/hu/aftersales/cancellation/submission/:id",
    component: _55c67d6d,
    name: "aftersales-cancellation-submission-id___hu"
  }, {
    path: "/hu/aftersales/change-flight/detail/:afsId",
    component: _377c5270,
    name: "aftersales-change-flight-detail-afsId___hu"
  }, {
    path: "/hu/aftersales/change-flight/submission/:id",
    component: _5c802845,
    name: "aftersales-change-flight-submission-id___hu"
  }, {
    path: "/hu/aftersales/change-passenger/detail/:afsId",
    component: _77639f82,
    name: "aftersales-change-passenger-detail-afsId___hu"
  }, {
    path: "/hu/aftersales/change-passenger/submission/:id",
    component: _19827d73,
    name: "aftersales-change-passenger-submission-id___hu"
  }, {
    path: "/hu/order/manage-hotel/cancel/:id",
    component: _dd6cbe18,
    name: "order-manage-hotel-cancel-id___hu"
  }, {
    path: "/hu/order/manage-hotel/change-data/:id",
    component: _93aae60e,
    name: "order-manage-hotel-change-data-id___hu"
  }, {
    path: "/id/aftersales/add-baggage/detail/:afsId",
    component: _66d6c3d2,
    name: "aftersales-add-baggage-detail-afsId___id"
  }, {
    path: "/id/aftersales/add-baggage/submission/:id",
    component: _6a71517e,
    name: "aftersales-add-baggage-submission-id___id"
  }, {
    path: "/id/aftersales/cancellation/detail/:afsId",
    component: _2f02b848,
    name: "aftersales-cancellation-detail-afsId___id"
  }, {
    path: "/id/aftersales/cancellation/submission/:id",
    component: _55c67d6d,
    name: "aftersales-cancellation-submission-id___id"
  }, {
    path: "/id/aftersales/change-flight/detail/:afsId",
    component: _377c5270,
    name: "aftersales-change-flight-detail-afsId___id"
  }, {
    path: "/id/aftersales/change-flight/submission/:id",
    component: _5c802845,
    name: "aftersales-change-flight-submission-id___id"
  }, {
    path: "/id/aftersales/change-passenger/detail/:afsId",
    component: _77639f82,
    name: "aftersales-change-passenger-detail-afsId___id"
  }, {
    path: "/id/aftersales/change-passenger/submission/:id",
    component: _19827d73,
    name: "aftersales-change-passenger-submission-id___id"
  }, {
    path: "/id/order/manage-hotel/cancel/:id",
    component: _dd6cbe18,
    name: "order-manage-hotel-cancel-id___id"
  }, {
    path: "/id/order/manage-hotel/change-data/:id",
    component: _93aae60e,
    name: "order-manage-hotel-change-data-id___id"
  }, {
    path: "/it/aftersales/add-baggage/detail/:afsId",
    component: _66d6c3d2,
    name: "aftersales-add-baggage-detail-afsId___it"
  }, {
    path: "/it/aftersales/add-baggage/submission/:id",
    component: _6a71517e,
    name: "aftersales-add-baggage-submission-id___it"
  }, {
    path: "/it/aftersales/cancellation/detail/:afsId",
    component: _2f02b848,
    name: "aftersales-cancellation-detail-afsId___it"
  }, {
    path: "/it/aftersales/cancellation/submission/:id",
    component: _55c67d6d,
    name: "aftersales-cancellation-submission-id___it"
  }, {
    path: "/it/aftersales/change-flight/detail/:afsId",
    component: _377c5270,
    name: "aftersales-change-flight-detail-afsId___it"
  }, {
    path: "/it/aftersales/change-flight/submission/:id",
    component: _5c802845,
    name: "aftersales-change-flight-submission-id___it"
  }, {
    path: "/it/aftersales/change-passenger/detail/:afsId",
    component: _77639f82,
    name: "aftersales-change-passenger-detail-afsId___it"
  }, {
    path: "/it/aftersales/change-passenger/submission/:id",
    component: _19827d73,
    name: "aftersales-change-passenger-submission-id___it"
  }, {
    path: "/it/order/manage-hotel/cancel/:id",
    component: _dd6cbe18,
    name: "order-manage-hotel-cancel-id___it"
  }, {
    path: "/it/order/manage-hotel/change-data/:id",
    component: _93aae60e,
    name: "order-manage-hotel-change-data-id___it"
  }, {
    path: "/ja/aftersales/add-baggage/detail/:afsId",
    component: _66d6c3d2,
    name: "aftersales-add-baggage-detail-afsId___ja"
  }, {
    path: "/ja/aftersales/add-baggage/submission/:id",
    component: _6a71517e,
    name: "aftersales-add-baggage-submission-id___ja"
  }, {
    path: "/ja/aftersales/cancellation/detail/:afsId",
    component: _2f02b848,
    name: "aftersales-cancellation-detail-afsId___ja"
  }, {
    path: "/ja/aftersales/cancellation/submission/:id",
    component: _55c67d6d,
    name: "aftersales-cancellation-submission-id___ja"
  }, {
    path: "/ja/aftersales/change-flight/detail/:afsId",
    component: _377c5270,
    name: "aftersales-change-flight-detail-afsId___ja"
  }, {
    path: "/ja/aftersales/change-flight/submission/:id",
    component: _5c802845,
    name: "aftersales-change-flight-submission-id___ja"
  }, {
    path: "/ja/aftersales/change-passenger/detail/:afsId",
    component: _77639f82,
    name: "aftersales-change-passenger-detail-afsId___ja"
  }, {
    path: "/ja/aftersales/change-passenger/submission/:id",
    component: _19827d73,
    name: "aftersales-change-passenger-submission-id___ja"
  }, {
    path: "/ja/order/manage-hotel/cancel/:id",
    component: _dd6cbe18,
    name: "order-manage-hotel-cancel-id___ja"
  }, {
    path: "/ja/order/manage-hotel/change-data/:id",
    component: _93aae60e,
    name: "order-manage-hotel-change-data-id___ja"
  }, {
    path: "/km/aftersales/add-baggage/detail/:afsId",
    component: _66d6c3d2,
    name: "aftersales-add-baggage-detail-afsId___km"
  }, {
    path: "/km/aftersales/add-baggage/submission/:id",
    component: _6a71517e,
    name: "aftersales-add-baggage-submission-id___km"
  }, {
    path: "/km/aftersales/cancellation/detail/:afsId",
    component: _2f02b848,
    name: "aftersales-cancellation-detail-afsId___km"
  }, {
    path: "/km/aftersales/cancellation/submission/:id",
    component: _55c67d6d,
    name: "aftersales-cancellation-submission-id___km"
  }, {
    path: "/km/aftersales/change-flight/detail/:afsId",
    component: _377c5270,
    name: "aftersales-change-flight-detail-afsId___km"
  }, {
    path: "/km/aftersales/change-flight/submission/:id",
    component: _5c802845,
    name: "aftersales-change-flight-submission-id___km"
  }, {
    path: "/km/aftersales/change-passenger/detail/:afsId",
    component: _77639f82,
    name: "aftersales-change-passenger-detail-afsId___km"
  }, {
    path: "/km/aftersales/change-passenger/submission/:id",
    component: _19827d73,
    name: "aftersales-change-passenger-submission-id___km"
  }, {
    path: "/km/order/manage-hotel/cancel/:id",
    component: _dd6cbe18,
    name: "order-manage-hotel-cancel-id___km"
  }, {
    path: "/km/order/manage-hotel/change-data/:id",
    component: _93aae60e,
    name: "order-manage-hotel-change-data-id___km"
  }, {
    path: "/ko/aftersales/add-baggage/detail/:afsId",
    component: _66d6c3d2,
    name: "aftersales-add-baggage-detail-afsId___ko"
  }, {
    path: "/ko/aftersales/add-baggage/submission/:id",
    component: _6a71517e,
    name: "aftersales-add-baggage-submission-id___ko"
  }, {
    path: "/ko/aftersales/cancellation/detail/:afsId",
    component: _2f02b848,
    name: "aftersales-cancellation-detail-afsId___ko"
  }, {
    path: "/ko/aftersales/cancellation/submission/:id",
    component: _55c67d6d,
    name: "aftersales-cancellation-submission-id___ko"
  }, {
    path: "/ko/aftersales/change-flight/detail/:afsId",
    component: _377c5270,
    name: "aftersales-change-flight-detail-afsId___ko"
  }, {
    path: "/ko/aftersales/change-flight/submission/:id",
    component: _5c802845,
    name: "aftersales-change-flight-submission-id___ko"
  }, {
    path: "/ko/aftersales/change-passenger/detail/:afsId",
    component: _77639f82,
    name: "aftersales-change-passenger-detail-afsId___ko"
  }, {
    path: "/ko/aftersales/change-passenger/submission/:id",
    component: _19827d73,
    name: "aftersales-change-passenger-submission-id___ko"
  }, {
    path: "/ko/order/manage-hotel/cancel/:id",
    component: _dd6cbe18,
    name: "order-manage-hotel-cancel-id___ko"
  }, {
    path: "/ko/order/manage-hotel/change-data/:id",
    component: _93aae60e,
    name: "order-manage-hotel-change-data-id___ko"
  }, {
    path: "/ms/aftersales/add-baggage/detail/:afsId",
    component: _66d6c3d2,
    name: "aftersales-add-baggage-detail-afsId___ms"
  }, {
    path: "/ms/aftersales/add-baggage/submission/:id",
    component: _6a71517e,
    name: "aftersales-add-baggage-submission-id___ms"
  }, {
    path: "/ms/aftersales/cancellation/detail/:afsId",
    component: _2f02b848,
    name: "aftersales-cancellation-detail-afsId___ms"
  }, {
    path: "/ms/aftersales/cancellation/submission/:id",
    component: _55c67d6d,
    name: "aftersales-cancellation-submission-id___ms"
  }, {
    path: "/ms/aftersales/change-flight/detail/:afsId",
    component: _377c5270,
    name: "aftersales-change-flight-detail-afsId___ms"
  }, {
    path: "/ms/aftersales/change-flight/submission/:id",
    component: _5c802845,
    name: "aftersales-change-flight-submission-id___ms"
  }, {
    path: "/ms/aftersales/change-passenger/detail/:afsId",
    component: _77639f82,
    name: "aftersales-change-passenger-detail-afsId___ms"
  }, {
    path: "/ms/aftersales/change-passenger/submission/:id",
    component: _19827d73,
    name: "aftersales-change-passenger-submission-id___ms"
  }, {
    path: "/ms/order/manage-hotel/cancel/:id",
    component: _dd6cbe18,
    name: "order-manage-hotel-cancel-id___ms"
  }, {
    path: "/ms/order/manage-hotel/change-data/:id",
    component: _93aae60e,
    name: "order-manage-hotel-change-data-id___ms"
  }, {
    path: "/nb/aftersales/add-baggage/detail/:afsId",
    component: _66d6c3d2,
    name: "aftersales-add-baggage-detail-afsId___nb"
  }, {
    path: "/nb/aftersales/add-baggage/submission/:id",
    component: _6a71517e,
    name: "aftersales-add-baggage-submission-id___nb"
  }, {
    path: "/nb/aftersales/cancellation/detail/:afsId",
    component: _2f02b848,
    name: "aftersales-cancellation-detail-afsId___nb"
  }, {
    path: "/nb/aftersales/cancellation/submission/:id",
    component: _55c67d6d,
    name: "aftersales-cancellation-submission-id___nb"
  }, {
    path: "/nb/aftersales/change-flight/detail/:afsId",
    component: _377c5270,
    name: "aftersales-change-flight-detail-afsId___nb"
  }, {
    path: "/nb/aftersales/change-flight/submission/:id",
    component: _5c802845,
    name: "aftersales-change-flight-submission-id___nb"
  }, {
    path: "/nb/aftersales/change-passenger/detail/:afsId",
    component: _77639f82,
    name: "aftersales-change-passenger-detail-afsId___nb"
  }, {
    path: "/nb/aftersales/change-passenger/submission/:id",
    component: _19827d73,
    name: "aftersales-change-passenger-submission-id___nb"
  }, {
    path: "/nb/order/manage-hotel/cancel/:id",
    component: _dd6cbe18,
    name: "order-manage-hotel-cancel-id___nb"
  }, {
    path: "/nb/order/manage-hotel/change-data/:id",
    component: _93aae60e,
    name: "order-manage-hotel-change-data-id___nb"
  }, {
    path: "/nl/aftersales/add-baggage/detail/:afsId",
    component: _66d6c3d2,
    name: "aftersales-add-baggage-detail-afsId___nl"
  }, {
    path: "/nl/aftersales/add-baggage/submission/:id",
    component: _6a71517e,
    name: "aftersales-add-baggage-submission-id___nl"
  }, {
    path: "/nl/aftersales/cancellation/detail/:afsId",
    component: _2f02b848,
    name: "aftersales-cancellation-detail-afsId___nl"
  }, {
    path: "/nl/aftersales/cancellation/submission/:id",
    component: _55c67d6d,
    name: "aftersales-cancellation-submission-id___nl"
  }, {
    path: "/nl/aftersales/change-flight/detail/:afsId",
    component: _377c5270,
    name: "aftersales-change-flight-detail-afsId___nl"
  }, {
    path: "/nl/aftersales/change-flight/submission/:id",
    component: _5c802845,
    name: "aftersales-change-flight-submission-id___nl"
  }, {
    path: "/nl/aftersales/change-passenger/detail/:afsId",
    component: _77639f82,
    name: "aftersales-change-passenger-detail-afsId___nl"
  }, {
    path: "/nl/aftersales/change-passenger/submission/:id",
    component: _19827d73,
    name: "aftersales-change-passenger-submission-id___nl"
  }, {
    path: "/nl/order/manage-hotel/cancel/:id",
    component: _dd6cbe18,
    name: "order-manage-hotel-cancel-id___nl"
  }, {
    path: "/nl/order/manage-hotel/change-data/:id",
    component: _93aae60e,
    name: "order-manage-hotel-change-data-id___nl"
  }, {
    path: "/pl/aftersales/add-baggage/detail/:afsId",
    component: _66d6c3d2,
    name: "aftersales-add-baggage-detail-afsId___pl"
  }, {
    path: "/pl/aftersales/add-baggage/submission/:id",
    component: _6a71517e,
    name: "aftersales-add-baggage-submission-id___pl"
  }, {
    path: "/pl/aftersales/cancellation/detail/:afsId",
    component: _2f02b848,
    name: "aftersales-cancellation-detail-afsId___pl"
  }, {
    path: "/pl/aftersales/cancellation/submission/:id",
    component: _55c67d6d,
    name: "aftersales-cancellation-submission-id___pl"
  }, {
    path: "/pl/aftersales/change-flight/detail/:afsId",
    component: _377c5270,
    name: "aftersales-change-flight-detail-afsId___pl"
  }, {
    path: "/pl/aftersales/change-flight/submission/:id",
    component: _5c802845,
    name: "aftersales-change-flight-submission-id___pl"
  }, {
    path: "/pl/aftersales/change-passenger/detail/:afsId",
    component: _77639f82,
    name: "aftersales-change-passenger-detail-afsId___pl"
  }, {
    path: "/pl/aftersales/change-passenger/submission/:id",
    component: _19827d73,
    name: "aftersales-change-passenger-submission-id___pl"
  }, {
    path: "/pl/order/manage-hotel/cancel/:id",
    component: _dd6cbe18,
    name: "order-manage-hotel-cancel-id___pl"
  }, {
    path: "/pl/order/manage-hotel/change-data/:id",
    component: _93aae60e,
    name: "order-manage-hotel-change-data-id___pl"
  }, {
    path: "/pt/aftersales/add-baggage/detail/:afsId",
    component: _66d6c3d2,
    name: "aftersales-add-baggage-detail-afsId___pt"
  }, {
    path: "/pt/aftersales/add-baggage/submission/:id",
    component: _6a71517e,
    name: "aftersales-add-baggage-submission-id___pt"
  }, {
    path: "/pt/aftersales/cancellation/detail/:afsId",
    component: _2f02b848,
    name: "aftersales-cancellation-detail-afsId___pt"
  }, {
    path: "/pt/aftersales/cancellation/submission/:id",
    component: _55c67d6d,
    name: "aftersales-cancellation-submission-id___pt"
  }, {
    path: "/pt/aftersales/change-flight/detail/:afsId",
    component: _377c5270,
    name: "aftersales-change-flight-detail-afsId___pt"
  }, {
    path: "/pt/aftersales/change-flight/submission/:id",
    component: _5c802845,
    name: "aftersales-change-flight-submission-id___pt"
  }, {
    path: "/pt/aftersales/change-passenger/detail/:afsId",
    component: _77639f82,
    name: "aftersales-change-passenger-detail-afsId___pt"
  }, {
    path: "/pt/aftersales/change-passenger/submission/:id",
    component: _19827d73,
    name: "aftersales-change-passenger-submission-id___pt"
  }, {
    path: "/pt/order/manage-hotel/cancel/:id",
    component: _dd6cbe18,
    name: "order-manage-hotel-cancel-id___pt"
  }, {
    path: "/pt/order/manage-hotel/change-data/:id",
    component: _93aae60e,
    name: "order-manage-hotel-change-data-id___pt"
  }, {
    path: "/ro/aftersales/add-baggage/detail/:afsId",
    component: _66d6c3d2,
    name: "aftersales-add-baggage-detail-afsId___ro"
  }, {
    path: "/ro/aftersales/add-baggage/submission/:id",
    component: _6a71517e,
    name: "aftersales-add-baggage-submission-id___ro"
  }, {
    path: "/ro/aftersales/cancellation/detail/:afsId",
    component: _2f02b848,
    name: "aftersales-cancellation-detail-afsId___ro"
  }, {
    path: "/ro/aftersales/cancellation/submission/:id",
    component: _55c67d6d,
    name: "aftersales-cancellation-submission-id___ro"
  }, {
    path: "/ro/aftersales/change-flight/detail/:afsId",
    component: _377c5270,
    name: "aftersales-change-flight-detail-afsId___ro"
  }, {
    path: "/ro/aftersales/change-flight/submission/:id",
    component: _5c802845,
    name: "aftersales-change-flight-submission-id___ro"
  }, {
    path: "/ro/aftersales/change-passenger/detail/:afsId",
    component: _77639f82,
    name: "aftersales-change-passenger-detail-afsId___ro"
  }, {
    path: "/ro/aftersales/change-passenger/submission/:id",
    component: _19827d73,
    name: "aftersales-change-passenger-submission-id___ro"
  }, {
    path: "/ro/order/manage-hotel/cancel/:id",
    component: _dd6cbe18,
    name: "order-manage-hotel-cancel-id___ro"
  }, {
    path: "/ro/order/manage-hotel/change-data/:id",
    component: _93aae60e,
    name: "order-manage-hotel-change-data-id___ro"
  }, {
    path: "/ru/aftersales/add-baggage/detail/:afsId",
    component: _66d6c3d2,
    name: "aftersales-add-baggage-detail-afsId___ru"
  }, {
    path: "/ru/aftersales/add-baggage/submission/:id",
    component: _6a71517e,
    name: "aftersales-add-baggage-submission-id___ru"
  }, {
    path: "/ru/aftersales/cancellation/detail/:afsId",
    component: _2f02b848,
    name: "aftersales-cancellation-detail-afsId___ru"
  }, {
    path: "/ru/aftersales/cancellation/submission/:id",
    component: _55c67d6d,
    name: "aftersales-cancellation-submission-id___ru"
  }, {
    path: "/ru/aftersales/change-flight/detail/:afsId",
    component: _377c5270,
    name: "aftersales-change-flight-detail-afsId___ru"
  }, {
    path: "/ru/aftersales/change-flight/submission/:id",
    component: _5c802845,
    name: "aftersales-change-flight-submission-id___ru"
  }, {
    path: "/ru/aftersales/change-passenger/detail/:afsId",
    component: _77639f82,
    name: "aftersales-change-passenger-detail-afsId___ru"
  }, {
    path: "/ru/aftersales/change-passenger/submission/:id",
    component: _19827d73,
    name: "aftersales-change-passenger-submission-id___ru"
  }, {
    path: "/ru/order/manage-hotel/cancel/:id",
    component: _dd6cbe18,
    name: "order-manage-hotel-cancel-id___ru"
  }, {
    path: "/ru/order/manage-hotel/change-data/:id",
    component: _93aae60e,
    name: "order-manage-hotel-change-data-id___ru"
  }, {
    path: "/sk/aftersales/add-baggage/detail/:afsId",
    component: _66d6c3d2,
    name: "aftersales-add-baggage-detail-afsId___sk"
  }, {
    path: "/sk/aftersales/add-baggage/submission/:id",
    component: _6a71517e,
    name: "aftersales-add-baggage-submission-id___sk"
  }, {
    path: "/sk/aftersales/cancellation/detail/:afsId",
    component: _2f02b848,
    name: "aftersales-cancellation-detail-afsId___sk"
  }, {
    path: "/sk/aftersales/cancellation/submission/:id",
    component: _55c67d6d,
    name: "aftersales-cancellation-submission-id___sk"
  }, {
    path: "/sk/aftersales/change-flight/detail/:afsId",
    component: _377c5270,
    name: "aftersales-change-flight-detail-afsId___sk"
  }, {
    path: "/sk/aftersales/change-flight/submission/:id",
    component: _5c802845,
    name: "aftersales-change-flight-submission-id___sk"
  }, {
    path: "/sk/aftersales/change-passenger/detail/:afsId",
    component: _77639f82,
    name: "aftersales-change-passenger-detail-afsId___sk"
  }, {
    path: "/sk/aftersales/change-passenger/submission/:id",
    component: _19827d73,
    name: "aftersales-change-passenger-submission-id___sk"
  }, {
    path: "/sk/order/manage-hotel/cancel/:id",
    component: _dd6cbe18,
    name: "order-manage-hotel-cancel-id___sk"
  }, {
    path: "/sk/order/manage-hotel/change-data/:id",
    component: _93aae60e,
    name: "order-manage-hotel-change-data-id___sk"
  }, {
    path: "/sl/aftersales/add-baggage/detail/:afsId",
    component: _66d6c3d2,
    name: "aftersales-add-baggage-detail-afsId___sl"
  }, {
    path: "/sl/aftersales/add-baggage/submission/:id",
    component: _6a71517e,
    name: "aftersales-add-baggage-submission-id___sl"
  }, {
    path: "/sl/aftersales/cancellation/detail/:afsId",
    component: _2f02b848,
    name: "aftersales-cancellation-detail-afsId___sl"
  }, {
    path: "/sl/aftersales/cancellation/submission/:id",
    component: _55c67d6d,
    name: "aftersales-cancellation-submission-id___sl"
  }, {
    path: "/sl/aftersales/change-flight/detail/:afsId",
    component: _377c5270,
    name: "aftersales-change-flight-detail-afsId___sl"
  }, {
    path: "/sl/aftersales/change-flight/submission/:id",
    component: _5c802845,
    name: "aftersales-change-flight-submission-id___sl"
  }, {
    path: "/sl/aftersales/change-passenger/detail/:afsId",
    component: _77639f82,
    name: "aftersales-change-passenger-detail-afsId___sl"
  }, {
    path: "/sl/aftersales/change-passenger/submission/:id",
    component: _19827d73,
    name: "aftersales-change-passenger-submission-id___sl"
  }, {
    path: "/sl/order/manage-hotel/cancel/:id",
    component: _dd6cbe18,
    name: "order-manage-hotel-cancel-id___sl"
  }, {
    path: "/sl/order/manage-hotel/change-data/:id",
    component: _93aae60e,
    name: "order-manage-hotel-change-data-id___sl"
  }, {
    path: "/sr/aftersales/add-baggage/detail/:afsId",
    component: _66d6c3d2,
    name: "aftersales-add-baggage-detail-afsId___sr"
  }, {
    path: "/sr/aftersales/add-baggage/submission/:id",
    component: _6a71517e,
    name: "aftersales-add-baggage-submission-id___sr"
  }, {
    path: "/sr/aftersales/cancellation/detail/:afsId",
    component: _2f02b848,
    name: "aftersales-cancellation-detail-afsId___sr"
  }, {
    path: "/sr/aftersales/cancellation/submission/:id",
    component: _55c67d6d,
    name: "aftersales-cancellation-submission-id___sr"
  }, {
    path: "/sr/aftersales/change-flight/detail/:afsId",
    component: _377c5270,
    name: "aftersales-change-flight-detail-afsId___sr"
  }, {
    path: "/sr/aftersales/change-flight/submission/:id",
    component: _5c802845,
    name: "aftersales-change-flight-submission-id___sr"
  }, {
    path: "/sr/aftersales/change-passenger/detail/:afsId",
    component: _77639f82,
    name: "aftersales-change-passenger-detail-afsId___sr"
  }, {
    path: "/sr/aftersales/change-passenger/submission/:id",
    component: _19827d73,
    name: "aftersales-change-passenger-submission-id___sr"
  }, {
    path: "/sr/order/manage-hotel/cancel/:id",
    component: _dd6cbe18,
    name: "order-manage-hotel-cancel-id___sr"
  }, {
    path: "/sr/order/manage-hotel/change-data/:id",
    component: _93aae60e,
    name: "order-manage-hotel-change-data-id___sr"
  }, {
    path: "/sv/aftersales/add-baggage/detail/:afsId",
    component: _66d6c3d2,
    name: "aftersales-add-baggage-detail-afsId___sv"
  }, {
    path: "/sv/aftersales/add-baggage/submission/:id",
    component: _6a71517e,
    name: "aftersales-add-baggage-submission-id___sv"
  }, {
    path: "/sv/aftersales/cancellation/detail/:afsId",
    component: _2f02b848,
    name: "aftersales-cancellation-detail-afsId___sv"
  }, {
    path: "/sv/aftersales/cancellation/submission/:id",
    component: _55c67d6d,
    name: "aftersales-cancellation-submission-id___sv"
  }, {
    path: "/sv/aftersales/change-flight/detail/:afsId",
    component: _377c5270,
    name: "aftersales-change-flight-detail-afsId___sv"
  }, {
    path: "/sv/aftersales/change-flight/submission/:id",
    component: _5c802845,
    name: "aftersales-change-flight-submission-id___sv"
  }, {
    path: "/sv/aftersales/change-passenger/detail/:afsId",
    component: _77639f82,
    name: "aftersales-change-passenger-detail-afsId___sv"
  }, {
    path: "/sv/aftersales/change-passenger/submission/:id",
    component: _19827d73,
    name: "aftersales-change-passenger-submission-id___sv"
  }, {
    path: "/sv/order/manage-hotel/cancel/:id",
    component: _dd6cbe18,
    name: "order-manage-hotel-cancel-id___sv"
  }, {
    path: "/sv/order/manage-hotel/change-data/:id",
    component: _93aae60e,
    name: "order-manage-hotel-change-data-id___sv"
  }, {
    path: "/th/aftersales/add-baggage/detail/:afsId",
    component: _66d6c3d2,
    name: "aftersales-add-baggage-detail-afsId___th"
  }, {
    path: "/th/aftersales/add-baggage/submission/:id",
    component: _6a71517e,
    name: "aftersales-add-baggage-submission-id___th"
  }, {
    path: "/th/aftersales/cancellation/detail/:afsId",
    component: _2f02b848,
    name: "aftersales-cancellation-detail-afsId___th"
  }, {
    path: "/th/aftersales/cancellation/submission/:id",
    component: _55c67d6d,
    name: "aftersales-cancellation-submission-id___th"
  }, {
    path: "/th/aftersales/change-flight/detail/:afsId",
    component: _377c5270,
    name: "aftersales-change-flight-detail-afsId___th"
  }, {
    path: "/th/aftersales/change-flight/submission/:id",
    component: _5c802845,
    name: "aftersales-change-flight-submission-id___th"
  }, {
    path: "/th/aftersales/change-passenger/detail/:afsId",
    component: _77639f82,
    name: "aftersales-change-passenger-detail-afsId___th"
  }, {
    path: "/th/aftersales/change-passenger/submission/:id",
    component: _19827d73,
    name: "aftersales-change-passenger-submission-id___th"
  }, {
    path: "/th/order/manage-hotel/cancel/:id",
    component: _dd6cbe18,
    name: "order-manage-hotel-cancel-id___th"
  }, {
    path: "/th/order/manage-hotel/change-data/:id",
    component: _93aae60e,
    name: "order-manage-hotel-change-data-id___th"
  }, {
    path: "/tl/aftersales/add-baggage/detail/:afsId",
    component: _66d6c3d2,
    name: "aftersales-add-baggage-detail-afsId___tl"
  }, {
    path: "/tl/aftersales/add-baggage/submission/:id",
    component: _6a71517e,
    name: "aftersales-add-baggage-submission-id___tl"
  }, {
    path: "/tl/aftersales/cancellation/detail/:afsId",
    component: _2f02b848,
    name: "aftersales-cancellation-detail-afsId___tl"
  }, {
    path: "/tl/aftersales/cancellation/submission/:id",
    component: _55c67d6d,
    name: "aftersales-cancellation-submission-id___tl"
  }, {
    path: "/tl/aftersales/change-flight/detail/:afsId",
    component: _377c5270,
    name: "aftersales-change-flight-detail-afsId___tl"
  }, {
    path: "/tl/aftersales/change-flight/submission/:id",
    component: _5c802845,
    name: "aftersales-change-flight-submission-id___tl"
  }, {
    path: "/tl/aftersales/change-passenger/detail/:afsId",
    component: _77639f82,
    name: "aftersales-change-passenger-detail-afsId___tl"
  }, {
    path: "/tl/aftersales/change-passenger/submission/:id",
    component: _19827d73,
    name: "aftersales-change-passenger-submission-id___tl"
  }, {
    path: "/tl/order/manage-hotel/cancel/:id",
    component: _dd6cbe18,
    name: "order-manage-hotel-cancel-id___tl"
  }, {
    path: "/tl/order/manage-hotel/change-data/:id",
    component: _93aae60e,
    name: "order-manage-hotel-change-data-id___tl"
  }, {
    path: "/tr/aftersales/add-baggage/detail/:afsId",
    component: _66d6c3d2,
    name: "aftersales-add-baggage-detail-afsId___tr"
  }, {
    path: "/tr/aftersales/add-baggage/submission/:id",
    component: _6a71517e,
    name: "aftersales-add-baggage-submission-id___tr"
  }, {
    path: "/tr/aftersales/cancellation/detail/:afsId",
    component: _2f02b848,
    name: "aftersales-cancellation-detail-afsId___tr"
  }, {
    path: "/tr/aftersales/cancellation/submission/:id",
    component: _55c67d6d,
    name: "aftersales-cancellation-submission-id___tr"
  }, {
    path: "/tr/aftersales/change-flight/detail/:afsId",
    component: _377c5270,
    name: "aftersales-change-flight-detail-afsId___tr"
  }, {
    path: "/tr/aftersales/change-flight/submission/:id",
    component: _5c802845,
    name: "aftersales-change-flight-submission-id___tr"
  }, {
    path: "/tr/aftersales/change-passenger/detail/:afsId",
    component: _77639f82,
    name: "aftersales-change-passenger-detail-afsId___tr"
  }, {
    path: "/tr/aftersales/change-passenger/submission/:id",
    component: _19827d73,
    name: "aftersales-change-passenger-submission-id___tr"
  }, {
    path: "/tr/order/manage-hotel/cancel/:id",
    component: _dd6cbe18,
    name: "order-manage-hotel-cancel-id___tr"
  }, {
    path: "/tr/order/manage-hotel/change-data/:id",
    component: _93aae60e,
    name: "order-manage-hotel-change-data-id___tr"
  }, {
    path: "/uk/aftersales/add-baggage/detail/:afsId",
    component: _66d6c3d2,
    name: "aftersales-add-baggage-detail-afsId___uk"
  }, {
    path: "/uk/aftersales/add-baggage/submission/:id",
    component: _6a71517e,
    name: "aftersales-add-baggage-submission-id___uk"
  }, {
    path: "/uk/aftersales/cancellation/detail/:afsId",
    component: _2f02b848,
    name: "aftersales-cancellation-detail-afsId___uk"
  }, {
    path: "/uk/aftersales/cancellation/submission/:id",
    component: _55c67d6d,
    name: "aftersales-cancellation-submission-id___uk"
  }, {
    path: "/uk/aftersales/change-flight/detail/:afsId",
    component: _377c5270,
    name: "aftersales-change-flight-detail-afsId___uk"
  }, {
    path: "/uk/aftersales/change-flight/submission/:id",
    component: _5c802845,
    name: "aftersales-change-flight-submission-id___uk"
  }, {
    path: "/uk/aftersales/change-passenger/detail/:afsId",
    component: _77639f82,
    name: "aftersales-change-passenger-detail-afsId___uk"
  }, {
    path: "/uk/aftersales/change-passenger/submission/:id",
    component: _19827d73,
    name: "aftersales-change-passenger-submission-id___uk"
  }, {
    path: "/uk/order/manage-hotel/cancel/:id",
    component: _dd6cbe18,
    name: "order-manage-hotel-cancel-id___uk"
  }, {
    path: "/uk/order/manage-hotel/change-data/:id",
    component: _93aae60e,
    name: "order-manage-hotel-change-data-id___uk"
  }, {
    path: "/vi/aftersales/add-baggage/detail/:afsId",
    component: _66d6c3d2,
    name: "aftersales-add-baggage-detail-afsId___vi"
  }, {
    path: "/vi/aftersales/add-baggage/submission/:id",
    component: _6a71517e,
    name: "aftersales-add-baggage-submission-id___vi"
  }, {
    path: "/vi/aftersales/cancellation/detail/:afsId",
    component: _2f02b848,
    name: "aftersales-cancellation-detail-afsId___vi"
  }, {
    path: "/vi/aftersales/cancellation/submission/:id",
    component: _55c67d6d,
    name: "aftersales-cancellation-submission-id___vi"
  }, {
    path: "/vi/aftersales/change-flight/detail/:afsId",
    component: _377c5270,
    name: "aftersales-change-flight-detail-afsId___vi"
  }, {
    path: "/vi/aftersales/change-flight/submission/:id",
    component: _5c802845,
    name: "aftersales-change-flight-submission-id___vi"
  }, {
    path: "/vi/aftersales/change-passenger/detail/:afsId",
    component: _77639f82,
    name: "aftersales-change-passenger-detail-afsId___vi"
  }, {
    path: "/vi/aftersales/change-passenger/submission/:id",
    component: _19827d73,
    name: "aftersales-change-passenger-submission-id___vi"
  }, {
    path: "/vi/order/manage-hotel/cancel/:id",
    component: _dd6cbe18,
    name: "order-manage-hotel-cancel-id___vi"
  }, {
    path: "/vi/order/manage-hotel/change-data/:id",
    component: _93aae60e,
    name: "order-manage-hotel-change-data-id___vi"
  }, {
    path: "/zh-tw/aftersales/add-baggage/detail/:afsId",
    component: _66d6c3d2,
    name: "aftersales-add-baggage-detail-afsId___zh-tw"
  }, {
    path: "/zh-tw/aftersales/add-baggage/submission/:id",
    component: _6a71517e,
    name: "aftersales-add-baggage-submission-id___zh-tw"
  }, {
    path: "/zh-tw/aftersales/cancellation/detail/:afsId",
    component: _2f02b848,
    name: "aftersales-cancellation-detail-afsId___zh-tw"
  }, {
    path: "/zh-tw/aftersales/cancellation/submission/:id",
    component: _55c67d6d,
    name: "aftersales-cancellation-submission-id___zh-tw"
  }, {
    path: "/zh-tw/aftersales/change-flight/detail/:afsId",
    component: _377c5270,
    name: "aftersales-change-flight-detail-afsId___zh-tw"
  }, {
    path: "/zh-tw/aftersales/change-flight/submission/:id",
    component: _5c802845,
    name: "aftersales-change-flight-submission-id___zh-tw"
  }, {
    path: "/zh-tw/aftersales/change-passenger/detail/:afsId",
    component: _77639f82,
    name: "aftersales-change-passenger-detail-afsId___zh-tw"
  }, {
    path: "/zh-tw/aftersales/change-passenger/submission/:id",
    component: _19827d73,
    name: "aftersales-change-passenger-submission-id___zh-tw"
  }, {
    path: "/zh-tw/order/manage-hotel/cancel/:id",
    component: _dd6cbe18,
    name: "order-manage-hotel-cancel-id___zh-tw"
  }, {
    path: "/zh-tw/order/manage-hotel/change-data/:id",
    component: _93aae60e,
    name: "order-manage-hotel-change-data-id___zh-tw"
  }, {
    path: "/zh/aftersales/add-baggage/detail/:afsId",
    component: _66d6c3d2,
    name: "aftersales-add-baggage-detail-afsId___zh"
  }, {
    path: "/zh/aftersales/add-baggage/submission/:id",
    component: _6a71517e,
    name: "aftersales-add-baggage-submission-id___zh"
  }, {
    path: "/zh/aftersales/cancellation/detail/:afsId",
    component: _2f02b848,
    name: "aftersales-cancellation-detail-afsId___zh"
  }, {
    path: "/zh/aftersales/cancellation/submission/:id",
    component: _55c67d6d,
    name: "aftersales-cancellation-submission-id___zh"
  }, {
    path: "/zh/aftersales/change-flight/detail/:afsId",
    component: _377c5270,
    name: "aftersales-change-flight-detail-afsId___zh"
  }, {
    path: "/zh/aftersales/change-flight/submission/:id",
    component: _5c802845,
    name: "aftersales-change-flight-submission-id___zh"
  }, {
    path: "/zh/aftersales/change-passenger/detail/:afsId",
    component: _77639f82,
    name: "aftersales-change-passenger-detail-afsId___zh"
  }, {
    path: "/zh/aftersales/change-passenger/submission/:id",
    component: _19827d73,
    name: "aftersales-change-passenger-submission-id___zh"
  }, {
    path: "/zh/order/manage-hotel/cancel/:id",
    component: _dd6cbe18,
    name: "order-manage-hotel-cancel-id___zh"
  }, {
    path: "/zh/order/manage-hotel/change-data/:id",
    component: _93aae60e,
    name: "order-manage-hotel-change-data-id___zh"
  }, {
    path: "/bg/aftersales/cancellation/submission/:id?/document",
    component: _5ce5a050,
    name: "aftersales-cancellation-submission-id-document___bg"
  }, {
    path: "/bg/aftersales/cancellation/submission/:id?/flight",
    component: _917af736,
    name: "aftersales-cancellation-submission-id-flight___bg"
  }, {
    path: "/bg/aftersales/change-flight/submission/:id?/flight",
    component: _f08396e6,
    name: "aftersales-change-flight-submission-id-flight___bg"
  }, {
    path: "/bg/aftersales/change-flight/submission/:id?/search",
    component: _41788c76,
    name: "aftersales-change-flight-submission-id-search___bg"
  }, {
    path: "/bn/aftersales/cancellation/submission/:id?/document",
    component: _5ce5a050,
    name: "aftersales-cancellation-submission-id-document___bn"
  }, {
    path: "/bn/aftersales/cancellation/submission/:id?/flight",
    component: _917af736,
    name: "aftersales-cancellation-submission-id-flight___bn"
  }, {
    path: "/bn/aftersales/change-flight/submission/:id?/flight",
    component: _f08396e6,
    name: "aftersales-change-flight-submission-id-flight___bn"
  }, {
    path: "/bn/aftersales/change-flight/submission/:id?/search",
    component: _41788c76,
    name: "aftersales-change-flight-submission-id-search___bn"
  }, {
    path: "/ca/aftersales/cancellation/submission/:id?/document",
    component: _5ce5a050,
    name: "aftersales-cancellation-submission-id-document___ca"
  }, {
    path: "/ca/aftersales/cancellation/submission/:id?/flight",
    component: _917af736,
    name: "aftersales-cancellation-submission-id-flight___ca"
  }, {
    path: "/ca/aftersales/change-flight/submission/:id?/flight",
    component: _f08396e6,
    name: "aftersales-change-flight-submission-id-flight___ca"
  }, {
    path: "/ca/aftersales/change-flight/submission/:id?/search",
    component: _41788c76,
    name: "aftersales-change-flight-submission-id-search___ca"
  }, {
    path: "/cs/aftersales/cancellation/submission/:id?/document",
    component: _5ce5a050,
    name: "aftersales-cancellation-submission-id-document___cs"
  }, {
    path: "/cs/aftersales/cancellation/submission/:id?/flight",
    component: _917af736,
    name: "aftersales-cancellation-submission-id-flight___cs"
  }, {
    path: "/cs/aftersales/change-flight/submission/:id?/flight",
    component: _f08396e6,
    name: "aftersales-change-flight-submission-id-flight___cs"
  }, {
    path: "/cs/aftersales/change-flight/submission/:id?/search",
    component: _41788c76,
    name: "aftersales-change-flight-submission-id-search___cs"
  }, {
    path: "/da/aftersales/cancellation/submission/:id?/document",
    component: _5ce5a050,
    name: "aftersales-cancellation-submission-id-document___da"
  }, {
    path: "/da/aftersales/cancellation/submission/:id?/flight",
    component: _917af736,
    name: "aftersales-cancellation-submission-id-flight___da"
  }, {
    path: "/da/aftersales/change-flight/submission/:id?/flight",
    component: _f08396e6,
    name: "aftersales-change-flight-submission-id-flight___da"
  }, {
    path: "/da/aftersales/change-flight/submission/:id?/search",
    component: _41788c76,
    name: "aftersales-change-flight-submission-id-search___da"
  }, {
    path: "/de/aftersales/cancellation/submission/:id?/document",
    component: _5ce5a050,
    name: "aftersales-cancellation-submission-id-document___de"
  }, {
    path: "/de/aftersales/cancellation/submission/:id?/flight",
    component: _917af736,
    name: "aftersales-cancellation-submission-id-flight___de"
  }, {
    path: "/de/aftersales/change-flight/submission/:id?/flight",
    component: _f08396e6,
    name: "aftersales-change-flight-submission-id-flight___de"
  }, {
    path: "/de/aftersales/change-flight/submission/:id?/search",
    component: _41788c76,
    name: "aftersales-change-flight-submission-id-search___de"
  }, {
    path: "/el/aftersales/cancellation/submission/:id?/document",
    component: _5ce5a050,
    name: "aftersales-cancellation-submission-id-document___el"
  }, {
    path: "/el/aftersales/cancellation/submission/:id?/flight",
    component: _917af736,
    name: "aftersales-cancellation-submission-id-flight___el"
  }, {
    path: "/el/aftersales/change-flight/submission/:id?/flight",
    component: _f08396e6,
    name: "aftersales-change-flight-submission-id-flight___el"
  }, {
    path: "/el/aftersales/change-flight/submission/:id?/search",
    component: _41788c76,
    name: "aftersales-change-flight-submission-id-search___el"
  }, {
    path: "/en/aftersales/cancellation/submission/:id?/document",
    component: _5ce5a050,
    name: "aftersales-cancellation-submission-id-document___en"
  }, {
    path: "/en/aftersales/cancellation/submission/:id?/flight",
    component: _917af736,
    name: "aftersales-cancellation-submission-id-flight___en"
  }, {
    path: "/en/aftersales/change-flight/submission/:id?/flight",
    component: _f08396e6,
    name: "aftersales-change-flight-submission-id-flight___en"
  }, {
    path: "/en/aftersales/change-flight/submission/:id?/search",
    component: _41788c76,
    name: "aftersales-change-flight-submission-id-search___en"
  }, {
    path: "/es/aftersales/cancellation/submission/:id?/document",
    component: _5ce5a050,
    name: "aftersales-cancellation-submission-id-document___es"
  }, {
    path: "/es/aftersales/cancellation/submission/:id?/flight",
    component: _917af736,
    name: "aftersales-cancellation-submission-id-flight___es"
  }, {
    path: "/es/aftersales/change-flight/submission/:id?/flight",
    component: _f08396e6,
    name: "aftersales-change-flight-submission-id-flight___es"
  }, {
    path: "/es/aftersales/change-flight/submission/:id?/search",
    component: _41788c76,
    name: "aftersales-change-flight-submission-id-search___es"
  }, {
    path: "/fi/aftersales/cancellation/submission/:id?/document",
    component: _5ce5a050,
    name: "aftersales-cancellation-submission-id-document___fi"
  }, {
    path: "/fi/aftersales/cancellation/submission/:id?/flight",
    component: _917af736,
    name: "aftersales-cancellation-submission-id-flight___fi"
  }, {
    path: "/fi/aftersales/change-flight/submission/:id?/flight",
    component: _f08396e6,
    name: "aftersales-change-flight-submission-id-flight___fi"
  }, {
    path: "/fi/aftersales/change-flight/submission/:id?/search",
    component: _41788c76,
    name: "aftersales-change-flight-submission-id-search___fi"
  }, {
    path: "/fr/aftersales/cancellation/submission/:id?/document",
    component: _5ce5a050,
    name: "aftersales-cancellation-submission-id-document___fr"
  }, {
    path: "/fr/aftersales/cancellation/submission/:id?/flight",
    component: _917af736,
    name: "aftersales-cancellation-submission-id-flight___fr"
  }, {
    path: "/fr/aftersales/change-flight/submission/:id?/flight",
    component: _f08396e6,
    name: "aftersales-change-flight-submission-id-flight___fr"
  }, {
    path: "/fr/aftersales/change-flight/submission/:id?/search",
    component: _41788c76,
    name: "aftersales-change-flight-submission-id-search___fr"
  }, {
    path: "/hi/aftersales/cancellation/submission/:id?/document",
    component: _5ce5a050,
    name: "aftersales-cancellation-submission-id-document___hi"
  }, {
    path: "/hi/aftersales/cancellation/submission/:id?/flight",
    component: _917af736,
    name: "aftersales-cancellation-submission-id-flight___hi"
  }, {
    path: "/hi/aftersales/change-flight/submission/:id?/flight",
    component: _f08396e6,
    name: "aftersales-change-flight-submission-id-flight___hi"
  }, {
    path: "/hi/aftersales/change-flight/submission/:id?/search",
    component: _41788c76,
    name: "aftersales-change-flight-submission-id-search___hi"
  }, {
    path: "/hr/aftersales/cancellation/submission/:id?/document",
    component: _5ce5a050,
    name: "aftersales-cancellation-submission-id-document___hr"
  }, {
    path: "/hr/aftersales/cancellation/submission/:id?/flight",
    component: _917af736,
    name: "aftersales-cancellation-submission-id-flight___hr"
  }, {
    path: "/hr/aftersales/change-flight/submission/:id?/flight",
    component: _f08396e6,
    name: "aftersales-change-flight-submission-id-flight___hr"
  }, {
    path: "/hr/aftersales/change-flight/submission/:id?/search",
    component: _41788c76,
    name: "aftersales-change-flight-submission-id-search___hr"
  }, {
    path: "/hu/aftersales/cancellation/submission/:id?/document",
    component: _5ce5a050,
    name: "aftersales-cancellation-submission-id-document___hu"
  }, {
    path: "/hu/aftersales/cancellation/submission/:id?/flight",
    component: _917af736,
    name: "aftersales-cancellation-submission-id-flight___hu"
  }, {
    path: "/hu/aftersales/change-flight/submission/:id?/flight",
    component: _f08396e6,
    name: "aftersales-change-flight-submission-id-flight___hu"
  }, {
    path: "/hu/aftersales/change-flight/submission/:id?/search",
    component: _41788c76,
    name: "aftersales-change-flight-submission-id-search___hu"
  }, {
    path: "/id/aftersales/cancellation/submission/:id?/document",
    component: _5ce5a050,
    name: "aftersales-cancellation-submission-id-document___id"
  }, {
    path: "/id/aftersales/cancellation/submission/:id?/flight",
    component: _917af736,
    name: "aftersales-cancellation-submission-id-flight___id"
  }, {
    path: "/id/aftersales/change-flight/submission/:id?/flight",
    component: _f08396e6,
    name: "aftersales-change-flight-submission-id-flight___id"
  }, {
    path: "/id/aftersales/change-flight/submission/:id?/search",
    component: _41788c76,
    name: "aftersales-change-flight-submission-id-search___id"
  }, {
    path: "/it/aftersales/cancellation/submission/:id?/document",
    component: _5ce5a050,
    name: "aftersales-cancellation-submission-id-document___it"
  }, {
    path: "/it/aftersales/cancellation/submission/:id?/flight",
    component: _917af736,
    name: "aftersales-cancellation-submission-id-flight___it"
  }, {
    path: "/it/aftersales/change-flight/submission/:id?/flight",
    component: _f08396e6,
    name: "aftersales-change-flight-submission-id-flight___it"
  }, {
    path: "/it/aftersales/change-flight/submission/:id?/search",
    component: _41788c76,
    name: "aftersales-change-flight-submission-id-search___it"
  }, {
    path: "/ja/aftersales/cancellation/submission/:id?/document",
    component: _5ce5a050,
    name: "aftersales-cancellation-submission-id-document___ja"
  }, {
    path: "/ja/aftersales/cancellation/submission/:id?/flight",
    component: _917af736,
    name: "aftersales-cancellation-submission-id-flight___ja"
  }, {
    path: "/ja/aftersales/change-flight/submission/:id?/flight",
    component: _f08396e6,
    name: "aftersales-change-flight-submission-id-flight___ja"
  }, {
    path: "/ja/aftersales/change-flight/submission/:id?/search",
    component: _41788c76,
    name: "aftersales-change-flight-submission-id-search___ja"
  }, {
    path: "/km/aftersales/cancellation/submission/:id?/document",
    component: _5ce5a050,
    name: "aftersales-cancellation-submission-id-document___km"
  }, {
    path: "/km/aftersales/cancellation/submission/:id?/flight",
    component: _917af736,
    name: "aftersales-cancellation-submission-id-flight___km"
  }, {
    path: "/km/aftersales/change-flight/submission/:id?/flight",
    component: _f08396e6,
    name: "aftersales-change-flight-submission-id-flight___km"
  }, {
    path: "/km/aftersales/change-flight/submission/:id?/search",
    component: _41788c76,
    name: "aftersales-change-flight-submission-id-search___km"
  }, {
    path: "/ko/aftersales/cancellation/submission/:id?/document",
    component: _5ce5a050,
    name: "aftersales-cancellation-submission-id-document___ko"
  }, {
    path: "/ko/aftersales/cancellation/submission/:id?/flight",
    component: _917af736,
    name: "aftersales-cancellation-submission-id-flight___ko"
  }, {
    path: "/ko/aftersales/change-flight/submission/:id?/flight",
    component: _f08396e6,
    name: "aftersales-change-flight-submission-id-flight___ko"
  }, {
    path: "/ko/aftersales/change-flight/submission/:id?/search",
    component: _41788c76,
    name: "aftersales-change-flight-submission-id-search___ko"
  }, {
    path: "/ms/aftersales/cancellation/submission/:id?/document",
    component: _5ce5a050,
    name: "aftersales-cancellation-submission-id-document___ms"
  }, {
    path: "/ms/aftersales/cancellation/submission/:id?/flight",
    component: _917af736,
    name: "aftersales-cancellation-submission-id-flight___ms"
  }, {
    path: "/ms/aftersales/change-flight/submission/:id?/flight",
    component: _f08396e6,
    name: "aftersales-change-flight-submission-id-flight___ms"
  }, {
    path: "/ms/aftersales/change-flight/submission/:id?/search",
    component: _41788c76,
    name: "aftersales-change-flight-submission-id-search___ms"
  }, {
    path: "/nb/aftersales/cancellation/submission/:id?/document",
    component: _5ce5a050,
    name: "aftersales-cancellation-submission-id-document___nb"
  }, {
    path: "/nb/aftersales/cancellation/submission/:id?/flight",
    component: _917af736,
    name: "aftersales-cancellation-submission-id-flight___nb"
  }, {
    path: "/nb/aftersales/change-flight/submission/:id?/flight",
    component: _f08396e6,
    name: "aftersales-change-flight-submission-id-flight___nb"
  }, {
    path: "/nb/aftersales/change-flight/submission/:id?/search",
    component: _41788c76,
    name: "aftersales-change-flight-submission-id-search___nb"
  }, {
    path: "/nl/aftersales/cancellation/submission/:id?/document",
    component: _5ce5a050,
    name: "aftersales-cancellation-submission-id-document___nl"
  }, {
    path: "/nl/aftersales/cancellation/submission/:id?/flight",
    component: _917af736,
    name: "aftersales-cancellation-submission-id-flight___nl"
  }, {
    path: "/nl/aftersales/change-flight/submission/:id?/flight",
    component: _f08396e6,
    name: "aftersales-change-flight-submission-id-flight___nl"
  }, {
    path: "/nl/aftersales/change-flight/submission/:id?/search",
    component: _41788c76,
    name: "aftersales-change-flight-submission-id-search___nl"
  }, {
    path: "/pl/aftersales/cancellation/submission/:id?/document",
    component: _5ce5a050,
    name: "aftersales-cancellation-submission-id-document___pl"
  }, {
    path: "/pl/aftersales/cancellation/submission/:id?/flight",
    component: _917af736,
    name: "aftersales-cancellation-submission-id-flight___pl"
  }, {
    path: "/pl/aftersales/change-flight/submission/:id?/flight",
    component: _f08396e6,
    name: "aftersales-change-flight-submission-id-flight___pl"
  }, {
    path: "/pl/aftersales/change-flight/submission/:id?/search",
    component: _41788c76,
    name: "aftersales-change-flight-submission-id-search___pl"
  }, {
    path: "/pt/aftersales/cancellation/submission/:id?/document",
    component: _5ce5a050,
    name: "aftersales-cancellation-submission-id-document___pt"
  }, {
    path: "/pt/aftersales/cancellation/submission/:id?/flight",
    component: _917af736,
    name: "aftersales-cancellation-submission-id-flight___pt"
  }, {
    path: "/pt/aftersales/change-flight/submission/:id?/flight",
    component: _f08396e6,
    name: "aftersales-change-flight-submission-id-flight___pt"
  }, {
    path: "/pt/aftersales/change-flight/submission/:id?/search",
    component: _41788c76,
    name: "aftersales-change-flight-submission-id-search___pt"
  }, {
    path: "/ro/aftersales/cancellation/submission/:id?/document",
    component: _5ce5a050,
    name: "aftersales-cancellation-submission-id-document___ro"
  }, {
    path: "/ro/aftersales/cancellation/submission/:id?/flight",
    component: _917af736,
    name: "aftersales-cancellation-submission-id-flight___ro"
  }, {
    path: "/ro/aftersales/change-flight/submission/:id?/flight",
    component: _f08396e6,
    name: "aftersales-change-flight-submission-id-flight___ro"
  }, {
    path: "/ro/aftersales/change-flight/submission/:id?/search",
    component: _41788c76,
    name: "aftersales-change-flight-submission-id-search___ro"
  }, {
    path: "/ru/aftersales/cancellation/submission/:id?/document",
    component: _5ce5a050,
    name: "aftersales-cancellation-submission-id-document___ru"
  }, {
    path: "/ru/aftersales/cancellation/submission/:id?/flight",
    component: _917af736,
    name: "aftersales-cancellation-submission-id-flight___ru"
  }, {
    path: "/ru/aftersales/change-flight/submission/:id?/flight",
    component: _f08396e6,
    name: "aftersales-change-flight-submission-id-flight___ru"
  }, {
    path: "/ru/aftersales/change-flight/submission/:id?/search",
    component: _41788c76,
    name: "aftersales-change-flight-submission-id-search___ru"
  }, {
    path: "/sk/aftersales/cancellation/submission/:id?/document",
    component: _5ce5a050,
    name: "aftersales-cancellation-submission-id-document___sk"
  }, {
    path: "/sk/aftersales/cancellation/submission/:id?/flight",
    component: _917af736,
    name: "aftersales-cancellation-submission-id-flight___sk"
  }, {
    path: "/sk/aftersales/change-flight/submission/:id?/flight",
    component: _f08396e6,
    name: "aftersales-change-flight-submission-id-flight___sk"
  }, {
    path: "/sk/aftersales/change-flight/submission/:id?/search",
    component: _41788c76,
    name: "aftersales-change-flight-submission-id-search___sk"
  }, {
    path: "/sl/aftersales/cancellation/submission/:id?/document",
    component: _5ce5a050,
    name: "aftersales-cancellation-submission-id-document___sl"
  }, {
    path: "/sl/aftersales/cancellation/submission/:id?/flight",
    component: _917af736,
    name: "aftersales-cancellation-submission-id-flight___sl"
  }, {
    path: "/sl/aftersales/change-flight/submission/:id?/flight",
    component: _f08396e6,
    name: "aftersales-change-flight-submission-id-flight___sl"
  }, {
    path: "/sl/aftersales/change-flight/submission/:id?/search",
    component: _41788c76,
    name: "aftersales-change-flight-submission-id-search___sl"
  }, {
    path: "/sr/aftersales/cancellation/submission/:id?/document",
    component: _5ce5a050,
    name: "aftersales-cancellation-submission-id-document___sr"
  }, {
    path: "/sr/aftersales/cancellation/submission/:id?/flight",
    component: _917af736,
    name: "aftersales-cancellation-submission-id-flight___sr"
  }, {
    path: "/sr/aftersales/change-flight/submission/:id?/flight",
    component: _f08396e6,
    name: "aftersales-change-flight-submission-id-flight___sr"
  }, {
    path: "/sr/aftersales/change-flight/submission/:id?/search",
    component: _41788c76,
    name: "aftersales-change-flight-submission-id-search___sr"
  }, {
    path: "/sv/aftersales/cancellation/submission/:id?/document",
    component: _5ce5a050,
    name: "aftersales-cancellation-submission-id-document___sv"
  }, {
    path: "/sv/aftersales/cancellation/submission/:id?/flight",
    component: _917af736,
    name: "aftersales-cancellation-submission-id-flight___sv"
  }, {
    path: "/sv/aftersales/change-flight/submission/:id?/flight",
    component: _f08396e6,
    name: "aftersales-change-flight-submission-id-flight___sv"
  }, {
    path: "/sv/aftersales/change-flight/submission/:id?/search",
    component: _41788c76,
    name: "aftersales-change-flight-submission-id-search___sv"
  }, {
    path: "/th/aftersales/cancellation/submission/:id?/document",
    component: _5ce5a050,
    name: "aftersales-cancellation-submission-id-document___th"
  }, {
    path: "/th/aftersales/cancellation/submission/:id?/flight",
    component: _917af736,
    name: "aftersales-cancellation-submission-id-flight___th"
  }, {
    path: "/th/aftersales/change-flight/submission/:id?/flight",
    component: _f08396e6,
    name: "aftersales-change-flight-submission-id-flight___th"
  }, {
    path: "/th/aftersales/change-flight/submission/:id?/search",
    component: _41788c76,
    name: "aftersales-change-flight-submission-id-search___th"
  }, {
    path: "/tl/aftersales/cancellation/submission/:id?/document",
    component: _5ce5a050,
    name: "aftersales-cancellation-submission-id-document___tl"
  }, {
    path: "/tl/aftersales/cancellation/submission/:id?/flight",
    component: _917af736,
    name: "aftersales-cancellation-submission-id-flight___tl"
  }, {
    path: "/tl/aftersales/change-flight/submission/:id?/flight",
    component: _f08396e6,
    name: "aftersales-change-flight-submission-id-flight___tl"
  }, {
    path: "/tl/aftersales/change-flight/submission/:id?/search",
    component: _41788c76,
    name: "aftersales-change-flight-submission-id-search___tl"
  }, {
    path: "/tr/aftersales/cancellation/submission/:id?/document",
    component: _5ce5a050,
    name: "aftersales-cancellation-submission-id-document___tr"
  }, {
    path: "/tr/aftersales/cancellation/submission/:id?/flight",
    component: _917af736,
    name: "aftersales-cancellation-submission-id-flight___tr"
  }, {
    path: "/tr/aftersales/change-flight/submission/:id?/flight",
    component: _f08396e6,
    name: "aftersales-change-flight-submission-id-flight___tr"
  }, {
    path: "/tr/aftersales/change-flight/submission/:id?/search",
    component: _41788c76,
    name: "aftersales-change-flight-submission-id-search___tr"
  }, {
    path: "/uk/aftersales/cancellation/submission/:id?/document",
    component: _5ce5a050,
    name: "aftersales-cancellation-submission-id-document___uk"
  }, {
    path: "/uk/aftersales/cancellation/submission/:id?/flight",
    component: _917af736,
    name: "aftersales-cancellation-submission-id-flight___uk"
  }, {
    path: "/uk/aftersales/change-flight/submission/:id?/flight",
    component: _f08396e6,
    name: "aftersales-change-flight-submission-id-flight___uk"
  }, {
    path: "/uk/aftersales/change-flight/submission/:id?/search",
    component: _41788c76,
    name: "aftersales-change-flight-submission-id-search___uk"
  }, {
    path: "/vi/aftersales/cancellation/submission/:id?/document",
    component: _5ce5a050,
    name: "aftersales-cancellation-submission-id-document___vi"
  }, {
    path: "/vi/aftersales/cancellation/submission/:id?/flight",
    component: _917af736,
    name: "aftersales-cancellation-submission-id-flight___vi"
  }, {
    path: "/vi/aftersales/change-flight/submission/:id?/flight",
    component: _f08396e6,
    name: "aftersales-change-flight-submission-id-flight___vi"
  }, {
    path: "/vi/aftersales/change-flight/submission/:id?/search",
    component: _41788c76,
    name: "aftersales-change-flight-submission-id-search___vi"
  }, {
    path: "/zh-tw/aftersales/cancellation/submission/:id?/document",
    component: _5ce5a050,
    name: "aftersales-cancellation-submission-id-document___zh-tw"
  }, {
    path: "/zh-tw/aftersales/cancellation/submission/:id?/flight",
    component: _917af736,
    name: "aftersales-cancellation-submission-id-flight___zh-tw"
  }, {
    path: "/zh-tw/aftersales/change-flight/submission/:id?/flight",
    component: _f08396e6,
    name: "aftersales-change-flight-submission-id-flight___zh-tw"
  }, {
    path: "/zh-tw/aftersales/change-flight/submission/:id?/search",
    component: _41788c76,
    name: "aftersales-change-flight-submission-id-search___zh-tw"
  }, {
    path: "/zh/aftersales/cancellation/submission/:id?/document",
    component: _5ce5a050,
    name: "aftersales-cancellation-submission-id-document___zh"
  }, {
    path: "/zh/aftersales/cancellation/submission/:id?/flight",
    component: _917af736,
    name: "aftersales-cancellation-submission-id-flight___zh"
  }, {
    path: "/zh/aftersales/change-flight/submission/:id?/flight",
    component: _f08396e6,
    name: "aftersales-change-flight-submission-id-flight___zh"
  }, {
    path: "/zh/aftersales/change-flight/submission/:id?/search",
    component: _41788c76,
    name: "aftersales-change-flight-submission-id-search___zh"
  }, {
    path: "/bg/aftersales/cancellation/:id",
    component: _1e70909a,
    name: "aftersales-cancellation-id___bg"
  }, {
    path: "/bg/aftersales/detail/:afsId",
    component: _9df1a0dc,
    name: "aftersales-detail-afsId___bg"
  }, {
    path: "/bg/flight/book/:bookid",
    component: _258d12a9,
    name: "flight-book-bookid___bg"
  }, {
    path: "/bg/hotel/book/:bookid",
    component: _1107e381,
    name: "hotel-book-bookid___bg"
  }, {
    path: "/bg/hotel/review/:hash?",
    component: _b3f3d492,
    name: "hotel-review-hash___bg"
  }, {
    path: "/bg/order/summary/:id",
    component: _654c3086,
    name: "order-summary-id___bg"
  }, {
    path: "/bg/promo/view/:slug?",
    component: _ca6a8534,
    name: "promo-view-slug___bg"
  }, {
    path: "/bg/reset/pass/:id",
    component: _5dd872c5,
    name: "reset-pass-id___bg"
  }, {
    path: "/bn/aftersales/cancellation/:id",
    component: _1e70909a,
    name: "aftersales-cancellation-id___bn"
  }, {
    path: "/bn/aftersales/detail/:afsId",
    component: _9df1a0dc,
    name: "aftersales-detail-afsId___bn"
  }, {
    path: "/bn/flight/book/:bookid",
    component: _258d12a9,
    name: "flight-book-bookid___bn"
  }, {
    path: "/bn/hotel/book/:bookid",
    component: _1107e381,
    name: "hotel-book-bookid___bn"
  }, {
    path: "/bn/hotel/review/:hash?",
    component: _b3f3d492,
    name: "hotel-review-hash___bn"
  }, {
    path: "/bn/order/summary/:id",
    component: _654c3086,
    name: "order-summary-id___bn"
  }, {
    path: "/bn/promo/view/:slug?",
    component: _ca6a8534,
    name: "promo-view-slug___bn"
  }, {
    path: "/bn/reset/pass/:id",
    component: _5dd872c5,
    name: "reset-pass-id___bn"
  }, {
    path: "/ca/aftersales/cancellation/:id",
    component: _1e70909a,
    name: "aftersales-cancellation-id___ca"
  }, {
    path: "/ca/aftersales/detail/:afsId",
    component: _9df1a0dc,
    name: "aftersales-detail-afsId___ca"
  }, {
    path: "/ca/flight/book/:bookid",
    component: _258d12a9,
    name: "flight-book-bookid___ca"
  }, {
    path: "/ca/hotel/book/:bookid",
    component: _1107e381,
    name: "hotel-book-bookid___ca"
  }, {
    path: "/ca/hotel/review/:hash?",
    component: _b3f3d492,
    name: "hotel-review-hash___ca"
  }, {
    path: "/ca/order/summary/:id",
    component: _654c3086,
    name: "order-summary-id___ca"
  }, {
    path: "/ca/promo/view/:slug?",
    component: _ca6a8534,
    name: "promo-view-slug___ca"
  }, {
    path: "/ca/reset/pass/:id",
    component: _5dd872c5,
    name: "reset-pass-id___ca"
  }, {
    path: "/cs/aftersales/cancellation/:id",
    component: _1e70909a,
    name: "aftersales-cancellation-id___cs"
  }, {
    path: "/cs/aftersales/detail/:afsId",
    component: _9df1a0dc,
    name: "aftersales-detail-afsId___cs"
  }, {
    path: "/cs/flight/book/:bookid",
    component: _258d12a9,
    name: "flight-book-bookid___cs"
  }, {
    path: "/cs/hotel/book/:bookid",
    component: _1107e381,
    name: "hotel-book-bookid___cs"
  }, {
    path: "/cs/hotel/review/:hash?",
    component: _b3f3d492,
    name: "hotel-review-hash___cs"
  }, {
    path: "/cs/order/summary/:id",
    component: _654c3086,
    name: "order-summary-id___cs"
  }, {
    path: "/cs/promo/view/:slug?",
    component: _ca6a8534,
    name: "promo-view-slug___cs"
  }, {
    path: "/cs/reset/pass/:id",
    component: _5dd872c5,
    name: "reset-pass-id___cs"
  }, {
    path: "/da/aftersales/cancellation/:id",
    component: _1e70909a,
    name: "aftersales-cancellation-id___da"
  }, {
    path: "/da/aftersales/detail/:afsId",
    component: _9df1a0dc,
    name: "aftersales-detail-afsId___da"
  }, {
    path: "/da/flight/book/:bookid",
    component: _258d12a9,
    name: "flight-book-bookid___da"
  }, {
    path: "/da/hotel/book/:bookid",
    component: _1107e381,
    name: "hotel-book-bookid___da"
  }, {
    path: "/da/hotel/review/:hash?",
    component: _b3f3d492,
    name: "hotel-review-hash___da"
  }, {
    path: "/da/order/summary/:id",
    component: _654c3086,
    name: "order-summary-id___da"
  }, {
    path: "/da/promo/view/:slug?",
    component: _ca6a8534,
    name: "promo-view-slug___da"
  }, {
    path: "/da/reset/pass/:id",
    component: _5dd872c5,
    name: "reset-pass-id___da"
  }, {
    path: "/de/aftersales/cancellation/:id",
    component: _1e70909a,
    name: "aftersales-cancellation-id___de"
  }, {
    path: "/de/aftersales/detail/:afsId",
    component: _9df1a0dc,
    name: "aftersales-detail-afsId___de"
  }, {
    path: "/de/flight/book/:bookid",
    component: _258d12a9,
    name: "flight-book-bookid___de"
  }, {
    path: "/de/hotel/book/:bookid",
    component: _1107e381,
    name: "hotel-book-bookid___de"
  }, {
    path: "/de/hotel/review/:hash?",
    component: _b3f3d492,
    name: "hotel-review-hash___de"
  }, {
    path: "/de/order/summary/:id",
    component: _654c3086,
    name: "order-summary-id___de"
  }, {
    path: "/de/promo/view/:slug?",
    component: _ca6a8534,
    name: "promo-view-slug___de"
  }, {
    path: "/de/reset/pass/:id",
    component: _5dd872c5,
    name: "reset-pass-id___de"
  }, {
    path: "/el/aftersales/cancellation/:id",
    component: _1e70909a,
    name: "aftersales-cancellation-id___el"
  }, {
    path: "/el/aftersales/detail/:afsId",
    component: _9df1a0dc,
    name: "aftersales-detail-afsId___el"
  }, {
    path: "/el/flight/book/:bookid",
    component: _258d12a9,
    name: "flight-book-bookid___el"
  }, {
    path: "/el/hotel/book/:bookid",
    component: _1107e381,
    name: "hotel-book-bookid___el"
  }, {
    path: "/el/hotel/review/:hash?",
    component: _b3f3d492,
    name: "hotel-review-hash___el"
  }, {
    path: "/el/order/summary/:id",
    component: _654c3086,
    name: "order-summary-id___el"
  }, {
    path: "/el/promo/view/:slug?",
    component: _ca6a8534,
    name: "promo-view-slug___el"
  }, {
    path: "/el/reset/pass/:id",
    component: _5dd872c5,
    name: "reset-pass-id___el"
  }, {
    path: "/en/aftersales/cancellation/:id",
    component: _1e70909a,
    name: "aftersales-cancellation-id___en"
  }, {
    path: "/en/aftersales/detail/:afsId",
    component: _9df1a0dc,
    name: "aftersales-detail-afsId___en"
  }, {
    path: "/en/flight/book/:bookid",
    component: _258d12a9,
    name: "flight-book-bookid___en"
  }, {
    path: "/en/hotel/book/:bookid",
    component: _1107e381,
    name: "hotel-book-bookid___en"
  }, {
    path: "/en/hotel/review/:hash?",
    component: _b3f3d492,
    name: "hotel-review-hash___en"
  }, {
    path: "/en/order/summary/:id",
    component: _654c3086,
    name: "order-summary-id___en"
  }, {
    path: "/en/promo/view/:slug?",
    component: _ca6a8534,
    name: "promo-view-slug___en"
  }, {
    path: "/en/reset/pass/:id",
    component: _5dd872c5,
    name: "reset-pass-id___en"
  }, {
    path: "/es/aftersales/cancellation/:id",
    component: _1e70909a,
    name: "aftersales-cancellation-id___es"
  }, {
    path: "/es/aftersales/detail/:afsId",
    component: _9df1a0dc,
    name: "aftersales-detail-afsId___es"
  }, {
    path: "/es/flight/book/:bookid",
    component: _258d12a9,
    name: "flight-book-bookid___es"
  }, {
    path: "/es/hotel/book/:bookid",
    component: _1107e381,
    name: "hotel-book-bookid___es"
  }, {
    path: "/es/hotel/review/:hash?",
    component: _b3f3d492,
    name: "hotel-review-hash___es"
  }, {
    path: "/es/order/summary/:id",
    component: _654c3086,
    name: "order-summary-id___es"
  }, {
    path: "/es/promo/view/:slug?",
    component: _ca6a8534,
    name: "promo-view-slug___es"
  }, {
    path: "/es/reset/pass/:id",
    component: _5dd872c5,
    name: "reset-pass-id___es"
  }, {
    path: "/fi/aftersales/cancellation/:id",
    component: _1e70909a,
    name: "aftersales-cancellation-id___fi"
  }, {
    path: "/fi/aftersales/detail/:afsId",
    component: _9df1a0dc,
    name: "aftersales-detail-afsId___fi"
  }, {
    path: "/fi/flight/book/:bookid",
    component: _258d12a9,
    name: "flight-book-bookid___fi"
  }, {
    path: "/fi/hotel/book/:bookid",
    component: _1107e381,
    name: "hotel-book-bookid___fi"
  }, {
    path: "/fi/hotel/review/:hash?",
    component: _b3f3d492,
    name: "hotel-review-hash___fi"
  }, {
    path: "/fi/order/summary/:id",
    component: _654c3086,
    name: "order-summary-id___fi"
  }, {
    path: "/fi/promo/view/:slug?",
    component: _ca6a8534,
    name: "promo-view-slug___fi"
  }, {
    path: "/fi/reset/pass/:id",
    component: _5dd872c5,
    name: "reset-pass-id___fi"
  }, {
    path: "/fr/aftersales/cancellation/:id",
    component: _1e70909a,
    name: "aftersales-cancellation-id___fr"
  }, {
    path: "/fr/aftersales/detail/:afsId",
    component: _9df1a0dc,
    name: "aftersales-detail-afsId___fr"
  }, {
    path: "/fr/flight/book/:bookid",
    component: _258d12a9,
    name: "flight-book-bookid___fr"
  }, {
    path: "/fr/hotel/book/:bookid",
    component: _1107e381,
    name: "hotel-book-bookid___fr"
  }, {
    path: "/fr/hotel/review/:hash?",
    component: _b3f3d492,
    name: "hotel-review-hash___fr"
  }, {
    path: "/fr/order/summary/:id",
    component: _654c3086,
    name: "order-summary-id___fr"
  }, {
    path: "/fr/promo/view/:slug?",
    component: _ca6a8534,
    name: "promo-view-slug___fr"
  }, {
    path: "/fr/reset/pass/:id",
    component: _5dd872c5,
    name: "reset-pass-id___fr"
  }, {
    path: "/hi/aftersales/cancellation/:id",
    component: _1e70909a,
    name: "aftersales-cancellation-id___hi"
  }, {
    path: "/hi/aftersales/detail/:afsId",
    component: _9df1a0dc,
    name: "aftersales-detail-afsId___hi"
  }, {
    path: "/hi/flight/book/:bookid",
    component: _258d12a9,
    name: "flight-book-bookid___hi"
  }, {
    path: "/hi/hotel/book/:bookid",
    component: _1107e381,
    name: "hotel-book-bookid___hi"
  }, {
    path: "/hi/hotel/review/:hash?",
    component: _b3f3d492,
    name: "hotel-review-hash___hi"
  }, {
    path: "/hi/order/summary/:id",
    component: _654c3086,
    name: "order-summary-id___hi"
  }, {
    path: "/hi/promo/view/:slug?",
    component: _ca6a8534,
    name: "promo-view-slug___hi"
  }, {
    path: "/hi/reset/pass/:id",
    component: _5dd872c5,
    name: "reset-pass-id___hi"
  }, {
    path: "/hr/aftersales/cancellation/:id",
    component: _1e70909a,
    name: "aftersales-cancellation-id___hr"
  }, {
    path: "/hr/aftersales/detail/:afsId",
    component: _9df1a0dc,
    name: "aftersales-detail-afsId___hr"
  }, {
    path: "/hr/flight/book/:bookid",
    component: _258d12a9,
    name: "flight-book-bookid___hr"
  }, {
    path: "/hr/hotel/book/:bookid",
    component: _1107e381,
    name: "hotel-book-bookid___hr"
  }, {
    path: "/hr/hotel/review/:hash?",
    component: _b3f3d492,
    name: "hotel-review-hash___hr"
  }, {
    path: "/hr/order/summary/:id",
    component: _654c3086,
    name: "order-summary-id___hr"
  }, {
    path: "/hr/promo/view/:slug?",
    component: _ca6a8534,
    name: "promo-view-slug___hr"
  }, {
    path: "/hr/reset/pass/:id",
    component: _5dd872c5,
    name: "reset-pass-id___hr"
  }, {
    path: "/hu/aftersales/cancellation/:id",
    component: _1e70909a,
    name: "aftersales-cancellation-id___hu"
  }, {
    path: "/hu/aftersales/detail/:afsId",
    component: _9df1a0dc,
    name: "aftersales-detail-afsId___hu"
  }, {
    path: "/hu/flight/book/:bookid",
    component: _258d12a9,
    name: "flight-book-bookid___hu"
  }, {
    path: "/hu/hotel/book/:bookid",
    component: _1107e381,
    name: "hotel-book-bookid___hu"
  }, {
    path: "/hu/hotel/review/:hash?",
    component: _b3f3d492,
    name: "hotel-review-hash___hu"
  }, {
    path: "/hu/order/summary/:id",
    component: _654c3086,
    name: "order-summary-id___hu"
  }, {
    path: "/hu/promo/view/:slug?",
    component: _ca6a8534,
    name: "promo-view-slug___hu"
  }, {
    path: "/hu/reset/pass/:id",
    component: _5dd872c5,
    name: "reset-pass-id___hu"
  }, {
    path: "/id/aftersales/cancellation/:id",
    component: _1e70909a,
    name: "aftersales-cancellation-id___id"
  }, {
    path: "/id/aftersales/detail/:afsId",
    component: _9df1a0dc,
    name: "aftersales-detail-afsId___id"
  }, {
    path: "/id/flight/book/:bookid",
    component: _258d12a9,
    name: "flight-book-bookid___id"
  }, {
    path: "/id/hotel/book/:bookid",
    component: _1107e381,
    name: "hotel-book-bookid___id"
  }, {
    path: "/id/hotel/review/:hash?",
    component: _b3f3d492,
    name: "hotel-review-hash___id"
  }, {
    path: "/id/order/summary/:id",
    component: _654c3086,
    name: "order-summary-id___id"
  }, {
    path: "/id/promo/view/:slug?",
    component: _ca6a8534,
    name: "promo-view-slug___id"
  }, {
    path: "/id/reset/pass/:id",
    component: _5dd872c5,
    name: "reset-pass-id___id"
  }, {
    path: "/it/aftersales/cancellation/:id",
    component: _1e70909a,
    name: "aftersales-cancellation-id___it"
  }, {
    path: "/it/aftersales/detail/:afsId",
    component: _9df1a0dc,
    name: "aftersales-detail-afsId___it"
  }, {
    path: "/it/flight/book/:bookid",
    component: _258d12a9,
    name: "flight-book-bookid___it"
  }, {
    path: "/it/hotel/book/:bookid",
    component: _1107e381,
    name: "hotel-book-bookid___it"
  }, {
    path: "/it/hotel/review/:hash?",
    component: _b3f3d492,
    name: "hotel-review-hash___it"
  }, {
    path: "/it/order/summary/:id",
    component: _654c3086,
    name: "order-summary-id___it"
  }, {
    path: "/it/promo/view/:slug?",
    component: _ca6a8534,
    name: "promo-view-slug___it"
  }, {
    path: "/it/reset/pass/:id",
    component: _5dd872c5,
    name: "reset-pass-id___it"
  }, {
    path: "/ja/aftersales/cancellation/:id",
    component: _1e70909a,
    name: "aftersales-cancellation-id___ja"
  }, {
    path: "/ja/aftersales/detail/:afsId",
    component: _9df1a0dc,
    name: "aftersales-detail-afsId___ja"
  }, {
    path: "/ja/flight/book/:bookid",
    component: _258d12a9,
    name: "flight-book-bookid___ja"
  }, {
    path: "/ja/hotel/book/:bookid",
    component: _1107e381,
    name: "hotel-book-bookid___ja"
  }, {
    path: "/ja/hotel/review/:hash?",
    component: _b3f3d492,
    name: "hotel-review-hash___ja"
  }, {
    path: "/ja/order/summary/:id",
    component: _654c3086,
    name: "order-summary-id___ja"
  }, {
    path: "/ja/promo/view/:slug?",
    component: _ca6a8534,
    name: "promo-view-slug___ja"
  }, {
    path: "/ja/reset/pass/:id",
    component: _5dd872c5,
    name: "reset-pass-id___ja"
  }, {
    path: "/km/aftersales/cancellation/:id",
    component: _1e70909a,
    name: "aftersales-cancellation-id___km"
  }, {
    path: "/km/aftersales/detail/:afsId",
    component: _9df1a0dc,
    name: "aftersales-detail-afsId___km"
  }, {
    path: "/km/flight/book/:bookid",
    component: _258d12a9,
    name: "flight-book-bookid___km"
  }, {
    path: "/km/hotel/book/:bookid",
    component: _1107e381,
    name: "hotel-book-bookid___km"
  }, {
    path: "/km/hotel/review/:hash?",
    component: _b3f3d492,
    name: "hotel-review-hash___km"
  }, {
    path: "/km/order/summary/:id",
    component: _654c3086,
    name: "order-summary-id___km"
  }, {
    path: "/km/promo/view/:slug?",
    component: _ca6a8534,
    name: "promo-view-slug___km"
  }, {
    path: "/km/reset/pass/:id",
    component: _5dd872c5,
    name: "reset-pass-id___km"
  }, {
    path: "/ko/aftersales/cancellation/:id",
    component: _1e70909a,
    name: "aftersales-cancellation-id___ko"
  }, {
    path: "/ko/aftersales/detail/:afsId",
    component: _9df1a0dc,
    name: "aftersales-detail-afsId___ko"
  }, {
    path: "/ko/flight/book/:bookid",
    component: _258d12a9,
    name: "flight-book-bookid___ko"
  }, {
    path: "/ko/hotel/book/:bookid",
    component: _1107e381,
    name: "hotel-book-bookid___ko"
  }, {
    path: "/ko/hotel/review/:hash?",
    component: _b3f3d492,
    name: "hotel-review-hash___ko"
  }, {
    path: "/ko/order/summary/:id",
    component: _654c3086,
    name: "order-summary-id___ko"
  }, {
    path: "/ko/promo/view/:slug?",
    component: _ca6a8534,
    name: "promo-view-slug___ko"
  }, {
    path: "/ko/reset/pass/:id",
    component: _5dd872c5,
    name: "reset-pass-id___ko"
  }, {
    path: "/ms/aftersales/cancellation/:id",
    component: _1e70909a,
    name: "aftersales-cancellation-id___ms"
  }, {
    path: "/ms/aftersales/detail/:afsId",
    component: _9df1a0dc,
    name: "aftersales-detail-afsId___ms"
  }, {
    path: "/ms/flight/book/:bookid",
    component: _258d12a9,
    name: "flight-book-bookid___ms"
  }, {
    path: "/ms/hotel/book/:bookid",
    component: _1107e381,
    name: "hotel-book-bookid___ms"
  }, {
    path: "/ms/hotel/review/:hash?",
    component: _b3f3d492,
    name: "hotel-review-hash___ms"
  }, {
    path: "/ms/order/summary/:id",
    component: _654c3086,
    name: "order-summary-id___ms"
  }, {
    path: "/ms/promo/view/:slug?",
    component: _ca6a8534,
    name: "promo-view-slug___ms"
  }, {
    path: "/ms/reset/pass/:id",
    component: _5dd872c5,
    name: "reset-pass-id___ms"
  }, {
    path: "/nb/aftersales/cancellation/:id",
    component: _1e70909a,
    name: "aftersales-cancellation-id___nb"
  }, {
    path: "/nb/aftersales/detail/:afsId",
    component: _9df1a0dc,
    name: "aftersales-detail-afsId___nb"
  }, {
    path: "/nb/flight/book/:bookid",
    component: _258d12a9,
    name: "flight-book-bookid___nb"
  }, {
    path: "/nb/hotel/book/:bookid",
    component: _1107e381,
    name: "hotel-book-bookid___nb"
  }, {
    path: "/nb/hotel/review/:hash?",
    component: _b3f3d492,
    name: "hotel-review-hash___nb"
  }, {
    path: "/nb/order/summary/:id",
    component: _654c3086,
    name: "order-summary-id___nb"
  }, {
    path: "/nb/promo/view/:slug?",
    component: _ca6a8534,
    name: "promo-view-slug___nb"
  }, {
    path: "/nb/reset/pass/:id",
    component: _5dd872c5,
    name: "reset-pass-id___nb"
  }, {
    path: "/nl/aftersales/cancellation/:id",
    component: _1e70909a,
    name: "aftersales-cancellation-id___nl"
  }, {
    path: "/nl/aftersales/detail/:afsId",
    component: _9df1a0dc,
    name: "aftersales-detail-afsId___nl"
  }, {
    path: "/nl/flight/book/:bookid",
    component: _258d12a9,
    name: "flight-book-bookid___nl"
  }, {
    path: "/nl/hotel/book/:bookid",
    component: _1107e381,
    name: "hotel-book-bookid___nl"
  }, {
    path: "/nl/hotel/review/:hash?",
    component: _b3f3d492,
    name: "hotel-review-hash___nl"
  }, {
    path: "/nl/order/summary/:id",
    component: _654c3086,
    name: "order-summary-id___nl"
  }, {
    path: "/nl/promo/view/:slug?",
    component: _ca6a8534,
    name: "promo-view-slug___nl"
  }, {
    path: "/nl/reset/pass/:id",
    component: _5dd872c5,
    name: "reset-pass-id___nl"
  }, {
    path: "/pl/aftersales/cancellation/:id",
    component: _1e70909a,
    name: "aftersales-cancellation-id___pl"
  }, {
    path: "/pl/aftersales/detail/:afsId",
    component: _9df1a0dc,
    name: "aftersales-detail-afsId___pl"
  }, {
    path: "/pl/flight/book/:bookid",
    component: _258d12a9,
    name: "flight-book-bookid___pl"
  }, {
    path: "/pl/hotel/book/:bookid",
    component: _1107e381,
    name: "hotel-book-bookid___pl"
  }, {
    path: "/pl/hotel/review/:hash?",
    component: _b3f3d492,
    name: "hotel-review-hash___pl"
  }, {
    path: "/pl/order/summary/:id",
    component: _654c3086,
    name: "order-summary-id___pl"
  }, {
    path: "/pl/promo/view/:slug?",
    component: _ca6a8534,
    name: "promo-view-slug___pl"
  }, {
    path: "/pl/reset/pass/:id",
    component: _5dd872c5,
    name: "reset-pass-id___pl"
  }, {
    path: "/pt/aftersales/cancellation/:id",
    component: _1e70909a,
    name: "aftersales-cancellation-id___pt"
  }, {
    path: "/pt/aftersales/detail/:afsId",
    component: _9df1a0dc,
    name: "aftersales-detail-afsId___pt"
  }, {
    path: "/pt/flight/book/:bookid",
    component: _258d12a9,
    name: "flight-book-bookid___pt"
  }, {
    path: "/pt/hotel/book/:bookid",
    component: _1107e381,
    name: "hotel-book-bookid___pt"
  }, {
    path: "/pt/hotel/review/:hash?",
    component: _b3f3d492,
    name: "hotel-review-hash___pt"
  }, {
    path: "/pt/order/summary/:id",
    component: _654c3086,
    name: "order-summary-id___pt"
  }, {
    path: "/pt/promo/view/:slug?",
    component: _ca6a8534,
    name: "promo-view-slug___pt"
  }, {
    path: "/pt/reset/pass/:id",
    component: _5dd872c5,
    name: "reset-pass-id___pt"
  }, {
    path: "/ro/aftersales/cancellation/:id",
    component: _1e70909a,
    name: "aftersales-cancellation-id___ro"
  }, {
    path: "/ro/aftersales/detail/:afsId",
    component: _9df1a0dc,
    name: "aftersales-detail-afsId___ro"
  }, {
    path: "/ro/flight/book/:bookid",
    component: _258d12a9,
    name: "flight-book-bookid___ro"
  }, {
    path: "/ro/hotel/book/:bookid",
    component: _1107e381,
    name: "hotel-book-bookid___ro"
  }, {
    path: "/ro/hotel/review/:hash?",
    component: _b3f3d492,
    name: "hotel-review-hash___ro"
  }, {
    path: "/ro/order/summary/:id",
    component: _654c3086,
    name: "order-summary-id___ro"
  }, {
    path: "/ro/promo/view/:slug?",
    component: _ca6a8534,
    name: "promo-view-slug___ro"
  }, {
    path: "/ro/reset/pass/:id",
    component: _5dd872c5,
    name: "reset-pass-id___ro"
  }, {
    path: "/ru/aftersales/cancellation/:id",
    component: _1e70909a,
    name: "aftersales-cancellation-id___ru"
  }, {
    path: "/ru/aftersales/detail/:afsId",
    component: _9df1a0dc,
    name: "aftersales-detail-afsId___ru"
  }, {
    path: "/ru/flight/book/:bookid",
    component: _258d12a9,
    name: "flight-book-bookid___ru"
  }, {
    path: "/ru/hotel/book/:bookid",
    component: _1107e381,
    name: "hotel-book-bookid___ru"
  }, {
    path: "/ru/hotel/review/:hash?",
    component: _b3f3d492,
    name: "hotel-review-hash___ru"
  }, {
    path: "/ru/order/summary/:id",
    component: _654c3086,
    name: "order-summary-id___ru"
  }, {
    path: "/ru/promo/view/:slug?",
    component: _ca6a8534,
    name: "promo-view-slug___ru"
  }, {
    path: "/ru/reset/pass/:id",
    component: _5dd872c5,
    name: "reset-pass-id___ru"
  }, {
    path: "/sk/aftersales/cancellation/:id",
    component: _1e70909a,
    name: "aftersales-cancellation-id___sk"
  }, {
    path: "/sk/aftersales/detail/:afsId",
    component: _9df1a0dc,
    name: "aftersales-detail-afsId___sk"
  }, {
    path: "/sk/flight/book/:bookid",
    component: _258d12a9,
    name: "flight-book-bookid___sk"
  }, {
    path: "/sk/hotel/book/:bookid",
    component: _1107e381,
    name: "hotel-book-bookid___sk"
  }, {
    path: "/sk/hotel/review/:hash?",
    component: _b3f3d492,
    name: "hotel-review-hash___sk"
  }, {
    path: "/sk/order/summary/:id",
    component: _654c3086,
    name: "order-summary-id___sk"
  }, {
    path: "/sk/promo/view/:slug?",
    component: _ca6a8534,
    name: "promo-view-slug___sk"
  }, {
    path: "/sk/reset/pass/:id",
    component: _5dd872c5,
    name: "reset-pass-id___sk"
  }, {
    path: "/sl/aftersales/cancellation/:id",
    component: _1e70909a,
    name: "aftersales-cancellation-id___sl"
  }, {
    path: "/sl/aftersales/detail/:afsId",
    component: _9df1a0dc,
    name: "aftersales-detail-afsId___sl"
  }, {
    path: "/sl/flight/book/:bookid",
    component: _258d12a9,
    name: "flight-book-bookid___sl"
  }, {
    path: "/sl/hotel/book/:bookid",
    component: _1107e381,
    name: "hotel-book-bookid___sl"
  }, {
    path: "/sl/hotel/review/:hash?",
    component: _b3f3d492,
    name: "hotel-review-hash___sl"
  }, {
    path: "/sl/order/summary/:id",
    component: _654c3086,
    name: "order-summary-id___sl"
  }, {
    path: "/sl/promo/view/:slug?",
    component: _ca6a8534,
    name: "promo-view-slug___sl"
  }, {
    path: "/sl/reset/pass/:id",
    component: _5dd872c5,
    name: "reset-pass-id___sl"
  }, {
    path: "/sr/aftersales/cancellation/:id",
    component: _1e70909a,
    name: "aftersales-cancellation-id___sr"
  }, {
    path: "/sr/aftersales/detail/:afsId",
    component: _9df1a0dc,
    name: "aftersales-detail-afsId___sr"
  }, {
    path: "/sr/flight/book/:bookid",
    component: _258d12a9,
    name: "flight-book-bookid___sr"
  }, {
    path: "/sr/hotel/book/:bookid",
    component: _1107e381,
    name: "hotel-book-bookid___sr"
  }, {
    path: "/sr/hotel/review/:hash?",
    component: _b3f3d492,
    name: "hotel-review-hash___sr"
  }, {
    path: "/sr/order/summary/:id",
    component: _654c3086,
    name: "order-summary-id___sr"
  }, {
    path: "/sr/promo/view/:slug?",
    component: _ca6a8534,
    name: "promo-view-slug___sr"
  }, {
    path: "/sr/reset/pass/:id",
    component: _5dd872c5,
    name: "reset-pass-id___sr"
  }, {
    path: "/sv/aftersales/cancellation/:id",
    component: _1e70909a,
    name: "aftersales-cancellation-id___sv"
  }, {
    path: "/sv/aftersales/detail/:afsId",
    component: _9df1a0dc,
    name: "aftersales-detail-afsId___sv"
  }, {
    path: "/sv/flight/book/:bookid",
    component: _258d12a9,
    name: "flight-book-bookid___sv"
  }, {
    path: "/sv/hotel/book/:bookid",
    component: _1107e381,
    name: "hotel-book-bookid___sv"
  }, {
    path: "/sv/hotel/review/:hash?",
    component: _b3f3d492,
    name: "hotel-review-hash___sv"
  }, {
    path: "/sv/order/summary/:id",
    component: _654c3086,
    name: "order-summary-id___sv"
  }, {
    path: "/sv/promo/view/:slug?",
    component: _ca6a8534,
    name: "promo-view-slug___sv"
  }, {
    path: "/sv/reset/pass/:id",
    component: _5dd872c5,
    name: "reset-pass-id___sv"
  }, {
    path: "/th/aftersales/cancellation/:id",
    component: _1e70909a,
    name: "aftersales-cancellation-id___th"
  }, {
    path: "/th/aftersales/detail/:afsId",
    component: _9df1a0dc,
    name: "aftersales-detail-afsId___th"
  }, {
    path: "/th/flight/book/:bookid",
    component: _258d12a9,
    name: "flight-book-bookid___th"
  }, {
    path: "/th/hotel/book/:bookid",
    component: _1107e381,
    name: "hotel-book-bookid___th"
  }, {
    path: "/th/hotel/review/:hash?",
    component: _b3f3d492,
    name: "hotel-review-hash___th"
  }, {
    path: "/th/order/summary/:id",
    component: _654c3086,
    name: "order-summary-id___th"
  }, {
    path: "/th/promo/view/:slug?",
    component: _ca6a8534,
    name: "promo-view-slug___th"
  }, {
    path: "/th/reset/pass/:id",
    component: _5dd872c5,
    name: "reset-pass-id___th"
  }, {
    path: "/tl/aftersales/cancellation/:id",
    component: _1e70909a,
    name: "aftersales-cancellation-id___tl"
  }, {
    path: "/tl/aftersales/detail/:afsId",
    component: _9df1a0dc,
    name: "aftersales-detail-afsId___tl"
  }, {
    path: "/tl/flight/book/:bookid",
    component: _258d12a9,
    name: "flight-book-bookid___tl"
  }, {
    path: "/tl/hotel/book/:bookid",
    component: _1107e381,
    name: "hotel-book-bookid___tl"
  }, {
    path: "/tl/hotel/review/:hash?",
    component: _b3f3d492,
    name: "hotel-review-hash___tl"
  }, {
    path: "/tl/order/summary/:id",
    component: _654c3086,
    name: "order-summary-id___tl"
  }, {
    path: "/tl/promo/view/:slug?",
    component: _ca6a8534,
    name: "promo-view-slug___tl"
  }, {
    path: "/tl/reset/pass/:id",
    component: _5dd872c5,
    name: "reset-pass-id___tl"
  }, {
    path: "/tr/aftersales/cancellation/:id",
    component: _1e70909a,
    name: "aftersales-cancellation-id___tr"
  }, {
    path: "/tr/aftersales/detail/:afsId",
    component: _9df1a0dc,
    name: "aftersales-detail-afsId___tr"
  }, {
    path: "/tr/flight/book/:bookid",
    component: _258d12a9,
    name: "flight-book-bookid___tr"
  }, {
    path: "/tr/hotel/book/:bookid",
    component: _1107e381,
    name: "hotel-book-bookid___tr"
  }, {
    path: "/tr/hotel/review/:hash?",
    component: _b3f3d492,
    name: "hotel-review-hash___tr"
  }, {
    path: "/tr/order/summary/:id",
    component: _654c3086,
    name: "order-summary-id___tr"
  }, {
    path: "/tr/promo/view/:slug?",
    component: _ca6a8534,
    name: "promo-view-slug___tr"
  }, {
    path: "/tr/reset/pass/:id",
    component: _5dd872c5,
    name: "reset-pass-id___tr"
  }, {
    path: "/uk/aftersales/cancellation/:id",
    component: _1e70909a,
    name: "aftersales-cancellation-id___uk"
  }, {
    path: "/uk/aftersales/detail/:afsId",
    component: _9df1a0dc,
    name: "aftersales-detail-afsId___uk"
  }, {
    path: "/uk/flight/book/:bookid",
    component: _258d12a9,
    name: "flight-book-bookid___uk"
  }, {
    path: "/uk/hotel/book/:bookid",
    component: _1107e381,
    name: "hotel-book-bookid___uk"
  }, {
    path: "/uk/hotel/review/:hash?",
    component: _b3f3d492,
    name: "hotel-review-hash___uk"
  }, {
    path: "/uk/order/summary/:id",
    component: _654c3086,
    name: "order-summary-id___uk"
  }, {
    path: "/uk/promo/view/:slug?",
    component: _ca6a8534,
    name: "promo-view-slug___uk"
  }, {
    path: "/uk/reset/pass/:id",
    component: _5dd872c5,
    name: "reset-pass-id___uk"
  }, {
    path: "/vi/aftersales/cancellation/:id",
    component: _1e70909a,
    name: "aftersales-cancellation-id___vi"
  }, {
    path: "/vi/aftersales/detail/:afsId",
    component: _9df1a0dc,
    name: "aftersales-detail-afsId___vi"
  }, {
    path: "/vi/flight/book/:bookid",
    component: _258d12a9,
    name: "flight-book-bookid___vi"
  }, {
    path: "/vi/hotel/book/:bookid",
    component: _1107e381,
    name: "hotel-book-bookid___vi"
  }, {
    path: "/vi/hotel/review/:hash?",
    component: _b3f3d492,
    name: "hotel-review-hash___vi"
  }, {
    path: "/vi/order/summary/:id",
    component: _654c3086,
    name: "order-summary-id___vi"
  }, {
    path: "/vi/promo/view/:slug?",
    component: _ca6a8534,
    name: "promo-view-slug___vi"
  }, {
    path: "/vi/reset/pass/:id",
    component: _5dd872c5,
    name: "reset-pass-id___vi"
  }, {
    path: "/zh-tw/aftersales/cancellation/:id",
    component: _1e70909a,
    name: "aftersales-cancellation-id___zh-tw"
  }, {
    path: "/zh-tw/aftersales/detail/:afsId",
    component: _9df1a0dc,
    name: "aftersales-detail-afsId___zh-tw"
  }, {
    path: "/zh-tw/flight/book/:bookid",
    component: _258d12a9,
    name: "flight-book-bookid___zh-tw"
  }, {
    path: "/zh-tw/hotel/book/:bookid",
    component: _1107e381,
    name: "hotel-book-bookid___zh-tw"
  }, {
    path: "/zh-tw/hotel/review/:hash?",
    component: _b3f3d492,
    name: "hotel-review-hash___zh-tw"
  }, {
    path: "/zh-tw/order/summary/:id",
    component: _654c3086,
    name: "order-summary-id___zh-tw"
  }, {
    path: "/zh-tw/promo/view/:slug?",
    component: _ca6a8534,
    name: "promo-view-slug___zh-tw"
  }, {
    path: "/zh-tw/reset/pass/:id",
    component: _5dd872c5,
    name: "reset-pass-id___zh-tw"
  }, {
    path: "/zh/aftersales/cancellation/:id",
    component: _1e70909a,
    name: "aftersales-cancellation-id___zh"
  }, {
    path: "/zh/aftersales/detail/:afsId",
    component: _9df1a0dc,
    name: "aftersales-detail-afsId___zh"
  }, {
    path: "/zh/flight/book/:bookid",
    component: _258d12a9,
    name: "flight-book-bookid___zh"
  }, {
    path: "/zh/hotel/book/:bookid",
    component: _1107e381,
    name: "hotel-book-bookid___zh"
  }, {
    path: "/zh/hotel/review/:hash?",
    component: _b3f3d492,
    name: "hotel-review-hash___zh"
  }, {
    path: "/zh/order/summary/:id",
    component: _654c3086,
    name: "order-summary-id___zh"
  }, {
    path: "/zh/promo/view/:slug?",
    component: _ca6a8534,
    name: "promo-view-slug___zh"
  }, {
    path: "/zh/reset/pass/:id",
    component: _5dd872c5,
    name: "reset-pass-id___zh"
  }, {
    path: "/bg/aftersales/cancellation/:id?/document",
    component: _3699bbfa,
    name: "aftersales-cancellation-id-document___bg"
  }, {
    path: "/bg/aftersales/cancellation/:id?/flight",
    component: _03dad6d8,
    name: "aftersales-cancellation-id-flight___bg"
  }, {
    path: "/bg/hotel/book/:bookid?/detail",
    component: _af70c79c,
    name: "hotel-book-bookid-detail___bg"
  }, {
    path: "/bn/aftersales/cancellation/:id?/document",
    component: _3699bbfa,
    name: "aftersales-cancellation-id-document___bn"
  }, {
    path: "/bn/aftersales/cancellation/:id?/flight",
    component: _03dad6d8,
    name: "aftersales-cancellation-id-flight___bn"
  }, {
    path: "/bn/hotel/book/:bookid?/detail",
    component: _af70c79c,
    name: "hotel-book-bookid-detail___bn"
  }, {
    path: "/ca/aftersales/cancellation/:id?/document",
    component: _3699bbfa,
    name: "aftersales-cancellation-id-document___ca"
  }, {
    path: "/ca/aftersales/cancellation/:id?/flight",
    component: _03dad6d8,
    name: "aftersales-cancellation-id-flight___ca"
  }, {
    path: "/ca/hotel/book/:bookid?/detail",
    component: _af70c79c,
    name: "hotel-book-bookid-detail___ca"
  }, {
    path: "/cs/aftersales/cancellation/:id?/document",
    component: _3699bbfa,
    name: "aftersales-cancellation-id-document___cs"
  }, {
    path: "/cs/aftersales/cancellation/:id?/flight",
    component: _03dad6d8,
    name: "aftersales-cancellation-id-flight___cs"
  }, {
    path: "/cs/hotel/book/:bookid?/detail",
    component: _af70c79c,
    name: "hotel-book-bookid-detail___cs"
  }, {
    path: "/da/aftersales/cancellation/:id?/document",
    component: _3699bbfa,
    name: "aftersales-cancellation-id-document___da"
  }, {
    path: "/da/aftersales/cancellation/:id?/flight",
    component: _03dad6d8,
    name: "aftersales-cancellation-id-flight___da"
  }, {
    path: "/da/hotel/book/:bookid?/detail",
    component: _af70c79c,
    name: "hotel-book-bookid-detail___da"
  }, {
    path: "/de/aftersales/cancellation/:id?/document",
    component: _3699bbfa,
    name: "aftersales-cancellation-id-document___de"
  }, {
    path: "/de/aftersales/cancellation/:id?/flight",
    component: _03dad6d8,
    name: "aftersales-cancellation-id-flight___de"
  }, {
    path: "/de/hotel/book/:bookid?/detail",
    component: _af70c79c,
    name: "hotel-book-bookid-detail___de"
  }, {
    path: "/el/aftersales/cancellation/:id?/document",
    component: _3699bbfa,
    name: "aftersales-cancellation-id-document___el"
  }, {
    path: "/el/aftersales/cancellation/:id?/flight",
    component: _03dad6d8,
    name: "aftersales-cancellation-id-flight___el"
  }, {
    path: "/el/hotel/book/:bookid?/detail",
    component: _af70c79c,
    name: "hotel-book-bookid-detail___el"
  }, {
    path: "/en/aftersales/cancellation/:id?/document",
    component: _3699bbfa,
    name: "aftersales-cancellation-id-document___en"
  }, {
    path: "/en/aftersales/cancellation/:id?/flight",
    component: _03dad6d8,
    name: "aftersales-cancellation-id-flight___en"
  }, {
    path: "/en/hotel/book/:bookid?/detail",
    component: _af70c79c,
    name: "hotel-book-bookid-detail___en"
  }, {
    path: "/es/aftersales/cancellation/:id?/document",
    component: _3699bbfa,
    name: "aftersales-cancellation-id-document___es"
  }, {
    path: "/es/aftersales/cancellation/:id?/flight",
    component: _03dad6d8,
    name: "aftersales-cancellation-id-flight___es"
  }, {
    path: "/es/hotel/book/:bookid?/detail",
    component: _af70c79c,
    name: "hotel-book-bookid-detail___es"
  }, {
    path: "/fi/aftersales/cancellation/:id?/document",
    component: _3699bbfa,
    name: "aftersales-cancellation-id-document___fi"
  }, {
    path: "/fi/aftersales/cancellation/:id?/flight",
    component: _03dad6d8,
    name: "aftersales-cancellation-id-flight___fi"
  }, {
    path: "/fi/hotel/book/:bookid?/detail",
    component: _af70c79c,
    name: "hotel-book-bookid-detail___fi"
  }, {
    path: "/fr/aftersales/cancellation/:id?/document",
    component: _3699bbfa,
    name: "aftersales-cancellation-id-document___fr"
  }, {
    path: "/fr/aftersales/cancellation/:id?/flight",
    component: _03dad6d8,
    name: "aftersales-cancellation-id-flight___fr"
  }, {
    path: "/fr/hotel/book/:bookid?/detail",
    component: _af70c79c,
    name: "hotel-book-bookid-detail___fr"
  }, {
    path: "/hi/aftersales/cancellation/:id?/document",
    component: _3699bbfa,
    name: "aftersales-cancellation-id-document___hi"
  }, {
    path: "/hi/aftersales/cancellation/:id?/flight",
    component: _03dad6d8,
    name: "aftersales-cancellation-id-flight___hi"
  }, {
    path: "/hi/hotel/book/:bookid?/detail",
    component: _af70c79c,
    name: "hotel-book-bookid-detail___hi"
  }, {
    path: "/hr/aftersales/cancellation/:id?/document",
    component: _3699bbfa,
    name: "aftersales-cancellation-id-document___hr"
  }, {
    path: "/hr/aftersales/cancellation/:id?/flight",
    component: _03dad6d8,
    name: "aftersales-cancellation-id-flight___hr"
  }, {
    path: "/hr/hotel/book/:bookid?/detail",
    component: _af70c79c,
    name: "hotel-book-bookid-detail___hr"
  }, {
    path: "/hu/aftersales/cancellation/:id?/document",
    component: _3699bbfa,
    name: "aftersales-cancellation-id-document___hu"
  }, {
    path: "/hu/aftersales/cancellation/:id?/flight",
    component: _03dad6d8,
    name: "aftersales-cancellation-id-flight___hu"
  }, {
    path: "/hu/hotel/book/:bookid?/detail",
    component: _af70c79c,
    name: "hotel-book-bookid-detail___hu"
  }, {
    path: "/id/aftersales/cancellation/:id?/document",
    component: _3699bbfa,
    name: "aftersales-cancellation-id-document___id"
  }, {
    path: "/id/aftersales/cancellation/:id?/flight",
    component: _03dad6d8,
    name: "aftersales-cancellation-id-flight___id"
  }, {
    path: "/id/hotel/book/:bookid?/detail",
    component: _af70c79c,
    name: "hotel-book-bookid-detail___id"
  }, {
    path: "/it/aftersales/cancellation/:id?/document",
    component: _3699bbfa,
    name: "aftersales-cancellation-id-document___it"
  }, {
    path: "/it/aftersales/cancellation/:id?/flight",
    component: _03dad6d8,
    name: "aftersales-cancellation-id-flight___it"
  }, {
    path: "/it/hotel/book/:bookid?/detail",
    component: _af70c79c,
    name: "hotel-book-bookid-detail___it"
  }, {
    path: "/ja/aftersales/cancellation/:id?/document",
    component: _3699bbfa,
    name: "aftersales-cancellation-id-document___ja"
  }, {
    path: "/ja/aftersales/cancellation/:id?/flight",
    component: _03dad6d8,
    name: "aftersales-cancellation-id-flight___ja"
  }, {
    path: "/ja/hotel/book/:bookid?/detail",
    component: _af70c79c,
    name: "hotel-book-bookid-detail___ja"
  }, {
    path: "/km/aftersales/cancellation/:id?/document",
    component: _3699bbfa,
    name: "aftersales-cancellation-id-document___km"
  }, {
    path: "/km/aftersales/cancellation/:id?/flight",
    component: _03dad6d8,
    name: "aftersales-cancellation-id-flight___km"
  }, {
    path: "/km/hotel/book/:bookid?/detail",
    component: _af70c79c,
    name: "hotel-book-bookid-detail___km"
  }, {
    path: "/ko/aftersales/cancellation/:id?/document",
    component: _3699bbfa,
    name: "aftersales-cancellation-id-document___ko"
  }, {
    path: "/ko/aftersales/cancellation/:id?/flight",
    component: _03dad6d8,
    name: "aftersales-cancellation-id-flight___ko"
  }, {
    path: "/ko/hotel/book/:bookid?/detail",
    component: _af70c79c,
    name: "hotel-book-bookid-detail___ko"
  }, {
    path: "/ms/aftersales/cancellation/:id?/document",
    component: _3699bbfa,
    name: "aftersales-cancellation-id-document___ms"
  }, {
    path: "/ms/aftersales/cancellation/:id?/flight",
    component: _03dad6d8,
    name: "aftersales-cancellation-id-flight___ms"
  }, {
    path: "/ms/hotel/book/:bookid?/detail",
    component: _af70c79c,
    name: "hotel-book-bookid-detail___ms"
  }, {
    path: "/nb/aftersales/cancellation/:id?/document",
    component: _3699bbfa,
    name: "aftersales-cancellation-id-document___nb"
  }, {
    path: "/nb/aftersales/cancellation/:id?/flight",
    component: _03dad6d8,
    name: "aftersales-cancellation-id-flight___nb"
  }, {
    path: "/nb/hotel/book/:bookid?/detail",
    component: _af70c79c,
    name: "hotel-book-bookid-detail___nb"
  }, {
    path: "/nl/aftersales/cancellation/:id?/document",
    component: _3699bbfa,
    name: "aftersales-cancellation-id-document___nl"
  }, {
    path: "/nl/aftersales/cancellation/:id?/flight",
    component: _03dad6d8,
    name: "aftersales-cancellation-id-flight___nl"
  }, {
    path: "/nl/hotel/book/:bookid?/detail",
    component: _af70c79c,
    name: "hotel-book-bookid-detail___nl"
  }, {
    path: "/pl/aftersales/cancellation/:id?/document",
    component: _3699bbfa,
    name: "aftersales-cancellation-id-document___pl"
  }, {
    path: "/pl/aftersales/cancellation/:id?/flight",
    component: _03dad6d8,
    name: "aftersales-cancellation-id-flight___pl"
  }, {
    path: "/pl/hotel/book/:bookid?/detail",
    component: _af70c79c,
    name: "hotel-book-bookid-detail___pl"
  }, {
    path: "/pt/aftersales/cancellation/:id?/document",
    component: _3699bbfa,
    name: "aftersales-cancellation-id-document___pt"
  }, {
    path: "/pt/aftersales/cancellation/:id?/flight",
    component: _03dad6d8,
    name: "aftersales-cancellation-id-flight___pt"
  }, {
    path: "/pt/hotel/book/:bookid?/detail",
    component: _af70c79c,
    name: "hotel-book-bookid-detail___pt"
  }, {
    path: "/ro/aftersales/cancellation/:id?/document",
    component: _3699bbfa,
    name: "aftersales-cancellation-id-document___ro"
  }, {
    path: "/ro/aftersales/cancellation/:id?/flight",
    component: _03dad6d8,
    name: "aftersales-cancellation-id-flight___ro"
  }, {
    path: "/ro/hotel/book/:bookid?/detail",
    component: _af70c79c,
    name: "hotel-book-bookid-detail___ro"
  }, {
    path: "/ru/aftersales/cancellation/:id?/document",
    component: _3699bbfa,
    name: "aftersales-cancellation-id-document___ru"
  }, {
    path: "/ru/aftersales/cancellation/:id?/flight",
    component: _03dad6d8,
    name: "aftersales-cancellation-id-flight___ru"
  }, {
    path: "/ru/hotel/book/:bookid?/detail",
    component: _af70c79c,
    name: "hotel-book-bookid-detail___ru"
  }, {
    path: "/sk/aftersales/cancellation/:id?/document",
    component: _3699bbfa,
    name: "aftersales-cancellation-id-document___sk"
  }, {
    path: "/sk/aftersales/cancellation/:id?/flight",
    component: _03dad6d8,
    name: "aftersales-cancellation-id-flight___sk"
  }, {
    path: "/sk/hotel/book/:bookid?/detail",
    component: _af70c79c,
    name: "hotel-book-bookid-detail___sk"
  }, {
    path: "/sl/aftersales/cancellation/:id?/document",
    component: _3699bbfa,
    name: "aftersales-cancellation-id-document___sl"
  }, {
    path: "/sl/aftersales/cancellation/:id?/flight",
    component: _03dad6d8,
    name: "aftersales-cancellation-id-flight___sl"
  }, {
    path: "/sl/hotel/book/:bookid?/detail",
    component: _af70c79c,
    name: "hotel-book-bookid-detail___sl"
  }, {
    path: "/sr/aftersales/cancellation/:id?/document",
    component: _3699bbfa,
    name: "aftersales-cancellation-id-document___sr"
  }, {
    path: "/sr/aftersales/cancellation/:id?/flight",
    component: _03dad6d8,
    name: "aftersales-cancellation-id-flight___sr"
  }, {
    path: "/sr/hotel/book/:bookid?/detail",
    component: _af70c79c,
    name: "hotel-book-bookid-detail___sr"
  }, {
    path: "/sv/aftersales/cancellation/:id?/document",
    component: _3699bbfa,
    name: "aftersales-cancellation-id-document___sv"
  }, {
    path: "/sv/aftersales/cancellation/:id?/flight",
    component: _03dad6d8,
    name: "aftersales-cancellation-id-flight___sv"
  }, {
    path: "/sv/hotel/book/:bookid?/detail",
    component: _af70c79c,
    name: "hotel-book-bookid-detail___sv"
  }, {
    path: "/th/aftersales/cancellation/:id?/document",
    component: _3699bbfa,
    name: "aftersales-cancellation-id-document___th"
  }, {
    path: "/th/aftersales/cancellation/:id?/flight",
    component: _03dad6d8,
    name: "aftersales-cancellation-id-flight___th"
  }, {
    path: "/th/hotel/book/:bookid?/detail",
    component: _af70c79c,
    name: "hotel-book-bookid-detail___th"
  }, {
    path: "/tl/aftersales/cancellation/:id?/document",
    component: _3699bbfa,
    name: "aftersales-cancellation-id-document___tl"
  }, {
    path: "/tl/aftersales/cancellation/:id?/flight",
    component: _03dad6d8,
    name: "aftersales-cancellation-id-flight___tl"
  }, {
    path: "/tl/hotel/book/:bookid?/detail",
    component: _af70c79c,
    name: "hotel-book-bookid-detail___tl"
  }, {
    path: "/tr/aftersales/cancellation/:id?/document",
    component: _3699bbfa,
    name: "aftersales-cancellation-id-document___tr"
  }, {
    path: "/tr/aftersales/cancellation/:id?/flight",
    component: _03dad6d8,
    name: "aftersales-cancellation-id-flight___tr"
  }, {
    path: "/tr/hotel/book/:bookid?/detail",
    component: _af70c79c,
    name: "hotel-book-bookid-detail___tr"
  }, {
    path: "/uk/aftersales/cancellation/:id?/document",
    component: _3699bbfa,
    name: "aftersales-cancellation-id-document___uk"
  }, {
    path: "/uk/aftersales/cancellation/:id?/flight",
    component: _03dad6d8,
    name: "aftersales-cancellation-id-flight___uk"
  }, {
    path: "/uk/hotel/book/:bookid?/detail",
    component: _af70c79c,
    name: "hotel-book-bookid-detail___uk"
  }, {
    path: "/vi/aftersales/cancellation/:id?/document",
    component: _3699bbfa,
    name: "aftersales-cancellation-id-document___vi"
  }, {
    path: "/vi/aftersales/cancellation/:id?/flight",
    component: _03dad6d8,
    name: "aftersales-cancellation-id-flight___vi"
  }, {
    path: "/vi/hotel/book/:bookid?/detail",
    component: _af70c79c,
    name: "hotel-book-bookid-detail___vi"
  }, {
    path: "/zh-tw/aftersales/cancellation/:id?/document",
    component: _3699bbfa,
    name: "aftersales-cancellation-id-document___zh-tw"
  }, {
    path: "/zh-tw/aftersales/cancellation/:id?/flight",
    component: _03dad6d8,
    name: "aftersales-cancellation-id-flight___zh-tw"
  }, {
    path: "/zh-tw/hotel/book/:bookid?/detail",
    component: _af70c79c,
    name: "hotel-book-bookid-detail___zh-tw"
  }, {
    path: "/zh/aftersales/cancellation/:id?/document",
    component: _3699bbfa,
    name: "aftersales-cancellation-id-document___zh"
  }, {
    path: "/zh/aftersales/cancellation/:id?/flight",
    component: _03dad6d8,
    name: "aftersales-cancellation-id-flight___zh"
  }, {
    path: "/zh/hotel/book/:bookid?/detail",
    component: _af70c79c,
    name: "hotel-book-bookid-detail___zh"
  }, {
    path: "/bg/hotel/book/:bookid?/edit/contact",
    component: _1d29f93c,
    name: "hotel-book-bookid-edit-contact___bg"
  }, {
    path: "/bg/hotel/book/:bookid?/edit/guest",
    component: _7d3c37f4,
    name: "hotel-book-bookid-edit-guest___bg"
  }, {
    path: "/bg/hotel/book/:bookid?/edit/ssr",
    component: _08382c2e,
    name: "hotel-book-bookid-edit-ssr___bg"
  }, {
    path: "/bn/hotel/book/:bookid?/edit/contact",
    component: _1d29f93c,
    name: "hotel-book-bookid-edit-contact___bn"
  }, {
    path: "/bn/hotel/book/:bookid?/edit/guest",
    component: _7d3c37f4,
    name: "hotel-book-bookid-edit-guest___bn"
  }, {
    path: "/bn/hotel/book/:bookid?/edit/ssr",
    component: _08382c2e,
    name: "hotel-book-bookid-edit-ssr___bn"
  }, {
    path: "/ca/hotel/book/:bookid?/edit/contact",
    component: _1d29f93c,
    name: "hotel-book-bookid-edit-contact___ca"
  }, {
    path: "/ca/hotel/book/:bookid?/edit/guest",
    component: _7d3c37f4,
    name: "hotel-book-bookid-edit-guest___ca"
  }, {
    path: "/ca/hotel/book/:bookid?/edit/ssr",
    component: _08382c2e,
    name: "hotel-book-bookid-edit-ssr___ca"
  }, {
    path: "/cs/hotel/book/:bookid?/edit/contact",
    component: _1d29f93c,
    name: "hotel-book-bookid-edit-contact___cs"
  }, {
    path: "/cs/hotel/book/:bookid?/edit/guest",
    component: _7d3c37f4,
    name: "hotel-book-bookid-edit-guest___cs"
  }, {
    path: "/cs/hotel/book/:bookid?/edit/ssr",
    component: _08382c2e,
    name: "hotel-book-bookid-edit-ssr___cs"
  }, {
    path: "/da/hotel/book/:bookid?/edit/contact",
    component: _1d29f93c,
    name: "hotel-book-bookid-edit-contact___da"
  }, {
    path: "/da/hotel/book/:bookid?/edit/guest",
    component: _7d3c37f4,
    name: "hotel-book-bookid-edit-guest___da"
  }, {
    path: "/da/hotel/book/:bookid?/edit/ssr",
    component: _08382c2e,
    name: "hotel-book-bookid-edit-ssr___da"
  }, {
    path: "/de/hotel/book/:bookid?/edit/contact",
    component: _1d29f93c,
    name: "hotel-book-bookid-edit-contact___de"
  }, {
    path: "/de/hotel/book/:bookid?/edit/guest",
    component: _7d3c37f4,
    name: "hotel-book-bookid-edit-guest___de"
  }, {
    path: "/de/hotel/book/:bookid?/edit/ssr",
    component: _08382c2e,
    name: "hotel-book-bookid-edit-ssr___de"
  }, {
    path: "/el/hotel/book/:bookid?/edit/contact",
    component: _1d29f93c,
    name: "hotel-book-bookid-edit-contact___el"
  }, {
    path: "/el/hotel/book/:bookid?/edit/guest",
    component: _7d3c37f4,
    name: "hotel-book-bookid-edit-guest___el"
  }, {
    path: "/el/hotel/book/:bookid?/edit/ssr",
    component: _08382c2e,
    name: "hotel-book-bookid-edit-ssr___el"
  }, {
    path: "/en/hotel/book/:bookid?/edit/contact",
    component: _1d29f93c,
    name: "hotel-book-bookid-edit-contact___en"
  }, {
    path: "/en/hotel/book/:bookid?/edit/guest",
    component: _7d3c37f4,
    name: "hotel-book-bookid-edit-guest___en"
  }, {
    path: "/en/hotel/book/:bookid?/edit/ssr",
    component: _08382c2e,
    name: "hotel-book-bookid-edit-ssr___en"
  }, {
    path: "/es/hotel/book/:bookid?/edit/contact",
    component: _1d29f93c,
    name: "hotel-book-bookid-edit-contact___es"
  }, {
    path: "/es/hotel/book/:bookid?/edit/guest",
    component: _7d3c37f4,
    name: "hotel-book-bookid-edit-guest___es"
  }, {
    path: "/es/hotel/book/:bookid?/edit/ssr",
    component: _08382c2e,
    name: "hotel-book-bookid-edit-ssr___es"
  }, {
    path: "/fi/hotel/book/:bookid?/edit/contact",
    component: _1d29f93c,
    name: "hotel-book-bookid-edit-contact___fi"
  }, {
    path: "/fi/hotel/book/:bookid?/edit/guest",
    component: _7d3c37f4,
    name: "hotel-book-bookid-edit-guest___fi"
  }, {
    path: "/fi/hotel/book/:bookid?/edit/ssr",
    component: _08382c2e,
    name: "hotel-book-bookid-edit-ssr___fi"
  }, {
    path: "/fr/hotel/book/:bookid?/edit/contact",
    component: _1d29f93c,
    name: "hotel-book-bookid-edit-contact___fr"
  }, {
    path: "/fr/hotel/book/:bookid?/edit/guest",
    component: _7d3c37f4,
    name: "hotel-book-bookid-edit-guest___fr"
  }, {
    path: "/fr/hotel/book/:bookid?/edit/ssr",
    component: _08382c2e,
    name: "hotel-book-bookid-edit-ssr___fr"
  }, {
    path: "/hi/hotel/book/:bookid?/edit/contact",
    component: _1d29f93c,
    name: "hotel-book-bookid-edit-contact___hi"
  }, {
    path: "/hi/hotel/book/:bookid?/edit/guest",
    component: _7d3c37f4,
    name: "hotel-book-bookid-edit-guest___hi"
  }, {
    path: "/hi/hotel/book/:bookid?/edit/ssr",
    component: _08382c2e,
    name: "hotel-book-bookid-edit-ssr___hi"
  }, {
    path: "/hr/hotel/book/:bookid?/edit/contact",
    component: _1d29f93c,
    name: "hotel-book-bookid-edit-contact___hr"
  }, {
    path: "/hr/hotel/book/:bookid?/edit/guest",
    component: _7d3c37f4,
    name: "hotel-book-bookid-edit-guest___hr"
  }, {
    path: "/hr/hotel/book/:bookid?/edit/ssr",
    component: _08382c2e,
    name: "hotel-book-bookid-edit-ssr___hr"
  }, {
    path: "/hu/hotel/book/:bookid?/edit/contact",
    component: _1d29f93c,
    name: "hotel-book-bookid-edit-contact___hu"
  }, {
    path: "/hu/hotel/book/:bookid?/edit/guest",
    component: _7d3c37f4,
    name: "hotel-book-bookid-edit-guest___hu"
  }, {
    path: "/hu/hotel/book/:bookid?/edit/ssr",
    component: _08382c2e,
    name: "hotel-book-bookid-edit-ssr___hu"
  }, {
    path: "/id/hotel/book/:bookid?/edit/contact",
    component: _1d29f93c,
    name: "hotel-book-bookid-edit-contact___id"
  }, {
    path: "/id/hotel/book/:bookid?/edit/guest",
    component: _7d3c37f4,
    name: "hotel-book-bookid-edit-guest___id"
  }, {
    path: "/id/hotel/book/:bookid?/edit/ssr",
    component: _08382c2e,
    name: "hotel-book-bookid-edit-ssr___id"
  }, {
    path: "/it/hotel/book/:bookid?/edit/contact",
    component: _1d29f93c,
    name: "hotel-book-bookid-edit-contact___it"
  }, {
    path: "/it/hotel/book/:bookid?/edit/guest",
    component: _7d3c37f4,
    name: "hotel-book-bookid-edit-guest___it"
  }, {
    path: "/it/hotel/book/:bookid?/edit/ssr",
    component: _08382c2e,
    name: "hotel-book-bookid-edit-ssr___it"
  }, {
    path: "/ja/hotel/book/:bookid?/edit/contact",
    component: _1d29f93c,
    name: "hotel-book-bookid-edit-contact___ja"
  }, {
    path: "/ja/hotel/book/:bookid?/edit/guest",
    component: _7d3c37f4,
    name: "hotel-book-bookid-edit-guest___ja"
  }, {
    path: "/ja/hotel/book/:bookid?/edit/ssr",
    component: _08382c2e,
    name: "hotel-book-bookid-edit-ssr___ja"
  }, {
    path: "/km/hotel/book/:bookid?/edit/contact",
    component: _1d29f93c,
    name: "hotel-book-bookid-edit-contact___km"
  }, {
    path: "/km/hotel/book/:bookid?/edit/guest",
    component: _7d3c37f4,
    name: "hotel-book-bookid-edit-guest___km"
  }, {
    path: "/km/hotel/book/:bookid?/edit/ssr",
    component: _08382c2e,
    name: "hotel-book-bookid-edit-ssr___km"
  }, {
    path: "/ko/hotel/book/:bookid?/edit/contact",
    component: _1d29f93c,
    name: "hotel-book-bookid-edit-contact___ko"
  }, {
    path: "/ko/hotel/book/:bookid?/edit/guest",
    component: _7d3c37f4,
    name: "hotel-book-bookid-edit-guest___ko"
  }, {
    path: "/ko/hotel/book/:bookid?/edit/ssr",
    component: _08382c2e,
    name: "hotel-book-bookid-edit-ssr___ko"
  }, {
    path: "/ms/hotel/book/:bookid?/edit/contact",
    component: _1d29f93c,
    name: "hotel-book-bookid-edit-contact___ms"
  }, {
    path: "/ms/hotel/book/:bookid?/edit/guest",
    component: _7d3c37f4,
    name: "hotel-book-bookid-edit-guest___ms"
  }, {
    path: "/ms/hotel/book/:bookid?/edit/ssr",
    component: _08382c2e,
    name: "hotel-book-bookid-edit-ssr___ms"
  }, {
    path: "/nb/hotel/book/:bookid?/edit/contact",
    component: _1d29f93c,
    name: "hotel-book-bookid-edit-contact___nb"
  }, {
    path: "/nb/hotel/book/:bookid?/edit/guest",
    component: _7d3c37f4,
    name: "hotel-book-bookid-edit-guest___nb"
  }, {
    path: "/nb/hotel/book/:bookid?/edit/ssr",
    component: _08382c2e,
    name: "hotel-book-bookid-edit-ssr___nb"
  }, {
    path: "/nl/hotel/book/:bookid?/edit/contact",
    component: _1d29f93c,
    name: "hotel-book-bookid-edit-contact___nl"
  }, {
    path: "/nl/hotel/book/:bookid?/edit/guest",
    component: _7d3c37f4,
    name: "hotel-book-bookid-edit-guest___nl"
  }, {
    path: "/nl/hotel/book/:bookid?/edit/ssr",
    component: _08382c2e,
    name: "hotel-book-bookid-edit-ssr___nl"
  }, {
    path: "/pl/hotel/book/:bookid?/edit/contact",
    component: _1d29f93c,
    name: "hotel-book-bookid-edit-contact___pl"
  }, {
    path: "/pl/hotel/book/:bookid?/edit/guest",
    component: _7d3c37f4,
    name: "hotel-book-bookid-edit-guest___pl"
  }, {
    path: "/pl/hotel/book/:bookid?/edit/ssr",
    component: _08382c2e,
    name: "hotel-book-bookid-edit-ssr___pl"
  }, {
    path: "/pt/hotel/book/:bookid?/edit/contact",
    component: _1d29f93c,
    name: "hotel-book-bookid-edit-contact___pt"
  }, {
    path: "/pt/hotel/book/:bookid?/edit/guest",
    component: _7d3c37f4,
    name: "hotel-book-bookid-edit-guest___pt"
  }, {
    path: "/pt/hotel/book/:bookid?/edit/ssr",
    component: _08382c2e,
    name: "hotel-book-bookid-edit-ssr___pt"
  }, {
    path: "/ro/hotel/book/:bookid?/edit/contact",
    component: _1d29f93c,
    name: "hotel-book-bookid-edit-contact___ro"
  }, {
    path: "/ro/hotel/book/:bookid?/edit/guest",
    component: _7d3c37f4,
    name: "hotel-book-bookid-edit-guest___ro"
  }, {
    path: "/ro/hotel/book/:bookid?/edit/ssr",
    component: _08382c2e,
    name: "hotel-book-bookid-edit-ssr___ro"
  }, {
    path: "/ru/hotel/book/:bookid?/edit/contact",
    component: _1d29f93c,
    name: "hotel-book-bookid-edit-contact___ru"
  }, {
    path: "/ru/hotel/book/:bookid?/edit/guest",
    component: _7d3c37f4,
    name: "hotel-book-bookid-edit-guest___ru"
  }, {
    path: "/ru/hotel/book/:bookid?/edit/ssr",
    component: _08382c2e,
    name: "hotel-book-bookid-edit-ssr___ru"
  }, {
    path: "/sk/hotel/book/:bookid?/edit/contact",
    component: _1d29f93c,
    name: "hotel-book-bookid-edit-contact___sk"
  }, {
    path: "/sk/hotel/book/:bookid?/edit/guest",
    component: _7d3c37f4,
    name: "hotel-book-bookid-edit-guest___sk"
  }, {
    path: "/sk/hotel/book/:bookid?/edit/ssr",
    component: _08382c2e,
    name: "hotel-book-bookid-edit-ssr___sk"
  }, {
    path: "/sl/hotel/book/:bookid?/edit/contact",
    component: _1d29f93c,
    name: "hotel-book-bookid-edit-contact___sl"
  }, {
    path: "/sl/hotel/book/:bookid?/edit/guest",
    component: _7d3c37f4,
    name: "hotel-book-bookid-edit-guest___sl"
  }, {
    path: "/sl/hotel/book/:bookid?/edit/ssr",
    component: _08382c2e,
    name: "hotel-book-bookid-edit-ssr___sl"
  }, {
    path: "/sr/hotel/book/:bookid?/edit/contact",
    component: _1d29f93c,
    name: "hotel-book-bookid-edit-contact___sr"
  }, {
    path: "/sr/hotel/book/:bookid?/edit/guest",
    component: _7d3c37f4,
    name: "hotel-book-bookid-edit-guest___sr"
  }, {
    path: "/sr/hotel/book/:bookid?/edit/ssr",
    component: _08382c2e,
    name: "hotel-book-bookid-edit-ssr___sr"
  }, {
    path: "/sv/hotel/book/:bookid?/edit/contact",
    component: _1d29f93c,
    name: "hotel-book-bookid-edit-contact___sv"
  }, {
    path: "/sv/hotel/book/:bookid?/edit/guest",
    component: _7d3c37f4,
    name: "hotel-book-bookid-edit-guest___sv"
  }, {
    path: "/sv/hotel/book/:bookid?/edit/ssr",
    component: _08382c2e,
    name: "hotel-book-bookid-edit-ssr___sv"
  }, {
    path: "/th/hotel/book/:bookid?/edit/contact",
    component: _1d29f93c,
    name: "hotel-book-bookid-edit-contact___th"
  }, {
    path: "/th/hotel/book/:bookid?/edit/guest",
    component: _7d3c37f4,
    name: "hotel-book-bookid-edit-guest___th"
  }, {
    path: "/th/hotel/book/:bookid?/edit/ssr",
    component: _08382c2e,
    name: "hotel-book-bookid-edit-ssr___th"
  }, {
    path: "/tl/hotel/book/:bookid?/edit/contact",
    component: _1d29f93c,
    name: "hotel-book-bookid-edit-contact___tl"
  }, {
    path: "/tl/hotel/book/:bookid?/edit/guest",
    component: _7d3c37f4,
    name: "hotel-book-bookid-edit-guest___tl"
  }, {
    path: "/tl/hotel/book/:bookid?/edit/ssr",
    component: _08382c2e,
    name: "hotel-book-bookid-edit-ssr___tl"
  }, {
    path: "/tr/hotel/book/:bookid?/edit/contact",
    component: _1d29f93c,
    name: "hotel-book-bookid-edit-contact___tr"
  }, {
    path: "/tr/hotel/book/:bookid?/edit/guest",
    component: _7d3c37f4,
    name: "hotel-book-bookid-edit-guest___tr"
  }, {
    path: "/tr/hotel/book/:bookid?/edit/ssr",
    component: _08382c2e,
    name: "hotel-book-bookid-edit-ssr___tr"
  }, {
    path: "/uk/hotel/book/:bookid?/edit/contact",
    component: _1d29f93c,
    name: "hotel-book-bookid-edit-contact___uk"
  }, {
    path: "/uk/hotel/book/:bookid?/edit/guest",
    component: _7d3c37f4,
    name: "hotel-book-bookid-edit-guest___uk"
  }, {
    path: "/uk/hotel/book/:bookid?/edit/ssr",
    component: _08382c2e,
    name: "hotel-book-bookid-edit-ssr___uk"
  }, {
    path: "/vi/hotel/book/:bookid?/edit/contact",
    component: _1d29f93c,
    name: "hotel-book-bookid-edit-contact___vi"
  }, {
    path: "/vi/hotel/book/:bookid?/edit/guest",
    component: _7d3c37f4,
    name: "hotel-book-bookid-edit-guest___vi"
  }, {
    path: "/vi/hotel/book/:bookid?/edit/ssr",
    component: _08382c2e,
    name: "hotel-book-bookid-edit-ssr___vi"
  }, {
    path: "/zh-tw/hotel/book/:bookid?/edit/contact",
    component: _1d29f93c,
    name: "hotel-book-bookid-edit-contact___zh-tw"
  }, {
    path: "/zh-tw/hotel/book/:bookid?/edit/guest",
    component: _7d3c37f4,
    name: "hotel-book-bookid-edit-guest___zh-tw"
  }, {
    path: "/zh-tw/hotel/book/:bookid?/edit/ssr",
    component: _08382c2e,
    name: "hotel-book-bookid-edit-ssr___zh-tw"
  }, {
    path: "/zh/hotel/book/:bookid?/edit/contact",
    component: _1d29f93c,
    name: "hotel-book-bookid-edit-contact___zh"
  }, {
    path: "/zh/hotel/book/:bookid?/edit/guest",
    component: _7d3c37f4,
    name: "hotel-book-bookid-edit-guest___zh"
  }, {
    path: "/zh/hotel/book/:bookid?/edit/ssr",
    component: _08382c2e,
    name: "hotel-book-bookid-edit-ssr___zh"
  }, {
    path: "/bg/%E0%B8%95%E0%B8%B1%E0%B9%8B%E0%B8%A7%E0%B9%80%E0%B8%84%E0%B8%A3%E0%B8%B7%E0%B9%88%E0%B8%AD%E0%B8%87%E0%B8%9A%E0%B8%B4%E0%B8%99/:slug?",
    component: _42c65424,
    name: "ตั๋วเครื่องบิน-slug___bg"
  }, {
    path: "/bg/%E6%9C%BA%E7%A5%A8/:slug?",
    component: _2d7c6c22,
    name: "机票-slug___bg"
  }, {
    path: "/bg/%E6%A9%9F%E7%A5%A8/:slug?",
    component: _1adecdaa,
    name: "機票-slug___bg"
  }, {
    path: "/bg/%E8%88%AA%E7%A9%BA%E5%88%B8/:slug?",
    component: _d68337e6,
    name: "航空券-slug___bg"
  }, {
    path: "/bg/%ED%95%AD%EA%B3%B5%EA%B6%8C/:slug?",
    component: _07612fc9,
    name: "항공권-slug___bg"
  }, {
    path: "/bg/book/:id?",
    component: _46aa325a,
    name: "book-id___bg"
  }, {
    path: "/bg/deals/:country",
    component: _175dcd30,
    name: "deals-country___bg"
  }, {
    path: "/bg/help/:type",
    component: _4d7e2591,
    children: [{
      path: "",
      component: _07c27b54,
      name: "help-type___bg"
    }, {
      path: ":section",
      component: _ef4e78d2,
      name: "help-type-section___bg"
    }, {
      path: ":section/:case",
      component: _81b62f58,
      name: "help-type-section-case___bg"
    }]
  }, {
    path: "/bg/mga-tiket-ng-flight/:slug?",
    component: _ccc511ca,
    name: "mga-tiket-ng-flight-slug___bg"
  }, {
    path: "/bg/paymentpartner/:trxId?",
    component: _2a4b11cd,
    name: "paymentpartner-trxId___bg"
  }, {
    path: "/bg/promo/:slug",
    component: _7b13b534,
    children: [{
      path: "",
      component: _3b4884b7,
      name: "promo-slug___bg"
    }, {
      path: ":id",
      component: _45ce48df,
      name: "promo-slug-id___bg"
    }]
  }, {
    path: "/bg/support/:type",
    component: _4d7e2591,
    children: [{
      path: "",
      component: _07c27b54,
      name: "support-type___bg"
    }, {
      path: ":section",
      component: _ef4e78d2,
      name: "support-type-section___bg"
    }, {
      path: ":section/:case",
      component: _81b62f58,
      name: "support-type-section-case___bg"
    }]
  }, {
    path: "/bn/%E0%B8%95%E0%B8%B1%E0%B9%8B%E0%B8%A7%E0%B9%80%E0%B8%84%E0%B8%A3%E0%B8%B7%E0%B9%88%E0%B8%AD%E0%B8%87%E0%B8%9A%E0%B8%B4%E0%B8%99/:slug?",
    component: _42c65424,
    name: "ตั๋วเครื่องบิน-slug___bn"
  }, {
    path: "/bn/%E6%9C%BA%E7%A5%A8/:slug?",
    component: _2d7c6c22,
    name: "机票-slug___bn"
  }, {
    path: "/bn/%E6%A9%9F%E7%A5%A8/:slug?",
    component: _1adecdaa,
    name: "機票-slug___bn"
  }, {
    path: "/bn/%E8%88%AA%E7%A9%BA%E5%88%B8/:slug?",
    component: _d68337e6,
    name: "航空券-slug___bn"
  }, {
    path: "/bn/%ED%95%AD%EA%B3%B5%EA%B6%8C/:slug?",
    component: _07612fc9,
    name: "항공권-slug___bn"
  }, {
    path: "/bn/book/:id?",
    component: _46aa325a,
    name: "book-id___bn"
  }, {
    path: "/bn/deals/:country",
    component: _175dcd30,
    name: "deals-country___bn"
  }, {
    path: "/bn/help/:type",
    component: _4d7e2591,
    children: [{
      path: "",
      component: _07c27b54,
      name: "help-type___bn"
    }, {
      path: ":section",
      component: _ef4e78d2,
      name: "help-type-section___bn"
    }, {
      path: ":section/:case",
      component: _81b62f58,
      name: "help-type-section-case___bn"
    }]
  }, {
    path: "/bn/mga-tiket-ng-flight/:slug?",
    component: _ccc511ca,
    name: "mga-tiket-ng-flight-slug___bn"
  }, {
    path: "/bn/paymentpartner/:trxId?",
    component: _2a4b11cd,
    name: "paymentpartner-trxId___bn"
  }, {
    path: "/bn/promo/:slug",
    component: _7b13b534,
    children: [{
      path: "",
      component: _3b4884b7,
      name: "promo-slug___bn"
    }, {
      path: ":id",
      component: _45ce48df,
      name: "promo-slug-id___bn"
    }]
  }, {
    path: "/bn/support/:type",
    component: _4d7e2591,
    children: [{
      path: "",
      component: _07c27b54,
      name: "support-type___bn"
    }, {
      path: ":section",
      component: _ef4e78d2,
      name: "support-type-section___bn"
    }, {
      path: ":section/:case",
      component: _81b62f58,
      name: "support-type-section-case___bn"
    }]
  }, {
    path: "/ca/%E0%B8%95%E0%B8%B1%E0%B9%8B%E0%B8%A7%E0%B9%80%E0%B8%84%E0%B8%A3%E0%B8%B7%E0%B9%88%E0%B8%AD%E0%B8%87%E0%B8%9A%E0%B8%B4%E0%B8%99/:slug?",
    component: _42c65424,
    name: "ตั๋วเครื่องบิน-slug___ca"
  }, {
    path: "/ca/%E6%9C%BA%E7%A5%A8/:slug?",
    component: _2d7c6c22,
    name: "机票-slug___ca"
  }, {
    path: "/ca/%E6%A9%9F%E7%A5%A8/:slug?",
    component: _1adecdaa,
    name: "機票-slug___ca"
  }, {
    path: "/ca/%E8%88%AA%E7%A9%BA%E5%88%B8/:slug?",
    component: _d68337e6,
    name: "航空券-slug___ca"
  }, {
    path: "/ca/%ED%95%AD%EA%B3%B5%EA%B6%8C/:slug?",
    component: _07612fc9,
    name: "항공권-slug___ca"
  }, {
    path: "/ca/book/:id?",
    component: _46aa325a,
    name: "book-id___ca"
  }, {
    path: "/ca/deals/:country",
    component: _175dcd30,
    name: "deals-country___ca"
  }, {
    path: "/ca/help/:type",
    component: _4d7e2591,
    children: [{
      path: "",
      component: _07c27b54,
      name: "help-type___ca"
    }, {
      path: ":section",
      component: _ef4e78d2,
      name: "help-type-section___ca"
    }, {
      path: ":section/:case",
      component: _81b62f58,
      name: "help-type-section-case___ca"
    }]
  }, {
    path: "/ca/mga-tiket-ng-flight/:slug?",
    component: _ccc511ca,
    name: "mga-tiket-ng-flight-slug___ca"
  }, {
    path: "/ca/paymentpartner/:trxId?",
    component: _2a4b11cd,
    name: "paymentpartner-trxId___ca"
  }, {
    path: "/ca/promo/:slug",
    component: _7b13b534,
    children: [{
      path: "",
      component: _3b4884b7,
      name: "promo-slug___ca"
    }, {
      path: ":id",
      component: _45ce48df,
      name: "promo-slug-id___ca"
    }]
  }, {
    path: "/ca/support/:type",
    component: _4d7e2591,
    children: [{
      path: "",
      component: _07c27b54,
      name: "support-type___ca"
    }, {
      path: ":section",
      component: _ef4e78d2,
      name: "support-type-section___ca"
    }, {
      path: ":section/:case",
      component: _81b62f58,
      name: "support-type-section-case___ca"
    }]
  }, {
    path: "/cs/%E0%B8%95%E0%B8%B1%E0%B9%8B%E0%B8%A7%E0%B9%80%E0%B8%84%E0%B8%A3%E0%B8%B7%E0%B9%88%E0%B8%AD%E0%B8%87%E0%B8%9A%E0%B8%B4%E0%B8%99/:slug?",
    component: _42c65424,
    name: "ตั๋วเครื่องบิน-slug___cs"
  }, {
    path: "/cs/%E6%9C%BA%E7%A5%A8/:slug?",
    component: _2d7c6c22,
    name: "机票-slug___cs"
  }, {
    path: "/cs/%E6%A9%9F%E7%A5%A8/:slug?",
    component: _1adecdaa,
    name: "機票-slug___cs"
  }, {
    path: "/cs/%E8%88%AA%E7%A9%BA%E5%88%B8/:slug?",
    component: _d68337e6,
    name: "航空券-slug___cs"
  }, {
    path: "/cs/%ED%95%AD%EA%B3%B5%EA%B6%8C/:slug?",
    component: _07612fc9,
    name: "항공권-slug___cs"
  }, {
    path: "/cs/book/:id?",
    component: _46aa325a,
    name: "book-id___cs"
  }, {
    path: "/cs/deals/:country",
    component: _175dcd30,
    name: "deals-country___cs"
  }, {
    path: "/cs/help/:type",
    component: _4d7e2591,
    children: [{
      path: "",
      component: _07c27b54,
      name: "help-type___cs"
    }, {
      path: ":section",
      component: _ef4e78d2,
      name: "help-type-section___cs"
    }, {
      path: ":section/:case",
      component: _81b62f58,
      name: "help-type-section-case___cs"
    }]
  }, {
    path: "/cs/mga-tiket-ng-flight/:slug?",
    component: _ccc511ca,
    name: "mga-tiket-ng-flight-slug___cs"
  }, {
    path: "/cs/paymentpartner/:trxId?",
    component: _2a4b11cd,
    name: "paymentpartner-trxId___cs"
  }, {
    path: "/cs/promo/:slug",
    component: _7b13b534,
    children: [{
      path: "",
      component: _3b4884b7,
      name: "promo-slug___cs"
    }, {
      path: ":id",
      component: _45ce48df,
      name: "promo-slug-id___cs"
    }]
  }, {
    path: "/cs/support/:type",
    component: _4d7e2591,
    children: [{
      path: "",
      component: _07c27b54,
      name: "support-type___cs"
    }, {
      path: ":section",
      component: _ef4e78d2,
      name: "support-type-section___cs"
    }, {
      path: ":section/:case",
      component: _81b62f58,
      name: "support-type-section-case___cs"
    }]
  }, {
    path: "/da/%E0%B8%95%E0%B8%B1%E0%B9%8B%E0%B8%A7%E0%B9%80%E0%B8%84%E0%B8%A3%E0%B8%B7%E0%B9%88%E0%B8%AD%E0%B8%87%E0%B8%9A%E0%B8%B4%E0%B8%99/:slug?",
    component: _42c65424,
    name: "ตั๋วเครื่องบิน-slug___da"
  }, {
    path: "/da/%E6%9C%BA%E7%A5%A8/:slug?",
    component: _2d7c6c22,
    name: "机票-slug___da"
  }, {
    path: "/da/%E6%A9%9F%E7%A5%A8/:slug?",
    component: _1adecdaa,
    name: "機票-slug___da"
  }, {
    path: "/da/%E8%88%AA%E7%A9%BA%E5%88%B8/:slug?",
    component: _d68337e6,
    name: "航空券-slug___da"
  }, {
    path: "/da/%ED%95%AD%EA%B3%B5%EA%B6%8C/:slug?",
    component: _07612fc9,
    name: "항공권-slug___da"
  }, {
    path: "/da/book/:id?",
    component: _46aa325a,
    name: "book-id___da"
  }, {
    path: "/da/deals/:country",
    component: _175dcd30,
    name: "deals-country___da"
  }, {
    path: "/da/help/:type",
    component: _4d7e2591,
    children: [{
      path: "",
      component: _07c27b54,
      name: "help-type___da"
    }, {
      path: ":section",
      component: _ef4e78d2,
      name: "help-type-section___da"
    }, {
      path: ":section/:case",
      component: _81b62f58,
      name: "help-type-section-case___da"
    }]
  }, {
    path: "/da/mga-tiket-ng-flight/:slug?",
    component: _ccc511ca,
    name: "mga-tiket-ng-flight-slug___da"
  }, {
    path: "/da/paymentpartner/:trxId?",
    component: _2a4b11cd,
    name: "paymentpartner-trxId___da"
  }, {
    path: "/da/promo/:slug",
    component: _7b13b534,
    children: [{
      path: "",
      component: _3b4884b7,
      name: "promo-slug___da"
    }, {
      path: ":id",
      component: _45ce48df,
      name: "promo-slug-id___da"
    }]
  }, {
    path: "/da/support/:type",
    component: _4d7e2591,
    children: [{
      path: "",
      component: _07c27b54,
      name: "support-type___da"
    }, {
      path: ":section",
      component: _ef4e78d2,
      name: "support-type-section___da"
    }, {
      path: ":section/:case",
      component: _81b62f58,
      name: "support-type-section-case___da"
    }]
  }, {
    path: "/de/%E0%B8%95%E0%B8%B1%E0%B9%8B%E0%B8%A7%E0%B9%80%E0%B8%84%E0%B8%A3%E0%B8%B7%E0%B9%88%E0%B8%AD%E0%B8%87%E0%B8%9A%E0%B8%B4%E0%B8%99/:slug?",
    component: _42c65424,
    name: "ตั๋วเครื่องบิน-slug___de"
  }, {
    path: "/de/%E6%9C%BA%E7%A5%A8/:slug?",
    component: _2d7c6c22,
    name: "机票-slug___de"
  }, {
    path: "/de/%E6%A9%9F%E7%A5%A8/:slug?",
    component: _1adecdaa,
    name: "機票-slug___de"
  }, {
    path: "/de/%E8%88%AA%E7%A9%BA%E5%88%B8/:slug?",
    component: _d68337e6,
    name: "航空券-slug___de"
  }, {
    path: "/de/%ED%95%AD%EA%B3%B5%EA%B6%8C/:slug?",
    component: _07612fc9,
    name: "항공권-slug___de"
  }, {
    path: "/de/book/:id?",
    component: _46aa325a,
    name: "book-id___de"
  }, {
    path: "/de/deals/:country",
    component: _175dcd30,
    name: "deals-country___de"
  }, {
    path: "/de/help/:type",
    component: _4d7e2591,
    children: [{
      path: "",
      component: _07c27b54,
      name: "help-type___de"
    }, {
      path: ":section",
      component: _ef4e78d2,
      name: "help-type-section___de"
    }, {
      path: ":section/:case",
      component: _81b62f58,
      name: "help-type-section-case___de"
    }]
  }, {
    path: "/de/mga-tiket-ng-flight/:slug?",
    component: _ccc511ca,
    name: "mga-tiket-ng-flight-slug___de"
  }, {
    path: "/de/paymentpartner/:trxId?",
    component: _2a4b11cd,
    name: "paymentpartner-trxId___de"
  }, {
    path: "/de/promo/:slug",
    component: _7b13b534,
    children: [{
      path: "",
      component: _3b4884b7,
      name: "promo-slug___de"
    }, {
      path: ":id",
      component: _45ce48df,
      name: "promo-slug-id___de"
    }]
  }, {
    path: "/de/support/:type",
    component: _4d7e2591,
    children: [{
      path: "",
      component: _07c27b54,
      name: "support-type___de"
    }, {
      path: ":section",
      component: _ef4e78d2,
      name: "support-type-section___de"
    }, {
      path: ":section/:case",
      component: _81b62f58,
      name: "support-type-section-case___de"
    }]
  }, {
    path: "/el/%E0%B8%95%E0%B8%B1%E0%B9%8B%E0%B8%A7%E0%B9%80%E0%B8%84%E0%B8%A3%E0%B8%B7%E0%B9%88%E0%B8%AD%E0%B8%87%E0%B8%9A%E0%B8%B4%E0%B8%99/:slug?",
    component: _42c65424,
    name: "ตั๋วเครื่องบิน-slug___el"
  }, {
    path: "/el/%E6%9C%BA%E7%A5%A8/:slug?",
    component: _2d7c6c22,
    name: "机票-slug___el"
  }, {
    path: "/el/%E6%A9%9F%E7%A5%A8/:slug?",
    component: _1adecdaa,
    name: "機票-slug___el"
  }, {
    path: "/el/%E8%88%AA%E7%A9%BA%E5%88%B8/:slug?",
    component: _d68337e6,
    name: "航空券-slug___el"
  }, {
    path: "/el/%ED%95%AD%EA%B3%B5%EA%B6%8C/:slug?",
    component: _07612fc9,
    name: "항공권-slug___el"
  }, {
    path: "/el/book/:id?",
    component: _46aa325a,
    name: "book-id___el"
  }, {
    path: "/el/deals/:country",
    component: _175dcd30,
    name: "deals-country___el"
  }, {
    path: "/el/help/:type",
    component: _4d7e2591,
    children: [{
      path: "",
      component: _07c27b54,
      name: "help-type___el"
    }, {
      path: ":section",
      component: _ef4e78d2,
      name: "help-type-section___el"
    }, {
      path: ":section/:case",
      component: _81b62f58,
      name: "help-type-section-case___el"
    }]
  }, {
    path: "/el/mga-tiket-ng-flight/:slug?",
    component: _ccc511ca,
    name: "mga-tiket-ng-flight-slug___el"
  }, {
    path: "/el/paymentpartner/:trxId?",
    component: _2a4b11cd,
    name: "paymentpartner-trxId___el"
  }, {
    path: "/el/promo/:slug",
    component: _7b13b534,
    children: [{
      path: "",
      component: _3b4884b7,
      name: "promo-slug___el"
    }, {
      path: ":id",
      component: _45ce48df,
      name: "promo-slug-id___el"
    }]
  }, {
    path: "/el/support/:type",
    component: _4d7e2591,
    children: [{
      path: "",
      component: _07c27b54,
      name: "support-type___el"
    }, {
      path: ":section",
      component: _ef4e78d2,
      name: "support-type-section___el"
    }, {
      path: ":section/:case",
      component: _81b62f58,
      name: "support-type-section-case___el"
    }]
  }, {
    path: "/en/%E0%B8%95%E0%B8%B1%E0%B9%8B%E0%B8%A7%E0%B9%80%E0%B8%84%E0%B8%A3%E0%B8%B7%E0%B9%88%E0%B8%AD%E0%B8%87%E0%B8%9A%E0%B8%B4%E0%B8%99/:slug?",
    component: _42c65424,
    name: "ตั๋วเครื่องบิน-slug___en"
  }, {
    path: "/en/%E6%9C%BA%E7%A5%A8/:slug?",
    component: _2d7c6c22,
    name: "机票-slug___en"
  }, {
    path: "/en/%E6%A9%9F%E7%A5%A8/:slug?",
    component: _1adecdaa,
    name: "機票-slug___en"
  }, {
    path: "/en/%E8%88%AA%E7%A9%BA%E5%88%B8/:slug?",
    component: _d68337e6,
    name: "航空券-slug___en"
  }, {
    path: "/en/%ED%95%AD%EA%B3%B5%EA%B6%8C/:slug?",
    component: _07612fc9,
    name: "항공권-slug___en"
  }, {
    path: "/en/book/:id?",
    component: _46aa325a,
    name: "book-id___en"
  }, {
    path: "/en/deals/:country",
    component: _175dcd30,
    name: "deals-country___en"
  }, {
    path: "/en/help/:type",
    component: _4d7e2591,
    children: [{
      path: "",
      component: _07c27b54,
      name: "help-type___en"
    }, {
      path: ":section",
      component: _ef4e78d2,
      name: "help-type-section___en"
    }, {
      path: ":section/:case",
      component: _81b62f58,
      name: "help-type-section-case___en"
    }]
  }, {
    path: "/en/mga-tiket-ng-flight/:slug?",
    component: _ccc511ca,
    name: "mga-tiket-ng-flight-slug___en"
  }, {
    path: "/en/paymentpartner/:trxId?",
    component: _2a4b11cd,
    name: "paymentpartner-trxId___en"
  }, {
    path: "/en/promo/:slug",
    component: _7b13b534,
    children: [{
      path: "",
      component: _3b4884b7,
      name: "promo-slug___en"
    }, {
      path: ":id",
      component: _45ce48df,
      name: "promo-slug-id___en"
    }]
  }, {
    path: "/en/support/:type",
    component: _4d7e2591,
    children: [{
      path: "",
      component: _07c27b54,
      name: "support-type___en"
    }, {
      path: ":section",
      component: _ef4e78d2,
      name: "support-type-section___en"
    }, {
      path: ":section/:case",
      component: _81b62f58,
      name: "support-type-section-case___en"
    }]
  }, {
    path: "/es/%E0%B8%95%E0%B8%B1%E0%B9%8B%E0%B8%A7%E0%B9%80%E0%B8%84%E0%B8%A3%E0%B8%B7%E0%B9%88%E0%B8%AD%E0%B8%87%E0%B8%9A%E0%B8%B4%E0%B8%99/:slug?",
    component: _42c65424,
    name: "ตั๋วเครื่องบิน-slug___es"
  }, {
    path: "/es/%E6%9C%BA%E7%A5%A8/:slug?",
    component: _2d7c6c22,
    name: "机票-slug___es"
  }, {
    path: "/es/%E6%A9%9F%E7%A5%A8/:slug?",
    component: _1adecdaa,
    name: "機票-slug___es"
  }, {
    path: "/es/%E8%88%AA%E7%A9%BA%E5%88%B8/:slug?",
    component: _d68337e6,
    name: "航空券-slug___es"
  }, {
    path: "/es/%ED%95%AD%EA%B3%B5%EA%B6%8C/:slug?",
    component: _07612fc9,
    name: "항공권-slug___es"
  }, {
    path: "/es/book/:id?",
    component: _46aa325a,
    name: "book-id___es"
  }, {
    path: "/es/deals/:country",
    component: _175dcd30,
    name: "deals-country___es"
  }, {
    path: "/es/help/:type",
    component: _4d7e2591,
    children: [{
      path: "",
      component: _07c27b54,
      name: "help-type___es"
    }, {
      path: ":section",
      component: _ef4e78d2,
      name: "help-type-section___es"
    }, {
      path: ":section/:case",
      component: _81b62f58,
      name: "help-type-section-case___es"
    }]
  }, {
    path: "/es/mga-tiket-ng-flight/:slug?",
    component: _ccc511ca,
    name: "mga-tiket-ng-flight-slug___es"
  }, {
    path: "/es/paymentpartner/:trxId?",
    component: _2a4b11cd,
    name: "paymentpartner-trxId___es"
  }, {
    path: "/es/promo/:slug",
    component: _7b13b534,
    children: [{
      path: "",
      component: _3b4884b7,
      name: "promo-slug___es"
    }, {
      path: ":id",
      component: _45ce48df,
      name: "promo-slug-id___es"
    }]
  }, {
    path: "/es/support/:type",
    component: _4d7e2591,
    children: [{
      path: "",
      component: _07c27b54,
      name: "support-type___es"
    }, {
      path: ":section",
      component: _ef4e78d2,
      name: "support-type-section___es"
    }, {
      path: ":section/:case",
      component: _81b62f58,
      name: "support-type-section-case___es"
    }]
  }, {
    path: "/fi/%E0%B8%95%E0%B8%B1%E0%B9%8B%E0%B8%A7%E0%B9%80%E0%B8%84%E0%B8%A3%E0%B8%B7%E0%B9%88%E0%B8%AD%E0%B8%87%E0%B8%9A%E0%B8%B4%E0%B8%99/:slug?",
    component: _42c65424,
    name: "ตั๋วเครื่องบิน-slug___fi"
  }, {
    path: "/fi/%E6%9C%BA%E7%A5%A8/:slug?",
    component: _2d7c6c22,
    name: "机票-slug___fi"
  }, {
    path: "/fi/%E6%A9%9F%E7%A5%A8/:slug?",
    component: _1adecdaa,
    name: "機票-slug___fi"
  }, {
    path: "/fi/%E8%88%AA%E7%A9%BA%E5%88%B8/:slug?",
    component: _d68337e6,
    name: "航空券-slug___fi"
  }, {
    path: "/fi/%ED%95%AD%EA%B3%B5%EA%B6%8C/:slug?",
    component: _07612fc9,
    name: "항공권-slug___fi"
  }, {
    path: "/fi/book/:id?",
    component: _46aa325a,
    name: "book-id___fi"
  }, {
    path: "/fi/deals/:country",
    component: _175dcd30,
    name: "deals-country___fi"
  }, {
    path: "/fi/help/:type",
    component: _4d7e2591,
    children: [{
      path: "",
      component: _07c27b54,
      name: "help-type___fi"
    }, {
      path: ":section",
      component: _ef4e78d2,
      name: "help-type-section___fi"
    }, {
      path: ":section/:case",
      component: _81b62f58,
      name: "help-type-section-case___fi"
    }]
  }, {
    path: "/fi/mga-tiket-ng-flight/:slug?",
    component: _ccc511ca,
    name: "mga-tiket-ng-flight-slug___fi"
  }, {
    path: "/fi/paymentpartner/:trxId?",
    component: _2a4b11cd,
    name: "paymentpartner-trxId___fi"
  }, {
    path: "/fi/promo/:slug",
    component: _7b13b534,
    children: [{
      path: "",
      component: _3b4884b7,
      name: "promo-slug___fi"
    }, {
      path: ":id",
      component: _45ce48df,
      name: "promo-slug-id___fi"
    }]
  }, {
    path: "/fi/support/:type",
    component: _4d7e2591,
    children: [{
      path: "",
      component: _07c27b54,
      name: "support-type___fi"
    }, {
      path: ":section",
      component: _ef4e78d2,
      name: "support-type-section___fi"
    }, {
      path: ":section/:case",
      component: _81b62f58,
      name: "support-type-section-case___fi"
    }]
  }, {
    path: "/fr/%E0%B8%95%E0%B8%B1%E0%B9%8B%E0%B8%A7%E0%B9%80%E0%B8%84%E0%B8%A3%E0%B8%B7%E0%B9%88%E0%B8%AD%E0%B8%87%E0%B8%9A%E0%B8%B4%E0%B8%99/:slug?",
    component: _42c65424,
    name: "ตั๋วเครื่องบิน-slug___fr"
  }, {
    path: "/fr/%E6%9C%BA%E7%A5%A8/:slug?",
    component: _2d7c6c22,
    name: "机票-slug___fr"
  }, {
    path: "/fr/%E6%A9%9F%E7%A5%A8/:slug?",
    component: _1adecdaa,
    name: "機票-slug___fr"
  }, {
    path: "/fr/%E8%88%AA%E7%A9%BA%E5%88%B8/:slug?",
    component: _d68337e6,
    name: "航空券-slug___fr"
  }, {
    path: "/fr/%ED%95%AD%EA%B3%B5%EA%B6%8C/:slug?",
    component: _07612fc9,
    name: "항공권-slug___fr"
  }, {
    path: "/fr/book/:id?",
    component: _46aa325a,
    name: "book-id___fr"
  }, {
    path: "/fr/deals/:country",
    component: _175dcd30,
    name: "deals-country___fr"
  }, {
    path: "/fr/help/:type",
    component: _4d7e2591,
    children: [{
      path: "",
      component: _07c27b54,
      name: "help-type___fr"
    }, {
      path: ":section",
      component: _ef4e78d2,
      name: "help-type-section___fr"
    }, {
      path: ":section/:case",
      component: _81b62f58,
      name: "help-type-section-case___fr"
    }]
  }, {
    path: "/fr/mga-tiket-ng-flight/:slug?",
    component: _ccc511ca,
    name: "mga-tiket-ng-flight-slug___fr"
  }, {
    path: "/fr/paymentpartner/:trxId?",
    component: _2a4b11cd,
    name: "paymentpartner-trxId___fr"
  }, {
    path: "/fr/promo/:slug",
    component: _7b13b534,
    children: [{
      path: "",
      component: _3b4884b7,
      name: "promo-slug___fr"
    }, {
      path: ":id",
      component: _45ce48df,
      name: "promo-slug-id___fr"
    }]
  }, {
    path: "/fr/support/:type",
    component: _4d7e2591,
    children: [{
      path: "",
      component: _07c27b54,
      name: "support-type___fr"
    }, {
      path: ":section",
      component: _ef4e78d2,
      name: "support-type-section___fr"
    }, {
      path: ":section/:case",
      component: _81b62f58,
      name: "support-type-section-case___fr"
    }]
  }, {
    path: "/hi/%E0%B8%95%E0%B8%B1%E0%B9%8B%E0%B8%A7%E0%B9%80%E0%B8%84%E0%B8%A3%E0%B8%B7%E0%B9%88%E0%B8%AD%E0%B8%87%E0%B8%9A%E0%B8%B4%E0%B8%99/:slug?",
    component: _42c65424,
    name: "ตั๋วเครื่องบิน-slug___hi"
  }, {
    path: "/hi/%E6%9C%BA%E7%A5%A8/:slug?",
    component: _2d7c6c22,
    name: "机票-slug___hi"
  }, {
    path: "/hi/%E6%A9%9F%E7%A5%A8/:slug?",
    component: _1adecdaa,
    name: "機票-slug___hi"
  }, {
    path: "/hi/%E8%88%AA%E7%A9%BA%E5%88%B8/:slug?",
    component: _d68337e6,
    name: "航空券-slug___hi"
  }, {
    path: "/hi/%ED%95%AD%EA%B3%B5%EA%B6%8C/:slug?",
    component: _07612fc9,
    name: "항공권-slug___hi"
  }, {
    path: "/hi/book/:id?",
    component: _46aa325a,
    name: "book-id___hi"
  }, {
    path: "/hi/deals/:country",
    component: _175dcd30,
    name: "deals-country___hi"
  }, {
    path: "/hi/help/:type",
    component: _4d7e2591,
    children: [{
      path: "",
      component: _07c27b54,
      name: "help-type___hi"
    }, {
      path: ":section",
      component: _ef4e78d2,
      name: "help-type-section___hi"
    }, {
      path: ":section/:case",
      component: _81b62f58,
      name: "help-type-section-case___hi"
    }]
  }, {
    path: "/hi/mga-tiket-ng-flight/:slug?",
    component: _ccc511ca,
    name: "mga-tiket-ng-flight-slug___hi"
  }, {
    path: "/hi/paymentpartner/:trxId?",
    component: _2a4b11cd,
    name: "paymentpartner-trxId___hi"
  }, {
    path: "/hi/promo/:slug",
    component: _7b13b534,
    children: [{
      path: "",
      component: _3b4884b7,
      name: "promo-slug___hi"
    }, {
      path: ":id",
      component: _45ce48df,
      name: "promo-slug-id___hi"
    }]
  }, {
    path: "/hi/support/:type",
    component: _4d7e2591,
    children: [{
      path: "",
      component: _07c27b54,
      name: "support-type___hi"
    }, {
      path: ":section",
      component: _ef4e78d2,
      name: "support-type-section___hi"
    }, {
      path: ":section/:case",
      component: _81b62f58,
      name: "support-type-section-case___hi"
    }]
  }, {
    path: "/hr/%E0%B8%95%E0%B8%B1%E0%B9%8B%E0%B8%A7%E0%B9%80%E0%B8%84%E0%B8%A3%E0%B8%B7%E0%B9%88%E0%B8%AD%E0%B8%87%E0%B8%9A%E0%B8%B4%E0%B8%99/:slug?",
    component: _42c65424,
    name: "ตั๋วเครื่องบิน-slug___hr"
  }, {
    path: "/hr/%E6%9C%BA%E7%A5%A8/:slug?",
    component: _2d7c6c22,
    name: "机票-slug___hr"
  }, {
    path: "/hr/%E6%A9%9F%E7%A5%A8/:slug?",
    component: _1adecdaa,
    name: "機票-slug___hr"
  }, {
    path: "/hr/%E8%88%AA%E7%A9%BA%E5%88%B8/:slug?",
    component: _d68337e6,
    name: "航空券-slug___hr"
  }, {
    path: "/hr/%ED%95%AD%EA%B3%B5%EA%B6%8C/:slug?",
    component: _07612fc9,
    name: "항공권-slug___hr"
  }, {
    path: "/hr/book/:id?",
    component: _46aa325a,
    name: "book-id___hr"
  }, {
    path: "/hr/deals/:country",
    component: _175dcd30,
    name: "deals-country___hr"
  }, {
    path: "/hr/help/:type",
    component: _4d7e2591,
    children: [{
      path: "",
      component: _07c27b54,
      name: "help-type___hr"
    }, {
      path: ":section",
      component: _ef4e78d2,
      name: "help-type-section___hr"
    }, {
      path: ":section/:case",
      component: _81b62f58,
      name: "help-type-section-case___hr"
    }]
  }, {
    path: "/hr/mga-tiket-ng-flight/:slug?",
    component: _ccc511ca,
    name: "mga-tiket-ng-flight-slug___hr"
  }, {
    path: "/hr/paymentpartner/:trxId?",
    component: _2a4b11cd,
    name: "paymentpartner-trxId___hr"
  }, {
    path: "/hr/promo/:slug",
    component: _7b13b534,
    children: [{
      path: "",
      component: _3b4884b7,
      name: "promo-slug___hr"
    }, {
      path: ":id",
      component: _45ce48df,
      name: "promo-slug-id___hr"
    }]
  }, {
    path: "/hr/support/:type",
    component: _4d7e2591,
    children: [{
      path: "",
      component: _07c27b54,
      name: "support-type___hr"
    }, {
      path: ":section",
      component: _ef4e78d2,
      name: "support-type-section___hr"
    }, {
      path: ":section/:case",
      component: _81b62f58,
      name: "support-type-section-case___hr"
    }]
  }, {
    path: "/hu/%E0%B8%95%E0%B8%B1%E0%B9%8B%E0%B8%A7%E0%B9%80%E0%B8%84%E0%B8%A3%E0%B8%B7%E0%B9%88%E0%B8%AD%E0%B8%87%E0%B8%9A%E0%B8%B4%E0%B8%99/:slug?",
    component: _42c65424,
    name: "ตั๋วเครื่องบิน-slug___hu"
  }, {
    path: "/hu/%E6%9C%BA%E7%A5%A8/:slug?",
    component: _2d7c6c22,
    name: "机票-slug___hu"
  }, {
    path: "/hu/%E6%A9%9F%E7%A5%A8/:slug?",
    component: _1adecdaa,
    name: "機票-slug___hu"
  }, {
    path: "/hu/%E8%88%AA%E7%A9%BA%E5%88%B8/:slug?",
    component: _d68337e6,
    name: "航空券-slug___hu"
  }, {
    path: "/hu/%ED%95%AD%EA%B3%B5%EA%B6%8C/:slug?",
    component: _07612fc9,
    name: "항공권-slug___hu"
  }, {
    path: "/hu/book/:id?",
    component: _46aa325a,
    name: "book-id___hu"
  }, {
    path: "/hu/deals/:country",
    component: _175dcd30,
    name: "deals-country___hu"
  }, {
    path: "/hu/help/:type",
    component: _4d7e2591,
    children: [{
      path: "",
      component: _07c27b54,
      name: "help-type___hu"
    }, {
      path: ":section",
      component: _ef4e78d2,
      name: "help-type-section___hu"
    }, {
      path: ":section/:case",
      component: _81b62f58,
      name: "help-type-section-case___hu"
    }]
  }, {
    path: "/hu/mga-tiket-ng-flight/:slug?",
    component: _ccc511ca,
    name: "mga-tiket-ng-flight-slug___hu"
  }, {
    path: "/hu/paymentpartner/:trxId?",
    component: _2a4b11cd,
    name: "paymentpartner-trxId___hu"
  }, {
    path: "/hu/promo/:slug",
    component: _7b13b534,
    children: [{
      path: "",
      component: _3b4884b7,
      name: "promo-slug___hu"
    }, {
      path: ":id",
      component: _45ce48df,
      name: "promo-slug-id___hu"
    }]
  }, {
    path: "/hu/support/:type",
    component: _4d7e2591,
    children: [{
      path: "",
      component: _07c27b54,
      name: "support-type___hu"
    }, {
      path: ":section",
      component: _ef4e78d2,
      name: "support-type-section___hu"
    }, {
      path: ":section/:case",
      component: _81b62f58,
      name: "support-type-section-case___hu"
    }]
  }, {
    path: "/id/%E0%B8%95%E0%B8%B1%E0%B9%8B%E0%B8%A7%E0%B9%80%E0%B8%84%E0%B8%A3%E0%B8%B7%E0%B9%88%E0%B8%AD%E0%B8%87%E0%B8%9A%E0%B8%B4%E0%B8%99/:slug?",
    component: _42c65424,
    name: "ตั๋วเครื่องบิน-slug___id"
  }, {
    path: "/id/%E6%9C%BA%E7%A5%A8/:slug?",
    component: _2d7c6c22,
    name: "机票-slug___id"
  }, {
    path: "/id/%E6%A9%9F%E7%A5%A8/:slug?",
    component: _1adecdaa,
    name: "機票-slug___id"
  }, {
    path: "/id/%E8%88%AA%E7%A9%BA%E5%88%B8/:slug?",
    component: _d68337e6,
    name: "航空券-slug___id"
  }, {
    path: "/id/%ED%95%AD%EA%B3%B5%EA%B6%8C/:slug?",
    component: _07612fc9,
    name: "항공권-slug___id"
  }, {
    path: "/id/book/:id?",
    component: _46aa325a,
    name: "book-id___id"
  }, {
    path: "/id/deals/:country",
    component: _175dcd30,
    name: "deals-country___id"
  }, {
    path: "/id/help/:type",
    component: _4d7e2591,
    children: [{
      path: "",
      component: _07c27b54,
      name: "help-type___id"
    }, {
      path: ":section",
      component: _ef4e78d2,
      name: "help-type-section___id"
    }, {
      path: ":section/:case",
      component: _81b62f58,
      name: "help-type-section-case___id"
    }]
  }, {
    path: "/id/mga-tiket-ng-flight/:slug?",
    component: _ccc511ca,
    name: "mga-tiket-ng-flight-slug___id"
  }, {
    path: "/id/paymentpartner/:trxId?",
    component: _2a4b11cd,
    name: "paymentpartner-trxId___id"
  }, {
    path: "/id/promo/:slug",
    component: _7b13b534,
    children: [{
      path: "",
      component: _3b4884b7,
      name: "promo-slug___id"
    }, {
      path: ":id",
      component: _45ce48df,
      name: "promo-slug-id___id"
    }]
  }, {
    path: "/id/support/:type",
    component: _4d7e2591,
    children: [{
      path: "",
      component: _07c27b54,
      name: "support-type___id"
    }, {
      path: ":section",
      component: _ef4e78d2,
      name: "support-type-section___id"
    }, {
      path: ":section/:case",
      component: _81b62f58,
      name: "support-type-section-case___id"
    }]
  }, {
    path: "/it/%E0%B8%95%E0%B8%B1%E0%B9%8B%E0%B8%A7%E0%B9%80%E0%B8%84%E0%B8%A3%E0%B8%B7%E0%B9%88%E0%B8%AD%E0%B8%87%E0%B8%9A%E0%B8%B4%E0%B8%99/:slug?",
    component: _42c65424,
    name: "ตั๋วเครื่องบิน-slug___it"
  }, {
    path: "/it/%E6%9C%BA%E7%A5%A8/:slug?",
    component: _2d7c6c22,
    name: "机票-slug___it"
  }, {
    path: "/it/%E6%A9%9F%E7%A5%A8/:slug?",
    component: _1adecdaa,
    name: "機票-slug___it"
  }, {
    path: "/it/%E8%88%AA%E7%A9%BA%E5%88%B8/:slug?",
    component: _d68337e6,
    name: "航空券-slug___it"
  }, {
    path: "/it/%ED%95%AD%EA%B3%B5%EA%B6%8C/:slug?",
    component: _07612fc9,
    name: "항공권-slug___it"
  }, {
    path: "/it/book/:id?",
    component: _46aa325a,
    name: "book-id___it"
  }, {
    path: "/it/deals/:country",
    component: _175dcd30,
    name: "deals-country___it"
  }, {
    path: "/it/help/:type",
    component: _4d7e2591,
    children: [{
      path: "",
      component: _07c27b54,
      name: "help-type___it"
    }, {
      path: ":section",
      component: _ef4e78d2,
      name: "help-type-section___it"
    }, {
      path: ":section/:case",
      component: _81b62f58,
      name: "help-type-section-case___it"
    }]
  }, {
    path: "/it/mga-tiket-ng-flight/:slug?",
    component: _ccc511ca,
    name: "mga-tiket-ng-flight-slug___it"
  }, {
    path: "/it/paymentpartner/:trxId?",
    component: _2a4b11cd,
    name: "paymentpartner-trxId___it"
  }, {
    path: "/it/promo/:slug",
    component: _7b13b534,
    children: [{
      path: "",
      component: _3b4884b7,
      name: "promo-slug___it"
    }, {
      path: ":id",
      component: _45ce48df,
      name: "promo-slug-id___it"
    }]
  }, {
    path: "/it/support/:type",
    component: _4d7e2591,
    children: [{
      path: "",
      component: _07c27b54,
      name: "support-type___it"
    }, {
      path: ":section",
      component: _ef4e78d2,
      name: "support-type-section___it"
    }, {
      path: ":section/:case",
      component: _81b62f58,
      name: "support-type-section-case___it"
    }]
  }, {
    path: "/ja/%E0%B8%95%E0%B8%B1%E0%B9%8B%E0%B8%A7%E0%B9%80%E0%B8%84%E0%B8%A3%E0%B8%B7%E0%B9%88%E0%B8%AD%E0%B8%87%E0%B8%9A%E0%B8%B4%E0%B8%99/:slug?",
    component: _42c65424,
    name: "ตั๋วเครื่องบิน-slug___ja"
  }, {
    path: "/ja/%E6%9C%BA%E7%A5%A8/:slug?",
    component: _2d7c6c22,
    name: "机票-slug___ja"
  }, {
    path: "/ja/%E6%A9%9F%E7%A5%A8/:slug?",
    component: _1adecdaa,
    name: "機票-slug___ja"
  }, {
    path: "/ja/%E8%88%AA%E7%A9%BA%E5%88%B8/:slug?",
    component: _d68337e6,
    name: "航空券-slug___ja"
  }, {
    path: "/ja/%ED%95%AD%EA%B3%B5%EA%B6%8C/:slug?",
    component: _07612fc9,
    name: "항공권-slug___ja"
  }, {
    path: "/ja/book/:id?",
    component: _46aa325a,
    name: "book-id___ja"
  }, {
    path: "/ja/deals/:country",
    component: _175dcd30,
    name: "deals-country___ja"
  }, {
    path: "/ja/help/:type",
    component: _4d7e2591,
    children: [{
      path: "",
      component: _07c27b54,
      name: "help-type___ja"
    }, {
      path: ":section",
      component: _ef4e78d2,
      name: "help-type-section___ja"
    }, {
      path: ":section/:case",
      component: _81b62f58,
      name: "help-type-section-case___ja"
    }]
  }, {
    path: "/ja/mga-tiket-ng-flight/:slug?",
    component: _ccc511ca,
    name: "mga-tiket-ng-flight-slug___ja"
  }, {
    path: "/ja/paymentpartner/:trxId?",
    component: _2a4b11cd,
    name: "paymentpartner-trxId___ja"
  }, {
    path: "/ja/promo/:slug",
    component: _7b13b534,
    children: [{
      path: "",
      component: _3b4884b7,
      name: "promo-slug___ja"
    }, {
      path: ":id",
      component: _45ce48df,
      name: "promo-slug-id___ja"
    }]
  }, {
    path: "/ja/support/:type",
    component: _4d7e2591,
    children: [{
      path: "",
      component: _07c27b54,
      name: "support-type___ja"
    }, {
      path: ":section",
      component: _ef4e78d2,
      name: "support-type-section___ja"
    }, {
      path: ":section/:case",
      component: _81b62f58,
      name: "support-type-section-case___ja"
    }]
  }, {
    path: "/km/%E0%B8%95%E0%B8%B1%E0%B9%8B%E0%B8%A7%E0%B9%80%E0%B8%84%E0%B8%A3%E0%B8%B7%E0%B9%88%E0%B8%AD%E0%B8%87%E0%B8%9A%E0%B8%B4%E0%B8%99/:slug?",
    component: _42c65424,
    name: "ตั๋วเครื่องบิน-slug___km"
  }, {
    path: "/km/%E6%9C%BA%E7%A5%A8/:slug?",
    component: _2d7c6c22,
    name: "机票-slug___km"
  }, {
    path: "/km/%E6%A9%9F%E7%A5%A8/:slug?",
    component: _1adecdaa,
    name: "機票-slug___km"
  }, {
    path: "/km/%E8%88%AA%E7%A9%BA%E5%88%B8/:slug?",
    component: _d68337e6,
    name: "航空券-slug___km"
  }, {
    path: "/km/%ED%95%AD%EA%B3%B5%EA%B6%8C/:slug?",
    component: _07612fc9,
    name: "항공권-slug___km"
  }, {
    path: "/km/book/:id?",
    component: _46aa325a,
    name: "book-id___km"
  }, {
    path: "/km/deals/:country",
    component: _175dcd30,
    name: "deals-country___km"
  }, {
    path: "/km/help/:type",
    component: _4d7e2591,
    children: [{
      path: "",
      component: _07c27b54,
      name: "help-type___km"
    }, {
      path: ":section",
      component: _ef4e78d2,
      name: "help-type-section___km"
    }, {
      path: ":section/:case",
      component: _81b62f58,
      name: "help-type-section-case___km"
    }]
  }, {
    path: "/km/mga-tiket-ng-flight/:slug?",
    component: _ccc511ca,
    name: "mga-tiket-ng-flight-slug___km"
  }, {
    path: "/km/paymentpartner/:trxId?",
    component: _2a4b11cd,
    name: "paymentpartner-trxId___km"
  }, {
    path: "/km/promo/:slug",
    component: _7b13b534,
    children: [{
      path: "",
      component: _3b4884b7,
      name: "promo-slug___km"
    }, {
      path: ":id",
      component: _45ce48df,
      name: "promo-slug-id___km"
    }]
  }, {
    path: "/km/support/:type",
    component: _4d7e2591,
    children: [{
      path: "",
      component: _07c27b54,
      name: "support-type___km"
    }, {
      path: ":section",
      component: _ef4e78d2,
      name: "support-type-section___km"
    }, {
      path: ":section/:case",
      component: _81b62f58,
      name: "support-type-section-case___km"
    }]
  }, {
    path: "/ko/%E0%B8%95%E0%B8%B1%E0%B9%8B%E0%B8%A7%E0%B9%80%E0%B8%84%E0%B8%A3%E0%B8%B7%E0%B9%88%E0%B8%AD%E0%B8%87%E0%B8%9A%E0%B8%B4%E0%B8%99/:slug?",
    component: _42c65424,
    name: "ตั๋วเครื่องบิน-slug___ko"
  }, {
    path: "/ko/%E6%9C%BA%E7%A5%A8/:slug?",
    component: _2d7c6c22,
    name: "机票-slug___ko"
  }, {
    path: "/ko/%E6%A9%9F%E7%A5%A8/:slug?",
    component: _1adecdaa,
    name: "機票-slug___ko"
  }, {
    path: "/ko/%E8%88%AA%E7%A9%BA%E5%88%B8/:slug?",
    component: _d68337e6,
    name: "航空券-slug___ko"
  }, {
    path: "/ko/%ED%95%AD%EA%B3%B5%EA%B6%8C/:slug?",
    component: _07612fc9,
    name: "항공권-slug___ko"
  }, {
    path: "/ko/book/:id?",
    component: _46aa325a,
    name: "book-id___ko"
  }, {
    path: "/ko/deals/:country",
    component: _175dcd30,
    name: "deals-country___ko"
  }, {
    path: "/ko/help/:type",
    component: _4d7e2591,
    children: [{
      path: "",
      component: _07c27b54,
      name: "help-type___ko"
    }, {
      path: ":section",
      component: _ef4e78d2,
      name: "help-type-section___ko"
    }, {
      path: ":section/:case",
      component: _81b62f58,
      name: "help-type-section-case___ko"
    }]
  }, {
    path: "/ko/mga-tiket-ng-flight/:slug?",
    component: _ccc511ca,
    name: "mga-tiket-ng-flight-slug___ko"
  }, {
    path: "/ko/paymentpartner/:trxId?",
    component: _2a4b11cd,
    name: "paymentpartner-trxId___ko"
  }, {
    path: "/ko/promo/:slug",
    component: _7b13b534,
    children: [{
      path: "",
      component: _3b4884b7,
      name: "promo-slug___ko"
    }, {
      path: ":id",
      component: _45ce48df,
      name: "promo-slug-id___ko"
    }]
  }, {
    path: "/ko/support/:type",
    component: _4d7e2591,
    children: [{
      path: "",
      component: _07c27b54,
      name: "support-type___ko"
    }, {
      path: ":section",
      component: _ef4e78d2,
      name: "support-type-section___ko"
    }, {
      path: ":section/:case",
      component: _81b62f58,
      name: "support-type-section-case___ko"
    }]
  }, {
    path: "/ms/%E0%B8%95%E0%B8%B1%E0%B9%8B%E0%B8%A7%E0%B9%80%E0%B8%84%E0%B8%A3%E0%B8%B7%E0%B9%88%E0%B8%AD%E0%B8%87%E0%B8%9A%E0%B8%B4%E0%B8%99/:slug?",
    component: _42c65424,
    name: "ตั๋วเครื่องบิน-slug___ms"
  }, {
    path: "/ms/%E6%9C%BA%E7%A5%A8/:slug?",
    component: _2d7c6c22,
    name: "机票-slug___ms"
  }, {
    path: "/ms/%E6%A9%9F%E7%A5%A8/:slug?",
    component: _1adecdaa,
    name: "機票-slug___ms"
  }, {
    path: "/ms/%E8%88%AA%E7%A9%BA%E5%88%B8/:slug?",
    component: _d68337e6,
    name: "航空券-slug___ms"
  }, {
    path: "/ms/%ED%95%AD%EA%B3%B5%EA%B6%8C/:slug?",
    component: _07612fc9,
    name: "항공권-slug___ms"
  }, {
    path: "/ms/book/:id?",
    component: _46aa325a,
    name: "book-id___ms"
  }, {
    path: "/ms/deals/:country",
    component: _175dcd30,
    name: "deals-country___ms"
  }, {
    path: "/ms/help/:type",
    component: _4d7e2591,
    children: [{
      path: "",
      component: _07c27b54,
      name: "help-type___ms"
    }, {
      path: ":section",
      component: _ef4e78d2,
      name: "help-type-section___ms"
    }, {
      path: ":section/:case",
      component: _81b62f58,
      name: "help-type-section-case___ms"
    }]
  }, {
    path: "/ms/mga-tiket-ng-flight/:slug?",
    component: _ccc511ca,
    name: "mga-tiket-ng-flight-slug___ms"
  }, {
    path: "/ms/paymentpartner/:trxId?",
    component: _2a4b11cd,
    name: "paymentpartner-trxId___ms"
  }, {
    path: "/ms/promo/:slug",
    component: _7b13b534,
    children: [{
      path: "",
      component: _3b4884b7,
      name: "promo-slug___ms"
    }, {
      path: ":id",
      component: _45ce48df,
      name: "promo-slug-id___ms"
    }]
  }, {
    path: "/ms/support/:type",
    component: _4d7e2591,
    children: [{
      path: "",
      component: _07c27b54,
      name: "support-type___ms"
    }, {
      path: ":section",
      component: _ef4e78d2,
      name: "support-type-section___ms"
    }, {
      path: ":section/:case",
      component: _81b62f58,
      name: "support-type-section-case___ms"
    }]
  }, {
    path: "/nb/%E0%B8%95%E0%B8%B1%E0%B9%8B%E0%B8%A7%E0%B9%80%E0%B8%84%E0%B8%A3%E0%B8%B7%E0%B9%88%E0%B8%AD%E0%B8%87%E0%B8%9A%E0%B8%B4%E0%B8%99/:slug?",
    component: _42c65424,
    name: "ตั๋วเครื่องบิน-slug___nb"
  }, {
    path: "/nb/%E6%9C%BA%E7%A5%A8/:slug?",
    component: _2d7c6c22,
    name: "机票-slug___nb"
  }, {
    path: "/nb/%E6%A9%9F%E7%A5%A8/:slug?",
    component: _1adecdaa,
    name: "機票-slug___nb"
  }, {
    path: "/nb/%E8%88%AA%E7%A9%BA%E5%88%B8/:slug?",
    component: _d68337e6,
    name: "航空券-slug___nb"
  }, {
    path: "/nb/%ED%95%AD%EA%B3%B5%EA%B6%8C/:slug?",
    component: _07612fc9,
    name: "항공권-slug___nb"
  }, {
    path: "/nb/book/:id?",
    component: _46aa325a,
    name: "book-id___nb"
  }, {
    path: "/nb/deals/:country",
    component: _175dcd30,
    name: "deals-country___nb"
  }, {
    path: "/nb/help/:type",
    component: _4d7e2591,
    children: [{
      path: "",
      component: _07c27b54,
      name: "help-type___nb"
    }, {
      path: ":section",
      component: _ef4e78d2,
      name: "help-type-section___nb"
    }, {
      path: ":section/:case",
      component: _81b62f58,
      name: "help-type-section-case___nb"
    }]
  }, {
    path: "/nb/mga-tiket-ng-flight/:slug?",
    component: _ccc511ca,
    name: "mga-tiket-ng-flight-slug___nb"
  }, {
    path: "/nb/paymentpartner/:trxId?",
    component: _2a4b11cd,
    name: "paymentpartner-trxId___nb"
  }, {
    path: "/nb/promo/:slug",
    component: _7b13b534,
    children: [{
      path: "",
      component: _3b4884b7,
      name: "promo-slug___nb"
    }, {
      path: ":id",
      component: _45ce48df,
      name: "promo-slug-id___nb"
    }]
  }, {
    path: "/nb/support/:type",
    component: _4d7e2591,
    children: [{
      path: "",
      component: _07c27b54,
      name: "support-type___nb"
    }, {
      path: ":section",
      component: _ef4e78d2,
      name: "support-type-section___nb"
    }, {
      path: ":section/:case",
      component: _81b62f58,
      name: "support-type-section-case___nb"
    }]
  }, {
    path: "/nl/%E0%B8%95%E0%B8%B1%E0%B9%8B%E0%B8%A7%E0%B9%80%E0%B8%84%E0%B8%A3%E0%B8%B7%E0%B9%88%E0%B8%AD%E0%B8%87%E0%B8%9A%E0%B8%B4%E0%B8%99/:slug?",
    component: _42c65424,
    name: "ตั๋วเครื่องบิน-slug___nl"
  }, {
    path: "/nl/%E6%9C%BA%E7%A5%A8/:slug?",
    component: _2d7c6c22,
    name: "机票-slug___nl"
  }, {
    path: "/nl/%E6%A9%9F%E7%A5%A8/:slug?",
    component: _1adecdaa,
    name: "機票-slug___nl"
  }, {
    path: "/nl/%E8%88%AA%E7%A9%BA%E5%88%B8/:slug?",
    component: _d68337e6,
    name: "航空券-slug___nl"
  }, {
    path: "/nl/%ED%95%AD%EA%B3%B5%EA%B6%8C/:slug?",
    component: _07612fc9,
    name: "항공권-slug___nl"
  }, {
    path: "/nl/book/:id?",
    component: _46aa325a,
    name: "book-id___nl"
  }, {
    path: "/nl/deals/:country",
    component: _175dcd30,
    name: "deals-country___nl"
  }, {
    path: "/nl/help/:type",
    component: _4d7e2591,
    children: [{
      path: "",
      component: _07c27b54,
      name: "help-type___nl"
    }, {
      path: ":section",
      component: _ef4e78d2,
      name: "help-type-section___nl"
    }, {
      path: ":section/:case",
      component: _81b62f58,
      name: "help-type-section-case___nl"
    }]
  }, {
    path: "/nl/mga-tiket-ng-flight/:slug?",
    component: _ccc511ca,
    name: "mga-tiket-ng-flight-slug___nl"
  }, {
    path: "/nl/paymentpartner/:trxId?",
    component: _2a4b11cd,
    name: "paymentpartner-trxId___nl"
  }, {
    path: "/nl/promo/:slug",
    component: _7b13b534,
    children: [{
      path: "",
      component: _3b4884b7,
      name: "promo-slug___nl"
    }, {
      path: ":id",
      component: _45ce48df,
      name: "promo-slug-id___nl"
    }]
  }, {
    path: "/nl/support/:type",
    component: _4d7e2591,
    children: [{
      path: "",
      component: _07c27b54,
      name: "support-type___nl"
    }, {
      path: ":section",
      component: _ef4e78d2,
      name: "support-type-section___nl"
    }, {
      path: ":section/:case",
      component: _81b62f58,
      name: "support-type-section-case___nl"
    }]
  }, {
    path: "/pl/%E0%B8%95%E0%B8%B1%E0%B9%8B%E0%B8%A7%E0%B9%80%E0%B8%84%E0%B8%A3%E0%B8%B7%E0%B9%88%E0%B8%AD%E0%B8%87%E0%B8%9A%E0%B8%B4%E0%B8%99/:slug?",
    component: _42c65424,
    name: "ตั๋วเครื่องบิน-slug___pl"
  }, {
    path: "/pl/%E6%9C%BA%E7%A5%A8/:slug?",
    component: _2d7c6c22,
    name: "机票-slug___pl"
  }, {
    path: "/pl/%E6%A9%9F%E7%A5%A8/:slug?",
    component: _1adecdaa,
    name: "機票-slug___pl"
  }, {
    path: "/pl/%E8%88%AA%E7%A9%BA%E5%88%B8/:slug?",
    component: _d68337e6,
    name: "航空券-slug___pl"
  }, {
    path: "/pl/%ED%95%AD%EA%B3%B5%EA%B6%8C/:slug?",
    component: _07612fc9,
    name: "항공권-slug___pl"
  }, {
    path: "/pl/book/:id?",
    component: _46aa325a,
    name: "book-id___pl"
  }, {
    path: "/pl/deals/:country",
    component: _175dcd30,
    name: "deals-country___pl"
  }, {
    path: "/pl/help/:type",
    component: _4d7e2591,
    children: [{
      path: "",
      component: _07c27b54,
      name: "help-type___pl"
    }, {
      path: ":section",
      component: _ef4e78d2,
      name: "help-type-section___pl"
    }, {
      path: ":section/:case",
      component: _81b62f58,
      name: "help-type-section-case___pl"
    }]
  }, {
    path: "/pl/mga-tiket-ng-flight/:slug?",
    component: _ccc511ca,
    name: "mga-tiket-ng-flight-slug___pl"
  }, {
    path: "/pl/paymentpartner/:trxId?",
    component: _2a4b11cd,
    name: "paymentpartner-trxId___pl"
  }, {
    path: "/pl/promo/:slug",
    component: _7b13b534,
    children: [{
      path: "",
      component: _3b4884b7,
      name: "promo-slug___pl"
    }, {
      path: ":id",
      component: _45ce48df,
      name: "promo-slug-id___pl"
    }]
  }, {
    path: "/pl/support/:type",
    component: _4d7e2591,
    children: [{
      path: "",
      component: _07c27b54,
      name: "support-type___pl"
    }, {
      path: ":section",
      component: _ef4e78d2,
      name: "support-type-section___pl"
    }, {
      path: ":section/:case",
      component: _81b62f58,
      name: "support-type-section-case___pl"
    }]
  }, {
    path: "/pt/%E0%B8%95%E0%B8%B1%E0%B9%8B%E0%B8%A7%E0%B9%80%E0%B8%84%E0%B8%A3%E0%B8%B7%E0%B9%88%E0%B8%AD%E0%B8%87%E0%B8%9A%E0%B8%B4%E0%B8%99/:slug?",
    component: _42c65424,
    name: "ตั๋วเครื่องบิน-slug___pt"
  }, {
    path: "/pt/%E6%9C%BA%E7%A5%A8/:slug?",
    component: _2d7c6c22,
    name: "机票-slug___pt"
  }, {
    path: "/pt/%E6%A9%9F%E7%A5%A8/:slug?",
    component: _1adecdaa,
    name: "機票-slug___pt"
  }, {
    path: "/pt/%E8%88%AA%E7%A9%BA%E5%88%B8/:slug?",
    component: _d68337e6,
    name: "航空券-slug___pt"
  }, {
    path: "/pt/%ED%95%AD%EA%B3%B5%EA%B6%8C/:slug?",
    component: _07612fc9,
    name: "항공권-slug___pt"
  }, {
    path: "/pt/book/:id?",
    component: _46aa325a,
    name: "book-id___pt"
  }, {
    path: "/pt/deals/:country",
    component: _175dcd30,
    name: "deals-country___pt"
  }, {
    path: "/pt/help/:type",
    component: _4d7e2591,
    children: [{
      path: "",
      component: _07c27b54,
      name: "help-type___pt"
    }, {
      path: ":section",
      component: _ef4e78d2,
      name: "help-type-section___pt"
    }, {
      path: ":section/:case",
      component: _81b62f58,
      name: "help-type-section-case___pt"
    }]
  }, {
    path: "/pt/mga-tiket-ng-flight/:slug?",
    component: _ccc511ca,
    name: "mga-tiket-ng-flight-slug___pt"
  }, {
    path: "/pt/paymentpartner/:trxId?",
    component: _2a4b11cd,
    name: "paymentpartner-trxId___pt"
  }, {
    path: "/pt/promo/:slug",
    component: _7b13b534,
    children: [{
      path: "",
      component: _3b4884b7,
      name: "promo-slug___pt"
    }, {
      path: ":id",
      component: _45ce48df,
      name: "promo-slug-id___pt"
    }]
  }, {
    path: "/pt/support/:type",
    component: _4d7e2591,
    children: [{
      path: "",
      component: _07c27b54,
      name: "support-type___pt"
    }, {
      path: ":section",
      component: _ef4e78d2,
      name: "support-type-section___pt"
    }, {
      path: ":section/:case",
      component: _81b62f58,
      name: "support-type-section-case___pt"
    }]
  }, {
    path: "/redirect/dpl/:code?",
    component: _368c0b30,
    name: "redirect-dpl-code"
  }, {
    path: "/ro/%E0%B8%95%E0%B8%B1%E0%B9%8B%E0%B8%A7%E0%B9%80%E0%B8%84%E0%B8%A3%E0%B8%B7%E0%B9%88%E0%B8%AD%E0%B8%87%E0%B8%9A%E0%B8%B4%E0%B8%99/:slug?",
    component: _42c65424,
    name: "ตั๋วเครื่องบิน-slug___ro"
  }, {
    path: "/ro/%E6%9C%BA%E7%A5%A8/:slug?",
    component: _2d7c6c22,
    name: "机票-slug___ro"
  }, {
    path: "/ro/%E6%A9%9F%E7%A5%A8/:slug?",
    component: _1adecdaa,
    name: "機票-slug___ro"
  }, {
    path: "/ro/%E8%88%AA%E7%A9%BA%E5%88%B8/:slug?",
    component: _d68337e6,
    name: "航空券-slug___ro"
  }, {
    path: "/ro/%ED%95%AD%EA%B3%B5%EA%B6%8C/:slug?",
    component: _07612fc9,
    name: "항공권-slug___ro"
  }, {
    path: "/ro/book/:id?",
    component: _46aa325a,
    name: "book-id___ro"
  }, {
    path: "/ro/deals/:country",
    component: _175dcd30,
    name: "deals-country___ro"
  }, {
    path: "/ro/help/:type",
    component: _4d7e2591,
    children: [{
      path: "",
      component: _07c27b54,
      name: "help-type___ro"
    }, {
      path: ":section",
      component: _ef4e78d2,
      name: "help-type-section___ro"
    }, {
      path: ":section/:case",
      component: _81b62f58,
      name: "help-type-section-case___ro"
    }]
  }, {
    path: "/ro/mga-tiket-ng-flight/:slug?",
    component: _ccc511ca,
    name: "mga-tiket-ng-flight-slug___ro"
  }, {
    path: "/ro/paymentpartner/:trxId?",
    component: _2a4b11cd,
    name: "paymentpartner-trxId___ro"
  }, {
    path: "/ro/promo/:slug",
    component: _7b13b534,
    children: [{
      path: "",
      component: _3b4884b7,
      name: "promo-slug___ro"
    }, {
      path: ":id",
      component: _45ce48df,
      name: "promo-slug-id___ro"
    }]
  }, {
    path: "/ro/support/:type",
    component: _4d7e2591,
    children: [{
      path: "",
      component: _07c27b54,
      name: "support-type___ro"
    }, {
      path: ":section",
      component: _ef4e78d2,
      name: "support-type-section___ro"
    }, {
      path: ":section/:case",
      component: _81b62f58,
      name: "support-type-section-case___ro"
    }]
  }, {
    path: "/ru/%E0%B8%95%E0%B8%B1%E0%B9%8B%E0%B8%A7%E0%B9%80%E0%B8%84%E0%B8%A3%E0%B8%B7%E0%B9%88%E0%B8%AD%E0%B8%87%E0%B8%9A%E0%B8%B4%E0%B8%99/:slug?",
    component: _42c65424,
    name: "ตั๋วเครื่องบิน-slug___ru"
  }, {
    path: "/ru/%E6%9C%BA%E7%A5%A8/:slug?",
    component: _2d7c6c22,
    name: "机票-slug___ru"
  }, {
    path: "/ru/%E6%A9%9F%E7%A5%A8/:slug?",
    component: _1adecdaa,
    name: "機票-slug___ru"
  }, {
    path: "/ru/%E8%88%AA%E7%A9%BA%E5%88%B8/:slug?",
    component: _d68337e6,
    name: "航空券-slug___ru"
  }, {
    path: "/ru/%ED%95%AD%EA%B3%B5%EA%B6%8C/:slug?",
    component: _07612fc9,
    name: "항공권-slug___ru"
  }, {
    path: "/ru/book/:id?",
    component: _46aa325a,
    name: "book-id___ru"
  }, {
    path: "/ru/deals/:country",
    component: _175dcd30,
    name: "deals-country___ru"
  }, {
    path: "/ru/help/:type",
    component: _4d7e2591,
    children: [{
      path: "",
      component: _07c27b54,
      name: "help-type___ru"
    }, {
      path: ":section",
      component: _ef4e78d2,
      name: "help-type-section___ru"
    }, {
      path: ":section/:case",
      component: _81b62f58,
      name: "help-type-section-case___ru"
    }]
  }, {
    path: "/ru/mga-tiket-ng-flight/:slug?",
    component: _ccc511ca,
    name: "mga-tiket-ng-flight-slug___ru"
  }, {
    path: "/ru/paymentpartner/:trxId?",
    component: _2a4b11cd,
    name: "paymentpartner-trxId___ru"
  }, {
    path: "/ru/promo/:slug",
    component: _7b13b534,
    children: [{
      path: "",
      component: _3b4884b7,
      name: "promo-slug___ru"
    }, {
      path: ":id",
      component: _45ce48df,
      name: "promo-slug-id___ru"
    }]
  }, {
    path: "/ru/support/:type",
    component: _4d7e2591,
    children: [{
      path: "",
      component: _07c27b54,
      name: "support-type___ru"
    }, {
      path: ":section",
      component: _ef4e78d2,
      name: "support-type-section___ru"
    }, {
      path: ":section/:case",
      component: _81b62f58,
      name: "support-type-section-case___ru"
    }]
  }, {
    path: "/sk/%E0%B8%95%E0%B8%B1%E0%B9%8B%E0%B8%A7%E0%B9%80%E0%B8%84%E0%B8%A3%E0%B8%B7%E0%B9%88%E0%B8%AD%E0%B8%87%E0%B8%9A%E0%B8%B4%E0%B8%99/:slug?",
    component: _42c65424,
    name: "ตั๋วเครื่องบิน-slug___sk"
  }, {
    path: "/sk/%E6%9C%BA%E7%A5%A8/:slug?",
    component: _2d7c6c22,
    name: "机票-slug___sk"
  }, {
    path: "/sk/%E6%A9%9F%E7%A5%A8/:slug?",
    component: _1adecdaa,
    name: "機票-slug___sk"
  }, {
    path: "/sk/%E8%88%AA%E7%A9%BA%E5%88%B8/:slug?",
    component: _d68337e6,
    name: "航空券-slug___sk"
  }, {
    path: "/sk/%ED%95%AD%EA%B3%B5%EA%B6%8C/:slug?",
    component: _07612fc9,
    name: "항공권-slug___sk"
  }, {
    path: "/sk/book/:id?",
    component: _46aa325a,
    name: "book-id___sk"
  }, {
    path: "/sk/deals/:country",
    component: _175dcd30,
    name: "deals-country___sk"
  }, {
    path: "/sk/help/:type",
    component: _4d7e2591,
    children: [{
      path: "",
      component: _07c27b54,
      name: "help-type___sk"
    }, {
      path: ":section",
      component: _ef4e78d2,
      name: "help-type-section___sk"
    }, {
      path: ":section/:case",
      component: _81b62f58,
      name: "help-type-section-case___sk"
    }]
  }, {
    path: "/sk/mga-tiket-ng-flight/:slug?",
    component: _ccc511ca,
    name: "mga-tiket-ng-flight-slug___sk"
  }, {
    path: "/sk/paymentpartner/:trxId?",
    component: _2a4b11cd,
    name: "paymentpartner-trxId___sk"
  }, {
    path: "/sk/promo/:slug",
    component: _7b13b534,
    children: [{
      path: "",
      component: _3b4884b7,
      name: "promo-slug___sk"
    }, {
      path: ":id",
      component: _45ce48df,
      name: "promo-slug-id___sk"
    }]
  }, {
    path: "/sk/support/:type",
    component: _4d7e2591,
    children: [{
      path: "",
      component: _07c27b54,
      name: "support-type___sk"
    }, {
      path: ":section",
      component: _ef4e78d2,
      name: "support-type-section___sk"
    }, {
      path: ":section/:case",
      component: _81b62f58,
      name: "support-type-section-case___sk"
    }]
  }, {
    path: "/sl/%E0%B8%95%E0%B8%B1%E0%B9%8B%E0%B8%A7%E0%B9%80%E0%B8%84%E0%B8%A3%E0%B8%B7%E0%B9%88%E0%B8%AD%E0%B8%87%E0%B8%9A%E0%B8%B4%E0%B8%99/:slug?",
    component: _42c65424,
    name: "ตั๋วเครื่องบิน-slug___sl"
  }, {
    path: "/sl/%E6%9C%BA%E7%A5%A8/:slug?",
    component: _2d7c6c22,
    name: "机票-slug___sl"
  }, {
    path: "/sl/%E6%A9%9F%E7%A5%A8/:slug?",
    component: _1adecdaa,
    name: "機票-slug___sl"
  }, {
    path: "/sl/%E8%88%AA%E7%A9%BA%E5%88%B8/:slug?",
    component: _d68337e6,
    name: "航空券-slug___sl"
  }, {
    path: "/sl/%ED%95%AD%EA%B3%B5%EA%B6%8C/:slug?",
    component: _07612fc9,
    name: "항공권-slug___sl"
  }, {
    path: "/sl/book/:id?",
    component: _46aa325a,
    name: "book-id___sl"
  }, {
    path: "/sl/deals/:country",
    component: _175dcd30,
    name: "deals-country___sl"
  }, {
    path: "/sl/help/:type",
    component: _4d7e2591,
    children: [{
      path: "",
      component: _07c27b54,
      name: "help-type___sl"
    }, {
      path: ":section",
      component: _ef4e78d2,
      name: "help-type-section___sl"
    }, {
      path: ":section/:case",
      component: _81b62f58,
      name: "help-type-section-case___sl"
    }]
  }, {
    path: "/sl/mga-tiket-ng-flight/:slug?",
    component: _ccc511ca,
    name: "mga-tiket-ng-flight-slug___sl"
  }, {
    path: "/sl/paymentpartner/:trxId?",
    component: _2a4b11cd,
    name: "paymentpartner-trxId___sl"
  }, {
    path: "/sl/promo/:slug",
    component: _7b13b534,
    children: [{
      path: "",
      component: _3b4884b7,
      name: "promo-slug___sl"
    }, {
      path: ":id",
      component: _45ce48df,
      name: "promo-slug-id___sl"
    }]
  }, {
    path: "/sl/support/:type",
    component: _4d7e2591,
    children: [{
      path: "",
      component: _07c27b54,
      name: "support-type___sl"
    }, {
      path: ":section",
      component: _ef4e78d2,
      name: "support-type-section___sl"
    }, {
      path: ":section/:case",
      component: _81b62f58,
      name: "support-type-section-case___sl"
    }]
  }, {
    path: "/sr/%E0%B8%95%E0%B8%B1%E0%B9%8B%E0%B8%A7%E0%B9%80%E0%B8%84%E0%B8%A3%E0%B8%B7%E0%B9%88%E0%B8%AD%E0%B8%87%E0%B8%9A%E0%B8%B4%E0%B8%99/:slug?",
    component: _42c65424,
    name: "ตั๋วเครื่องบิน-slug___sr"
  }, {
    path: "/sr/%E6%9C%BA%E7%A5%A8/:slug?",
    component: _2d7c6c22,
    name: "机票-slug___sr"
  }, {
    path: "/sr/%E6%A9%9F%E7%A5%A8/:slug?",
    component: _1adecdaa,
    name: "機票-slug___sr"
  }, {
    path: "/sr/%E8%88%AA%E7%A9%BA%E5%88%B8/:slug?",
    component: _d68337e6,
    name: "航空券-slug___sr"
  }, {
    path: "/sr/%ED%95%AD%EA%B3%B5%EA%B6%8C/:slug?",
    component: _07612fc9,
    name: "항공권-slug___sr"
  }, {
    path: "/sr/book/:id?",
    component: _46aa325a,
    name: "book-id___sr"
  }, {
    path: "/sr/deals/:country",
    component: _175dcd30,
    name: "deals-country___sr"
  }, {
    path: "/sr/help/:type",
    component: _4d7e2591,
    children: [{
      path: "",
      component: _07c27b54,
      name: "help-type___sr"
    }, {
      path: ":section",
      component: _ef4e78d2,
      name: "help-type-section___sr"
    }, {
      path: ":section/:case",
      component: _81b62f58,
      name: "help-type-section-case___sr"
    }]
  }, {
    path: "/sr/mga-tiket-ng-flight/:slug?",
    component: _ccc511ca,
    name: "mga-tiket-ng-flight-slug___sr"
  }, {
    path: "/sr/paymentpartner/:trxId?",
    component: _2a4b11cd,
    name: "paymentpartner-trxId___sr"
  }, {
    path: "/sr/promo/:slug",
    component: _7b13b534,
    children: [{
      path: "",
      component: _3b4884b7,
      name: "promo-slug___sr"
    }, {
      path: ":id",
      component: _45ce48df,
      name: "promo-slug-id___sr"
    }]
  }, {
    path: "/sr/support/:type",
    component: _4d7e2591,
    children: [{
      path: "",
      component: _07c27b54,
      name: "support-type___sr"
    }, {
      path: ":section",
      component: _ef4e78d2,
      name: "support-type-section___sr"
    }, {
      path: ":section/:case",
      component: _81b62f58,
      name: "support-type-section-case___sr"
    }]
  }, {
    path: "/sv/%E0%B8%95%E0%B8%B1%E0%B9%8B%E0%B8%A7%E0%B9%80%E0%B8%84%E0%B8%A3%E0%B8%B7%E0%B9%88%E0%B8%AD%E0%B8%87%E0%B8%9A%E0%B8%B4%E0%B8%99/:slug?",
    component: _42c65424,
    name: "ตั๋วเครื่องบิน-slug___sv"
  }, {
    path: "/sv/%E6%9C%BA%E7%A5%A8/:slug?",
    component: _2d7c6c22,
    name: "机票-slug___sv"
  }, {
    path: "/sv/%E6%A9%9F%E7%A5%A8/:slug?",
    component: _1adecdaa,
    name: "機票-slug___sv"
  }, {
    path: "/sv/%E8%88%AA%E7%A9%BA%E5%88%B8/:slug?",
    component: _d68337e6,
    name: "航空券-slug___sv"
  }, {
    path: "/sv/%ED%95%AD%EA%B3%B5%EA%B6%8C/:slug?",
    component: _07612fc9,
    name: "항공권-slug___sv"
  }, {
    path: "/sv/book/:id?",
    component: _46aa325a,
    name: "book-id___sv"
  }, {
    path: "/sv/deals/:country",
    component: _175dcd30,
    name: "deals-country___sv"
  }, {
    path: "/sv/help/:type",
    component: _4d7e2591,
    children: [{
      path: "",
      component: _07c27b54,
      name: "help-type___sv"
    }, {
      path: ":section",
      component: _ef4e78d2,
      name: "help-type-section___sv"
    }, {
      path: ":section/:case",
      component: _81b62f58,
      name: "help-type-section-case___sv"
    }]
  }, {
    path: "/sv/mga-tiket-ng-flight/:slug?",
    component: _ccc511ca,
    name: "mga-tiket-ng-flight-slug___sv"
  }, {
    path: "/sv/paymentpartner/:trxId?",
    component: _2a4b11cd,
    name: "paymentpartner-trxId___sv"
  }, {
    path: "/sv/promo/:slug",
    component: _7b13b534,
    children: [{
      path: "",
      component: _3b4884b7,
      name: "promo-slug___sv"
    }, {
      path: ":id",
      component: _45ce48df,
      name: "promo-slug-id___sv"
    }]
  }, {
    path: "/sv/support/:type",
    component: _4d7e2591,
    children: [{
      path: "",
      component: _07c27b54,
      name: "support-type___sv"
    }, {
      path: ":section",
      component: _ef4e78d2,
      name: "support-type-section___sv"
    }, {
      path: ":section/:case",
      component: _81b62f58,
      name: "support-type-section-case___sv"
    }]
  }, {
    path: "/th/%E0%B8%95%E0%B8%B1%E0%B9%8B%E0%B8%A7%E0%B9%80%E0%B8%84%E0%B8%A3%E0%B8%B7%E0%B9%88%E0%B8%AD%E0%B8%87%E0%B8%9A%E0%B8%B4%E0%B8%99/:slug?",
    component: _42c65424,
    name: "ตั๋วเครื่องบิน-slug___th"
  }, {
    path: "/th/%E6%9C%BA%E7%A5%A8/:slug?",
    component: _2d7c6c22,
    name: "机票-slug___th"
  }, {
    path: "/th/%E6%A9%9F%E7%A5%A8/:slug?",
    component: _1adecdaa,
    name: "機票-slug___th"
  }, {
    path: "/th/%E8%88%AA%E7%A9%BA%E5%88%B8/:slug?",
    component: _d68337e6,
    name: "航空券-slug___th"
  }, {
    path: "/th/%ED%95%AD%EA%B3%B5%EA%B6%8C/:slug?",
    component: _07612fc9,
    name: "항공권-slug___th"
  }, {
    path: "/th/book/:id?",
    component: _46aa325a,
    name: "book-id___th"
  }, {
    path: "/th/deals/:country",
    component: _175dcd30,
    name: "deals-country___th"
  }, {
    path: "/th/help/:type",
    component: _4d7e2591,
    children: [{
      path: "",
      component: _07c27b54,
      name: "help-type___th"
    }, {
      path: ":section",
      component: _ef4e78d2,
      name: "help-type-section___th"
    }, {
      path: ":section/:case",
      component: _81b62f58,
      name: "help-type-section-case___th"
    }]
  }, {
    path: "/th/mga-tiket-ng-flight/:slug?",
    component: _ccc511ca,
    name: "mga-tiket-ng-flight-slug___th"
  }, {
    path: "/th/paymentpartner/:trxId?",
    component: _2a4b11cd,
    name: "paymentpartner-trxId___th"
  }, {
    path: "/th/promo/:slug",
    component: _7b13b534,
    children: [{
      path: "",
      component: _3b4884b7,
      name: "promo-slug___th"
    }, {
      path: ":id",
      component: _45ce48df,
      name: "promo-slug-id___th"
    }]
  }, {
    path: "/th/support/:type",
    component: _4d7e2591,
    children: [{
      path: "",
      component: _07c27b54,
      name: "support-type___th"
    }, {
      path: ":section",
      component: _ef4e78d2,
      name: "support-type-section___th"
    }, {
      path: ":section/:case",
      component: _81b62f58,
      name: "support-type-section-case___th"
    }]
  }, {
    path: "/tl/%E0%B8%95%E0%B8%B1%E0%B9%8B%E0%B8%A7%E0%B9%80%E0%B8%84%E0%B8%A3%E0%B8%B7%E0%B9%88%E0%B8%AD%E0%B8%87%E0%B8%9A%E0%B8%B4%E0%B8%99/:slug?",
    component: _42c65424,
    name: "ตั๋วเครื่องบิน-slug___tl"
  }, {
    path: "/tl/%E6%9C%BA%E7%A5%A8/:slug?",
    component: _2d7c6c22,
    name: "机票-slug___tl"
  }, {
    path: "/tl/%E6%A9%9F%E7%A5%A8/:slug?",
    component: _1adecdaa,
    name: "機票-slug___tl"
  }, {
    path: "/tl/%E8%88%AA%E7%A9%BA%E5%88%B8/:slug?",
    component: _d68337e6,
    name: "航空券-slug___tl"
  }, {
    path: "/tl/%ED%95%AD%EA%B3%B5%EA%B6%8C/:slug?",
    component: _07612fc9,
    name: "항공권-slug___tl"
  }, {
    path: "/tl/book/:id?",
    component: _46aa325a,
    name: "book-id___tl"
  }, {
    path: "/tl/deals/:country",
    component: _175dcd30,
    name: "deals-country___tl"
  }, {
    path: "/tl/help/:type",
    component: _4d7e2591,
    children: [{
      path: "",
      component: _07c27b54,
      name: "help-type___tl"
    }, {
      path: ":section",
      component: _ef4e78d2,
      name: "help-type-section___tl"
    }, {
      path: ":section/:case",
      component: _81b62f58,
      name: "help-type-section-case___tl"
    }]
  }, {
    path: "/tl/mga-tiket-ng-flight/:slug?",
    component: _ccc511ca,
    name: "mga-tiket-ng-flight-slug___tl"
  }, {
    path: "/tl/paymentpartner/:trxId?",
    component: _2a4b11cd,
    name: "paymentpartner-trxId___tl"
  }, {
    path: "/tl/promo/:slug",
    component: _7b13b534,
    children: [{
      path: "",
      component: _3b4884b7,
      name: "promo-slug___tl"
    }, {
      path: ":id",
      component: _45ce48df,
      name: "promo-slug-id___tl"
    }]
  }, {
    path: "/tl/support/:type",
    component: _4d7e2591,
    children: [{
      path: "",
      component: _07c27b54,
      name: "support-type___tl"
    }, {
      path: ":section",
      component: _ef4e78d2,
      name: "support-type-section___tl"
    }, {
      path: ":section/:case",
      component: _81b62f58,
      name: "support-type-section-case___tl"
    }]
  }, {
    path: "/tr/%E0%B8%95%E0%B8%B1%E0%B9%8B%E0%B8%A7%E0%B9%80%E0%B8%84%E0%B8%A3%E0%B8%B7%E0%B9%88%E0%B8%AD%E0%B8%87%E0%B8%9A%E0%B8%B4%E0%B8%99/:slug?",
    component: _42c65424,
    name: "ตั๋วเครื่องบิน-slug___tr"
  }, {
    path: "/tr/%E6%9C%BA%E7%A5%A8/:slug?",
    component: _2d7c6c22,
    name: "机票-slug___tr"
  }, {
    path: "/tr/%E6%A9%9F%E7%A5%A8/:slug?",
    component: _1adecdaa,
    name: "機票-slug___tr"
  }, {
    path: "/tr/%E8%88%AA%E7%A9%BA%E5%88%B8/:slug?",
    component: _d68337e6,
    name: "航空券-slug___tr"
  }, {
    path: "/tr/%ED%95%AD%EA%B3%B5%EA%B6%8C/:slug?",
    component: _07612fc9,
    name: "항공권-slug___tr"
  }, {
    path: "/tr/book/:id?",
    component: _46aa325a,
    name: "book-id___tr"
  }, {
    path: "/tr/deals/:country",
    component: _175dcd30,
    name: "deals-country___tr"
  }, {
    path: "/tr/help/:type",
    component: _4d7e2591,
    children: [{
      path: "",
      component: _07c27b54,
      name: "help-type___tr"
    }, {
      path: ":section",
      component: _ef4e78d2,
      name: "help-type-section___tr"
    }, {
      path: ":section/:case",
      component: _81b62f58,
      name: "help-type-section-case___tr"
    }]
  }, {
    path: "/tr/mga-tiket-ng-flight/:slug?",
    component: _ccc511ca,
    name: "mga-tiket-ng-flight-slug___tr"
  }, {
    path: "/tr/paymentpartner/:trxId?",
    component: _2a4b11cd,
    name: "paymentpartner-trxId___tr"
  }, {
    path: "/tr/promo/:slug",
    component: _7b13b534,
    children: [{
      path: "",
      component: _3b4884b7,
      name: "promo-slug___tr"
    }, {
      path: ":id",
      component: _45ce48df,
      name: "promo-slug-id___tr"
    }]
  }, {
    path: "/tr/support/:type",
    component: _4d7e2591,
    children: [{
      path: "",
      component: _07c27b54,
      name: "support-type___tr"
    }, {
      path: ":section",
      component: _ef4e78d2,
      name: "support-type-section___tr"
    }, {
      path: ":section/:case",
      component: _81b62f58,
      name: "support-type-section-case___tr"
    }]
  }, {
    path: "/uk/%E0%B8%95%E0%B8%B1%E0%B9%8B%E0%B8%A7%E0%B9%80%E0%B8%84%E0%B8%A3%E0%B8%B7%E0%B9%88%E0%B8%AD%E0%B8%87%E0%B8%9A%E0%B8%B4%E0%B8%99/:slug?",
    component: _42c65424,
    name: "ตั๋วเครื่องบิน-slug___uk"
  }, {
    path: "/uk/%E6%9C%BA%E7%A5%A8/:slug?",
    component: _2d7c6c22,
    name: "机票-slug___uk"
  }, {
    path: "/uk/%E6%A9%9F%E7%A5%A8/:slug?",
    component: _1adecdaa,
    name: "機票-slug___uk"
  }, {
    path: "/uk/%E8%88%AA%E7%A9%BA%E5%88%B8/:slug?",
    component: _d68337e6,
    name: "航空券-slug___uk"
  }, {
    path: "/uk/%ED%95%AD%EA%B3%B5%EA%B6%8C/:slug?",
    component: _07612fc9,
    name: "항공권-slug___uk"
  }, {
    path: "/uk/book/:id?",
    component: _46aa325a,
    name: "book-id___uk"
  }, {
    path: "/uk/deals/:country",
    component: _175dcd30,
    name: "deals-country___uk"
  }, {
    path: "/uk/help/:type",
    component: _4d7e2591,
    children: [{
      path: "",
      component: _07c27b54,
      name: "help-type___uk"
    }, {
      path: ":section",
      component: _ef4e78d2,
      name: "help-type-section___uk"
    }, {
      path: ":section/:case",
      component: _81b62f58,
      name: "help-type-section-case___uk"
    }]
  }, {
    path: "/uk/mga-tiket-ng-flight/:slug?",
    component: _ccc511ca,
    name: "mga-tiket-ng-flight-slug___uk"
  }, {
    path: "/uk/paymentpartner/:trxId?",
    component: _2a4b11cd,
    name: "paymentpartner-trxId___uk"
  }, {
    path: "/uk/promo/:slug",
    component: _7b13b534,
    children: [{
      path: "",
      component: _3b4884b7,
      name: "promo-slug___uk"
    }, {
      path: ":id",
      component: _45ce48df,
      name: "promo-slug-id___uk"
    }]
  }, {
    path: "/uk/support/:type",
    component: _4d7e2591,
    children: [{
      path: "",
      component: _07c27b54,
      name: "support-type___uk"
    }, {
      path: ":section",
      component: _ef4e78d2,
      name: "support-type-section___uk"
    }, {
      path: ":section/:case",
      component: _81b62f58,
      name: "support-type-section-case___uk"
    }]
  }, {
    path: "/vi/%E0%B8%95%E0%B8%B1%E0%B9%8B%E0%B8%A7%E0%B9%80%E0%B8%84%E0%B8%A3%E0%B8%B7%E0%B9%88%E0%B8%AD%E0%B8%87%E0%B8%9A%E0%B8%B4%E0%B8%99/:slug?",
    component: _42c65424,
    name: "ตั๋วเครื่องบิน-slug___vi"
  }, {
    path: "/vi/%E6%9C%BA%E7%A5%A8/:slug?",
    component: _2d7c6c22,
    name: "机票-slug___vi"
  }, {
    path: "/vi/%E6%A9%9F%E7%A5%A8/:slug?",
    component: _1adecdaa,
    name: "機票-slug___vi"
  }, {
    path: "/vi/%E8%88%AA%E7%A9%BA%E5%88%B8/:slug?",
    component: _d68337e6,
    name: "航空券-slug___vi"
  }, {
    path: "/vi/%ED%95%AD%EA%B3%B5%EA%B6%8C/:slug?",
    component: _07612fc9,
    name: "항공권-slug___vi"
  }, {
    path: "/vi/book/:id?",
    component: _46aa325a,
    name: "book-id___vi"
  }, {
    path: "/vi/deals/:country",
    component: _175dcd30,
    name: "deals-country___vi"
  }, {
    path: "/vi/help/:type",
    component: _4d7e2591,
    children: [{
      path: "",
      component: _07c27b54,
      name: "help-type___vi"
    }, {
      path: ":section",
      component: _ef4e78d2,
      name: "help-type-section___vi"
    }, {
      path: ":section/:case",
      component: _81b62f58,
      name: "help-type-section-case___vi"
    }]
  }, {
    path: "/vi/mga-tiket-ng-flight/:slug?",
    component: _ccc511ca,
    name: "mga-tiket-ng-flight-slug___vi"
  }, {
    path: "/vi/paymentpartner/:trxId?",
    component: _2a4b11cd,
    name: "paymentpartner-trxId___vi"
  }, {
    path: "/vi/promo/:slug",
    component: _7b13b534,
    children: [{
      path: "",
      component: _3b4884b7,
      name: "promo-slug___vi"
    }, {
      path: ":id",
      component: _45ce48df,
      name: "promo-slug-id___vi"
    }]
  }, {
    path: "/vi/support/:type",
    component: _4d7e2591,
    children: [{
      path: "",
      component: _07c27b54,
      name: "support-type___vi"
    }, {
      path: ":section",
      component: _ef4e78d2,
      name: "support-type-section___vi"
    }, {
      path: ":section/:case",
      component: _81b62f58,
      name: "support-type-section-case___vi"
    }]
  }, {
    path: "/zh-tw/%E0%B8%95%E0%B8%B1%E0%B9%8B%E0%B8%A7%E0%B9%80%E0%B8%84%E0%B8%A3%E0%B8%B7%E0%B9%88%E0%B8%AD%E0%B8%87%E0%B8%9A%E0%B8%B4%E0%B8%99/:slug?",
    component: _42c65424,
    name: "ตั๋วเครื่องบิน-slug___zh-tw"
  }, {
    path: "/zh-tw/%E6%9C%BA%E7%A5%A8/:slug?",
    component: _2d7c6c22,
    name: "机票-slug___zh-tw"
  }, {
    path: "/zh-tw/%E6%A9%9F%E7%A5%A8/:slug?",
    component: _1adecdaa,
    name: "機票-slug___zh-tw"
  }, {
    path: "/zh-tw/%E8%88%AA%E7%A9%BA%E5%88%B8/:slug?",
    component: _d68337e6,
    name: "航空券-slug___zh-tw"
  }, {
    path: "/zh-tw/%ED%95%AD%EA%B3%B5%EA%B6%8C/:slug?",
    component: _07612fc9,
    name: "항공권-slug___zh-tw"
  }, {
    path: "/zh-tw/book/:id?",
    component: _46aa325a,
    name: "book-id___zh-tw"
  }, {
    path: "/zh-tw/deals/:country",
    component: _175dcd30,
    name: "deals-country___zh-tw"
  }, {
    path: "/zh-tw/help/:type",
    component: _4d7e2591,
    children: [{
      path: "",
      component: _07c27b54,
      name: "help-type___zh-tw"
    }, {
      path: ":section",
      component: _ef4e78d2,
      name: "help-type-section___zh-tw"
    }, {
      path: ":section/:case",
      component: _81b62f58,
      name: "help-type-section-case___zh-tw"
    }]
  }, {
    path: "/zh-tw/mga-tiket-ng-flight/:slug?",
    component: _ccc511ca,
    name: "mga-tiket-ng-flight-slug___zh-tw"
  }, {
    path: "/zh-tw/paymentpartner/:trxId?",
    component: _2a4b11cd,
    name: "paymentpartner-trxId___zh-tw"
  }, {
    path: "/zh-tw/promo/:slug",
    component: _7b13b534,
    children: [{
      path: "",
      component: _3b4884b7,
      name: "promo-slug___zh-tw"
    }, {
      path: ":id",
      component: _45ce48df,
      name: "promo-slug-id___zh-tw"
    }]
  }, {
    path: "/zh-tw/support/:type",
    component: _4d7e2591,
    children: [{
      path: "",
      component: _07c27b54,
      name: "support-type___zh-tw"
    }, {
      path: ":section",
      component: _ef4e78d2,
      name: "support-type-section___zh-tw"
    }, {
      path: ":section/:case",
      component: _81b62f58,
      name: "support-type-section-case___zh-tw"
    }]
  }, {
    path: "/zh/%E0%B8%95%E0%B8%B1%E0%B9%8B%E0%B8%A7%E0%B9%80%E0%B8%84%E0%B8%A3%E0%B8%B7%E0%B9%88%E0%B8%AD%E0%B8%87%E0%B8%9A%E0%B8%B4%E0%B8%99/:slug?",
    component: _42c65424,
    name: "ตั๋วเครื่องบิน-slug___zh"
  }, {
    path: "/zh/%E6%9C%BA%E7%A5%A8/:slug?",
    component: _2d7c6c22,
    name: "机票-slug___zh"
  }, {
    path: "/zh/%E6%A9%9F%E7%A5%A8/:slug?",
    component: _1adecdaa,
    name: "機票-slug___zh"
  }, {
    path: "/zh/%E8%88%AA%E7%A9%BA%E5%88%B8/:slug?",
    component: _d68337e6,
    name: "航空券-slug___zh"
  }, {
    path: "/zh/%ED%95%AD%EA%B3%B5%EA%B6%8C/:slug?",
    component: _07612fc9,
    name: "항공권-slug___zh"
  }, {
    path: "/zh/book/:id?",
    component: _46aa325a,
    name: "book-id___zh"
  }, {
    path: "/zh/deals/:country",
    component: _175dcd30,
    name: "deals-country___zh"
  }, {
    path: "/zh/help/:type",
    component: _4d7e2591,
    children: [{
      path: "",
      component: _07c27b54,
      name: "help-type___zh"
    }, {
      path: ":section",
      component: _ef4e78d2,
      name: "help-type-section___zh"
    }, {
      path: ":section/:case",
      component: _81b62f58,
      name: "help-type-section-case___zh"
    }]
  }, {
    path: "/zh/mga-tiket-ng-flight/:slug?",
    component: _ccc511ca,
    name: "mga-tiket-ng-flight-slug___zh"
  }, {
    path: "/zh/paymentpartner/:trxId?",
    component: _2a4b11cd,
    name: "paymentpartner-trxId___zh"
  }, {
    path: "/zh/promo/:slug",
    component: _7b13b534,
    children: [{
      path: "",
      component: _3b4884b7,
      name: "promo-slug___zh"
    }, {
      path: ":id",
      component: _45ce48df,
      name: "promo-slug-id___zh"
    }]
  }, {
    path: "/zh/support/:type",
    component: _4d7e2591,
    children: [{
      path: "",
      component: _07c27b54,
      name: "support-type___zh"
    }, {
      path: ":section",
      component: _ef4e78d2,
      name: "support-type-section___zh"
    }, {
      path: ":section/:case",
      component: _81b62f58,
      name: "support-type-section-case___zh"
    }]
  }, {
    path: "/bg/deals/:country/:detail",
    component: _166abf86,
    name: "deals-country-detail___bg"
  }, {
    path: "/bn/deals/:country/:detail",
    component: _166abf86,
    name: "deals-country-detail___bn"
  }, {
    path: "/ca/deals/:country/:detail",
    component: _166abf86,
    name: "deals-country-detail___ca"
  }, {
    path: "/cs/deals/:country/:detail",
    component: _166abf86,
    name: "deals-country-detail___cs"
  }, {
    path: "/da/deals/:country/:detail",
    component: _166abf86,
    name: "deals-country-detail___da"
  }, {
    path: "/de/deals/:country/:detail",
    component: _166abf86,
    name: "deals-country-detail___de"
  }, {
    path: "/el/deals/:country/:detail",
    component: _166abf86,
    name: "deals-country-detail___el"
  }, {
    path: "/en/deals/:country/:detail",
    component: _166abf86,
    name: "deals-country-detail___en"
  }, {
    path: "/es/deals/:country/:detail",
    component: _166abf86,
    name: "deals-country-detail___es"
  }, {
    path: "/fi/deals/:country/:detail",
    component: _166abf86,
    name: "deals-country-detail___fi"
  }, {
    path: "/fr/deals/:country/:detail",
    component: _166abf86,
    name: "deals-country-detail___fr"
  }, {
    path: "/hi/deals/:country/:detail",
    component: _166abf86,
    name: "deals-country-detail___hi"
  }, {
    path: "/hr/deals/:country/:detail",
    component: _166abf86,
    name: "deals-country-detail___hr"
  }, {
    path: "/hu/deals/:country/:detail",
    component: _166abf86,
    name: "deals-country-detail___hu"
  }, {
    path: "/id/deals/:country/:detail",
    component: _166abf86,
    name: "deals-country-detail___id"
  }, {
    path: "/it/deals/:country/:detail",
    component: _166abf86,
    name: "deals-country-detail___it"
  }, {
    path: "/ja/deals/:country/:detail",
    component: _166abf86,
    name: "deals-country-detail___ja"
  }, {
    path: "/km/deals/:country/:detail",
    component: _166abf86,
    name: "deals-country-detail___km"
  }, {
    path: "/ko/deals/:country/:detail",
    component: _166abf86,
    name: "deals-country-detail___ko"
  }, {
    path: "/ms/deals/:country/:detail",
    component: _166abf86,
    name: "deals-country-detail___ms"
  }, {
    path: "/nb/deals/:country/:detail",
    component: _166abf86,
    name: "deals-country-detail___nb"
  }, {
    path: "/nl/deals/:country/:detail",
    component: _166abf86,
    name: "deals-country-detail___nl"
  }, {
    path: "/pl/deals/:country/:detail",
    component: _166abf86,
    name: "deals-country-detail___pl"
  }, {
    path: "/pt/deals/:country/:detail",
    component: _166abf86,
    name: "deals-country-detail___pt"
  }, {
    path: "/ro/deals/:country/:detail",
    component: _166abf86,
    name: "deals-country-detail___ro"
  }, {
    path: "/ru/deals/:country/:detail",
    component: _166abf86,
    name: "deals-country-detail___ru"
  }, {
    path: "/sk/deals/:country/:detail",
    component: _166abf86,
    name: "deals-country-detail___sk"
  }, {
    path: "/sl/deals/:country/:detail",
    component: _166abf86,
    name: "deals-country-detail___sl"
  }, {
    path: "/sr/deals/:country/:detail",
    component: _166abf86,
    name: "deals-country-detail___sr"
  }, {
    path: "/sv/deals/:country/:detail",
    component: _166abf86,
    name: "deals-country-detail___sv"
  }, {
    path: "/th/deals/:country/:detail",
    component: _166abf86,
    name: "deals-country-detail___th"
  }, {
    path: "/tl/deals/:country/:detail",
    component: _166abf86,
    name: "deals-country-detail___tl"
  }, {
    path: "/tr/deals/:country/:detail",
    component: _166abf86,
    name: "deals-country-detail___tr"
  }, {
    path: "/uk/deals/:country/:detail",
    component: _166abf86,
    name: "deals-country-detail___uk"
  }, {
    path: "/vi/deals/:country/:detail",
    component: _166abf86,
    name: "deals-country-detail___vi"
  }, {
    path: "/zh-tw/deals/:country/:detail",
    component: _166abf86,
    name: "deals-country-detail___zh-tw"
  }, {
    path: "/zh/deals/:country/:detail",
    component: _166abf86,
    name: "deals-country-detail___zh"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
