const middleware = {}

middleware['controller'] = require('../middleware/controller.js')
middleware['controller'] = middleware['controller'].default || middleware['controller']

middleware['conversion-tracker'] = require('../middleware/conversion-tracker.js')
middleware['conversion-tracker'] = middleware['conversion-tracker'].default || middleware['conversion-tracker']

middleware['flightSearch'] = require('../middleware/flightSearch.js')
middleware['flightSearch'] = middleware['flightSearch'].default || middleware['flightSearch']

middleware['healthz'] = require('../middleware/healthz.js')
middleware['healthz'] = middleware['healthz'].default || middleware['healthz']

middleware['hotelReview'] = require('../middleware/hotelReview.js')
middleware['hotelReview'] = middleware['hotelReview'].default || middleware['hotelReview']

middleware['hotelSearch'] = require('../middleware/hotelSearch.js')
middleware['hotelSearch'] = middleware['hotelSearch'].default || middleware['hotelSearch']

middleware['i18n-route'] = require('../middleware/i18n-route.js')
middleware['i18n-route'] = middleware['i18n-route'].default || middleware['i18n-route']

middleware['main'] = require('../middleware/main.js')
middleware['main'] = middleware['main'].default || middleware['main']

middleware['redirector'] = require('../middleware/redirector.js')
middleware['redirector'] = middleware['redirector'].default || middleware['redirector']

middleware['stats'] = require('../middleware/stats.js')
middleware['stats'] = middleware['stats'].default || middleware['stats']

middleware['tracker'] = require('../middleware/tracker.js')
middleware['tracker'] = middleware['tracker'].default || middleware['tracker']

middleware['unauthenticated'] = require('../middleware/unauthenticated.js')
middleware['unauthenticated'] = middleware['unauthenticated'].default || middleware['unauthenticated']

export default middleware
