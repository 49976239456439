import Middleware from './middleware'
import { Auth, authMiddleware, ExpiredAuthSessionError } from '~auth/runtime'

// Active schemes
import { RefreshScheme } from '~auth/runtime'

Middleware.auth = authMiddleware

export default function (ctx, inject) {
  // Options
  const options = {
  "resetOnError": true,
  "ignoreExceptions": false,
  "scopeKey": "scope",
  "rewriteRedirects": true,
  "fullPathRedirect": true,
  "watchLoggedIn": true,
  "redirect": {
    "login": "/login",
    "logout": "/",
    "home": "/",
    "callback": false
  },
  "vuex": {
    "namespace": "auth"
  },
  "cookie": {
    "prefix": "auth.",
    "options": {
      "path": "/",
      "maxAge": 1728000,
      "domain": ".airpaz.com"
    },
    "secure": true
  },
  "localStorage": false,
  "defaultStrategy": "airpaz"
}

  // Create a new Auth instance
  const $auth = new Auth(ctx, options)

  // Register strategies
  // airpaz
  $auth.registerStrategy('airpaz', new RefreshScheme($auth, {
  "token": {
    "property": "result.token",
    "maxAge": 1123200,
    "required": true
  },
  "refreshToken": {
    "property": "result.refreshToken",
    "maxAge": 2505600
  },
  "user": {
    "property": "result"
  },
  "endpoints": {
    "login": {
      "url": "/member/login",
      "method": "post"
    },
    "refresh": {
      "url": "/member/refresh-token",
      "method": "post"
    },
    "user": {
      "url": "/member/get",
      "method": "get"
    },
    "logout": false
  },
  "name": "airpaz"
}))

  // Inject it to nuxt context as $auth
  inject('auth', $auth)
  ctx.$auth = $auth

  // Initialize auth
  return $auth.init().catch(error => {
    if (process.client) {
      // Don't console log expired auth session errors. This error is common, and expected to happen.
      // The error happens whenever the user does an ssr request (reload/initial navigation) with an expired refresh
      // token. We don't want to log this as an error.
      if (error instanceof ExpiredAuthSessionError) {
        return
      }

      console.error('[ERROR] [AUTH]', error)
    }
  })
}
