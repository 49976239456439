import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_plugin_e0c55be8 from 'nuxt_plugin_plugin_e0c55be8' // Source: ./components/plugin.js (mode: 'all')
import nuxt_plugin_sentryserver_4deaace6 from 'nuxt_plugin_sentryserver_4deaace6' // Source: ./sentry.server.js (mode: 'server')
import nuxt_plugin_sentryclient_2f9d8944 from 'nuxt_plugin_sentryclient_2f9d8944' // Source: ./sentry.client.js (mode: 'client')
import nuxt_plugin_recaptcha_a40546de from 'nuxt_plugin_recaptcha_a40546de' // Source: ./recaptcha.js (mode: 'all')
import nuxt_plugin_pluginutils_a26db14e from 'nuxt_plugin_pluginutils_a26db14e' // Source: ./nuxt-i18n/plugin.utils.js (mode: 'all')
import nuxt_plugin_pluginrouting_51c57b38 from 'nuxt_plugin_pluginrouting_51c57b38' // Source: ./nuxt-i18n/plugin.routing.js (mode: 'all')
import nuxt_plugin_pluginmain_3e05638b from 'nuxt_plugin_pluginmain_3e05638b' // Source: ./nuxt-i18n/plugin.main.js (mode: 'all')
import nuxt_plugin_axios_a9fda1bc from 'nuxt_plugin_axios_a9fda1bc' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_cookieuniversalnuxt_37676a6c from 'nuxt_plugin_cookieuniversalnuxt_37676a6c' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_deviceplugin_701084ad from 'nuxt_plugin_deviceplugin_701084ad' // Source: ./device.plugin.js (mode: 'all')
import nuxt_plugin_userconfig_44ea3df8 from 'nuxt_plugin_userconfig_44ea3df8' // Source: ../plugins/user-config.server (mode: 'server')
import nuxt_plugin_currency_161dd0e8 from 'nuxt_plugin_currency_161dd0e8' // Source: ../plugins/currency (mode: 'all')
import nuxt_plugin_sessionid_ac2baa6e from 'nuxt_plugin_sessionid_ac2baa6e' // Source: ../plugins/session-id (mode: 'client')
import nuxt_plugin_polyfillloader_05ff9b9a from 'nuxt_plugin_polyfillloader_05ff9b9a' // Source: ../plugins/polyfill-loader (mode: 'client')
import nuxt_plugin_vuenossrcomponents_03c33f3f from 'nuxt_plugin_vuenossrcomponents_03c33f3f' // Source: ../plugins/vue-no-ssr-components (mode: 'all')
import nuxt_plugin_i18nFormatter_0620dda1 from 'nuxt_plugin_i18nFormatter_0620dda1' // Source: ../plugins/i18nFormatter.js (mode: 'all')
import nuxt_plugin_api_5e4622e4 from 'nuxt_plugin_api_5e4622e4' // Source: ../plugins/api (mode: 'all')
import nuxt_plugin_functionHelper_207d029c from 'nuxt_plugin_functionHelper_207d029c' // Source: ../plugins/functionHelper (mode: 'all')
import nuxt_plugin_seohelper_56a369d8 from 'nuxt_plugin_seohelper_56a369d8' // Source: ../plugins/seo-helper (mode: 'all')
import nuxt_plugin_featuretoggle_2ede2da2 from 'nuxt_plugin_featuretoggle_2ede2da2' // Source: ../plugins/feature-toggle (mode: 'all')
import nuxt_plugin_utmtracker_7bf7ba34 from 'nuxt_plugin_utmtracker_7bf7ba34' // Source: ../plugins/utm-tracker.js (mode: 'all')
import nuxt_plugin_conversiontracker_ae1a01c0 from 'nuxt_plugin_conversiontracker_ae1a01c0' // Source: ../plugins/conversion-tracker.js (mode: 'all')
import nuxt_plugin_conversiontrackernext_59585464 from 'nuxt_plugin_conversiontrackernext_59585464' // Source: ../plugins/conversion-tracker-next.js (mode: 'all')
import nuxt_plugin_googlescriptsclient_3e2dd529 from 'nuxt_plugin_googlescriptsclient_3e2dd529' // Source: ../plugins/google-scripts.client.js (mode: 'client')
import nuxt_plugin_trackerclient_1432d41f from 'nuxt_plugin_trackerclient_1432d41f' // Source: ../plugins/tracker.client.js (mode: 'client')
import nuxt_plugin_jitsupageview_1b9f8036 from 'nuxt_plugin_jitsupageview_1b9f8036' // Source: ../plugins/jitsu-pageview.js (mode: 'client')
import nuxt_plugin_vuelidate_4345260a from 'nuxt_plugin_vuelidate_4345260a' // Source: ../plugins/vuelidate (mode: 'all')
import nuxt_plugin_yandexMetrika_03287856 from 'nuxt_plugin_yandexMetrika_03287856' // Source: ../plugins/yandexMetrika.js (mode: 'client')
import nuxt_plugin_vuexpersist_25bdab30 from 'nuxt_plugin_vuexpersist_25bdab30' // Source: ../plugins/vuex-persist.js (mode: 'client')
import nuxt_plugin_vueglobalcomponents_61df3f82 from 'nuxt_plugin_vueglobalcomponents_61df3f82' // Source: ../plugins/vue-global-components (mode: 'all')
import nuxt_plugin_urls_56d0cd3b from 'nuxt_plugin_urls_56d0cd3b' // Source: ../plugins/urls (mode: 'all')
import nuxt_plugin_inithook_744895c9 from 'nuxt_plugin_inithook_744895c9' // Source: ../plugins/init-hook (mode: 'all')
import nuxt_plugin_bingads_6b7d803e from 'nuxt_plugin_bingads_6b7d803e' // Source: ../plugins/bing-ads.js (mode: 'client')
import nuxt_plugin_auth_4b4f0dce from 'nuxt_plugin_auth_4b4f0dce' // Source: ./auth.js (mode: 'all')
import nuxt_plugin_axios_3566aa80 from 'nuxt_plugin_axios_3566aa80' // Source: ../plugins/axios (mode: 'all')
import nuxt_plugin_auth_7f7561ce from 'nuxt_plugin_auth_7f7561ce' // Source: ../plugins/auth.js (mode: 'all')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root.$options.$nuxt
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const router = await createRouter(ssrContext, config)

  const store = createStore(ssrContext)
  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"name":"generator","content":"airpaz"},{"name":"format-detection","content":"telephone=no"},{"name":"format-detection","content":"telephone:no"},{"name":"msvalidate.01","content":"7541320287A5955043542DABB91B9137"},{"name":"facebook-domain-verification","content":"ez8t74us5bs5cngrhdpcrlu53cgu13"},{"hid":"og:site_name","property":"og:site_name","content":"Airpaz"},{"hid":"og:type","property":"og:type","content":"website"},{"hid":"twitter:card","name":"twitter:card","content":"summary"},{"hid":"twitter:url","name":"twitter:url","content":"http:\u002F\u002Fwww.airpaz.com\u002F"}],"link":[{"rel":"manifest","hid":"manifest","href":"\u002Fmanifest.json"},{"rel":"icon","type":"image\u002Fx-icon","href":"\u002Ffavicon.ico"},{"rel":"publisher","href":"https:\u002F\u002Fplus.google.com\u002F+Airpaz"},{"rel":"dns-prefetch","href":"https:\u002F\u002Fnext-api.airpaz.com"},{"rel":"preload","as":"style","href":"\u002Ffont\u002Ffont.css"},{"rel":"stylesheet","href":"\u002Ffont\u002Ffont.css"}],"script":[{"src":"\u002Fload-icons.js","defer":true}],"style":[]},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (typeof nuxt_plugin_plugin_e0c55be8 === 'function') {
    await nuxt_plugin_plugin_e0c55be8(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_sentryserver_4deaace6 === 'function') {
    await nuxt_plugin_sentryserver_4deaace6(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sentryclient_2f9d8944 === 'function') {
    await nuxt_plugin_sentryclient_2f9d8944(app.context, inject)
  }

  if (typeof nuxt_plugin_recaptcha_a40546de === 'function') {
    await nuxt_plugin_recaptcha_a40546de(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginutils_a26db14e === 'function') {
    await nuxt_plugin_pluginutils_a26db14e(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginrouting_51c57b38 === 'function') {
    await nuxt_plugin_pluginrouting_51c57b38(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginmain_3e05638b === 'function') {
    await nuxt_plugin_pluginmain_3e05638b(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_a9fda1bc === 'function') {
    await nuxt_plugin_axios_a9fda1bc(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_37676a6c === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_37676a6c(app.context, inject)
  }

  if (typeof nuxt_plugin_deviceplugin_701084ad === 'function') {
    await nuxt_plugin_deviceplugin_701084ad(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_userconfig_44ea3df8 === 'function') {
    await nuxt_plugin_userconfig_44ea3df8(app.context, inject)
  }

  if (typeof nuxt_plugin_currency_161dd0e8 === 'function') {
    await nuxt_plugin_currency_161dd0e8(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sessionid_ac2baa6e === 'function') {
    await nuxt_plugin_sessionid_ac2baa6e(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_polyfillloader_05ff9b9a === 'function') {
    await nuxt_plugin_polyfillloader_05ff9b9a(app.context, inject)
  }

  if (typeof nuxt_plugin_vuenossrcomponents_03c33f3f === 'function') {
    await nuxt_plugin_vuenossrcomponents_03c33f3f(app.context, inject)
  }

  if (typeof nuxt_plugin_i18nFormatter_0620dda1 === 'function') {
    await nuxt_plugin_i18nFormatter_0620dda1(app.context, inject)
  }

  if (typeof nuxt_plugin_api_5e4622e4 === 'function') {
    await nuxt_plugin_api_5e4622e4(app.context, inject)
  }

  if (typeof nuxt_plugin_functionHelper_207d029c === 'function') {
    await nuxt_plugin_functionHelper_207d029c(app.context, inject)
  }

  if (typeof nuxt_plugin_seohelper_56a369d8 === 'function') {
    await nuxt_plugin_seohelper_56a369d8(app.context, inject)
  }

  if (typeof nuxt_plugin_featuretoggle_2ede2da2 === 'function') {
    await nuxt_plugin_featuretoggle_2ede2da2(app.context, inject)
  }

  if (typeof nuxt_plugin_utmtracker_7bf7ba34 === 'function') {
    await nuxt_plugin_utmtracker_7bf7ba34(app.context, inject)
  }

  if (typeof nuxt_plugin_conversiontracker_ae1a01c0 === 'function') {
    await nuxt_plugin_conversiontracker_ae1a01c0(app.context, inject)
  }

  if (typeof nuxt_plugin_conversiontrackernext_59585464 === 'function') {
    await nuxt_plugin_conversiontrackernext_59585464(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_googlescriptsclient_3e2dd529 === 'function') {
    await nuxt_plugin_googlescriptsclient_3e2dd529(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_trackerclient_1432d41f === 'function') {
    await nuxt_plugin_trackerclient_1432d41f(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_jitsupageview_1b9f8036 === 'function') {
    await nuxt_plugin_jitsupageview_1b9f8036(app.context, inject)
  }

  if (typeof nuxt_plugin_vuelidate_4345260a === 'function') {
    await nuxt_plugin_vuelidate_4345260a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_yandexMetrika_03287856 === 'function') {
    await nuxt_plugin_yandexMetrika_03287856(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuexpersist_25bdab30 === 'function') {
    await nuxt_plugin_vuexpersist_25bdab30(app.context, inject)
  }

  if (typeof nuxt_plugin_vueglobalcomponents_61df3f82 === 'function') {
    await nuxt_plugin_vueglobalcomponents_61df3f82(app.context, inject)
  }

  if (typeof nuxt_plugin_urls_56d0cd3b === 'function') {
    await nuxt_plugin_urls_56d0cd3b(app.context, inject)
  }

  if (typeof nuxt_plugin_inithook_744895c9 === 'function') {
    await nuxt_plugin_inithook_744895c9(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_bingads_6b7d803e === 'function') {
    await nuxt_plugin_bingads_6b7d803e(app.context, inject)
  }

  if (typeof nuxt_plugin_auth_4b4f0dce === 'function') {
    await nuxt_plugin_auth_4b4f0dce(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_3566aa80 === 'function') {
    await nuxt_plugin_axios_3566aa80(app.context, inject)
  }

  if (typeof nuxt_plugin_auth_7f7561ce === 'function') {
    await nuxt_plugin_auth_7f7561ce(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
