

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"silentTranslationWarn":true},
  vueI18nLoader: false,
  locales: [{"code":"en","iso":"en-US","file":"en.js"},{"code":"id","iso":"id-ID","file":"id.js"},{"code":"tl","iso":"tl-PH","file":"tl.js"},{"code":"zh","iso":"zh-CN","file":"zh.js"},{"code":"zh-tw","iso":"zh-TW","file":"zh-tw.js"},{"code":"th","iso":"th-TH","file":"th.js"},{"code":"ja","iso":"ja-JP","file":"ja.js"},{"code":"ms","iso":"ms-MY","file":"ms.js"},{"code":"ko","iso":"ko-KR","file":"ko.js"},{"code":"vi","iso":"vi-VI","file":"vi.js"},{"code":"it","iso":"it-IT","file":"it.js"},{"code":"de","iso":"de-DE","file":"de.js"},{"code":"es","iso":"es-ES","file":"es.js"},{"code":"fr","iso":"fr-FR","file":"fr.js"},{"code":"hi","iso":"hi-IN","file":"hi.js"},{"code":"ru","iso":"ru-RU","file":"ru.js"},{"code":"bn","iso":"bn-BN","file":"bn.js"},{"code":"pt","iso":"pt-PT","file":"pt.js"},{"code":"km","iso":"km-KH","file":"km.js"},{"code":"nl","iso":"nl-NL","file":"nl.js"},{"code":"pl","iso":"pl-PL","file":"pl.js"},{"code":"tr","iso":"tr-TR","file":"tr.js"},{"code":"cs","iso":"cs-CZ","file":"cs.js"},{"code":"sv","iso":"sv-SE","file":"sv.js"},{"code":"nb","iso":"nb-NO","file":"nb.js"},{"code":"ro","iso":"ro-RO","file":"ro.js"},{"code":"el","iso":"el-GR","file":"el.js"},{"code":"da","iso":"da-DK","file":"da.js"},{"code":"uk","iso":"uk-UA","file":"uk.js"},{"code":"hr","iso":"hr-HR","file":"hr.js"},{"code":"ca","iso":"ca-ES","file":"ca.js"},{"code":"hu","iso":"hu-HU","file":"hu.js"},{"code":"sk","iso":"sk-SK","file":"sk.js"},{"code":"sl","iso":"sl-SI","file":"sl.js"},{"code":"fi","iso":"fi-FI","file":"fi.js"},{"code":"bg","iso":"bg-BG","file":"bg.js"},{"code":"sr","iso":"sr-RS","file":"sr.js"}],
  defaultLocale: "en",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix",
  lazy: true,
  langDir: "/builds/apzfront/forerunner/locales",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":false,"cookieCrossOrigin":false,"cookieDomain":".airpaz.com","cookieKey":"Language_code","cookieSecure":false,"fallbackLocale":"","redirectOn":"root","useCookie":true},
  differentDomains: false,
  baseUrl: "en",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {"cl":false,"callback":false},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  encodePaths: true,
  normalizedLocales: [{"code":"en","iso":"en-US","file":"en.js"},{"code":"id","iso":"id-ID","file":"id.js"},{"code":"tl","iso":"tl-PH","file":"tl.js"},{"code":"zh","iso":"zh-CN","file":"zh.js"},{"code":"zh-tw","iso":"zh-TW","file":"zh-tw.js"},{"code":"th","iso":"th-TH","file":"th.js"},{"code":"ja","iso":"ja-JP","file":"ja.js"},{"code":"ms","iso":"ms-MY","file":"ms.js"},{"code":"ko","iso":"ko-KR","file":"ko.js"},{"code":"vi","iso":"vi-VI","file":"vi.js"},{"code":"it","iso":"it-IT","file":"it.js"},{"code":"de","iso":"de-DE","file":"de.js"},{"code":"es","iso":"es-ES","file":"es.js"},{"code":"fr","iso":"fr-FR","file":"fr.js"},{"code":"hi","iso":"hi-IN","file":"hi.js"},{"code":"ru","iso":"ru-RU","file":"ru.js"},{"code":"bn","iso":"bn-BN","file":"bn.js"},{"code":"pt","iso":"pt-PT","file":"pt.js"},{"code":"km","iso":"km-KH","file":"km.js"},{"code":"nl","iso":"nl-NL","file":"nl.js"},{"code":"pl","iso":"pl-PL","file":"pl.js"},{"code":"tr","iso":"tr-TR","file":"tr.js"},{"code":"cs","iso":"cs-CZ","file":"cs.js"},{"code":"sv","iso":"sv-SE","file":"sv.js"},{"code":"nb","iso":"nb-NO","file":"nb.js"},{"code":"ro","iso":"ro-RO","file":"ro.js"},{"code":"el","iso":"el-GR","file":"el.js"},{"code":"da","iso":"da-DK","file":"da.js"},{"code":"uk","iso":"uk-UA","file":"uk.js"},{"code":"hr","iso":"hr-HR","file":"hr.js"},{"code":"ca","iso":"ca-ES","file":"ca.js"},{"code":"hu","iso":"hu-HU","file":"hu.js"},{"code":"sk","iso":"sk-SK","file":"sk.js"},{"code":"sl","iso":"sl-SI","file":"sl.js"},{"code":"fi","iso":"fi-FI","file":"fi.js"},{"code":"bg","iso":"bg-BG","file":"bg.js"},{"code":"sr","iso":"sr-RS","file":"sr.js"}],
  localeCodes: ["en","id","tl","zh","zh-tw","th","ja","ms","ko","vi","it","de","es","fr","hi","ru","bn","pt","km","nl","pl","tr","cs","sv","nb","ro","el","da","uk","hr","ca","hu","sk","sl","fi","bg","sr"],
  additionalMessages: [],
}

export const localeMessages = {
  'en.js': () => import('../../locales/en.js' /* webpackChunkName: "lang-en.js" */),
  'id.js': () => import('../../locales/id.js' /* webpackChunkName: "lang-id.js" */),
  'tl.js': () => import('../../locales/tl.js' /* webpackChunkName: "lang-tl.js" */),
  'zh.js': () => import('../../locales/zh.js' /* webpackChunkName: "lang-zh.js" */),
  'zh-tw.js': () => import('../../locales/zh-tw.js' /* webpackChunkName: "lang-zh-tw.js" */),
  'th.js': () => import('../../locales/th.js' /* webpackChunkName: "lang-th.js" */),
  'ja.js': () => import('../../locales/ja.js' /* webpackChunkName: "lang-ja.js" */),
  'ms.js': () => import('../../locales/ms.js' /* webpackChunkName: "lang-ms.js" */),
  'ko.js': () => import('../../locales/ko.js' /* webpackChunkName: "lang-ko.js" */),
  'vi.js': () => import('../../locales/vi.js' /* webpackChunkName: "lang-vi.js" */),
  'it.js': () => import('../../locales/it.js' /* webpackChunkName: "lang-it.js" */),
  'de.js': () => import('../../locales/de.js' /* webpackChunkName: "lang-de.js" */),
  'es.js': () => import('../../locales/es.js' /* webpackChunkName: "lang-es.js" */),
  'fr.js': () => import('../../locales/fr.js' /* webpackChunkName: "lang-fr.js" */),
  'hi.js': () => import('../../locales/hi.js' /* webpackChunkName: "lang-hi.js" */),
  'ru.js': () => import('../../locales/ru.js' /* webpackChunkName: "lang-ru.js" */),
  'bn.js': () => import('../../locales/bn.js' /* webpackChunkName: "lang-bn.js" */),
  'pt.js': () => import('../../locales/pt.js' /* webpackChunkName: "lang-pt.js" */),
  'km.js': () => import('../../locales/km.js' /* webpackChunkName: "lang-km.js" */),
  'nl.js': () => import('../../locales/nl.js' /* webpackChunkName: "lang-nl.js" */),
  'pl.js': () => import('../../locales/pl.js' /* webpackChunkName: "lang-pl.js" */),
  'tr.js': () => import('../../locales/tr.js' /* webpackChunkName: "lang-tr.js" */),
  'cs.js': () => import('../../locales/cs.js' /* webpackChunkName: "lang-cs.js" */),
  'sv.js': () => import('../../locales/sv.js' /* webpackChunkName: "lang-sv.js" */),
  'nb.js': () => import('../../locales/nb.js' /* webpackChunkName: "lang-nb.js" */),
  'ro.js': () => import('../../locales/ro.js' /* webpackChunkName: "lang-ro.js" */),
  'el.js': () => import('../../locales/el.js' /* webpackChunkName: "lang-el.js" */),
  'da.js': () => import('../../locales/da.js' /* webpackChunkName: "lang-da.js" */),
  'uk.js': () => import('../../locales/uk.js' /* webpackChunkName: "lang-uk.js" */),
  'hr.js': () => import('../../locales/hr.js' /* webpackChunkName: "lang-hr.js" */),
  'ca.js': () => import('../../locales/ca.js' /* webpackChunkName: "lang-ca.js" */),
  'hu.js': () => import('../../locales/hu.js' /* webpackChunkName: "lang-hu.js" */),
  'sk.js': () => import('../../locales/sk.js' /* webpackChunkName: "lang-sk.js" */),
  'sl.js': () => import('../../locales/sl.js' /* webpackChunkName: "lang-sl.js" */),
  'fi.js': () => import('../../locales/fi.js' /* webpackChunkName: "lang-fi.js" */),
  'bg.js': () => import('../../locales/bg.js' /* webpackChunkName: "lang-bg.js" */),
  'sr.js': () => import('../../locales/sr.js' /* webpackChunkName: "lang-sr.js" */),
}
